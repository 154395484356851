import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./en.json";
import hiTranslation from "./hi.json";
import teTranslation from "./te.json";
// The translations
const resources = {
  en: {
    translation: enTranslation,
  },
  hi: {
    translation: hiTranslation,
  },
  te: {
    translation: teTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  fallbackLng: "en", // Fallback language if a translation is missing
  debug: true, // Enable logging for development
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // React already escapes variables to prevent XSS
  },
});

export default i18n;
