import React from "react";

const Unpin = (props: any) => {
  let { index, onClick, name }: any = props;
  return (
    <svg
      className={name === "view" ? "ViewPin" : "pinUnpin"}
      onClick={() =>
        onClick({
          index: index,
          pin: false,
        })
      }
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={26} height={26} rx={4} fill="white" />
      <path
        d="M7.74998 16.0254L18.026 16.0254L18.026 14.3521C18.0259 12.5572 16.5708 11.1021 14.7759 11.1021L10.9999 11.1021C9.20494 11.1021 7.7499 12.5572 7.74994 14.3521L7.74998 16.0254Z"
        stroke="#404B3B"
        strokeWidth="1.5"
      />
      <rect
        x="0.749982"
        y="-0.75"
        width="3.85275"
        height="4.9233"
        transform="matrix(1 3.19191e-09 -2.45327e-05 -1 10.2109 9.92285)"
        stroke="#404B3B"
        strokeWidth="1.5"
      />
      <path
        d="M12.8877 16.2432L12.8878 21.5959"
        stroke="#404B3B"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7 5L18.776 5"
        stroke="#404B3B"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect
        width="2.13669"
        height="19.1881"
        rx="1.06834"
        transform="matrix(0.706921 -0.707292 -0.707352 -0.706861 18.5732 21.0742)"
        fill="white"
      />
      <path
        d="M6 8L18 20"
        stroke="#404B3B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Unpin;
