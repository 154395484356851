import React from "react";
import authenticationImage from "../assets/images/Group 1000004664.png";
import HastakalaParmank from "../assets/images/kvkp.png";
import { Button } from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";

const AuthenticatePage = (props: any) => {
  const location: any = useLocation();
  const history: any = useHistory();
  const ProductId: any = location?.pathname.split("/")[2];
  return (
    <div className="authentication">
      <img
        src={authenticationImage}
        alt="athenticationImage"
        className="authImag"
      />
      <img
        className="hastakalaParmank"
        alt="Hastakala Parmank"
        src={HastakalaParmank}
      />
      <div className="SC1Text">
        <h3>
          Certificate of <br /> Authenticity
        </h3>
        <div className="AuthDiv">
          <h4>Authenticate yourself to view the Certificate</h4>
          <Button
            onClick={() => {
              history.push(`/user-deatails/${ProductId}`);
            }}
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatePage;
