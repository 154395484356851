import React from 'react'

const TypeIcon = () => {
  return (
    <svg
    width="25"
    height="25"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.51519 12.2081C8.51519 11.634 8.51519 11.0644 8.51519 10.4903C8.51519 9.81513 8.1755 9.47524 7.50071 9.47524C6.92691 9.47524 6.35311 9.47524 5.7839 9.47524C4.23235 9.47524 2.62571 9.47524 1.0512 9.47065C1.04661 9.47065 1.04661 9.47065 1.04202 9.47065C0.509534 9.47065 0.169845 9.69112 0.0091808 10.1412L0 10.1688V17.2927L0.0091808 17.3202C0.11476 17.6555 0.339689 17.8806 0.679379 17.9908L0.706921 18H7.82663L7.85876 17.9908C8.30862 17.8255 8.52896 17.4856 8.52437 16.9482C8.51519 15.3682 8.51519 13.7606 8.51519 12.2081ZM1.75812 16.2409V11.2298H6.76166V16.2409H1.75812Z"
      fill="#C59C6C"
    />
    <path
      d="M17.9481 13.1038C17.5671 10.7429 15.428 9.17667 13.0731 9.53493C12.1688 9.67273 11.3104 10.1228 10.654 10.8072C9.99757 11.4916 9.58903 12.3597 9.5064 13.2599C9.3136 15.2625 10.4612 17.1043 12.2974 17.7428C12.5315 17.8254 12.7748 17.876 13.0089 17.9265C13.1145 17.9495 13.22 17.9724 13.3256 17.9954L13.3486 18H14.1106L14.1289 17.9954C14.1657 17.9862 14.2024 17.9816 14.2437 17.9724C14.3217 17.9586 14.3998 17.9403 14.4824 17.9311C16.7179 17.5545 18.3108 15.3452 17.9481 13.1038ZM16.2497 13.7468C16.2497 15.1293 15.1205 16.2546 13.7342 16.2546C13.7342 16.2546 13.7342 16.2546 13.7296 16.2546C12.3433 16.2546 11.2186 15.1247 11.2186 13.7422C11.2186 12.3551 12.3433 11.2252 13.7296 11.2252H13.7342C14.4044 11.2252 15.0332 11.487 15.506 11.9647C15.988 12.4378 16.2497 13.0716 16.2497 13.7468Z"
      fill="#C59C6C"
    />
    <path
      d="M10.6819 8.51528C11.4302 8.51528 12.1922 8.51528 12.9404 8.51528C13.1102 8.51528 13.2388 8.49691 13.3535 8.46017C13.6014 8.37749 13.785 8.19836 13.8768 7.95952C13.9732 7.70231 13.9365 7.41294 13.7896 7.16033C13.4086 6.51729 13.0184 5.86967 12.642 5.24042C12.4905 4.9878 12.3391 4.73977 12.1922 4.48715L11.7377 3.73389C11.0951 2.6637 10.4524 1.59351 9.80974 0.523321C9.74547 0.41768 9.67662 0.312039 9.58022 0.22477C9.38742 0.0502332 9.14413 -0.0278492 8.89166 0.00889545C8.62542 0.0456401 8.38213 0.215584 8.23064 0.468204C7.83587 1.12501 7.4365 1.79101 7.04632 2.43864C6.87189 2.73259 6.69745 3.02196 6.51842 3.31591L6.10988 4.00028C5.48558 5.04291 4.86129 6.08095 4.237 7.12358C4.12683 7.30271 4.01207 7.55074 4.0901 7.84929C4.19568 8.28104 4.53996 8.52906 5.02654 8.52906C5.98135 8.52906 6.93615 8.52906 7.89554 8.52906H9.00183H9.80974C10.0943 8.51528 10.3881 8.51528 10.6819 8.51528ZM11.5128 6.76073H6.4817L8.99724 2.56265L11.5128 6.76073Z"
      fill="#C59C6C"
    />
  </svg>
  )
}

export default TypeIcon