import React from "react";

const CategoryIcon = () => {
  return (
    <svg
      className=""
      width="25"
      height="25"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.38435C0.0733346 1.07146 0.171114 0.768341 0.400896 0.523892C0.831126 0.0692174 1.35914 -0.101897 1.96048 0.0594395C2.59116 0.225665 2.98227 0.655894 3.09472 1.30613C3.20717 1.95636 2.9725 2.48926 2.42982 2.85593C1.89692 3.21772 1.32002 3.22261 0.757791 2.89993C0.356895 2.66526 0.122224 2.30837 0.0293339 1.85858C0.0195559 1.81947 0.00977795 1.78036 0 1.74125C0 1.61902 0 1.50169 0 1.38435Z"
        fill="#C59C6C"
      />
      <path
        d="M0 10.8054C0.0684457 10.4974 0.171114 10.2041 0.386229 9.95473C0.806681 9.46094 1.56447 9.28005 2.17559 9.53916C2.80627 9.80806 3.20228 10.4876 3.11428 11.1476C3.02139 11.8565 2.49338 12.4188 1.82848 12.5165C1.1098 12.6241 0.454675 12.277 0.146669 11.617C0.0782236 11.4703 0.0488898 11.309 0 11.1525C0 11.0401 0 10.9227 0 10.8054Z"
        fill="#C59C6C"
      />
      <path
        d="M0 6.09732C0.0733346 5.77954 0.180892 5.4862 0.396007 5.23686C0.81157 4.75286 1.56936 4.57685 2.17559 4.83597C2.79649 5.10486 3.19739 5.78443 3.11428 6.42977C3.03605 7.04579 2.71338 7.49068 2.14137 7.71558C1.54003 7.95025 0.977795 7.8378 0.498675 7.40757C0.244449 7.18268 0.0977795 6.89423 0.0293339 6.56178C0.0195559 6.52267 0.00977795 6.48355 0 6.44444C0 6.332 0 6.21466 0 6.09732Z"
        fill="#C59C6C"
      />
      <path
        d="M11.2495 7.44668C9.44055 7.44668 7.63163 7.44668 5.81782 7.44668C5.3827 7.44668 5.00625 7.32935 4.74224 6.96267C4.47824 6.60089 4.43424 6.20488 4.61024 5.7991C4.79113 5.38842 5.1138 5.14886 5.56848 5.10486C5.65648 5.09508 5.74448 5.09019 5.83248 5.09019C9.44544 5.09019 13.0584 5.09019 16.6762 5.09019C17.1114 5.09019 17.4878 5.20264 17.7518 5.56443C18.0158 5.92132 18.0696 6.31733 17.8936 6.728C17.7176 7.13868 17.3949 7.38312 16.9402 7.43201C16.8229 7.44668 16.7056 7.44668 16.5882 7.44668C14.8135 7.44668 13.0291 7.44668 11.2495 7.44668Z"
        fill="#C59C6C"
      />
      <path
        d="M11.2643 0.387005C13.0781 0.387005 14.8968 0.387005 16.7106 0.387005C17.2239 0.387005 17.6199 0.572786 17.8644 1.03724C18.2408 1.75592 17.7519 2.64571 16.9404 2.72394C16.8475 2.73371 16.7546 2.7386 16.6617 2.7386C13.0536 2.7386 9.44556 2.7386 5.83749 2.7386C5.39749 2.7386 5.01615 2.62616 4.74725 2.25459C4.48325 1.88792 4.43925 1.48214 4.62503 1.07635C4.81081 0.665677 5.13837 0.431006 5.59304 0.396783C5.78372 0.382116 5.97927 0.387005 6.16994 0.387005C7.86642 0.387005 9.56778 0.387005 11.2643 0.387005Z"
        fill="#C59C6C"
      />
      <path
        d="M11.2494 12.1548C9.43561 12.1548 7.61691 12.1499 5.8031 12.1548C5.28976 12.1548 4.88887 11.9739 4.64442 11.5094C4.25819 10.7859 4.75197 9.88628 5.57332 9.81295C5.66132 9.80317 5.74933 9.79828 5.83733 9.79828C9.45028 9.79828 13.0632 9.79828 16.6762 9.79828C17.1113 9.79828 17.4878 9.91073 17.7566 10.2725C18.0255 10.6343 18.0744 11.0401 17.8887 11.4508C17.7029 11.8614 17.3802 12.0961 16.9255 12.1401C16.7642 12.1548 16.598 12.1499 16.4317 12.1499C14.7059 12.1548 12.9801 12.1548 11.2494 12.1548Z"
        fill="#C59C6C"
      />
    </svg>
  );
};

export default CategoryIcon;
