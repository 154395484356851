import React from "react";

const HomeIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.625 11.25V26.25H24.375V11.25L15 3.75L5.625 11.25Z"
        fill="#C59C6C"
      />
      <path
        d="M5.625 26.25V11.25L2.5 13.75L15 3.75L27.5 13.75L24.375 11.25V26.25H5.625Z"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 18.125V26.25H18.125V18.125H11.875Z"
        fill="#C59C6C"
        stroke="white"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M5.625 26.25H24.375"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HomeIcon;
