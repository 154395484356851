import React from "react";
import ViewEyeIcon from "../../../common/Components/Icons/ViewEyeIcon";
import ManIcon from "../../../common/Components/Icons/ManIcon";
import PhotoIcon from "../../../common/Components/Icons/PhotoIcon";
import ProductPreviewImage from "../ProductPreviewImage";
import { useTranslation } from "react-i18next";

const CommonCard = (props: any) => {
  let { media, karigar, name, photoProcess, karigarBy, uploadedBy, svg } =
    props;
  const { t } = useTranslation();

  return (
    <div className="rightCard">
      <div className="rightToolTipCard">
        <span className="TriangleIcon">{svg}</span>
        <div className="rightToolTipSubCard">
          <div>{name}</div>
          {/* <span className="line"></span>
                        <hr className="hrLine"></hr>
                        <div>{form?.designingDate}</div> */}
        </div>
      </div>
      <div className="subDiv">
        <ManIcon />
        <span className="SmallHeading">{karigarBy}:</span>
        <span className="SmallText">{karigar}</span>
      </div>
      <div className="subDiv">
        <PhotoIcon />
        <span className="SmallHeading">{photoProcess}</span>
      </div>
      <div className="subDiv1">
        <div className="imagePos">
          <div className="Number">{media?.length}</div>
          {media?.length ? (
            <div
              onClick={() => {
                props.handleOpen({
                  name,
                });
              }}
            >
              <ProductPreviewImage {...props} fileUrl={media[0]} />
            </div>
          ) : (
            <div className="NoUploadImg">{t("No_images_uploaded")}</div>
          )}
        </div>
        <div className="subDiv12">
          <div>
            {/* <div className="dGridM">
                            <span className="SmallHeading">
                              {t("Upload_Date")}:
                            </span>
                            <span className="SmallText">
                              {form?.designingDate !== ""
                                ? form?.designingDate.split("-")[1]
                                : " "}{" "}
                            
                            </span>
                          </div> */}
            <div className="dGridM">
              <span className="SmallHeading">{t("Uploaded_by")}: </span>
              <span className="SmallText">{uploadedBy}</span>
            </div>
          </div>
          <div className="ViewIcon">
            <ViewEyeIcon
              {...props}
              onClick={() => {
                props.handleOpen({
                  name,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonCard;
