import {types } from "./actionTypes";

// creating action
export const getCategory = (filters:any, callback:any) =>{
    return {
        type:types.GET_CATEGORY,
        filters,
        callback
    }
}
export const getCategoryFilter = (filters:any, callback:any) =>{
    return {
        type:types.GET_CATEGORY_FILTERS,
        filters,
        callback
    }
}

export const createCategory = (payload:any, callback:any) =>{
    return {
        type:types.CREATE_CATEGORY,
        payload,
        callback
    }
}
export const updateCategory = (payload:any, callback:any) =>{
    return {
        type:types.UPDATE_CATEGORY,
        payload,
        callback
    }
}
export const deleteCategory = (payload:any, callback:any) =>{
    return {
        type:types.DELETE_CATEGORY,
        payload,
        callback
    }
}