import React, { useState, useEffect } from "react";
import { getProductByIdProductCertification } from "../../Store/products/actions";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import ProductCertificationForDesignAgency from "./certification-page/ProductCertificationForDesignAgency";
const ProductCertificationTantuvi = (props: any) => {
  const [Loading, setLoading]: any = useState(false);
  const location: any = useLocation();
  const ProductId: any = location?.pathname.split("/")[2];
  useEffect(() => {
    if (ProductId) {
      getAllData();
    }
  }, [ProductId]);
  const getAllData = () => {
    setLoading(true);
    props.getProductByIdProductCertification(
      {
        offset: 0,
        limit: 10,
        ProductId: ProductId,
        // ProductsName: ProductName,
      },
      (res: any) => {
        setLoading(false);
      }
    );
  };
  let editDetails: any;
  if (props.ViewProductDetails !== undefined) {
    editDetails = props.ViewProductDetails?.data?.docs[0];
  }
  return (
    <>
      <ProductCertificationForDesignAgency
        {...props}
        editDetails={editDetails}
        Loading={Loading}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    ViewProductDetails: state?.productReducer?.productCertificationDetails,
  };
};
export default connect(mapStateToProps, {
  getProductByIdProductCertification,
})(ProductCertificationTantuvi);
