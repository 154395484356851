import React from "react";

const ShowIcon = (props: any) => {
  const { index, name, onClick, pin } = props;
  return (
    <>
      <svg
        onClick={() =>
          onClick({
            name,
            index,
            show: false,
            pin,
          })
        }
        className="showOrHide"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="26" height="26" rx="4" fill="white" />
        <path
          d="M5.5 13C5.5 13 8.22705 7.5459 12.9994 7.5459C17.7717 7.5459 20.4988 13 20.4988 13C20.4988 13 17.7717 18.4541 12.9994 18.4541C8.22705 18.4541 5.5 13 5.5 13Z"
          stroke="#404B3B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9994 15.0457C14.129 15.0457 15.0447 14.1299 15.0447 13.0004C15.0447 11.8708 14.129 10.9551 12.9994 10.9551C11.8698 10.9551 10.9541 11.8708 10.9541 13.0004C10.9541 14.1299 11.8698 15.0457 12.9994 15.0457Z"
          stroke="#404B3B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ShowIcon;
