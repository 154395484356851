import React, { useState, useEffect } from "react";
import { Label, Input, Button, Row, Col } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";

//components
import { debounce } from "../../common/commonFiles/debounce";
import Loader from "../../common/Components/Loader/Loader";
import notifications from "../../common/Components/Notifications/Notification";
// actions
import { getArtforms, getTypes, getCategory } from "../../Store/actions";
import {
  createWeaveType,
  updateWeaveType,
  getWeaveTypeFilter,
} from "../../Store/actions";
import { getSubCategorys } from "../../Store/actions";
let weaveTypeOptions: any = [];
let artformOptions: any = [];
let typeOptions: any = [];
let categoryOptions: any = [];
var formDetails: any = [];

const AddOrEditWeaveTypeForm = (props: any) => {
  const [loading, setLoading]: any = React.useState(false);
  const [filter, setFilter]: any = useState(false);
  const [filterName, setFilterName]: any = useState({
    artform: "",
    type: "",
    category: "",
    subCategory: "",
  });
  const [filterValue, setFilterValue]: any = useState("");
  const [form, setForm]: any = React.useState(
    props.editData?._id
      ? {
          artform: {
            label: props?.editData?.artform?.name,
            value: props.editData?.artform?._id,
          },
          type: {
            label: props.editData?.type?.name,
            value: props.editData?.type?._id,
          },
          category: {
            label: props.editData?.category?.name,
            value: props.editData?.category?._id,
          },
          subCategory: {
            label: props.editData?.category?.name,
            value: props.editData?.category?._id,
          },
          weaveType: props.editData?.name,
        }
      : props?.form?.subCategory
      ? {
          artform: {
            label: props?.form?.artform?.label,
            value: props?.form?.artform?.value,
          },
          type: {
            label: props?.form?.type?.label,
            value: props?.form?.type?.value,
          },
          category: {
            label: props.form?.category?.label,
            value: props.form?.category?.value,
          },
          subCategory: {
            label: props.form?.subCategory?.label,
            value: props.form?.subCategory?.value,
          },
          weaveType: "",
        }
      : {
          artform: "",
          type: "",
          category: "",
          subCategory: "",
          weaveType: "",
        }
  );
  const [Error, setError]: any = React.useState({
    subCategory: "",
    weaveType: "",
    artform: "",
    type: "",
    category: "",
  });
  useEffect(() => {
    if (
      form?.type?.value &&
      form?.category?.value &&
      form?.artform?.value &&
      !form?.subCategory?.value
    ) {
      getSubCategoryData();
    }
  }, [form]);
  useEffect(() => {
    if (form?.type?.value && !form?.category?.value) {
      getCategoryData();
    }
  }, [form]);
  useEffect(() => {
    getArtformData();
  }, []);
  useEffect(() => {
    if (form?.artform?.value && !form?.type?.value && filter) {
      getTypesData();
    }
  }, [form]);
  useEffect(() => {
    if (filterName?.artform) {
      getArtformData();
    }
    if (filterName?.type) {
      getTypesData();
    }
    if (filterName?.category) {
      getCategoryData();
    }
    if (filterName?.subCategory) {
      getSubCategoryData();
    }
  }, [filterName]);
  const getTypesData = (data?: any) => {
    props.getTypes(
      {
        offset: 0,
        limit: 10,
        typeName: filterName?.type || data?.value,
        artFormId: form?.artform?.value || data?.artform,
      },
      (res: any) => {
        typeOptions = [];
        res?.data?.docs.map((ele: any) => {
          typeOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getArtformData = (data?: any) => {
    props.getArtforms(
      {
        offset: 0,
        limit: 10,
        artformName: filterName?.artform || data?.value,
      },
      (res: any) => {
        artformOptions = [];
        res?.data?.docs.map((ele: any) => {
          artformOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getCategoryData = (data?: any) => {
    props.getCategory(
      {
        offset: 0,
        limit: 10,
        typeId: form?.type?.value || data?.type,
        categoryName: filterName?.category || data?.value,
        artformId: form?.artform?.value || data?.artform,
        categoryId: form?.category?.value || data?.category,
      },
      (res: any) => {
        categoryOptions = [];
        res?.data?.docs.map((ele: any) => {
          categoryOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getSubCategoryData = (data?: any) => {
    props.getSubCategorys(
      {
        offset: 0,
        limit: 10,
        artformId: form?.artform?.value || data?.artform,
        typeId: form?.type?.value || data?.type,
        categoryId: form?.category?.value || data?.category,
        // subCategoryId: form?.subCategory?.value || data?.subCategory,
        subCategoryName: data?.value,
      },
      (res: any) => {
        weaveTypeOptions = [];
        res?.data?.docs.map((ele: any) => {
          weaveTypeOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const validate = () => {
    let error = "";
    let err = Error;
    if (form?.artform === "") {
      error = "Required";
      err = {
        ...err,
        artform: "ArtForm is Required!",
      };
    }
    if (form?.type === "") {
      error = "Required";
      err = {
        ...err,
        type: "Type is Required!",
      };
    }
    if (form?.category === "") {
      error = "Required";
      err = {
        ...err,
        category: "Category is Required!",
      };
    }
    if (form?.subCategory === "") {
      error = "Required";
      err = {
        ...err,
        subCategory: "Sub-Category Name is Required!",
      };
    }
    if (form?.weaveType === "") {
      error = "Required";
      err = {
        ...err,
        weaveType: "Weave Type is Required!",
      };
    }
    setError(err);

    return error;
  };
  const handleSubmitChange = (type: any) => {
    if (type?.name === "artform") {
      setForm({
        ...form,
        type: "",
        category: "",
        subCategory: "",
        artform: type?.value,
      });
      formDetails = {
        ...formDetails,
        type: "",
        category: "",
        subCategory: "",
        artform: type?.value,
      };
    } else {
      setForm({ ...form, [type.name]: type?.value });
      formDetails = {
        ...formDetails,
        [type.name]: type?.value,
      };
    }
  };
  const handleSubmit = () => {
    let result: any = validate();
    if (result?.length === 0) {
      setLoading(true);
      props?.editData?._id
        ? props.updateWeaveType(
            {
              weaveTypeId: props?.editData?._id,
              subCategoryId: form?.subCategory?.value,
              name: form?.weaveType,
            },
            (res: any) => {
              setLoading(false);
              if (res.success) {
                notifications({
                  title: "Success!",
                  message: res?.message,
                  type: "success",
                });
                props.closeModal();
                props.getAllData();
              } else {
                notifications({
                  title: "Error!",
                  message: res?.message,
                  type: "danger",
                });
              }
            }
          )
        : props.createWeaveType(
            {
              name: form?.weaveType,
              subCategoryId: form?.subCategory?.value,
            },
            (res: any) => {
              setLoading(false);
              if (res.success) {
                notifications({
                  title: "Success!",
                  message: res?.message,
                  type: "success",
                });
                props.closeModal();
                props.getAllData();
                props.handleName({
                  name: "weaveType",
                  subCategory: form?.subCategory?.value,
                  weaveType: form?.weaveType,
                });
              } else {
                setLoading(false);
                notifications({
                  title: "Error!",
                  message: res?.message,
                  type: "danger",
                });
              }
            }
          );
    }
  };
  const handleOnInputChange = (type: any) => {
    setFilter(true);
    if (type?.name === "artform") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
      if (
        type?.value.length === 0 &&
        !form?.artform?.value &&
        !form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "type") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.artform?.value &&
        !form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
        });
      }
    }
    if (type?.name === "category") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
        });
      }
    }
    if (type?.name === "subCategory") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
          category: form?.category?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
          category: form?.category?.value,
        });
      }
    }
    // let filterMatserData = filterName;
    // if (type?.name === "artform") {
    //   if (type?.value?.length >= 2) {
    //     filterMatserData = {
    //       ...filterMatserData,
    //       artform: type?.value,
    //     };
    //   }
    //   if (type?.value.length === 0) {
    //     filterMatserData = {
    //       ...filterMatserData,
    //       artform: type?.value,
    //     };
    //   }
    // }
    // if (type?.name === "type") {
    //   if (type?.value?.length >= 2) {
    //     filterMatserData = {
    //       ...filterMatserData,
    //       type: type?.value,
    //     };
    //   } else if (type?.value.length === 0) {
    //     filterMatserData = {
    //       ...filterMatserData,
    //       type: type?.value,
    //     };
    //   }
    // }
    // if (type?.name === "category") {
    //   if (type?.value?.length >= 2) {
    //     filterMatserData = {
    //       ...filterMatserData,
    //       category: type?.value,
    //     };
    //   } else if (type?.value.length === 0) {
    //     filterMatserData = {
    //       ...filterMatserData,
    //       category: type?.value,
    //     };
    //   }
    // }
    // if (type?.name === "subCategory") {
    //   if (type?.value?.length >= 2) {
    //     filterMatserData = {
    //       ...filterMatserData,
    //       subCategory: type?.value,
    //     };
    //   } else if (type?.value.length === 0) {
    //     filterMatserData = {
    //       ...filterMatserData,
    //       subCategory: type?.value,
    //     };
    //   }
    // }
    // if (type?.name === "weaveType") {
    //   if (type?.value?.length >= 2) {
    //     filterMatserData = {
    //       ...filterMatserData,
    //       weaveType: type?.value,
    //     };
    //   } else if (type?.value.length === 0) {
    //     filterMatserData = {
    //       ...filterMatserData,
    //       weaveType: type?.value,
    //     };
    //   }
    // }
    // setFilterName(filterMatserData);
  };
  const callingGetWeaveType = (data: any) => {
    props.getWeaveTypeFilter(
      {
        offset: 0,
        limit: 10,
        artformId: formDetails?.artform?.value || data?.artform,
        typeId: formDetails?.type?.value || form?.type,
        categoryId: formDetails?.category?.value || form?.category,
        subCategoryId: formDetails?.subCategory?.value || data?.subCategory,
        weaveTypeName: data?.value,
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          res.data?.docs.map((ele: any, i: any) => {
            if (data?.value === ele?.name) {
              setFilterValue(ele?.name);
            }
          });
        }
      }
    );
  };
  const handleChangeDebounce = React.useCallback(
    debounce((data: any) => {
      if (data?.name === "weaveType") {
        callingGetWeaveType(data);
      }
      if (data?.name === "subCategory") {
        getSubCategoryData(data);
      }
      if (data?.name === "category") {
        getCategoryData(data);
      }
      if (data?.name === "artform") {
        getArtformData(data);
      }
      if (data?.name === "type") {
        getTypesData(data);
      }
    }, 1000),
    []
  );
  return (
    <>
      <div className="AddWeaveTypeForm">
        <div className="form">
          {loading ? (
            <div className="LoaderDivModal">
              <Loader />
            </div>
          ) : (
            <form>
              <Row className="RowForm">
                <Col xs={12} md={6}>
                  <div className="formDiv">
                    <Label>
                      Artform<span>*</span>
                    </Label>

                    <Select
                      className="React_selector text-white"
                      classNamePrefix="react-select"
                      placeholder="Select Artform"
                      // isSearchable
                      name="artform"
                      id="artform"
                      value={form?.artform}
                      options={artformOptions}
                      onChange={(e: any) => {
                        handleSubmitChange({
                          name: "artform",
                          value: e,
                        });
                      }}
                      onInputChange={(e) => {
                        handleOnInputChange({
                          name: "artform",
                          value: e,
                        });
                      }}
                    />
                    {Error && !form?.artform ? (
                      <p className="RequiredField">{Error?.artform} </p>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="formDiv">
                    <Label>
                      Type<span>*</span>
                    </Label>

                    <Select
                      className="React_selector text-white"
                      classNamePrefix="react-select"
                      placeholder="Select Type"
                      // isSearchable
                      name="Type"
                      id="Type"
                      value={form?.type}
                      options={
                        form?.artform?.value
                          ? typeOptions
                          : [
                              {
                                label: (
                                  <p className="req">
                                    ***Please select Artform***
                                  </p>
                                ),
                                value: "",
                              },
                            ]
                      }
                      onChange={(e: any) => {
                        handleSubmitChange({
                          name: "type",
                          value: e,
                        });
                      }}
                      onInputChange={(e) =>
                        handleOnInputChange({
                          name: "type",
                          value: e,
                        })
                      }
                    />
                    {Error.type && !form?.type ? (
                      <p className="RequiredField">{Error?.type} </p>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="RowForm1">
                <Col xs={12} md={6}>
                  <div className="formDiv">
                    <Label>
                      Category<span>*</span>
                    </Label>

                    <Select
                      className="React_selector text-white"
                      classNamePrefix="react-select"
                      placeholder="Select Category"
                      // isSearchable
                      name="Category"
                      id="Category"
                      value={form?.category}
                      options={
                        form?.type?.value
                          ? categoryOptions
                          : [
                              {
                                label: (
                                  <p className="req">
                                    ***Please select Type***
                                  </p>
                                ),
                                value: "",
                              },
                            ]
                      }
                      onChange={(e: any) => {
                        handleSubmitChange({
                          name: "category",
                          value: e,
                        });
                      }}
                      onInputChange={(e) =>
                        handleOnInputChange({
                          name: "category",
                          value: e,
                        })
                      }
                    />
                    {Error.category && !form?.category ? (
                      <p className="RequiredField">{Error?.category} </p>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="formDiv">
                    <Label>
                      Sub-Category<span>*</span>
                    </Label>

                    <Select
                      className="React_selector text-white"
                      classNamePrefix="react-select"
                      placeholder="Select Sub-Category"
                      // isSearchable
                      name="SubCategory"
                      id="SubCategory"
                      value={form?.subCategory}
                      options={
                        form?.category?.value
                          ? weaveTypeOptions
                          : [
                              {
                                label: (
                                  <p className="req">
                                    ***Please select Category***
                                  </p>
                                ),
                                value: "",
                              },
                            ]
                      }
                      onChange={(e: any) => {
                        handleSubmitChange({
                          name: "subCategory",
                          value: e,
                        });
                      }}
                      onInputChange={(e) =>
                        handleOnInputChange({
                          name: "subCategory",
                          value: e,
                        })
                      }
                    />
                    {Error.subCategory && !form?.subCategory ? (
                      <p className="RequiredField">{Error?.subCategory} </p>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>

              <div className="formDiv">
                <Label>
                  Weave Type<span>*</span>
                </Label>
                <Input
                  name="weaveType"
                  id="weaveType"
                  className="form-control"
                  placeholder="Type Name"
                  value={form?.weaveType}
                  onChange={(e: any) => {
                    handleSubmitChange({
                      name: "weaveType",
                      value: e.target.value,
                    });
                    handleChangeDebounce({
                      name: "weaveType",
                      artform: form?.artform?.value,
                      type: form?.type?.value,
                      category: form?.category?.value,
                      subCategory: form?.subCategory?.value,
                      value: e.target.value,
                    });
                  }}
                />
                {Error.weaveType && !form?.weaveType ? (
                  <p className="RequiredField">{Error?.weaveType} </p>
                ) : (
                  ""
                )}
                {form?.weaveType && form?.weaveType === filterValue ? (
                  // filterValue?.product === ""
                  <p className="RequiredField">
                    Sub-Category is already exist's
                  </p>
                ) : (
                  ""
                )}
              </div>
              <Button
                className="artButton"
                type="button"
                onClick={() => handleSubmit()}
              >
                {loading ? "Processing" : "Save"}
              </Button>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  createWeaveType,
  getSubCategorys,
  updateWeaveType,
  getCategory,
  getArtforms,
  getTypes,
  getWeaveTypeFilter,
})(AddOrEditWeaveTypeForm);
