import React, { useEffect } from "react";
import HkvBadge from "../assets/images/HKP Badge.png";
import verify from "../assets/gif/verify.gif";
import { useLocation, useHistory } from "react-router-dom";
import { sixHoursExpiryMilliSec } from "../DateTime";

const VerifyingProduct = (props: any) => {
  let productVerficationCode =
    localStorage.getItem("productVerificationToken") || "";
  const location: any = useLocation();
  const history: any = useHistory();
  const ProductId: any = location?.pathname.split("/")[2];

  useEffect(() => {
    setTimeout(() => {
      if (productVerficationCode?.length > 0) {
        history.push(
          `/product-certification-new/${sixHoursExpiryMilliSec}/${ProductId}`
        );
      } else {
        history.push(`/user-deatails/${sixHoursExpiryMilliSec}/${ProductId}`);
      }
    }, 1000);
  }, [productVerficationCode]);
  return (
    <div className="productfakeAlert productVerify">
      <div className="productVerifySub">
        <div className="fakealDiv">
          <img src={HkvBadge} alt="Fake Alert" />
        </div>
        <div className="fakealDiv2">
          <h3>
            Supporting the Makers, <br />
            Protecting the Buyers
          </h3>
        </div>

        <div className="gifDiv">
          <img src={verify} alt="verify" className="gif" />
        </div>
      </div>
    </div>
  );
};

export default VerifyingProduct;
