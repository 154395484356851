import { types } from "./actionTypes";
const initialState = {
  loginDetails:null
};
const authLoginReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
        case types.CHECK_LOGIN:
          state = {
            ...state,
            loginDetails: action.payload,
          };
          return state;
    default:
      return state;
  }
};
export default authLoginReducer;
