import React from "react";

const ProductFinishIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30" r="30" fill="#EAD6C0" />
      <path
        d="M18.5683 43C18.1277 42.7969 18 42.4477 18 41.978C18.0128 37.5601 18.0064 33.1484 18.0064 28.7305C18.0064 28.0322 18.2746 27.7656 18.9769 27.7656C19.9028 27.7656 20.835 27.7593 21.7608 27.7656C22.8974 27.772 23.7658 28.4194 24.0531 29.4922C24.117 29.7271 24.1297 29.9746 24.1297 30.2222C24.1361 33.6563 24.117 37.0903 24.1425 40.5244C24.1489 41.8066 23.3252 42.8096 22.1376 42.9746C22.1248 42.9746 22.1056 42.9936 22.0929 43C20.918 43 19.7431 43 18.5683 43ZM21.8439 38.4297C21.4288 38.4233 21.0776 38.7661 21.0713 39.1851C21.0649 39.5977 21.4097 39.9468 21.8311 39.9531C22.2461 39.9595 22.5973 39.6167 22.6037 39.1978C22.6037 38.7852 22.2653 38.436 21.8439 38.4297Z"
        fill="#8C673E"
      />
      <path
        d="M25.6748 40.1879C25.6748 36.5253 25.6748 32.8627 25.6748 29.1874C25.8919 29.1176 26.1154 29.0541 26.3261 28.9716C27.5648 28.4765 28.3949 27.5434 29.0334 26.4262C29.7613 25.144 30.1891 23.7411 30.5722 22.332C30.7063 21.8242 30.9745 21.6337 31.5108 21.621C32.36 21.602 33.088 21.8432 33.6243 22.5287C33.9691 22.9667 34.084 23.4745 34.1032 24.0204C34.167 25.747 33.8286 27.3974 33.2731 29.0224C33.254 29.0859 33.2284 29.1493 33.2157 29.2128C33.2093 29.2255 33.2157 29.2446 33.2157 29.289C33.305 29.289 33.3944 29.289 33.4838 29.289C35.4505 29.289 37.4171 29.289 39.3837 29.289C40.1052 29.289 40.6799 29.6698 40.9034 30.2792C41.2035 31.1108 40.782 31.974 39.9392 32.2533C39.8817 32.2724 39.8307 32.2914 39.7157 32.3295C40.5075 32.5707 40.9991 33.0531 40.9928 33.8593C40.9928 34.6591 40.5203 35.1479 39.7093 35.3764C39.8179 35.4208 39.8754 35.4399 39.9328 35.4653C40.6352 35.7001 41.0758 36.373 40.9864 37.0712C40.8906 37.8139 40.3159 38.3725 39.5561 38.4169C39.1347 38.4423 38.7069 38.4233 38.2408 38.4233C38.4579 38.5312 38.6494 38.5946 38.809 38.7152C39.339 39.1025 39.5625 39.6293 39.422 40.2704C39.2815 40.9115 38.8665 41.3051 38.228 41.4384C38.0747 41.4701 37.9087 41.4765 37.7491 41.4765C35.4951 41.4765 33.2412 41.4701 30.9809 41.4828C30.0614 41.4828 29.1675 41.3559 28.2927 41.0702C27.469 40.8036 26.6453 40.5307 25.828 40.2577C25.7706 40.2387 25.7259 40.2133 25.6748 40.1879Z"
        fill="#8C673E"
      />
      <path
        d="M32.5639 18.5615C32.5639 18.8154 32.5703 19.0693 32.5639 19.3232C32.5512 19.7803 32.2255 20.0977 31.7913 20.0977C31.3699 20.0913 31.0443 19.7803 31.0379 19.3359C31.0251 18.8154 31.0251 18.2886 31.0379 17.7681C31.0443 17.3174 31.3699 17 31.7913 17C32.2191 17 32.5448 17.311 32.5576 17.749C32.5703 18.022 32.5639 18.2886 32.5639 18.5615Z"
        fill="#8C673E"
      />
      <path
        d="M26.1348 19.8438C26.1667 19.5265 26.3008 19.2916 26.5753 19.1519C26.8435 19.0123 27.1436 19.0059 27.3671 19.2154C27.8077 19.6153 28.2291 20.0343 28.625 20.4723C28.874 20.7516 28.8101 21.1768 28.542 21.4434C28.2738 21.7037 27.846 21.7672 27.565 21.5133C27.1245 21.1197 26.7094 20.6881 26.3008 20.2564C26.2114 20.1485 26.1922 19.9771 26.1348 19.8438Z"
        fill="#8C673E"
      />
      <path
        d="M37.4872 19.8374C37.3978 20.0088 37.3339 20.2119 37.2062 20.3579C36.8614 20.7324 36.4975 21.0879 36.1272 21.437C35.8015 21.7417 35.3482 21.7354 35.0481 21.4307C34.7543 21.1387 34.7416 20.6943 35.0353 20.377C35.3992 19.9961 35.7696 19.6279 36.1527 19.2661C36.3889 19.0376 36.6827 18.9995 36.9828 19.1328C37.2765 19.2534 37.4233 19.4946 37.4872 19.8374Z"
        fill="#8C673E"
      />
    </svg>
  );
};

export default ProductFinishIcon;
