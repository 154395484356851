import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, Label } from "reactstrap";
import { connect } from "react-redux";
import { connectOrg, updateParty } from "../../Store/organisation/actions";
import ModalCloseIcon from "../../common/Components/Icons/ModalCloseIcon";
import Loader from "../../common/Components/Loader/Loader";
import notifications from "../../common/Components/Notifications/Notification";
import Select from "react-select";
import { AddIcon } from "../../common/Components/Icons/AddIcon";
import AddOrEditOrganisation from "./AddOrEditOrganisation";
var partyOptions: any = [
  {
    label: "Customer",
    value: "Customer",
  },
  {
    label: "Supplier",
    value: "Supplier",
  },
];
var regexpForGst =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Zz][0-9A-Z]{1}$/;
let gstOptionsData: any = [];
const AddParty = (props: any) => {
  let {
    openModal,
    closeModal,
    handleOnInputChangeFilters,
    gstOptions,
    getAllData,
  } = props;
  gstOptionsData = gstOptions;
  const [loading, setLoading]: any = useState(false);

  const [form, setForm]: any = useState({
    partyName: "",
    partyType: "",
  });
  const [Error, setError]: any = useState({
    partyName: "",
    partyType: "",
  });
  const [openModalOrg, setModalOrg]: any = useState(false);
  useEffect(() => {
    if (props.partyDetails?.id?.length > 0) {
      setForm({
        ...form,
        partyName: {
          label:
            (props.partyDetails?.gst?.length > 0
              ? props.partyDetails?.gst
              : "N/A") +
            "(" +
            props.partyDetails?.name +
            ")" +
            "(" +
            (props.partyDetails?.phone?.length > 0
              ? props.partyDetails?.phone
              : "N/A") +
            ")",
          value: {
            gst: props.partyDetails?.gst,
            phone: props.partyDetails?.phone,
          },
        },
        partyType: {
          label: props.partyDetails?.partyType,
          value: props.partyDetails?.partyType,
        },
      });
    }
  }, [props.partyDetails?.id?.length > 0]);
  const handleChange = (type: any) => {
    setForm({
      ...form,
      [type?.name]: type.value,
    });
  };
  const validate = () => {
    let error = "";
    let err = Error;
    if (form?.partyName === "") {
      error = "Required";
      err = {
        ...err,
        partyName: "Party Name is Required!",
      };
    }
    if (form?.partyType === "") {
      error = "Required";
      err = {
        ...err,
        partyType: "Party Type is Required!",
      };
    }
    setError(err);
    return error;
  };
  const handleSubmit = () => {
    let result: any = validate();
    if (result?.length === 0) {
      if (props.partyDetails?.id?.length > 0) {
        let payload = {
          id: props.partyDetails?.id,
          gst:
            form?.partyName?.value?.gst?.length > 0
              ? form?.partyName?.value?.gst?.toLowerCase()
              : "",
          phoneNumber:
            form?.partyName?.value?.phone?.length > 0
              ? ""
              : form?.partyName?.value?.phone,
          partyType: form?.partyType?.value,
        };
        props.updateParty(payload, (res: any) => {
          getAllData();
          if (res.success) {
            notifications({
              title: "Success!",
              message: res?.message,
              type: "success",
            });
            gstOptions = [];
            setForm({
              partyName: "",
              partyType: "",
            });
            closeModal();
          } else {
            notifications({
              title: "Error!",
              message: res?.message,
              type: "danger",
            });
          }
        });
      } else {
        let payload = {
          gst:
            form?.partyName?.value?.gst?.length > 0
              ? form?.partyName?.value?.gst?.toLowerCase()
              : "",
          phoneNumber:
            form?.partyName?.value?.gst?.length > 0
              ? ""
              : form?.partyName?.value?.phone,
          partyType: form?.partyType?.value,
        };
        props.connectOrg(payload, (res: any) => {
          getAllData();
          if (res.success) {
            notifications({
              title: "Success!",
              message: res?.message,
              type: "success",
            });
            gstOptions = [];
            setForm({
              partyName: "",
              partyType: "",
            });
            closeModal();
          } else {
            notifications({
              title: "Error!",
              message: res?.message,
              type: "danger",
            });
          }
        });
      }
    }
  };
  const handleCloseModal = () => {
    closeModal();
    setForm({ partyName: "", partyType: "" });
  };
  const handleOpenOrg = () => {
    setModalOrg(true);
  };
  const handleGstOptions = (data: any) => {
    setForm({
      ...form,
      partyName: data,
    });
  };
  const handleCloseOrg = () => {
    setModalOrg(false);
  };
  return (
    <>
      <Modal
        isOpen={openModalOrg}
        toggle={handleCloseOrg}
        size="lg"
        className="OrganizationModal"
        fade={false}
      >
        <ModalHeader>
          <div className="Heading">
            <h3>{"Add New Organization"}</h3>
            <svg
              onClick={() => handleCloseOrg()}
              width="20"
              height="20"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L1 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1L8 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalHeader>
        <ModalBody>
          <AddOrEditOrganisation
            {...props}
            closeModal={handleCloseOrg}
            // editData={editData}
            getAllData={getAllData}
            name="addParty"
            handleGstOptions={handleGstOptions}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openModal}
        toggle={closeModal}
        size="lg"
        className="ArtFormModal partyModal"
        fade={false}
      >
        <ModalHeader>
          <div className="Heading">
            <h3>Add Party</h3>
            <ModalCloseIcon {...props} closeModal={handleCloseModal} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="AddArtform">
            <div className="form">
              {loading ? (
                <div className="LoaderDivModal">
                  <Loader />
                </div>
              ) : (
                <>
                  <div className="formDiv">
                    <Label>
                      Party Type<span>*</span>
                    </Label>
                    <div className="formDivSub">
                      <Select
                        className="selector_selector text-white w-100"
                        classNamePrefix="react-select"
                        placeholder="Filter with Gst No. & phone no."
                        value={form?.partyName}
                        options={gstOptionsData}
                        onChange={(e: any) => {
                          handleChange({
                            name: "partyName",
                            value: e,
                          });
                        }}
                        onInputChange={(e) => {
                          if (regexpForGst.test(e)) {
                            handleOnInputChangeFilters({
                              name: "gst",
                              value: e.trim(),
                            });
                          } else {
                            handleOnInputChangeFilters({
                              name: "phone",
                              value: e,
                            });
                          }
                        }}
                      />
                      <div className="DivSep"></div>
                      <div className="formAddDiv">
                        <AddIcon openModal={handleOpenOrg} />
                      </div>
                    </div>

                    {Error.partyName && !form?.partyName ? (
                      <p className="RequiredField">{Error?.partyName} </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="formDiv">
                    <Select
                      className="selector_selector text-white w-100"
                      classNamePrefix="react-select"
                      placeholder="Select party type"
                      value={form?.partyType}
                      options={partyOptions}
                      onChange={(e: any) => {
                        handleChange({
                          name: "partyType",
                          value: e,
                        });
                      }}
                    />
                    {Error.partyType && !form?.partyType ? (
                      <p className="RequiredField">{Error?.partyType} </p>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
              <Button className="artButton" onClick={() => handleSubmit()}>
                {loading ? "Processing" : "Add Party"}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  connectOrg,
  updateParty,
})(AddParty);
