export const HeaderData: any = {
  columns: [
    {
      label: "Weave Type",
      field: "weaveType",
      sort: true,
      width: 200,
    },

    {
      label: "ArtForm",
      field: "artForm",
      sort: true,
      width: 200,
    },
    {
      label: "Type",
      field: "type",
      sort: true,
      width: 200,
    },
    {
      label: "Category",
      field: "category",
      sort: true,
      width: 200,
    },
    {
      label: "Sub-Category",
      field: "subCategory",
      sort: true,
      width: 200,
    },
    {
      label: "Created by",
      field: "createdBy",
      sort: true,
      width: 200,
    },
    {
      label: "Created at",
      field: "createdAt",
      sort: true,
      width: 300,
    },
    {
      label: "Actions",
      field: "actions",
      width: 200,
    },
  ],
};
export const HeaderDataOrgAdmin: any = {
  columns: [
    {
      label: "Weave Type",
      field: "weaveType",
      sort: true,
      width: 200,
    },

    {
      label: "ArtForm",
      field: "artForm",
      sort: true,
      width: 200,
    },
    {
      label: "Type",
      field: "type",
      sort: true,
      width: 200,
    },
    {
      label: "Category",
      field: "category",
      sort: true,
      width: 200,
    },
    {
      label: "Sub-Category",
      field: "subCategory",
      sort: true,
      width: 200,
    },
    {
      label: "Created by",
      field: "createdBy",
      sort: true,
      width: 200,
    },
    {
      label: "Created at",
      field: "createdAt",
      sort: true,
      width: 300,
    },
  ],
};
