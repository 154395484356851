import React from 'react'

const ModalCloseIcon = (props:any) => {
  return (
    <svg
    onClick={() => props?.closeModal()
    }
    width="20"
    height="20"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1L1 8"
      stroke="#C59C6C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 1L8 8"
      stroke="#C59C6C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}

export default ModalCloseIcon