export const types = {
  CREATE_TYPES:"CREATE_TYPES",
  CREATE_TYPES_SUCCESS:"CREATE_TYPES_SUCCESS",
  GET_TYPES:"GET_TYPES",
  GET_TYPES_SUCCESS:"GET_TYPES_SUCCESS",
  GET_TYPES_FILTERS:"GET_TYPES_FILTERS",
  GET_TYPES_FILTERS_SUCCESS:"GET_TYPES_FILTERS_SUCCESS",
  UPDATE_TYPES:"UPDATE_TYPES",
  UPDATE_TYPES_SUCCESS:"UPDATE_TYPES_SUCCESS",
  DELETE_TYPES:"DELETE_TYPES",
  DELETE_TYPES_SUCCESS:"DELETE_TYPES_SUCCESS"
    
}