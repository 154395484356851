import React, { useState } from "react";
import { Button, Card, Input } from "reactstrap";
const ProductApiFailed = (props: any) => {
  const [form, setForm]: any = useState({
    name: "",
    phone: "",
    report: "",
  });
  const handleChange = (data: any) => {
    setForm({ ...form, [data?.name]: data?.value });
  };
  return (
    <>
      {" "}
      <Card className="SomeWentCard">
        <div className="SomethingWentWrong pt-5">
          Page failed. Please report your bug
          <div className="mb-3 mt-4">
            <Input
              type="number"
              placeholder="Phone Number"
              className=" mt-3"
              value={form?.phone}
              onChange={(e: any) =>
                handleChange({
                  name: "phone",
                  value: e.target.value,
                })
              }
              required
            />
            {form?.phone?.length === 9 || form?.phone?.length > 10
              ? !/^[6-9]\d{9}$/.test(form?.phone) && (
                  <p className="RequiredField">Invalid phone number! </p>
                )
              : ""}

            <Input
              type="text"
              placeholder="Name"
              className=" mt-3"
              value={form?.name}
              onChange={(e: any) =>
                handleChange({
                  name: "name",
                  value: e.target.value,
                })
              }
              required
            />

            <Input
              type="textarea"
              placeholder="Please report your bug..."
              className=" mt-3"
              value={form?.report}
              onChange={(e: any) =>
                handleChange({
                  name: "report",
                  value: e.target.value,
                })
              }
              required
            />
          </div>
          <Button
            className="mb-4"
            onClick={() => {
              if (
                form?.name?.length ||
                form?.phone?.length ||
                form?.report?.length
              ) {
                alert(`Your feedback has been submitted`);
              } else {
                alert("Please fill your details");
              }
            }}
          >
            Submit
          </Button>
        </div>
      </Card>{" "}
    </>
  );
};

export default ProductApiFailed;
