export const HeaderData: any = {
  columns: [
    {
      label: "ArtForm",
      field: "artForm",
      sort: true,
      width: 200,
    },
    {
      label: "Created by",
      field: "createdBy",
      sort: true,
      width: 200,
    },
    {
      label: "Created at",
      field: "createdAt",
      sort: true,
      width: 200,
    },
    {
      label: "Actions",
      field: "actions",
      width: 200,
    },
  ],
};
export const HeaderDataOrgAdmin: any = {
  columns: [
    {
      label: "ArtForm",
      field: "artForm",
      sort: true,
      width: 200,
    },
    {
      label: "Created by",
      field: "createdBy",
      sort: true,
      width: 200,
    },
    {
      label: "Created at",
      field: "createdAt",
      sort: true,
      width: 200,
    },
  ],
};
