export const types = {
  CHECK_LOGIN: "CHECK_LOGIN",
  CHECK_LOGIN_SUCCESS: "CHECK_LOGIN_SUCCESS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
};
