import {types } from "./actionTypes";

// creating action
export const fetchProfilePresignedUrl = (payload:any,callback: any) => {
    return {
      type: types.GET_USER_PROFILE_PRESIGNED_URL,
      payload,
      callback,
    };
  };

  export const fetchProductWeavingPresignedUrl = (payload:any,callback: any) => {
    return {
      type: types.GET_PRODUCT_WEAVING_PRESIGNED_URL,
      payload,
      callback,
    };
  };
  export const fetchProductDyingPresignedUrl = (payload:any,callback: any) => {
    return {
      type: types.GET_PRODUCT_DYER_PRESIGNED_URL,
      payload,
      callback,
    };
  };
  export const fetchProductDesigningPresignedUrl = (payload:any,callback: any) => {
    return {
      type: types.GET_PRODUCT_DESIGNING_PRESIGNED_URL,
      payload,
      callback,
    };
  };
  export const fetchProductFinishingPresignedUrl = (payload:any,callback: any) => {
    return {
      type: types.GET_PRODUCT_FINISHING_PRESIGNED_URL,
      payload,
      callback,
    };
  };
  export const fetchMainLogoPresignedUrl = (payload:any,callback: any) => {
    return {
      type: types.GET_MAIN_LOGO_PRESIGNED_URL,
      payload,
      callback,
    };
  };
  export const uploadImages = (uri: any, data: any, headers: any) => ({
    type: types.UPLOAD_IMAGE,
    uri,
    data,
    headers,
  });
  export const uploadImages1 = (uri: any, data: any, headers: any,callback:any) => ({
    type: types.UPLOAD_IMAGE1,
    uri,
    data,
    headers,
    callback
  });