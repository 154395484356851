import React from "react";
import ProductCer from "../assets/images/productCertification.jpeg";
import { Row, Col } from "reactstrap";
import Loader from "../../../common/Components/Loader/Loader";
const ProductCertificationForDesignAgency = (props: any) => {
  var form: any = {
    productUniqueId: "",
    productName: "",
    artform: "",
    type: "",
    category: "",
    subCategory: "",
    weaveType: "",
    nameOfWeaver: "",
    weavingCenter: "Varanasi",
    color: "",
    zariUsed: "",
    weightOfSaree: "",
    yarnType: "",
    workingHours: "",
    // new
    SrNo: "",
    typeOfSareeByDesign: "",
    productionTimeInMonths: "",
  };
  if (props.editDetails !== undefined) {
    let editDetails = props.editDetails;
    form = {
      color: editDetails?.color,
      productUniqueId: editDetails?.productUniqueId,
      artform: editDetails?.artform?.name,
      type: editDetails?.type?.name,
      category: editDetails?.category?.name,
      subCategory: editDetails?.subcategory?.name,
      weaveType: editDetails?.weavetype?.name,
      productName: editDetails?.name,
      nameOfBrand: editDetails?.brand,
      nameOfWeaver: editDetails?.weaver,
      zariUsed: { label: editDetails?.zari, value: editDetails?.purity },
      weightOfSaree: editDetails?.sareeWeight,
      weightOfZariUsed: editDetails?.zariWeight,
      purity: editDetails?.purity,
      yarnType: editDetails?.yarnType,
      workingHours: editDetails?.workingHours,
      loomNum: editDetails?.loomNum,
      SrNo: editDetails?.srNo,
      weavingCenter: editDetails?.weavingCenter || form?.weavingCenter,
    };
  }
  // const form = props.form;
  let testData: any = [
    ["Name of Product", form?.productName],
    ["Artform", form?.artform],
    ["TYPE", form?.type],
    ["CATEGORY", form?.category],
    ["WEAVING CENTER", form?.weavingCenter],
    ["TYPE OF WEAVE", form?.weaveType],
    ["COLOR OF PRODUCT", form?.color],
    ["NAME OF KARIGAR", "Shriman  " + form?.nameOfWeaver],
    ["WEIGHT OF SAREE", form?.weightOfSaree],
    ["ZARI USED", form?.zariUsed?.label],
    // ["PURITY OF ZARI", "99% Silver , 24 carat gold"],
    // ["WEIGHT OF ZARI USED IN TEXTILE", form?.weightOfZariUsed],
    ["MAN HOURS USED IN PRODUCTION", form?.workingHours],
    ["YARN USED", form?.yarnType],
  ];
  return (
    <>
      {props?.Loading ? (
        <div className="LoaderDiv">
          <Loader />
        </div>
      ) : (
        <div className="DesignAgencyParent">
          <img src={ProductCer} alt="ProductCer" className="PImag" />
          {/* <div className="designAgencyProductPage"> */}
          <div className="RowParent">
            <div className="RowDidP">
              {testData.map((ele: any, i: any) => {
                return (
                  <div className="RowDiv">
                    <div className="Text1">{ele[0]}</div>
                    <div className="Text3">:</div>
                    <div className="Text2"> {ele[1]}</div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* </div> */}
        </div>
      )}
    </>
  );
};

export default ProductCertificationForDesignAgency;
