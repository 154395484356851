import React from "react";
import Select from "react-select";

const SelectorField = (props: any) => {
  const {
    name,
    placeholder,
    onChange,
    value,
    options,
    onInputChange,
    onFocus,
  }: any = props;
  return (
    <Select
      {...props}
      className="selector_selector text-white w-100"
      classNamePrefix="react-select"
      autoFocus={false}
      isSearchable
      name={name}
      id={name}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      options={options}
      onInputChange={onInputChange}
      // onFocus={onFocus}
    />
  );
};

export default SelectorField;
