import React from "react";
import VerticalMenuBar from "./VerticalMenuBar";
import { withRouter } from "react-router-dom";

import { Grid } from "@mui/material";
const VerticalLayout = ({ children }: any) => {
  return <React.Fragment>{children}</React.Fragment>;
};

export default withRouter(VerticalLayout);
