import React from "react";
import Slider from "react-slick";
import ViewEyeIcon from "../../../common/Components/Icons/ViewEyeIcon";
const CarouselForProduct = (props: any) => {
  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="CarouselForProduct">
        <Slider {...settings}>
          {props.images.map((ele: any) => (
            <div
              onClick={() => {
                props.handleOpen({
                  name: "mainLogo",
                });
                props.handleSetSelectedImage(ele);
              }}
            >
              {ele?.toLowerCase().endsWith(".pdf") ? (
                <iframe title={ele} src={ele} width="100%" height="100%" />
              ) : ele?.toLowerCase().endsWith(".docx") ||
                ele?.toLowerCase().endsWith(".doc") ? (
                <iframe
                  title="RulesDocx"
                  src={`https://docs.google.com/viewer?url=${ele}&embedded=true`}
                  height={"100%"}
                  width={"100%"}
                  frameBorder="0"
                ></iframe>
              ) : ele.toLowerCase().endsWith(".mp4") ||
                ele.toLowerCase().endsWith(".webm") ||
                ele.toLowerCase().endsWith(".ogg") ? (
                <div className="ImageDis-bg-bg">
                  <div
                    style={{
                      backgroundImage: `url(${ele})`,
                      position: "absolute",
                    }}
                    className="ImageDis-bg"
                  ></div>
                  <video className="ImageDis" width="100%" controls muted>
                    <source src={ele} type="video/mp4" />
                  </video>
                </div>
              ) : (
                <div className="ImageDis-bg-bg">
                  <div
                    style={{
                      backgroundImage: `url(${ele})`,
                      position: "absolute",
                    }}
                    className="ImageDis-bg"
                  ></div>
                  <img
                    src={ele}
                    className="ImageDis cursor-pointer"
                    alt="logo"
                  ></img>
                </div>
              )}
            </div>
          ))}
        </Slider>
        {/* <div className="viewIconForPreview">
          <ViewEyeIcon
            {...props}
            onClick={() => {
              props.handleOpen({
                name: "mainLogo",
              });
            }}
          />
        </div> */}
      </div>
    </>
  );
};
export default CarouselForProduct;
