import React, { useState, useEffect } from "react";
import { Label, Input, Button, Col, Row } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";

//components
import { debounce } from "../../common/commonFiles/debounce";
import Loader from "../../common/Components/Loader/Loader";
import notifications from "../../common/Components/Notifications/Notification";
// actions
import {
  createSubCategory,
  updateSubCategory,
  getSubCategorysFilter,
} from "../../Store/actions";
import { getArtforms, getTypes } from "../../Store/actions";
import { getCategory } from "../../Store/actions";
let artformOptions: any = [];
let typeOptions: any = [];
let categoryOptions: any = [];
var formDetails: any = [];

const AddOrEditSubCategoryForm = (props: any) => {
  const [loading, setLoading]: any = React.useState(false);
  const [filterName, setFilterName]: any = useState({
    artform: "",
    type: "",
    category: "",
  });
  const [filterValue, setFilterValue]: any = useState("");
  const [form, setForm]: any = React.useState(
    props.editData?._id
      ? {
          artform: {
            label: props?.editData?.artform?.name,
            value: props.editData?.artform?._id,
          },
          type: {
            label: props.editData?.type?.name,
            value: props.editData?.type?._id,
          },
          category: {
            label: props.editData?.category?.name,
            value: props.editData?.category?._id,
          },
          subCategory: props.editData?.name,
        }
      : props?.form?.category
      ? {
          artform: {
            label: props?.form?.artform?.label,
            value: props?.form?.artform?.value,
          },
          type: {
            label: props?.form?.type?.label,
            value: props?.form?.type?.value,
          },
          category: {
            label: props.form?.category?.label,
            value: props.form?.category?.value,
          },
          subCategory: "",
        }
      : {
          artform: "",
          type: "",
          category: "",
          subCategory: "",
        }
  );
  const [Error, setError]: any = React.useState({
    artform: "",
    type: "",
    category: "",
    subCategory: "",
  });
  useEffect(() => {
    if (form?.type?.value && !form?.category?.value) {
      getCategoryData();
    }
    if (form?.artform?.value && !form?.type?.value) {
      getTypesData();
    }
  }, [form]);
  useEffect(() => {
    getArtformData();
  }, []);

  const getTypesData = (data?: any) => {
    props.getTypes(
      {
        offset: 0,
        limit: 10,
        typeName: filterName?.type || data?.value,
        artFormId: form?.artform?.value || data?.artform,
      },
      (res: any) => {
        typeOptions = [];
        res?.data?.docs.map((ele: any) => {
          typeOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getArtformData = (data?: any) => {
    props.getArtforms(
      {
        offset: 0,
        limit: 10,
        artformName: filterName?.artform || data?.artform,
      },
      (res: any) => {
        artformOptions = [];
        res?.data?.docs.map((ele: any) => {
          artformOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getCategoryData = (data?: any) => {
    props.getCategory(
      {
        offset: 0,
        limit: 10,
        typeId: form?.type?.value || data?.type,
        categoryName: filterName?.category || data?.value,
        artformId: form?.artform?.value || data?.artform,
        categoryId: form?.category?.value,
      },
      (res: any) => {
        categoryOptions = [];
        res?.data?.docs.map((ele: any) => {
          categoryOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const validate = () => {
    let error = "";
    let err = Error;
    if (form?.artform === "") {
      error = "Required";
      err = {
        ...err,
        artform: "ArtForm is Required!",
      };
    }
    if (form?.type === "") {
      error = "Required";
      err = {
        ...err,
        type: "Type is Required!",
      };
    }
    if (form?.category === "") {
      error = "Required";
      err = {
        ...err,
        category: "Category is Required!",
      };
    }
    if (form?.subCategory === "") {
      error = "Required";
      err = {
        ...err,
        subCategory: "Sub-Category Name is Required!",
      };
    }
    setError(err);
    return error;
  };
  const handleSubmitChange = (type: any) => {
    if (type?.name === "artform") {
      setForm({
        ...form,
        type: "",
        category: "",
        artform: type?.value,
      });
      formDetails = {
        ...formDetails,
        type: "",
        category: "",
        artform: type?.value,
      };
    } else {
      setForm({ ...form, [type.name]: type?.value });
      formDetails = {
        ...formDetails,
        [type.name]: type?.value,
      };
    }
  };
  const handleSubmit = () => {
    let result: any = validate();
    if (result?.length === 0) {
      setLoading(true);
      props?.editData?._id
        ? props.updateSubCategory(
            {
              id: props?.editData?._id,
              categoryId: form?.category?.value,
              name: form?.subCategory,
            },
            (res: any) => {
              setLoading(false);
              if (res.success) {
                notifications({
                  title: "Success!",
                  message: res?.message,
                  type: "success",
                });
                props.closeModal();
                props.getAllData();
              } else {
                notifications({
                  title: "Error!",
                  message: res?.message,
                  type: "danger",
                });
              }
            }
          )
        : props.createSubCategory(
            {
              name: form?.subCategory,
              // artFormId: form?.type?.value?.artFormId,
              // typeId:form?.type?.value?.typeId,
              categoryId: form?.category?.value,
            },
            (res: any) => {
              setLoading(false);
              if (res.success) {
                notifications({
                  title: "Success!",
                  message: res?.message,
                  type: "success",
                });
                props.closeModal();
                props.getAllData();
                props.handleName({
                  name: "subCategory",
                  category: form?.category?.value,
                  subCategory: form?.subCategory,
                });
              } else {
                notifications({
                  title: "Error!",
                  message: res?.message,
                  type: "danger",
                });
              }
            }
          );
    }
  };
  const handleOnInputChange = (type: any) => {
    if (type?.name === "artform") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
      if (
        type?.value.length === 0 &&
        !form?.artform?.value &&
        !form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "type") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.artform?.value &&
        !form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
        });
      }
    }
    if (type?.name === "category") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
        });
      }
    }
  };
  const callingGetSuCategory = (value: any) => {
    props.getSubCategorysFilter(
      {
        offset: 0,
        limit: 10,
        artformId: formDetails?.artform?.value || value?.artform,
        typeId: formDetails?.type?.value || value?.type,
        categoryId: formDetails?.category?.value || value?.category,
        subCategoryName: value?.value,
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          res.data?.docs.map((ele: any, i: any) => {
            if (value.match(ele?.name)) {
              setFilterValue(ele?.name);
            }
          });
        }
      }
    );
  };
  const handleChangeDebounce = React.useCallback(
    debounce((data: any) => {
      if (data?.name === "subCategory") {
        callingGetSuCategory(data);
      }
      if (data?.name === "category") {
        getCategoryData(data);
      }
      if (data?.name === "artform") {
        getArtformData(data);
      }
      if (data?.name === "type") {
        getTypesData(data);
      }
    }, 1000),
    []
  );
  return (
    <>
      <div className="AddSubCategoryForm">
        <div className="form">
          {loading ? (
            <div className="LoaderDivModal">
              <Loader />
            </div>
          ) : (
            <>
              <form>
                <Row className="RowForm">
                  <Col xs={12} md={6}>
                    <div className="formDiv">
                      <Label>
                        Artform<span>*</span>
                      </Label>

                      <Select
                        className="React_selector text-white"
                        classNamePrefix="react-select"
                        placeholder="Select Artform"
                        isSearchable
                        name="artform"
                        id="artform"
                        value={form?.artform}
                        options={artformOptions}
                        onChange={(e: any) => {
                          handleSubmitChange({
                            name: "artform",
                            value: e,
                          });
                        }}
                        onInputChange={(e) =>
                          handleOnInputChange({
                            name: "artform",
                            value: e,
                          })
                        }
                      />
                      {Error && !form?.artform ? (
                        <p className="RequiredField">{Error?.artform} </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="formDiv">
                      <Label>
                        Type<span>*</span>
                      </Label>

                      <Select
                        className="React_selector text-white"
                        classNamePrefix="react-select"
                        placeholder="Select Type"
                        isSearchable
                        name="Type"
                        id="Type"
                        value={form?.type}
                        options={
                          form?.artform?.value
                            ? typeOptions
                            : [
                                {
                                  label: (
                                    <p className="req">
                                      ***Please select Artform***
                                    </p>
                                  ),
                                  value: "",
                                },
                              ]
                        }
                        onChange={(e: any) => {
                          handleSubmitChange({
                            name: "type",
                            value: e,
                          });
                        }}
                        onInputChange={(e) =>
                          handleOnInputChange({
                            name: "type",
                            value: e,
                          })
                        }
                      />
                      {Error.type && !form?.type ? (
                        <p className="RequiredField">{Error?.type} </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="formDiv">
                  <Label>
                    Category<span>*</span>
                  </Label>

                  <Select
                    className="React_selector text-white"
                    classNamePrefix="react-select"
                    placeholder="Select Category"
                    isSearchable
                    name="Category"
                    id="Category"
                    value={form?.category}
                    options={
                      form?.type?.value
                        ? categoryOptions
                        : [
                            {
                              label: (
                                <p className="req">***Please select Type***</p>
                              ),
                              value: "",
                            },
                          ]
                    }
                    onChange={(e: any) => {
                      handleSubmitChange({
                        name: "category",
                        value: e,
                      });
                    }}
                    onInputChange={(e) =>
                      handleOnInputChange({
                        name: "category",
                        value: e,
                      })
                    }
                  />
                  {Error.category && !form?.category ? (
                    <p className="RequiredField">{Error?.category} </p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="formDiv">
                  <Label>
                    Sub-Category<span>*</span>
                  </Label>
                  <Input
                    name="subCategory"
                    id="subCategory"
                    className="form-control"
                    placeholder="Type Name"
                    value={form?.subCategory}
                    onChange={(e: any) => {
                      handleSubmitChange({
                        name: "subCategory",
                        value: e.target.value,
                      });
                      handleChangeDebounce({
                        name: "subCategory",
                        artform: form?.artform?.value,
                        type: form?.type?.value,
                        category: form?.category?.value,
                        value: e.target.value,
                      });
                    }}
                  />
                  {Error.subCategory && !form?.subCategory ? (
                    <p className="RequiredField">{Error?.subCategory} </p>
                  ) : (
                    ""
                  )}
                  {form?.subCategory && form?.subCategory === filterValue ? (
                    // filterValue?.product === ""
                    <p className="RequiredField">
                      Sub-Category is already exist's
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Button
                  className="artButton"
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  {loading ? "Processing" : "Save"}
                </Button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  createSubCategory,
  updateSubCategory,
  getCategory,
  getArtforms,
  getTypes,
  getSubCategorysFilter,
})(AddOrEditSubCategoryForm);
