import React from "react";
import SelectorField from "../../common/Components/SearchBarField/SelectorField";
import DateRangeSearchFilter from "../../common/Components/DateRangePicker/DateRangeSearchFilter";
import InputSearch from "../../common/Components/SearchBarField/InputSearch";
import { Button } from "reactstrap";
const QuickFilter = (props: any) => {
  return (
    <div className="QuickFiltersHeader ps-3 pe-3">
      <div className="InputColP2">
        <div>
          <svg
            onClick={props.openModalPopUp}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="34" height="34" rx="4" fill="#1A1A1A" />
            <path
              d="M17.5 12.5503V22.4497"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.5503 17.5H22.4498"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div>
          <Button
            className="ResetBtn"
            onClick={() => {
              props.handleResetData();
            }}
          >
            Reset
          </Button>
          {/* <svg
          onClick={() => props.getAllData()}
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="34" height="34" rx="4" fill="#C59C6C" />
          <path
            d="M18.6628 20.6016C17.1671 21.4531 15.594 21.6466 13.9588 21.128C12.6724 20.7215 11.6534 19.9358 10.9249 18.7978C9.39438 16.4213 9.79735 13.3712 11.882 11.4669C13.8736 9.64378 16.9889 9.50831 19.1355 11.1262C21.4255 12.8487 22.1462 16.0458 20.6156 18.6469C20.6583 18.674 20.7009 18.7011 20.7435 18.7282C21.6076 19.2391 22.4368 19.7965 23.2117 20.4351C23.7271 20.8609 24.0254 21.395 23.9983 22.0724C23.9634 22.9007 23.5566 23.5123 22.7894 23.8297C22.0222 24.1471 21.286 24.0232 20.697 23.4387C20.325 23.071 20.0151 22.6375 19.709 22.2079C19.3641 21.724 19.058 21.2131 18.7364 20.7138C18.7131 20.6751 18.6899 20.6403 18.6628 20.6016ZM12.13 15.7013C12.13 17.6754 13.7418 19.2817 15.718 19.2817C17.6902 19.2817 19.2944 17.6792 19.2982 15.7091C19.3021 13.7312 17.698 12.1249 15.718 12.121C13.7457 12.1171 12.13 13.7273 12.13 15.7013Z"
            fill="white"
          />
        </svg>
        <svg
          onClick={() => props.handleResetData()}
          className="mt-2 ms-3"
          width="15"
          height="15"
          viewBox="0 0 9 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 1L1 8"
            stroke="#1A1A1A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 1L8 8"
            stroke="#1A1A1A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg> */}
        </div>
      </div>
      <div className="InputColP addIcon">
        <svg
          onClick={props.openModalPopUp}
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="34" height="34" rx="4" fill="#1A1A1A" />
          <path
            d="M17.5 12.5503V22.4497"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5503 17.5H22.4498"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="InputColP">
        <InputSearch
          {...props}
          name="subCategory"
          placeholder="Sub-Category"
          value={props.filters?.subCategory}
          onChange={(e: any) =>
            props.handleOnchangeFilter({
              name: "subCategory",
              value: e.target.value,
            })
          }
          onKeyDown={(event: any) => props.handleKeyDown(event)}
        />
      </div>
      <div className="InputColP">
        <SelectorField
          {...props}
          name="artform"
          placeholder="Artform"
          options={props.artformOptions}
          onChange={(e: any) =>
            props.handleOnchangeFilter({
              name: "artform",
              value: e,
            })
          }
          value={props.filters?.artform}
          onInputChange={(e: any) =>
            props.handleOnInputChange({
              name: "artform",
              value: e,
            })
          }
          onFocus={(e: any) => {
            props.autoFocus({
              name: "artform",
            });
          }}
        />
      </div>
      <div className="InputColP">
        <SelectorField
          {...props}
          name="type"
          placeholder="Type"
          options={
            props.filters?.artform
              ? props.typeOptions
              : [
                  {
                    label: <p className="req">***Please select artform***</p>,
                    value: "",
                  },
                ]
          }
          onChange={(e: any) =>
            props.handleOnchangeFilter({
              name: "type",
              value: e,
            })
          }
          value={props.filters?.type}
          onFocus={(e: any) => {
            if (props.filters?.artform) {
              props.autoFocus({
                name: "type",
              });
            }
          }}
          onInputChange={(e: any) =>
            props.handleOnInputChange({
              name: "type",
              value: e,
            })
          }
        />
      </div>
      <div className="InputColP">
        <SelectorField
          {...props}
          placeholder="Category"
          name="category"
          onChange={(e: any) =>
            props.handleOnchangeFilter({
              name: "category",
              value: e,
            })
          }
          value={props.filters?.category}
          options={
            props.filters?.type
              ? props.categoryOptions
              : [
                  {
                    label: <p className="req">***Please select type***</p>,
                    value: "",
                  },
                ]
          }
          onFocus={(e: any) => {
            if (props.filters?.type) {
              props.autoFocus({
                name: "category",
              });
            }
          }}
          onInputChange={(e: any) =>
            props.handleOnInputChange({
              name: "category",
              value: e,
            })
          }
        />
      </div>
      <div className="InputColP">
        <SelectorField
          {...props}
          name="creator"
          placeholder="Creator"
          onChange={(e: any) =>
            props.handleOnchangeFilter({
              name: "creator",
              value: e,
            })
          }
          value={props.filters?.creator}
          options={props.creatorOptions}
          onFocus={(e: any) => {
            props.autoFocus({
              name: "creator",
            });
          }}
          onInputChange={(e: any) =>
            props.handleOnInputChange({
              name: "creator",
              value: e,
            })
          }
        />
      </div>
      <div className="InputColP">
        <DateRangeSearchFilter
          value={props.filterDateValue}
          onChange={(date: any) => {
            props.handleChangeDate(date);
          }}
        />
      </div>
      <div className="InputColP1 searchIcon">
        <Button
          className="ResetBtn"
          onClick={() => {
            props.handleResetData();
          }}
        >
          Reset
        </Button>
        {/* <svg
           onClick={() => props.getAllData()}
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="34" height="34" rx="4" fill="#C59C6C" />
          <path
            d="M18.6628 20.6016C17.1671 21.4531 15.594 21.6466 13.9588 21.128C12.6724 20.7215 11.6534 19.9358 10.9249 18.7978C9.39438 16.4213 9.79735 13.3712 11.882 11.4669C13.8736 9.64378 16.9889 9.50831 19.1355 11.1262C21.4255 12.8487 22.1462 16.0458 20.6156 18.6469C20.6583 18.674 20.7009 18.7011 20.7435 18.7282C21.6076 19.2391 22.4368 19.7965 23.2117 20.4351C23.7271 20.8609 24.0254 21.395 23.9983 22.0724C23.9634 22.9007 23.5566 23.5123 22.7894 23.8297C22.0222 24.1471 21.286 24.0232 20.697 23.4387C20.325 23.071 20.0151 22.6375 19.709 22.2079C19.3641 21.724 19.058 21.2131 18.7364 20.7138C18.7131 20.6751 18.6899 20.6403 18.6628 20.6016ZM12.13 15.7013C12.13 17.6754 13.7418 19.2817 15.718 19.2817C17.6902 19.2817 19.2944 17.6792 19.2982 15.7091C19.3021 13.7312 17.698 12.1249 15.718 12.121C13.7457 12.1171 12.13 13.7273 12.13 15.7013Z"
            fill="white"
          />
        </svg>
        <svg
           onClick={() => {
            props.handleResetData();
          }}
          className="mt-2 ms-3"
          width="15"
          height="15"
          viewBox="0 0 9 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 1L1 8"
            stroke="#1A1A1A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 1L8 8"
            stroke="#1A1A1A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg> */}
      </div>
    </div>
  );
};

export default QuickFilter;
