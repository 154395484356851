export const types = {
  CREATE_ARTFORM:"CREATE_ARTFORM",
  CREATE_ARTFORM_SUCCESS:"CREATE_ARTFORM_SUCCESS",
  GET_ARTFORM:"GET_ARTFORM",
  GET_ARTFORM_SUCCESS:"GET_ARTFORM_SUCCESS",
  UPDATE_ARTFORM:"UPDATE_ARTFORM",
  UPDATE_ARTFORM_SUCCESS:"UPDATE_ARTFORM_SUCCESS",
  DELETE_ARTFORM:"DELETE_ARTFORM",
  DELETE_ARTFORM_SUCCESS:"DELETE_ARTFORM_SUCCESS",
  GET_ARTFORM_FILTERS:"GET_ARTFORM_FILTERS",
  GET_ARTFORM_FILTERS_SUCCESS:"GET_ARTFORM_FILTERS_SUCCESS",
}