import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { Button, Row, Col, Label, Input } from "reactstrap";
import Select from "react-select";

//components
import { statesList } from "../users/stateList";
import notifications from "../../common/Components/Notifications/Notification";
//store
import { updateOrganisation } from "../../Store/actions";
import { getUsers, updateUsers } from "../../Store/users/actions";
import { debounce } from "../../common/commonFiles/debounce";
import {
  uploadImages1,
  fetchMainLogoPresignedUrl,
} from "../../Store/uploadModule/actions";
import CircleLoader from "../../common/Components/Loader/CircleLoader";
let productMainImageStatusCode: any = [];
let orgTypeOptions: any = [
  {
    label: "Design Agency",
    value: "Design Agency",
  },
  {
    label: "Manufacturer",
    value: "Manufacturer",
  },
];
var regexpForGst =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Zz][0-9A-Z]{1}$/;
let regexForPinCode = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;
const EditProfileOrganizationDetails = (props: any) => {
  const history = useHistory();
  const [baseImageLogo, setBaseImageLogo]: any = useState("");
  const [selectedFile, setSelectedFile]: any = useState();
  const [loading, setLoading]: any = useState(false);
  const [mainImageLoader, setMainImageLoader]: any = useState(false);

  // const [form, setForm]: any = useState(authUser?.users);
  const [form, setForm]: any = useState({
    orgId: "",
    orgName: "",
    orgContact: "",
    profileURL: "",
    orgAddress: "",
    orgGST: "",
    orgTan: "",
    orgType: "",
  });
  const [errorMobile, setMobileError]: any = useState("");
  const [errorGst, setGst]: any = useState("");
  const [errorPinCard, setPinCard]: any = useState("");

  const [lengthApplication, setLengthApplication]: any = useState();
  const fileInputProfile: any = React.useRef();
  const fileUploadTrigger = () => {
    fileInputProfile.current.click();
  };
  useEffect(() => {
    // getAllData();
    productMainImageStatusCode = [];
  }, []);
  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";

  useEffect(() => {
    if (authUser?.users?._id) {
      getUserDataById();
    }
  }, [authUser?.users?._id]);
  useEffect(() => {
    if (form?.phone?.length === 0 || form?.phone?.length === 10) {
      setMobileError("");
    }
  }, [errorMobile, form?.phone]);

  useEffect(() => {
    if (
      form?.pinCode?.length === 0 ||
      (form?.pinCode?.length === 6 && regexForPinCode.test(form?.pinCode))
    ) {
      setPinCard("");
    }
  }, [errorPinCard, form?.pinCode]);
  useEffect(() => {
    if (
      form?.orgGST?.length === 0 ||
      (form?.orgGST?.length === 15 && regexpForGst.test(form?.orgGST))
    ) {
      setGst("");
    }
  }, [errorGst, form?.orgGST]);
  const getUserDataById = () => {
    setLoading(true);
    props.getUsers(
      {
        userId: authUser?.users?._id,
      },
      (res: any) => {
        setLoading(false);
        let formOrganisationDetails: any = [];
        let editDetails: any = res?.data?.docs[0];
        if (editDetails?.businessRoleDetails?.length) {
          if (editDetails?.businessRoleDetails[0]?.role === "NAQSEBAND") {
            editDetails?.organizationDetails.forEach((ele: any) => {
              formOrganisationDetails.push({
                label: ele?.name,
                value: ele?._id,
              });
            });
          }
        }
        setForm({
          profileURL: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.logo
            : "",
          orgId: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?._id
            : "",

          orgContact: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.phone
            : "----",

          addressLane: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.address?.addressLane
            : "",
          city: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.address?.city
            : "",
          pinCode: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.address?.pinCode
            : "",
          state: editDetails?.organizationDetails?.length
            ? {
                label: editDetails?.organizationDetails[0]?.address?.state,
                value: editDetails?.organizationDetails[0]?.address?.state,
              }
            : "",

          orgName: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.name
            : "",
          orgType:
            editDetails?.organizationDetails?.length > 0
              ? {
                  label: editDetails?.organizationDetails[0]?.organizationType,
                  value: editDetails?.organizationDetails[0]?.organizationType,
                }
              : "",
          orgGST: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.gst
            : "",
          orgTan: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.tan
            : "",
          domain: {
            value: editDetails?.businessDetails?.domain,
            label: editDetails?.businessDetails?.domain,
          },
          artisanCard: editDetails?.businessDetails?.artisanCard,
          //   profileURL: editDetails?.profileURL,
        });
      }
    );
  };
  useEffect(() => {
    if (form?.orgContact?.length === 0 || form?.orgContact?.length === 10) {
      setMobileError("");
    }
  }, [errorMobile, form?.orgContact]);
  useEffect(() => {
    if (selectedFile) {
      S3Upload();
    }
  }, [selectedFile]);
  const S3Upload = async () => {
    setMainImageLoader(true);

    if (selectedFile) {
      props.fetchMainLogoPresignedUrl(
        { fileType: selectedFile?.type },
        (res: any) => {
          let softCopy = "";
          softCopy = res.data.split("?")[0];
          setForm({
            ...form,
            profileURL: softCopy,
          });
          const formData = new FormData();
          formData.append(selectedFile, selectedFile.name);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", selectedFile.type);
          props.uploadImages1(
            res.data + "&x-amz-acl=public-read",
            selectedFile,
            myHeaders,
            (resServer: any) => {
              setMainImageLoader(false);
              productMainImageStatusCode[0] = resServer?.status;
            }
          );
        }
      );
    }
  };
  const fileUpload = (event: any) => {
    let name = event.target.name;
    if (name === "profileURL") {
      uploadImage(event);
      setSelectedFile(event.target.files[0]);
    }
  };
  const uploadImage = async (e: any) => {
    const name = e.target.name;
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    setBaseImageLogo(base64);
  };
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleSubmit = () => {
    let organisationIds: any = [];
    if (form?.role?.label === "NAQSEBAND") {
      form?.organization.forEach((ele: any) => {
        organisationIds.push(ele?.value);
      });
    }
    let payload: any = {
      id: form?.orgId,
      name: form?.orgName,
      phone: form?.orgContact,
      logo: form?.profileURL,
      address: {
        addressLane: form?.addressLane,
        city: form?.city,
        state: form?.state ? form?.state?.label : "",
        // pinCode: form?.pinCode,
        pinCode: form?.pinCode,
      },
      gst: form?.orgGST,
      tan: form?.orgTan,
      organizationType: form?.orgType ? form?.orgType?.label : "",
    };
    props.updateOrganisation(payload, (res: any) => {
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        history.push("/view-user-profile");
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  const handleOnInputChange = (type: any) => {
    setForm({
      ...form,
      [type.name]: type?.value,
    });
  };

  const handleChangeDebounceForMobile = React.useCallback(
    debounce((data: any) => {
      if (data?.name === "phone") {
        setMobileError("Invalid mobile number!");
      }
      if (data?.name === "pinCode") {
        if (regexForPinCode.test(form?.pinCode)) {
          setPinCard(" ");
        } else {
          setPinCard("Invalid pincode!");
        }
      }
      if (data?.name === "gst") {
        if (regexpForGst.test(form?.orgGST)) {
          setGst("");
        } else {
          setGst("Invalid Gst number!");
        }
      }
    }, 1000),
    []
  );
  return (
    <>
      <div className="createUser profileSection">
        <div className="form">
          <div className="UserDetailsDiv">
            <div className="UserHeader">Organization Details</div>
            <div className="userPd1">
              <div className="d1">
                <div className="imageDiv">
                  {form?.profileURL?.length || baseImageLogo ? (
                    <>
                      <div className="editPen">
                        <svg
                          onClick={() => fileUploadTrigger()}
                          width="26"
                          height="26"
                          viewBox="0 0 26 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="26" height="26" rx="4" fill="#262D22" />
                          <path
                            d="M16.3473 6.6267C16.546 6.42801 16.7819 6.2704 17.0415 6.16287C17.3011 6.05534 17.5793 6 17.8603 6C18.1413 6 18.4195 6.05534 18.6791 6.16287C18.9387 6.2704 19.1746 6.42801 19.3733 6.6267C19.572 6.82538 19.7296 7.06126 19.8371 7.32086C19.9447 7.58045 20 7.85869 20 8.13968C20 8.42066 19.9447 8.6989 19.8371 8.95849C19.7296 9.21809 19.572 9.45397 19.3733 9.65266L9.16069 19.8653L5 21L6.13473 16.8393L16.3473 6.6267Z"
                            stroke="#C69C6D"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <input
                          type="file"
                          name="Image"
                          multiple={true}
                          accept=".png,.jpg,.jpeg,.heic,.bmp"
                          style={{ display: "none" }}
                          ref={fileInputProfile}
                          onChange={(e) => {
                            fileUpload({
                              target: {
                                name: "profileURL",
                                files: e.target.files,
                              },
                            });
                          }}
                        />
                      </div>
                      {mainImageLoader ? (
                        <CircleLoader />
                      ) : (
                        <img
                          src={baseImageLogo ? baseImageLogo : form?.profileURL}
                          alt="logo"
                          width="200px"
                          height="200px"
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <div className="d-block">
                        <svg
                          onClick={() => fileUploadTrigger()}
                          width="50"
                          height="50"
                          viewBox="0 0 50 50"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 31.6534C0.0758962 31.2107 0.126494 30.768 0.227689 30.3253C0.809559 27.6309 2.23894 25.5185 4.51582 23.9753C4.65497 23.8741 4.80676 23.7729 4.9712 23.659C4.17429 20.9521 4.57907 18.4855 6.60297 16.4616C8.61422 14.4503 11.0808 13.9949 13.7372 14.8045C17.0893 8.92253 22.0225 6.15232 28.7773 7.22752C33.9256 8.04973 39.1245 12.4517 39.9214 19.3709C40.2376 19.4468 40.5665 19.4974 40.8827 19.5986C44.8167 20.7876 47.511 23.3428 48.8265 27.2009C50.6607 32.5515 48.7633 37.9781 44.0704 41.1531C42.2995 42.3548 40.3262 42.9873 38.1631 42.9873C35.0008 42.9873 31.8511 42.9873 28.6888 42.9873C28.499 42.9873 28.3093 42.9873 28.0942 42.9873C28.0942 39.3949 28.0942 35.8657 28.0942 32.2733C28.2587 32.2606 28.4105 32.248 28.5623 32.248C29.4351 32.248 30.3079 32.2353 31.168 32.248C31.7246 32.2606 32.2432 32.2227 32.5342 31.6534C32.8378 31.0842 32.5595 30.6415 32.2306 30.1988C31.0415 28.5543 29.8525 26.9099 28.6635 25.2655C27.7021 23.9373 26.7408 22.5965 25.7794 21.2683C25.1343 20.3829 24.35 20.3702 23.7176 21.2683C21.5419 24.2662 19.3788 27.2768 17.2031 30.2747C17.1525 30.3379 17.1146 30.4012 17.064 30.4771C16.811 30.8565 16.7351 31.2487 16.9501 31.6661C17.1652 32.0709 17.5194 32.2606 17.9874 32.2606C19.1132 32.2606 20.239 32.2606 21.4027 32.2606C21.4027 35.853 21.4027 39.3949 21.4027 42.9999C21.2003 42.9999 21.0106 42.9999 20.8209 42.9999C17.3043 42.9999 13.7878 42.9873 10.2839 42.9999C6.78006 43.0126 4.11104 41.4314 2.0745 38.6865C0.974001 37.1939 0.328883 35.5115 0.0885456 33.6647C0.0758962 33.5761 0.0252987 33.4876 0 33.3991C0 32.8172 0 32.2353 0 31.6534Z"
                            fill="#C6C6C6"
                          />
                        </svg>
                        <input
                          type="file"
                          name="Image"
                          multiple={true}
                          accept=".png,.jpg,.jpeg,.heic,.bmp"
                          style={{ display: "none" }}
                          ref={fileInputProfile}
                          onChange={(e) => {
                            fileUpload({
                              target: {
                                name: "profileURL",
                                files: e.target.files,
                              },
                            });
                          }}
                        />
                        <span className="clickText">click to upload</span>
                        <br />
                        <span className="clickText1">
                          {" "}
                          company profile logo
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="d-flex justify-content-center">
                  {productMainImageStatusCode?.length > 0 &&
                  productMainImageStatusCode[0] !== 200 ? (
                    <p className="RequiredField">
                      Image upload failed into server!
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d2">
                <Row>
                  <Col xs={12} md={4}>
                    <Label>Name</Label>
                    <Input
                      name="organizationName"
                      id="organizationName"
                      className="form-control"
                      placeholder="Enter Name"
                      value={form?.orgName || ""}
                      // disabled={true}
                      onChange={(e: any) => {
                        handleOnInputChange({
                          name: "orgName",
                          value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Label>GST</Label>
                    <Input
                      type="text"
                      name="gst"
                      id="gst"
                      className="form-control text-Uppercase"
                      placeholder="Ex: 06BZAHM6385P6Z2"
                      value={form?.orgGST}
                      // disabled={true}
                      disabled={
                        authUser?.users?.role === "ORGANIZATION_ADMIN" ||
                        authUser?.users?.role === "ORGANIZATION_MANAGER" ||
                        authUser?.users?.role === "SUPER_ADMIN"
                          ? false
                          : true
                      }
                      onChange={(e: any) => {
                        handleOnInputChange({
                          name: "orgGST",
                          value:
                            e.target.value?.length <= 15
                              ? e.target.value
                              : form?.GST,
                        });
                        if (
                          e.target.value?.length <= 15 ||
                          e.target.value?.length > 1
                        ) {
                          handleChangeDebounceForMobile({
                            name: "gst",
                          });
                        }
                        if (
                          e.target.value?.length === 0 ||
                          e.target.value?.length === 15
                        ) {
                          setGst("");
                        }
                      }}
                    />
                    <p className="RequiredField">{errorGst}</p>
                  </Col>
                  <Col xs={12} md={4}>
                    <Label>Contact</Label>
                    <Input
                      name="orgContact"
                      id="orgContact"
                      className="form-control"
                      placeholder="Enter Phone"
                      value={form?.orgContact}
                      maxLength={10}
                      // disabled={true}
                      disabled={
                        authUser?.users?.role === "ORGANIZATION_ADMIN" ||
                        authUser?.users?.role === "ORGANIZATION_MANAGER" ||
                        authUser?.users?.role === "SUPER_ADMIN"
                          ? false
                          : true
                      }
                      onChange={(e: any) => {
                        handleOnInputChange({
                          name: "orgContact",
                          value:
                            e.target.value?.length <= 10
                              ? e.target.value
                              : form?.orgContact,
                        });
                        if (
                          e.target.value?.length <= 9 ||
                          e.target.value?.length > 1
                        ) {
                          handleChangeDebounceForMobile({
                            name: "phone",
                          });
                        }
                        if (
                          e.target.value?.length === 0 ||
                          e.target.value?.length === 10
                        ) {
                          setMobileError("");
                        }
                      }}
                    />
                    <p className="RequiredField">{errorMobile}</p>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} md={4}>
                    <Label>Type</Label>
                    {/* <Input
                      type="text"
                      name="City"
                      id="City"
                      className="form-control"
                      placeholder="Type"
                      value={form?.orgType ? form?.orgType : "N/A"}
                      disabled={true}
                    /> */}
                    <Select
                      className="React_selector text-white"
                      classNamePrefix="react-select"
                      placeholder="Select type"
                      autoFocus={false}
                      isSearchable
                      value={form?.orgType}
                      options={orgTypeOptions}
                      onChange={(e: any) => {
                        handleOnInputChange({
                          name: "orgType",
                          value: e,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Label>Address</Label>
                    <Input
                      type="textarea"
                      name="Organization Address"
                      id="Organization Address"
                      className="form-control"
                      placeholder="Enter Company Address"
                      value={form?.addressLane}
                      // disabled={true}
                      onChange={(e: any) => {
                        handleOnInputChange({
                          name: "addressLane",
                          value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} md={4}>
                    <Label>City</Label>
                    <Input
                      type="text"
                      name="City"
                      id="City"
                      className="form-control"
                      placeholder="Enter Company Address"
                      value={form?.city}
                      // disabled={true}
                      onChange={(e: any) => {
                        handleOnInputChange({
                          name: "city",
                          value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Label>State</Label>
                    {/* <Input
                      name="state"
                      id="state"
                      value={form?.state || ""}
                      // disabled={true}
                      onChange={(e: any) => {
                        handleOnInputChange({
                          name: "state",
                          value: e.target.value,
                        });
                      }}
                    /> */}
                    <Select
                      className="React_selector text-white"
                      classNamePrefix="react-select"
                      placeholder="Select"
                      isSearchable
                      name="state"
                      id="state"
                      value={form?.state}
                      options={statesList}
                      onChange={(e: any) => {
                        handleOnInputChange({
                          name: "state",
                          value: e,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Label>PinCode</Label>
                    <Input
                      type="text"
                      name="pinCode"
                      className="form-control"
                      placeholder="Ex: 524302"
                      value={form?.pinCode}
                      // disabled={true}
                      onChange={(e: any) => {
                        handleOnInputChange({
                          name: "pinCode",
                          value:
                            e.target.value?.length <= 6
                              ? e.target.value
                              : form?.pinCode,
                        });
                        if (
                          e.target.value?.length <= 6 ||
                          e.target.value?.length > 1
                        ) {
                          handleChangeDebounceForMobile({
                            name: "pinCode",
                          });
                        }
                        if (
                          e.target.value?.length === 0 ||
                          (e.target.value?.length === 6 &&
                            regexForPinCode.test(form?.pinCode))
                        ) {
                          setPinCard("");
                        }
                      }}
                    />
                    <p className="RequiredField">{errorPinCard}</p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <Button
            className="updateProfileButton"
            onClick={() => handleSubmit()}
          >
            Update
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    userDetails: state?.usersReducer?.getUsersDetails,
  };
};
export default connect(mapStateToProps, {
  uploadImages1,
  fetchMainLogoPresignedUrl,
  getUsers,
  updateUsers,
  updateOrganisation,
})(withRouter(EditProfileOrganizationDetails));
