import {types } from "./actionTypes";

// creating action
export const getWeaveType = (filters:any, callback:any) =>{
    return {
        type:types.GET_WEAVE_TYPE,
        filters,
        callback
    }
}
export const getWeaveTypeFilter = (filters:any, callback:any) =>{
    return {
        type:types.GET_WEAVE_TYPE_FILTER,
        filters,
        callback
    }
}
export const createWeaveType = (payload:any, callback:any) =>{
    return {
        type:types.CREATE_WEAVE_TYPE,
        payload,
        callback
    }
}
export const updateWeaveType = (payload:any, callback:any) =>{
    return {
        type:types.UPDATE_WEAVE_TYPE,
        payload,
        callback
    }
}
export const deleteWeaveType = (payload:any, callback:any) =>{
    return {
        type:types.DELETE_WEAVE_TYPE,
        payload,
        callback
    }
}