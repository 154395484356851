import { types } from "./actionTypes";

// creating action
export const getUsers = (filters: any, callback: any) => {
  return {
    type: types.GET_USERS,
    filters,
    callback,
  };
};
export const getUsersPrpfileLogo = (filters: any, callback: any) => {
  return {
    type: types.GET_USERS_LOGO_PROFILE,
    filters,
    callback,
  };
};

export const createUser = (payload: any, callback: any) => {
  return {
    type: types.CREATE_USERS,
    payload,
    callback,
  };
};
export const registerUser = (payload: any, callback: any) => {
  return {
    type: types.REGISTER_USERS,
    payload,
    callback,
  };
};
export const updateUsers = (payload: any, callback: any) => {
  return {
    type: types.UPDATE_USERS,
    payload,
    callback,
  };
};
export const updateCompanyProfile = (payload: any, callback: any) => {
  return {
    type: types.UPDATE_COMPANY_PROFILE,
    payload,
    callback,
  };
};
export const deleteUsers = (payload: any, callback: any) => {
  return {
    type: types.DELETE_USERS,
    payload,
    callback,
  };
};
export const getUserRoles = (filters: any, callback: any) => {
  return {
    type: types.GET_ROLES,
    filters,
    callback,
  };
};
export const changeUsersStatus = (payload: any, callback: any) => {
  return {
    type: types.CHANGE_USER_STATUS,
    payload,
    callback,
  };
};
export const createUserRole = (payload: any, callback: any) => {
  return {
    type: types.CREATE_ROLE,
    payload,
    callback,
  };
};
