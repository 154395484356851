import { combineReducers } from "redux";
// importing all reducers
import authLoginReducer from "./auth/login/reducer";
import artformReducer from "./artform/reducer";
import typeReducer from "./type/reducer";
import categoryReducer from "./category/reducer";
import subCategoryReducer from "./sub-category/reducer";
import productReducer from "./products/reducer";
import usersReducer from "./users/reducer";
import weaveTypeReducer from "./weave-type/reducer";
import organisationReducer from "./organisation/reducer";
import dashboardReducer from "./dashboard/reducer";
const rootReducer = combineReducers({
  authLoginReducer,
  artformReducer,
  categoryReducer,
  subCategoryReducer,
  typeReducer,
  organisationReducer,
  productReducer,
  usersReducer,
  weaveTypeReducer,
  dashboardReducer,
});
export default rootReducer;
