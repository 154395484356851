import {types } from "./actionTypes";

// creating action
export const getArtforms = (filters:any, callback:any) =>{
    return {
        type:types.GET_ARTFORM,
        filters,
        callback
    }
}
export const getArtformsWithFilters = (filters:any, callback:any) =>{
    return {
        type:types.GET_ARTFORM_FILTERS,
        filters,
        callback
    }
}

export const createArtform = (payload:any, callback:any) =>{
    return {
        type:types.CREATE_ARTFORM,
        payload,
        callback
    }
}
export const updateArtforms = (payload:any, callback:any) =>{
    return {
        type:types.UPDATE_ARTFORM,
        payload,
        callback
    }
}
export const deleteArtforms = (payload:any, callback:any) =>{
    return {
        type:types.DELETE_ARTFORM,
        payload,
        callback
    }
}