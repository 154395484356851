import React from "react";
const DownloadIcon = (props: any) => {
  const { item, i, name, image } = props;

  const handleClick = (data: any) => {
    let fileTypeSplit = data?.type.split("/");
    let fileType: any = "";
    if (
      data?.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      fileType = "docx";
    } else if (data?.type === "application/msword") {
      fileType = "doc";
    } else {
      fileType = fileTypeSplit[fileTypeSplit?.length - 1];
    }
    let headers = new Headers();

    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append(
      "Access-Control-Allow-Headers",
      "content-type, authorization"
    );
    fetch(data?.url, {
      method: "GET",
      headers: {},
      // mode:"no-cors",
      cache: "no-cache",
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          let saveFile: any = new Date().getSeconds();
          link.download = `${name}${saveFile}.${fileType}`;
          link.click();
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  return (
    <>
      <svg
        key={i}
        onClick={() =>
          handleClick({
            url: item?.blobImage?.length ? item?.blobImage : image,
            type:
              item?.type?.length > 0
                ? item?.type
                : `image.${image.split(".")[1]}`,
            name: name,
          })
        }
        className={
          props.placePosition === "first" ? "DownloadIcon1" : "DownloadIcon"
        }
        width="25"
        height="25"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="25" rx="1" fill="#FFF"></rect>
        <path
          d="M9 7V8.33333C9 8.51014 8.92976 8.67971 8.80474 8.80474C8.67971 8.92976 8.51014 9 8.33333 9H3.66667C3.48986 9 3.32029 8.92976 3.19526 8.80474C3.07024 8.67971 3 8.51014 3 8.33333V7"
          fill="#FFF"
        ></path>
        <path
          d="M9 7V8.33333C9 8.51014 8.92976 8.67971 8.80474 8.80474C8.67971 8.92976 8.51014 9 8.33333 9H3.66667C3.48986 9 3.32029 8.92976 3.19526 8.80474C3.07024 8.67971 3 8.51014 3 8.33333V7"
          stroke="#404B3B"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M4.33301 5.33331L5.99967 6.99998L7.66634 5.33331"
          fill="#FFF"
        ></path>
        <path
          d="M4.33301 5.33331L5.99967 6.99998L7.66634 5.33331"
          stroke="#404B3B"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M6 7V3"
          stroke="#404B3B"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </>
  );
};

export default DownloadIcon;
