import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import  { AxiosResponse } from "axios";
//medthods
import {
  postApi,
  getApi,
  deleteApi,
  putApi
} from "../../helpers/auth_helpers";

// workers
function* workerGetCategory({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let categoryId = filters?.categoryId ? filters?.categoryId : "";
  let categoryName = filters?.categoryName ? filters?.categoryName : "";
  let typeId = filters?.typeId ? filters?.typeId :"";
  let artFormId= filters?.artformId ? filters?.artformId:"";
  let startDate = filters?.startDate ? filters?.startDate : "";
  let endDate = filters?.endDate ? filters?.endDate :"";
  let sortBy = filters?.sortBy ? filters?.sortBy :"createdAt";
  let orderBy = filters?.orderBy ? filters?.orderBy :"-1";
  let createdBy = filters?.createdBy ? filters?.createdBy :""

  try {
    const url: string =
    process.env.REACT_APP_SERVERURL +
      "/category/categories?offset=" +
      offset +
      "&limit=" +
      limit +
      "&categoryId="+
      categoryId+
      "&typeId="+
      typeId+
      "&artFormId="+
      artFormId+
      "&name=" +
      categoryName+
      "&sortBy="+
      sortBy+
      "&orderBy="+
      orderBy+
      "&startDate="+
      startDate+
      "&endDate="+
      endDate+
      "&createdBy="+
      createdBy;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_CATEGORY_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetCategoryFilter({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let categoryName = filters?.categoryName ? filters?.categoryName : "";
  let typeId = filters?.typeId ? filters?.typeId :"";
  let artFormId= filters?.artformId ? filters?.artformId:"";
  try {
    const url: string =
    process.env.REACT_APP_SERVERURL +
      "/category/categories?offset=" +
      offset +
      "&limit=" +
      limit +
      "&typeId="+
      typeId+
      "&artFormId="+
      artFormId+
      "&name=" +
      categoryName;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_CATEGORY_FILTERS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateCategory({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/category/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_CATEGORY_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateCategory({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/category/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_CATEGORY_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteCategory({ payload, callback }: any) {
    try {
      const url: string = process.env.REACT_APP_SERVERURL + "/category/delete";
      const response: AxiosResponse = yield call(deleteApi, url, payload);
      callback(response);
      yield put({
        type: types.DELETE_CATEGORY_SUCCESS,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
  }

// watchers
export function* watchGetCategory() {
  yield takeEvery(types.GET_CATEGORY, workerGetCategory);
}
export function* watchGetCategoryFilter() {
  yield takeEvery(types.GET_CATEGORY_FILTERS, workerGetCategoryFilter);
}
export function* watchCreateCategory() {
  yield takeEvery(types.CREATE_CATEGORY, workerCreateCategory);
}
export function* watchUpdateCategory() {
  yield takeEvery(types.UPDATE_CATEGORY, workerUpdateCategory);
}
export function* watchDeleteCategory() {
    yield takeEvery(types.DELETE_CATEGORY, workerDeleteCategory);
  }

function* categorySaga() {
  yield all([fork(watchGetCategory)]);
  yield all([fork(watchGetCategoryFilter)]);
  yield all([fork(watchCreateCategory)]);
  yield all([fork(watchUpdateCategory)]);
  yield all([fork(watchDeleteCategory)]);
}
export default categorySaga;
