import React, { useState, useEffect } from "react";
import { Label, Input, Button, Row, Col, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import ReactChipInput from "react-chip-input";
import { Toggle } from "rsuite";
//components
import ShowIcon from "../../common/Components/Icons/ShowIcon";
import HideIcon from "../../common/Components/Icons/HideIcon";
import { debounce } from "../../common/commonFiles/debounce";
import Loader from "../../common/Components/Loader/Loader";
import notifications from "../../common/Components/Notifications/Notification";
import FilePreviewPart from "./FilePreviewPart";
import ImageClose from "../../common/Components/Icons/ImageClose";
import ImageAddIcon from "../../common/Components/Icons/ImageAddIcon";
import ProductDesignIcon from "../../common/Components/Icons/ProductDesignIcon";
import ProductWeaveIcon from "../../common/Components/Icons/ProductWeaveIcon";
import ProductFinishIcon from "../../common/Components/Icons/ProductFinishIcon";
import ImageDivPart from "./ImageDivPart";
import ArtformModal from "../artform/ArtformModal";
import TypeModal from "../type/TypeModal";
import CategoryModal from "../category/CategoryModal";
import SubCategoryModal from "../sub-category/SubCategoryModal";
import WeaveTypeModal from "../weave-type/WeaveTypeModal";
import CircleLoader from "../../common/Components/Loader/CircleLoader";

// actions
import { getArtforms } from "../../Store/artform/actions";
import { createUser, updateUsers } from "../../Store/users/actions";
import {
  fetchProfilePresignedUrl,
  fetchProductWeavingPresignedUrl,
  fetchProductDesigningPresignedUrl,
  fetchProductFinishingPresignedUrl,
  fetchProductDyingPresignedUrl,
  uploadImages1,
  uploadImages,
} from "../../Store/uploadModule/actions";
import { getUsers } from "../../Store/users/actions";
import { getCategory } from "../../Store/actions";
import { getSubCategorys } from "../../Store/actions";
import { getTypes } from "../../Store/type/actions";
import { getWeaveType } from "../../Store/weave-type/actions";
import { createProduct, getProducts } from "../../Store/products/actions";
import { AddIcon } from "../../common/Components/Icons/AddIcon";
import CreateUserModal from "../users/CreateUserModal";
import CreateProductHeader from "./CreateProductHeader";
import Pin from "../../common/Components/Icons/Pin";
import Unpin from "../../common/Components/Icons/Unpin";
import ResetIcon from "../../common/Components/Icons/ResetIcon";

import {
  zariUsedOptions,
  timeTakenInMonthsOptions,
  yarnUsedOptions,
  typeOfSareeByDesignOptions,
} from "./options";

// let zariUsedOptions = [
//   {
//     label: "None",
//     value: "",
//   },
//   {
//     label: "Real zari",
//     value:
//       "Cotton base - Wrapped with 99.8% silver electroplated with 1.5% 24 carat gold",
//   },
//   {
//     label: "Semi real zari",
//     value: "Cotton base - 8% silver electroplated with 1.5% 24 carat gold",
//   },
//   {
//     label: "Tested zari",
//     value: "Cotton base - Wrapped with brass Metal ",
//   },
// ];
// selector options
var productOptions: any = [];
var artformOptions: any = [];
var typeOptions: any = [];
var categoryOptions: any = [];
var designerOptions: any = [];
var weaverOptions: any = [];
var dyerOptions: any = [];
var finisherOptions: any = [];
let subCategoryOptions: any = [];
let weaveTypeOptions: any = [];

// logo
let fileObjLogo: any = [];
let fileArrayLogo: any = [];
let promiseForLogo: any = [];
let promiseLogoUrl: any = [];
let promiseLogoStatusCode: any = [];

// designing
let fileObjDesign: any = [];
let fileArray: any = [];
let promiseForDesign: any = [];
let promiseDesignUrl: any = [];
let promiseDesignStatusCode: any = [];
// weaving
let fileObjWeaving: any = [];
let fileArrayWeaving: any = [];
let promiseForWeaving: any = [];
let proWeavingUrl: any = [];
let proWeavingCodes: any = [];
// dying
let fileObjDying: any = [];
let fileArrayDying: any = [];
let promiseForDying: any = [];
let proDyingUrl: any = [];
let proDyingCodes: any = [];
// finishing
let fileObjFinishing: any = [];
let fileArrayFinishing: any = [];
let promiseForFinishing: any = [];
let proFinishingUrl: any = [];
let proFinishingUCodes: any = [];
// variant
let fileObjVariant: any = [];
let fileArrayVariant: any = [];
let promiseForVariant: any = [];
let promiseVariantUrl: any = [];
let promiseVariantStatusCode: any = [];

let allStatusCodes: any = [];
let productMainImageStatusCode: any = [];
let fileObjImages: any = [];

let finishingRoleIds = [
  "63c64da18667bd64a57c6303",
  "63c64da18667bd64a57c6302",
  "63c64da18667bd64a57c6301",
];

const CreateProduct = (props: any) => {
  const history = useHistory();
  const location: any = useLocation();
  let productCount: any = localStorage?.getItem("ProductCount");
  const [form, setForm]: any = useState({
    artform: "",
    type: "",
    category: "",
    subCategory: "",
    weaveType: "",
    productName: "",
    designer: "",
    designerMedia: [],
    weaver: "",
    weaverMedia: [],
    dyer: "",
    dyerMedia: [],
    finisher: "",
    finisherMedia: [],
    profileURL: [],
    tags: [],
    singleTag: "",
    variantCode: "",
    variantImages: [],
    // new
    nameOfBrand: "",
    nameOfWeaver: "",
    weightOfSaree: "",
    zariUsed: "",
    silkMark: "",
    handloomMark: "",
    madeInIndia: true,
    weightOfZariUsed: "",
    workingHours: "",
    yarnType: "",
    loomNum: "",
    xuid: "",
    // new fields
    SrNo: Number(productCount) + 1,
    weavingCenter: "Varanasi",
    typeOfSareeByDesign: "",
    productionTimeInMonths: "",
  });
  const [selectedProduct, setSelectedProduct]: any = useState();
  const [switchButton, setSwitchButton]: any = useState(false);
  const [filterProduct, setFilterProduct]: any = useState("");
  const [loading, setLoading]: any = useState(false);
  const [baseImageLogo, setBaseImageLogo]: any = useState([]);
  const [baseImageDying, setBaseImageDying]: any = useState([]);
  const [baseImageDesigner, setBaseImageDesigner]: any = useState([]);
  const [baseImageVariant, setBaseImageVariant]: any = useState([]);
  const [deleteIndexLogo, setDeleteIndexLogo]: any = useState();
  const [deleteIndexDesigner, setDeleteIndexDesigner]: any = useState();
  const [deleteIndexWeaver, setDeleteIndexWeaver]: any = useState();
  const [deleteIndexDying, setDeleteIndexDying]: any = useState();
  const [deleteIndexFinisher, setDeleteIndexFinisher]: any = useState();
  const [deleteIndexVariant, setDeleteIndexVariant]: any = useState();

  const [baseImageWeaver, setBaseImageWeaver]: any = useState([]);
  const [baseImageFinisher, setBaseImageFinisher]: any = useState([]);

  const [selectedFile, setSelectedFile]: any = useState();
  const [selectedFileDying, setSelectedFileDying]: any = useState();
  const [selectedFileWeaver, setSelectedFileWeaver]: any = useState();
  const [selectedFileDesigner, setSelectedFileDesigner]: any = useState();
  const [selectedFileFinisher, setSelectedFileFinisher]: any = useState();
  const [selectedFileVariant, setSelectedFileVariant]: any = useState();
  const [selectTab, setSelectTab]: any = useState("");
  const [selectTab1, setSelectTab1]: any = useState("");
  const [baseindex, setBaseIndex]: any = useState({
    designing: "",
    dying: "",
    weaving: "",
    finishing: "",
    logo: "",
  });
  const [baseindexForLogo, setBaseIndexForLogo]: any = useState("");
  const [Error, setError]: any = React.useState({
    artform: "",
    type: "",
    category: "",
    subCategory: "",
    weaveType: "",
    variantImages: "",
    variantCode: "",
    SrNo: "",
  });
  const [openModal, setOpenModal]: any = useState({
    artform: false,
    type: false,
    category: false,
    subCategory: false,
    weaveType: false,
    designing: false,
    dying: false,
    weaving: false,
    finishing: false,
  });
  const [filterValue, setFilterValue]: any = useState({
    type: "",
    category: "",
    subCategory: "",
    weaveType: "",
    product: "",
  });
  const [loader, setLoader]: any = useState(
    false
    //   {
    //   designing: false,
    //   weaving: false,
    //   dying: false,
    //   finishing: false,
    //   variants: false,
    // }
  );
  const [handleNameFlag, setHandleNameFlag]: any = useState({
    designing: false,
    weaving: false,
    dying: false,
    finishing: false,
  });
  const [mainImageLoader, setMainImageLoader]: any = useState(false);
  const fileInputProfile: any = React.useRef();
  const fileInputDesigner: any = React.useRef();
  const fileInputWeaver: any = React.useRef();
  const fileInputUploadDying: any = React.useRef();
  const fileInputFinisher: any = React.useRef();
  const fileInputVariant: any = React.useRef();

  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  let organizationId =
    authUser?.users?.role === "ORGANIZATION_MANAGER" ||
      authUser?.users?.role === "ORGANIZATION_ADMIN"
      ? authUser?.users?.organizationResponse?._id
      : "";
  const fileUploadTriggerVariant = () => {
    fileInputVariant.current.click();
  };
  const fileUploadTrigger = () => {
    fileInputProfile.current.click();
  };
  const fileUploadTriggerWeaver = () => {
    fileInputWeaver.current.click();
  };
  const fileUploadTriggerDesigner = () => {
    fileInputDesigner.current.click();
  };

  const fileUploadTriggerFinisher = () => {
    fileInputFinisher.current.click();
  };

  const fileUploadTriggerDying = () => {
    fileInputUploadDying.current.click();
  };
  const handleSwitchButton = (checked: boolean) => {
    setSwitchButton(checked);
  };
  useEffect(() => {
    // if (location.pathname.split("/")[1] === "edit-product") {
    resetVariables();
    fileArray = [];
    fileArrayDying = [];
    fileArrayFinishing = [];
    fileArrayWeaving = [];
    fileObjDesign = [];
    fileObjDying = [];
    fileObjLogo = [];
    fileObjFinishing = [];
    fileObjWeaving = [];
    fileObjVariant = [];
    productMainImageStatusCode = [];
    // uniqueArr = [];
    // }
  }, []);
  const resetVariables = () => {
    // selector options
    productOptions = [];
    artformOptions = [];
    typeOptions = [];
    categoryOptions = [];
    designerOptions = [];
    weaverOptions = [];
    dyerOptions = [];
    finisherOptions = [];
    subCategoryOptions = [];
    weaveTypeOptions = [];

    // logo
    fileObjLogo = [];
    fileArrayLogo = [];
    promiseForLogo = [];
    promiseLogoUrl = [];
    promiseLogoStatusCode = [];

    // designing
    fileObjDesign = [];
    fileArray = [];
    promiseForDesign = [];
    promiseDesignUrl = [];
    promiseDesignStatusCode = [];
    // weaving
    fileObjWeaving = [];
    fileArrayWeaving = [];
    promiseForWeaving = [];
    proWeavingUrl = [];
    proWeavingCodes = [];
    // dying
    fileObjDying = [];
    fileArrayDying = [];
    promiseForDying = [];
    proDyingUrl = [];
    proDyingCodes = [];
    // finishing
    fileObjFinishing = [];
    fileArrayFinishing = [];
    promiseForFinishing = [];
    proFinishingUrl = [];
    proFinishingUCodes = [];
    // variant
    fileObjVariant = [];
    fileArrayVariant = [];
    promiseForVariant = [];
    promiseVariantUrl = [];
    promiseVariantStatusCode = [];

    allStatusCodes = [];
    productMainImageStatusCode = [];
    fileObjImages = [];
  };
  useEffect(() => {
    getArtformData();
    getCreatorsDataDesignerOption();
    getCreatorsDataDyingOption();
    getCreatorsDataFinishingOption();
    getCreatorsDataWeaverOption();
    CallingGettProductForFilter();
  }, []);

  useEffect(() => {
    if (location?.pathname === "/createProduct") {
      fileArray = [];
      fileArrayDying = [];
      fileArrayFinishing = [];
      fileArrayWeaving = [];
      fileObjDesign = [];
      fileObjDying = [];
      fileObjFinishing = [];
      fileObjWeaving = [];
      fileObjVariant = [];
      productMainImageStatusCode = [];
    }
  }, []);
  useEffect(() => {
    if (form?.type?.value?.length) {
      getCategoryData();
    }
  }, [form?.type?.value]);
  useEffect(() => {
    if (form?.artform?.value?.length) {
      getTypesData();
    }
  }, [form?.artform?.value]);
  useEffect(() => {
    if (form?.category?.value?.length) {
      getSubCategoryData();
    }
  }, [form?.category?.value]);
  useEffect(() => {
    if (form?.subCategory?.value?.length) {
      getWeaveTypeData();
    }
  }, [form?.subCategory?.value]);

  // useEffect(() => {
  //   if (selectedFile) {
  //     S3Upload();
  //   }
  // }, [selectedFile]);
  useEffect(() => {
    deleteImagesByIndex({
      deleteIndex: deleteIndexLogo,
      baseImage: baseImageLogo,
      media: form?.profileURL,
      selectedFiles: selectedFile,
      fileArr: fileObjLogo,
      name: "logo",
    });
  }, [deleteIndexLogo]);

  useEffect(() => {
    deleteImagesByIndex({
      deleteIndex: deleteIndexVariant,
      baseImage: baseImageVariant,
      media: form?.variantImages,
      selectedFiles: selectedFileVariant,
      fileArr: fileObjVariant,
      name: "variant",
    });
  }, [deleteIndexVariant]);

  useEffect(() => {
    deleteImagesByIndex({
      deleteIndex: deleteIndexDesigner,
      baseImage: baseImageDesigner,
      media: form?.designerMedia,
      selectedFiles: selectedFileDesigner,
      fileArr: fileObjDesign,
      name: "desinging",
    });
  }, [deleteIndexDesigner]);

  useEffect(() => {
    deleteImagesByIndex({
      deleteIndex: deleteIndexWeaver,
      baseImage: baseImageWeaver,
      media: form?.weaverMedia,
      selectedFiles: selectedFileWeaver,
      fileArr: fileObjWeaving,
      name: "weaving",
    });
  }, [deleteIndexWeaver]);

  useEffect(() => {
    deleteImagesByIndex({
      deleteIndex: deleteIndexDying,
      baseImage: baseImageDying,
      media: form?.dyerMedia,
      selectedFiles: selectedFileDying,
      fileArr: fileObjDying,
      name: "dying",
    });
  }, [deleteIndexDying]);

  useEffect(() => {
    deleteImagesByIndex({
      deleteIndex: deleteIndexFinisher,
      baseImage: baseImageFinisher,
      media: form?.finisherMedia,
      selectedFiles: selectedFileFinisher,
      fileArr: fileObjFinishing,
      name: "finishing",
    });
  }, [deleteIndexFinisher]);

  // delete data  reuse function

  const deleteImagesByIndex = (data: any) => {
    let { deleteIndex, baseImage, media, selectedFiles, name, fileArr } = data;
    let deleteBaseImage = baseImage.filter(
      (ele: any, i: any) =>
        i !== deleteIndex?.i && ele?.blobImage !== deleteIndex?.item?.blobImage
    );
    let deleteMedia =
      media?.length && media.filter((ele: any, i: any) => i !== deleteIndex?.i);
    let deletefileArray =
      fileArr?.length &&
      fileArr.filter(
        (ele: any, i: any) => ele?.name !== deleteIndex?.item?.fileName
      );
    let deleteSelectedFile =
      selectedFiles?.length &&
      selectedFiles.filter((ele: any, i: any) => i !== deleteIndex?.i);
    if (name === "desinging") {
      fileObjDesign = deletefileArray?.length ? deletefileArray : [];
      setForm({
        ...form,
        designerMedia: deleteMedia,
      });
      setSelectedFileDesigner(deleteSelectedFile);
      setBaseImageDesigner([...deleteBaseImage]);
    } else if (name === "weaving") {
      fileObjWeaving = deletefileArray?.length ? deletefileArray : [];
      setSelectedFileWeaver(deleteSelectedFile);
      setForm({
        ...form,
        weaverMedia: deleteMedia,
      });
      setBaseImageWeaver([...deleteBaseImage]);
    } else if (name === "dying") {
      fileObjDying = deletefileArray?.length ? deletefileArray : [];
      setForm({
        ...form,
        dyerMedia: deleteMedia,
      });
      setBaseImageDying([...deleteBaseImage]);
      setSelectedFileDying(deleteSelectedFile);
    } else if (name === "finishing") {
      fileObjFinishing = deletefileArray?.length ? deletefileArray : [];
      setSelectedFileFinisher(deleteSelectedFile);
      setForm({
        ...form,
        finisherMedia: deleteMedia,
      });
      setBaseImageFinisher(deleteBaseImage);
    } else if (name === "variant") {
      fileObjVariant = deletefileArray?.length ? deletefileArray : [];
      setSelectedFileVariant(deleteSelectedFile);
      setForm({
        ...form,
        variantImages: deleteMedia,
      });
      setBaseImageVariant([...deleteBaseImage]);
    } else if (name === "logo") {
      fileObjLogo = deletefileArray?.length ? deletefileArray : [];
      setSelectedFile(deleteSelectedFile);
      setForm({
        ...form,
        profileURL: deleteMedia,
      });
      setBaseImageLogo([...deleteBaseImage]);
    }
  };

  // api calls
  const getWeaveTypeData = (data?: any) => {
    props.getWeaveType(
      {
        offset: 0,
        limit: 10,
        artformId: form?.artform?.value || data?.artform,
        typeId: form?.type?.value || data?.type,
        categoryId: form?.category?.value || data?.category,
        subCategoryId: form?.subCategory?.value || data?.subCategory,
        weaveTypeId: form?.weaveType?.value,
        weaveTypeName: data?.value,
      },
      (res: any) => {
        weaveTypeOptions = [];
        res?.data?.docs.map((ele: any) => {
          weaveTypeOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };

  const getSubCategoryData = (data?: any) => {
    props.getSubCategorys(
      {
        offset: 0,
        limit: 10,
        artformId: form?.artform?.value || data?.artform,
        typeId: form?.type?.value || data?.type,
        categoryId: form?.category?.value || data?.category,
        // subCategoryId: form?.subCategory?.value || data?.subCategory,
        subCategoryName: data?.value,
      },
      (res: any) => {
        subCategoryOptions = [];
        res?.data?.docs.map((ele: any) => {
          subCategoryOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getCreatorsData = (data?: any) => {
    props.getUsers(
      {
        offset: 0,
        limit: 10,
        organizationId,
        userId: data?.userId,
      },
      (res: any) => {
        designerOptions = [];
        res?.data?.docs.map((ele: any) => {
          let formValues = form;
          if (data?.name === "designing") {
            formValues = {
              ...formValues,
              designer: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
                // {
                //   id: res?.data?.docs[0]?._id,
                //   roleId: res?.data?.docs[0]?.businessRoleDetails[0]?._id,
                // },
              },
            };
            getCreatorsDataDesignerOption();
          }
          if (data?.name === "weaving") {
            formValues = {
              ...formValues,
              weaver: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
                // {
                //   id: res?.data?.docs[0]?._id,
                //   roleId: res?.data?.docs[0]?.businessRoleDetails[0]?._id,
                // },
              },
            };
            getCreatorsDataWeaverOption();
          }
          if (data?.name === "dying") {
            formValues = {
              ...formValues,
              dyer: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
                // {
                //   id: res?.data?.docs[0]?._id,
                //   roleId: res?.data?.docs[0]?.businessRoleDetails[0]?._id,
                // },
              },
            };
            getCreatorsDataDyingOption();
          }
          if (data?.name === "finishing") {
            formValues = {
              ...formValues,
              finisher: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
                // {
                //   id: res?.data?.docs[0]?._id,
                //   roleId: res?.data?.docs[0]?.businessRoleDetails[0]?._id,
                // },
              },
            };
            getCreatorsDataFinishingOption();
          }
          setForm(formValues);
        });
      }
    );
  };
  const getCreatorsDataDesignerOption = (name?: any) => {
    props.getUsers(
      {
        offset: 0,
        limit: 10,
        organizationId,
        usersName: name,
        role: "63c64da18667bd64a57c62fe",
      },
      (res: any) => {
        designerOptions = [];
        res?.data?.docs.map((ele: any) => {
          if (ele?.businessRoleDetails[0]?.role === "NAQSEBAND") {
            designerOptions.push({
              label: ele?.name,
              value: ele?._id,
            });
          }
        });
      }
    );
  };
  const getCreatorsDataWeaverOption = (name?: any) => {
    props.getUsers(
      {
        offset: 0,
        limit: 10,
        organizationId,
        usersName: name,
        role: "63c64da18667bd64a57c62ff",
      },
      (res: any) => {
        weaverOptions = [];
        res?.data?.docs.map((ele: any) => {
          if (ele?.businessRoleDetails[0]?.role === "WEAVER") {
            weaverOptions.push({
              label: ele?.name,
              value: ele?._id,
            });
          }
        });
      }
    );
  };
  const getCreatorsDataDyingOption = (name?: any) => {
    props.getUsers(
      {
        offset: 0,
        limit: 10,
        organizationId,
        usersName: name,
        role: "63c64da18667bd64a57c6300",
      },
      (res: any) => {
        dyerOptions = [];
        res?.data?.docs.map((ele: any) => {
          if (ele?.businessRoleDetails[0]?.role === "DYER") {
            dyerOptions.push({
              label: ele?.name,
              value: ele?._id,
            });
          }
        });
      }
    );
  };
  const getCreatorsDataFinishingOption = (name?: any) => {
    props.getUsers(
      {
        offset: 0,
        limit: 10,
        organizationId,
        usersName: name,
        role: [...finishingRoleIds],
      },
      (res: any) => {
        finisherOptions = [];
        res?.data?.docs.map((ele: any) => {
          if (
            ele?.businessRoleDetails[0]?.role === "RAFUGAR" ||
            ele?.businessRoleDetails[0]?.role === "STAIN_MASTER" ||
            ele?.businessRoleDetails[0]?.role === "TASSEL"
          ) {
            finisherOptions.push({
              label: ele?.name,
              value: ele?._id,
            });
          }
        });
      }
    );
  };
  const getCategoryData = (data?: any) => {
    props.getCategory(
      {
        offset: 0,
        limit: 10,
        artformId: form?.artform?.value || data?.artform,
        typeId: form?.type?.value || data?.type,
        categoryName: data?.value,
      },
      (res: any) => {
        categoryOptions = [];
        res?.data?.docs.map((ele: any) => {
          categoryOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getTypesData = (data?: any) => {
    props.getTypes(
      {
        offset: 0,
        limit: 10,
        artFormId: form?.artform?.value || data?.artform,
        typeName: data?.value,
      },
      (res: any) => {
        typeOptions = [];
        res?.data?.docs.map((ele: any) => {
          typeOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const CallingGettProductForFilter = (value?: any) => {
    props.getProducts(
      {
        offset: 0,
        limit: 10,
        ProductsName: value?.value,
      },
      (res: any) => {
        setLoading(false);
        if (res?.data?.docs?.length) {
          productOptions = [];
          res.data?.docs.map((ele: any, i: any) => {
            productOptions.push({
              label: ele?.name,
              value: {
                id: ele?._id,
                artform: ele?.artform,
                type: ele?.type,
                category: ele?.category,
                subCategory: ele?.subcategory,
                weaveType: ele?.weavetype,
                productName: ele?.name,
                tags: ele?.tags,
              },
            });
          });
        }
      }
    );
  };
  const CallingGettProduct = (value: any) => {
    props.getProducts(
      {
        offset: 0,
        limit: 10,
        ProductsName: value?.productName,
        artformId: value?.artform,
        typeId: value?.type,
        categoryId: value?.category,
        subCategoryId: value?.subCategory,
        weaveTypeId: value?.weaveType,
      },
      (res: any) => {
        setLoading(false);
        if (res?.data?.docs?.length) {
          res.data?.docs.map((ele: any, i: any) => {
            if (value?.productName === ele?.name) {
              setFilterProduct(ele?.name);
            }
          });
        }
      }
    );
  };

  const getArtformData = (name?: any) => {
    props.getArtforms(
      {
        offset: 0,
        limit: 10,
        artformName: name,
      },
      (res: any) => {
        artformOptions = [];
        res?.data?.docs.map((ele: any) => {
          artformOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };

  const callingCreateProduct = (payload: any, msg?: any) => {
    // let result: any = validate();
    // if (result?.length === 0) {
    setLoading(true);
    props.createProduct(payload, (res: any) => {
      setLoading(false);
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message + " " + (msg?.length > 0 ? msg : ""),
          type: "success",
        });
        history.push("/products");
      } else if (res?.status === 400) {
        notifications({
          title: "Error!",
          message: res?.detail,
          type: "danger",
        });
        resetVariables();

        fileObjDesign = [];
        fileObjDying = [];
        fileObjFinishing = [];
        fileObjWeaving = [];
        fileObjVariant = [];
        fileArray = [];
        fileArrayDying = [];
        fileArrayFinishing = [];
        fileArrayWeaving = [];
        allStatusCodes = [];
        proFinishingUCodes = [];
        proDyingCodes = [];
        promiseDesignStatusCode = [];
        proWeavingCodes = [];
        proDyingUrl = [];
        proWeavingUrl = [];
        promiseDesignUrl = [];
        proFinishingUrl = [];
        promiseForDesign = [];
        promiseForDying = [];
        promiseForFinishing = [];
        promiseForWeaving = [];
        promiseForVariant = [];
        promiseVariantUrl = [];
        promiseVariantStatusCode = [];
        fileArrayVariant = [];
        fileObjLogo = [];
        fileArrayLogo = [];
        promiseForLogo = [];
        promiseLogoUrl = [];
        promiseLogoStatusCode = [];
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
        resetVariables();

        fileObjDesign = [];
        fileObjDying = [];
        fileObjFinishing = [];
        fileObjWeaving = [];
        fileObjVariant = [];
        fileArray = [];
        fileArrayDying = [];
        fileArrayFinishing = [];
        fileArrayWeaving = [];
        allStatusCodes = [];
        proFinishingUCodes = [];
        proDyingCodes = [];
        promiseDesignStatusCode = [];
        proWeavingCodes = [];
        proDyingUrl = [];
        proWeavingUrl = [];
        promiseDesignUrl = [];
        proFinishingUrl = [];
        promiseForDesign = [];
        promiseForDying = [];
        promiseForFinishing = [];
        promiseForWeaving = [];
        promiseForVariant = [];
        promiseVariantUrl = [];
        promiseVariantStatusCode = [];
        fileArrayVariant = [];
        fileObjLogo = [];
        fileArrayLogo = [];
        promiseForLogo = [];
        promiseLogoUrl = [];
        promiseLogoStatusCode = [];
      }
    });
    // }
  };
  const validate = () => {
    let error = "";
    let err = Error;

    if (form?.artform === "") {
      error = "Required";
      err = {
        ...err,
        artform: "ArtForm is Required!",
      };
    }
    if (form?.type === "") {
      error = "Required";
      err = {
        ...err,
        type: "Type Name is Required!",
      };
    }
    if (form?.category === "") {
      error = "Required";
      err = {
        ...err,
        category: "Category Name is Required!",
      };
    }
    if (form?.subCategory === "") {
      error = "Required";
      err = {
        ...err,
        subCategory: "Sub-Category Name is Required!",
      };
    }
    if (form?.weaveType === "") {
      error = "Required";
      err = {
        ...err,
        weaveType: "Weave Type is Required!",
      };
    }
    if (form?.productName === "") {
      error = "Required";
      err = {
        ...err,
        product: "Product Name is Required!",
      };
    }
    if (form?.SrNo === "") {
      error = "Required";
      err = {
        ...err,
        SrNo: "SrNo is Required!",
      };
    }
    if (form?.variantCode === "") {
      error = "Required";
      setError({
        ...Error,
      });
      err = {
        ...err,
        variantCode: "Color Name is Required!",
      };
    }
    setError(err);
    return error;
  };
  const handleOnInputChangeFilters = (type: any) => {
    if (type?.name === "productFilter") {
      if (type?.value?.length >= 2 || type?.value?.length == 0) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "designing") {
      if (type?.value?.length >= 2 || type?.value?.length == 0) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "weaving") {
      if (type?.value?.length >= 2 || type?.value?.length == 0) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "dying") {
      if (type?.value?.length >= 2 || type?.value?.length == 0) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "finishing") {
      if (type?.value?.length >= 2 || type?.value?.length == 0) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "artform") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
      if (
        type?.value.length === 0 &&
        !form?.artform?.value &&
        !form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "type") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.artform?.value &&
        !form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
        });
      }
    }
    if (type?.name === "category") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
        });
      }
    }
    if (type?.name === "subCategory") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
          category: form?.category?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
          category: form?.category?.value,
        });
      }
    }
    if (type?.name === "weaveType") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
          category: form?.category?.value,
          subCategory: form?.subCategory?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
          category: form?.category?.value,
          subCategory: form?.subCategory?.value,
        });
      }
    }
  };
  const handleSubmit = async (data: any) => {
    let result: any = validate();
    if (result?.length === 0) {
      if (selectedFile?.length > 0) {
        setLoader(true);
        const resultForLogo: any = await S3UploadForLogo();
        resultForLogo.forEach((ele: any, i: any) => {
          promiseLogoStatusCode.push(ele?.statusCode);
          allStatusCodes.push(ele?.statusCode);
          if (ele?.statusCode === 200) {
            promiseLogoUrl.push({
              mediaUrl: ele?.url,
              show: baseImageLogo[i]?.show === true ? 1 : 0,
              pin: baseImageLogo[i]?.pin === true ? 1 : 0,
            });
          }
        });
        promiseLogoStatusCode.map((ele: any, i: any) => {
          if (ele === 200) {
          } else {
            notifications({
              title: "Error!",
              message: `Media ${i + 1
                } upload failed into server under logo section !`,
              type: "danger",
            });
          }
        });
        setLoader(false);
      }
      if (selectedFileDesigner?.length > 0) {
        // setLoader({ ...loader, designing: true });
        setLoader(true);

        const resultForDesigning: any = await S3UploadDesigner();
        resultForDesigning.forEach((ele: any, i: any) => {
          promiseDesignStatusCode.push(ele?.statusCode);
          allStatusCodes.push(ele?.statusCode);
          if (ele?.statusCode === 200) {
            promiseDesignUrl.push({
              mediaUrl: ele?.url,
              show: baseImageDesigner[i]?.show === true ? 1 : 0,
            });
          }
        });
        promiseDesignStatusCode.map((ele: any, i: any) => {
          if (ele === 200) {
            // notifications({
            //   title: "Success!",
            //   message: `Image Upload successfully`,
            //   type: "success",
            // });
          } else {
            notifications({
              title: "Error!",
              message: `Media ${i + 1
                } upload failed into server under designing section !`,
              type: "danger",
            });
          }
        });
        setLoader(false);
        // setLoader({ ...loader, designing: false });
      }
      if (selectedFileWeaver?.length > 0) {
        // setLoader({ ...loader, weaving: true });
        setLoader(true);

        const resultForWeaving: any = await S3UploadWeaving();
        resultForWeaving.forEach((ele: any, i: any) => {
          proWeavingCodes.push(ele?.statusCode);
          allStatusCodes.push(ele?.statusCode);
          if (ele?.statusCode === 200) {
            proWeavingUrl.push({
              mediaUrl: ele?.url,
              show: baseImageWeaver[i]?.show === true ? 1 : 0,
            });
          }
        });
        proWeavingCodes.map((ele: any, i: any) => {
          if (ele === 200) {
            // notifications({
            //   title: "Success!",
            //   message: `Image Upload successfully`,
            //   type: "success",
            // });
          } else {
            notifications({
              title: "Error!",
              message: `Media ${i + 1
                } upload failed into server under weaving section !`,
              type: "danger",
            });
          }
        });
        setLoader(false);

        // setLoader({ ...loader, weaving: false });
      }
      if (selectedFileDying?.length > 0) {
        // setLoader({ ...loader, dying: true });
        setLoader(true);

        const resultForDying: any = await S3UploadDying();
        resultForDying.forEach((ele: any, i: any) => {
          proDyingCodes.push(ele?.statusCode);
          allStatusCodes.push(ele?.statusCode);
          if (ele?.statusCode === 200) {
            proDyingUrl.push({
              mediaUrl: ele?.url,
              show: baseImageDying[i]?.show === true ? 1 : 0,
            });
          }
        });
        proDyingCodes.map((ele: any, i: any) => {
          if (ele === 200) {
            // notifications({
            //   title: "Success!",
            //   message: `Image Upload successfully`,
            //   type: "success",
            // });
          } else {
            notifications({
              title: "Error!",
              message: `Media ${i + 1
                } upload failed into server under dying section !`,
              type: "danger",
            });
          }
        });
        setLoader(false);

        // setLoader({ ...loader, dying: false });
      }
      if (selectedFileFinisher?.length > 0) {
        // setLoader({ ...loader, finishing: true });
        setLoader(true);

        const resultForFinishing: any = await S3UploadFinishing();
        resultForFinishing.forEach((ele: any, i: any) => {
          proFinishingUCodes.push(ele?.statusCode);
          allStatusCodes.push(ele?.statusCode);
          if (ele?.statusCode === 200) {
            proFinishingUrl.push({
              mediaUrl: ele?.url,
              show: baseImageFinisher[i]?.show === true ? 1 : 0,
            });
          }
        });
        proFinishingUCodes.map((ele: any, i: any) => {
          if (ele === 200) {
            // notifications({
            //   title: "Success!",
            //   message: `Image Upload successfully`,
            //   type: "success",
            // });
          } else {
            notifications({
              title: "Error!",
              message: `Media ${i + 1
                } upload failed into server under finishing section !`,
              type: "danger",
            });
          }
        });
        setLoader(false);

        // setLoader({ ...loader, finishing: false });
      }
      // if (selectedFileVariant?.length > 0) {
      //   // setLoader({ ...loader, variants: true });
      //   setLoader(true);

      //   const resultForVariants: any = await S3UploadVariants();
      //   resultForVariants.forEach((ele: any) => {
      //     promiseVariantStatusCode.push(ele?.statusCode);
      //     allStatusCodes.push(ele?.statusCode);
      //     if (ele?.statusCode === 200) {
      //       promiseVariantUrl.push(ele?.url);
      //     }
      //   });
      //   promiseVariantStatusCode.map((ele: any, i: any) => {
      //     if (ele === 200) {
      //     } else {
      //       notifications({
      //         title: "Error!",
      //         message: `File ${i + 1} upload failed`,
      //         type: "danger",
      //       });
      //     }
      //   });
      //   setLoader(false);

      //   // setLoader({ ...loader, variants: false });
      // }
      let payload: any = {
        name: form?.productName,
        tags: form?.tags,
        xuid: form?.xuid?.length ? form?.xuid.trim() : "",
        thumbnails: {
          media:
            selectedFile?.length > 0
              ? promiseLogoUrl
              : [
                {
                  mediaUrl: "",
                  show: 0,
                  pin: 0,
                },
              ],
        },
        // thumbnailDetails: { media: promiseLogoUrl ? promiseLogoUrl : [] },
        artformId: form?.artform?.value,
        typeId: form?.type?.value,
        categoryId: form?.category?.value,
        subcategoryId: form?.subCategory?.value,
        weavetypeId: form?.weaveType?.value,
        // associationId: "",
        designing: {
          media:
            selectedFileDesigner?.length > 0
              ? promiseDesignUrl
              : [
                {
                  mediaUrl: "",
                  show: 0,
                },
              ],
          karigarId: form?.designer?.value ? form?.designer?.value : "",
        },
        weaving: {
          media:
            selectedFileWeaver?.length > 0
              ? proWeavingUrl
              : [
                {
                  mediaUrl: "",
                  show: 0,
                },
              ],
          karigarId: form?.weaver?.value ? form?.weaver?.value : "",
        },
        dying: {
          media:
            selectedFileDying?.length > 0
              ? proDyingUrl
              : [
                {
                  mediaUrl: "",
                  show: 0,
                },
              ],
          karigarId: form?.dyer?.value ? form?.dyer?.value : "",
        },
        finishing: {
          media:
            selectedFileFinisher?.length > 0
              ? proFinishingUrl
              : [
                {
                  mediaUrl: "",
                  show: 0,
                },
              ],
          karigarId: form?.finisher?.value ? form?.finisher?.value : "",
        },
        status: data?.status,
        color: form?.variantCode,
        additionalImages: promiseVariantUrl ? promiseVariantUrl : [],
        brand: form?.nameOfBrand,
        weaver: form?.nameOfWeaver,
        handloom: form?.handloomMark,
        silk: form?.silkMark,
        zari: form?.zariUsed?.label,
        sareeWeight: form?.weightOfSaree,
        zariWeight: form?.weightOfZariUsed,
        purity: form?.zariUsed?.value,
        madeIn: form?.madeInIndia === true ? "true" : "false",
        yarnType: form?.yarnType?.label ? form?.yarnType?.label : "",
        workingHours: form?.workingHours,
        loomNum: form?.loomNum,
        // new
        srNo: form?.SrNo,
        weavingCenter: form?.weavingCenter,
        typeOfSareeByDesign: form?.typeOfSareeByDesign?.label,
        productionTimeInMonths: form?.productionTimeInMonths?.label,
      };
      if (
        selectedFileDesigner?.length > 0 ||
        selectedFileDying?.length > 0 ||
        selectedFileFinisher?.length > 0 ||
        selectedFileWeaver?.length > 0 ||
        selectedFileVariant?.length > 0 ||
        selectedFile?.length > 0
      ) {
        if (allStatusCodes?.length > 0) {
          let codes = allStatusCodes.every((ele: any) => ele === 200);
          if (codes === true) {
            callingCreateProduct(payload);
          } else {
            callingCreateProduct(
              payload,
              "Kindly edit and add the media again !"
            );
          }
        }
      }
      if (
        !selectedFileDesigner &&
        !selectedFileDying &&
        !selectedFileWeaver &&
        !selectedFileFinisher &&
        !selectedFileVariant &&
        !selectedFile
      ) {
        callingCreateProduct(payload);
      }
    }
  };
  const handleOnInputChange = (type: any) => {
    setHandleNameFlag({
      designing: false,
      weaving: false,
      dying: false,
      finishing: false,
    });
    let formValues = form;
    if (type?.name === "artform") {
      formValues = {
        ...formValues,
        type: "",
        category: "",
        subCategory: "",
        weaveType: "",
        artform: type?.value,
        productName: "",
      };
    } else if (type?.name === "type") {
      formValues = {
        ...formValues,
        type: type?.value,
        category: "",
        subCategory: "",
        weaveType: "",
        productName: "",
      };
    } else if (type?.name === "category") {
      formValues = {
        ...formValues,
        category: type?.value,
        subCategory: "",
        weaveType: "",
        productName: "",
      };
    } else if (type?.name === "subCategory") {
      formValues = {
        ...formValues,
        subCategory: type?.value,
        weaveType: "",
        productName: "",
      };
    } else if (type?.name === "weaveType") {
      formValues = {
        ...formValues,
        weaveType: type?.value,
        productName: "",
      };
    } else {
      if (
        authUser?.users?.organizationResponse?.name ===
        "tantuvi by smriti morarka" &&
        type?.name === "typeOfSareeByDesign"
      ) {
        formValues = {
          ...formValues,
          productName: type?.value?.value,
          typeOfSareeByDesign: type?.value,
        };
      } else if (type?.name === "timeTakenInMonths") {
        formValues = {
          ...formValues,
          workingHours: type?.value?.value,
          productionTimeInMonths: type?.value,
        };
      } else {
        formValues = {
          ...formValues,
          [type.name]: type?.value,
        };
      }
    }
    setForm(formValues);
    setFilterValue({
      type: "",
      category: "",
      subCategory: "",
      weaveType: "",
    });
  };
  const gettingFileType = (file: any) => {
    let fileType: any;
    if (
      file ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      fileType = "application/docx";
    } else if (file === "application/msword") {
      fileType = "application/doc";
    } else {
      fileType = file;
    }
    return fileType;
  };
  // S3 upload into awd
  const S3UploadVariants = async () => {
    let softCopyArry: any = [];
    for (let i = 0; i < selectedFileVariant?.length; i++) {
      let fileType: any;
      fileType = gettingFileType(selectedFileVariant[i]?.type);
      const promise = new Promise((resolve: any, reject: any) => {
        props.fetchProductDesigningPresignedUrl(
          { fileType: fileType },
          (res: any) => {
            const formData = new FormData();
            formData.append(
              selectedFileVariant[i],
              selectedFileVariant[i].name
            );
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", selectedFileVariant[i].type);
            props.uploadImages1(
              res.data,
              selectedFileVariant[i],
              myHeaders,
              (resSerever: any) => {
                softCopyArry.push({
                  statusCode: resSerever?.status,
                  url: resSerever?.url.split("?")[0],
                });
                resolve(softCopyArry);
              }
            );
          }
        );
      });
      promiseForVariant.push(promise);
    }
    let t: any = [];
    t = await Promise.all(promiseForVariant);
    return t[0];
  };
  const S3UploadFinishing = async () => {
    let softCopyArryFinishing: any = [];
    let status: any = [];
    for (let i = 0; i < selectedFileFinisher?.length; i++) {
      let fileType: any;
      fileType = gettingFileType(selectedFileFinisher[i]?.type);
      const promise = new Promise((resolve: any, reject: any) => {
        props.fetchProductFinishingPresignedUrl(
          { fileType: fileType },
          (res: any) => {
            const formData = new FormData();
            formData.append(
              selectedFileFinisher[i],
              selectedFileFinisher[i].name
            );
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", selectedFileFinisher[i].type);
            props.uploadImages1(
              res.data,
              selectedFileFinisher[i],
              myHeaders,
              (resSerever: any) => {
                status.push(resSerever?.status);
                softCopyArryFinishing.push({
                  statusCode: resSerever?.status,
                  url: resSerever?.url.split("?")[0],
                });
                resolve(softCopyArryFinishing);
              }
            );
          }
        );
      });
      promiseForFinishing.push(promise);
    }
    let t: any = [];
    t = await Promise.all(promiseForFinishing);
    return t[0];
  };
  const S3UploadWeaving = async () => {
    let softCopyArryWeaving: any = [];
    for (let i = 0; i < selectedFileWeaver?.length; i++) {
      let fileType: any;
      fileType = gettingFileType(selectedFileWeaver[i]?.type);
      const promise = new Promise((resolve: any, reject: any) => {
        props.fetchProductWeavingPresignedUrl(
          { fileType: fileType },
          (res: any) => {
            const formData = new FormData();
            formData.append(selectedFileWeaver[i], selectedFileWeaver[i].name);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", selectedFileWeaver[i].type);
            props.uploadImages1(
              res.data,
              selectedFileWeaver[i],
              myHeaders,
              (resSerever: any) => {
                softCopyArryWeaving.push({
                  statusCode: resSerever?.status,
                  url: resSerever?.url.split("?")[0],
                });
                resolve(softCopyArryWeaving);
              }
            );
          }
        );
      });
      promiseForWeaving.push(promise);
    }
    let t: any = [];
    t = await Promise.all(promiseForWeaving);
    return t[0];
  };
  const S3UploadForLogo = async () => {
    let softCopyArry: any = [];

    for (let i = 0; i < selectedFile?.length; i++) {
      let fileType: any;
      fileType = gettingFileType(selectedFile[i]?.type);
      const promise = new Promise((resolve: any, reject: any) => {
        props.fetchProfilePresignedUrl({ fileType: fileType }, (res: any) => {
          const formData = new FormData();
          formData.append(selectedFile[i], selectedFile[i].name);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", selectedFile[i].type);
          props.uploadImages1(
            res.data,
            selectedFile[i],
            myHeaders,
            (resSerever: any) => {
              softCopyArry.push({
                statusCode: resSerever?.status,
                url: resSerever?.url.split("?")[0],
              });
              resolve(softCopyArry);
            }
          );
        });
      });
      promiseForLogo.push(promise);
    }
    let t: any = [];
    t = await Promise.all(promiseForLogo);
    return t[0];
  };
  const S3UploadDesigner = async () => {
    let softCopyArry: any = [];

    for (let i = 0; i < selectedFileDesigner?.length; i++) {
      let fileType: any;
      fileType = gettingFileType(selectedFileDesigner[i]?.type);
      const promise = new Promise((resolve: any, reject: any) => {
        props.fetchProductDesigningPresignedUrl(
          { fileType: fileType },
          (res: any) => {
            const formData = new FormData();
            formData.append(
              selectedFileDesigner[i],
              selectedFileDesigner[i].name
            );
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", selectedFileDesigner[i].type);
            props.uploadImages1(
              res.data,
              selectedFileDesigner[i],
              myHeaders,
              (resSerever: any) => {
                softCopyArry.push({
                  statusCode: resSerever?.status,
                  url: resSerever?.url.split("?")[0],
                });
                resolve(softCopyArry);
              }
            );
          }
        );
      });
      promiseForDesign.push(promise);
    }
    let t: any = [];
    t = await Promise.all(promiseForDesign);
    return t[0];
  };
  const S3UploadDying = async () => {
    let softCopyArryForDying: any = [];
    for (let i = 0; i < selectedFileDying?.length; i++) {
      let fileType: any;
      fileType = gettingFileType(selectedFileDying[i]?.type);
      const promise = new Promise((resolve: any, reject: any) => {
        props.fetchProductDyingPresignedUrl(
          { fileType: fileType },
          (res: any) => {
            const formData = new FormData();
            formData.append(selectedFileDying[i], selectedFileDying[i].name);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", selectedFileDying[i].type);
            props.uploadImages1(
              res.data,
              selectedFileDying[i],
              myHeaders,
              (resSerever: any) => {
                softCopyArryForDying.push({
                  statusCode: resSerever?.status,
                  url: resSerever?.url.split("?")[0],
                });
                resolve(softCopyArryForDying);
              }
            );
          }
        );
      });
      promiseForDying.push(promise);
    }
    let t: any = [];
    t = await Promise.all(promiseForDying);
    return t[0];
  };

  // file upload method start reuseable function
  const uploadFiles = (data: any) => {
    let { fileObject, name, files } = data;
    if (files.length) {
      fileObject.push(Array.prototype.slice.call(files));
      fileObjImages = fileObject.flatMap((e: any) => e);
    }

    let uniqueArr = fileObjImages.filter((obj: any, index: any, self: any) => {
      return index === self.findIndex((t: any) => t.name === obj.name);
    });
    uploadImageArray({
      name,
      uniqueArr,
    });
    if (name === "designing") {
      setSelectedFileDesigner(uniqueArr);
      fileObjDesign = uniqueArr;
    } else if (name === "dying") {
      setSelectedFileDying(uniqueArr);
      fileObjDying = uniqueArr;
    } else if (name === "weaving") {
      setSelectedFileWeaver(uniqueArr);
      fileObjWeaving = uniqueArr;
    } else if (name === "finishing") {
      setSelectedFileFinisher(uniqueArr);
      fileObjFinishing = uniqueArr;
    } else if (name === "variant") {
      setSelectedFileVariant(uniqueArr);
      fileObjVariant = uniqueArr;
    } else if (name === "logo") {
      setSelectedFile(uniqueArr);
      fileObjLogo = uniqueArr;
    }
  };
  // base image reuseable function
  const uploadImageArray = async (data: any) => {
    let { name, uniqueArr } = data;
    let base64Files: any = [];
    for (let i = 0; i < uniqueArr.length; i++) {
      if (name === "logo") {
        base64Files.push({
          type: uniqueArr[i]?.type,
          blobImage:
            uniqueArr[i]?.type === uniqueArr[i]?.type.includes("image")
              ? await convertBase64(uniqueArr[i])
              : await URL.createObjectURL(uniqueArr[i]),
          fileName: uniqueArr[i]?.name,
          show: true,
          pin: false,
        });
      } else {
        base64Files.push({
          type: uniqueArr[i]?.type,
          blobImage:
            uniqueArr[i]?.type === uniqueArr[i]?.type.includes("image")
              ? await convertBase64(uniqueArr[i])
              : await URL.createObjectURL(uniqueArr[i]),
          fileName: uniqueArr[i]?.name,
          show: true,
        });
      }
    }
    if (name === "designing") {
      setBaseImageDesigner([...base64Files]);
    } else if (name === "dying") {
      setBaseImageDying([...base64Files]);
    } else if (name === "weaving") {
      setBaseImageWeaver([...base64Files]);
    } else if (name === "finishing") {
      setBaseImageFinisher([...base64Files]);
    } else if (name === "variant") {
      setBaseImageVariant([...base64Files]);
    } else if (name === "logo") {
      setBaseImageLogo([...base64Files]);
    }
  };
  // logo file upload function

  const uploadImage = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImageLogo(base64);
  };
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const addChip = (value: any) => {
    const chips = form.tags.slice();
    chips.push(value);
    setForm({
      ...form,
      tags: chips,
    });
  };
  const removeChip = (index: any) => {
    const chips = form.tags.slice();
    chips.splice(index, 1);
    setForm({
      ...form,
      tags: chips,
    });
  };
  const handleDeleteFinisher = (i: any, item: any) => {
    setDeleteIndexFinisher({
      i,
      item,
    });
  };
  const handleDeleteWeaver = (i: any, item: any) => {
    setDeleteIndexWeaver({
      i,
      item,
    });
  };
  const handleDeleteDying = (i: any, item: any) => {
    setDeleteIndexDying({
      i,
      item,
    });
  };
  const handleDeleteDesigner = (i: any, item: any) => {
    setDeleteIndexDesigner({
      i,
      item,
    });
  };

  const handleDeleteLogo = (i: any, item: any) => {
    setDeleteIndexLogo({
      i,
      item,
    });
  };
  const handleDeleteVariant = (i: any, item: any) => {
    setDeleteIndexVariant({
      i,
      item,
    });
  };

  const handleChangeDebounce = React.useCallback(
    debounce((data: any) => {
      if (data?.name === "productFilter") {
        CallingGettProductForFilter(data);
      }
      if (data?.name === "product") {
        CallingGettProduct(data);
      }
      if (data?.name === "artform") {
        getArtformData(data?.value);
      }
      if (data?.name === "type") {
        getTypesData(data);
      }
      if (data?.name === "category") {
        getCategoryData(data);
      }
      if (data?.name === "subCategory") {
        getSubCategoryData(data);
      }
      if (data?.name === "weaveType") {
        getWeaveTypeData(data);
      }
      if (data?.name === "designing") {
        getCreatorsDataDesignerOption(data?.value);
      }
      if (data?.name === "weaving") {
        getCreatorsDataWeaverOption(data?.value);
      }
      if (data?.name === "dying") {
        getCreatorsDataDyingOption(data?.value);
      }
      if (data?.name === "finishing") {
        getCreatorsDataFinishingOption(data?.value);
      }
    }, 1000),
    []
  );
  const closeModal = () => {
    setOpenModal({
      artform: false,
      type: false,
      category: false,
      subCategory: false,
      weaveType: false,
      designing: false,
      dying: false,
      weaving: false,
      finishing: false,
    });
  };
  const callingGetTypesData = (value: any) => {
    props.getTypes(
      {
        offset: 0,
        limit: 1,
        artFormId: value?.artformId,
        typeName: value?.type,
      },
      (res: any) => {
        // let newValues={...filterValue}
        if (res?.data?.docs?.length) {
          res.data?.docs.map((ele: any, i: any) => {
            setForm({
              ...form,
              type: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
              },
            });
            setFilterValue({
              ...filterValue,
              type: ele?._id,
            });
          });
        }
      }
    );
  };
  const callingGetCategoryData = (value: any) => {
    props.getCategory(
      {
        offset: 0,
        limit: 10,
        typeId: value?.typeId,
        categoryName: value?.category,
        artformId: value?.artformId,
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          res?.data?.docs.map((ele: any, i: any) => {
            setForm({
              ...form,
              category: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
              },
            });
            setFilterValue({
              ...filterValue,
              category: ele?._id,
            });
          });
        }
      }
    );
  };
  const callingGetSubCategory = (value: any) => {
    props.getSubCategorys(
      {
        offset: 0,
        limit: 1,
        // category:value?.category,
        subCategoryName: value?.subCategory,
        artformId: value?.artformId,
        typeId: value?.typeId,
        categoryId: value?.categoryId,
        subCategoryId: value?.subCategoryId,
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          res?.data?.docs.map((ele: any, i: any) => {
            setForm({
              ...form,
              subCategory: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
              },
            });
            setFilterValue({
              ...filterValue,
              subCategory: ele?._id,
            });
          });
        }
      }
    );
  };

  const callingGetWeaveType = (value: any) => {
    props.getWeaveType(
      {
        offset: 0,
        limit: 10,
        artformId: value?.artformId,
        typeId: value?.typeId,
        categoryId: value?.categoryId,
        subCategoryId: value?.subCategoryId,
        weaveTypeId: value?.weaveTypeId,
        weaveTypeName: value.weaveType,
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          res?.data?.docs.map((ele: any, i: any) => {
            setForm({
              ...form,
              weaveType: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
              },
            });
            setFilterValue({
              ...filterValue,
              weaveType: ele?._id,
            });
          });
        }
      }
    );
  };
  const handleName = (value: any) => {
    if (value?.name === "artform") {
      props.getArtforms(
        {
          offset: 0,
          limit: 1,
          artformName: value?.artform,
        },
        (res: any) => {
          if (res?.data?.docs?.length) {
            setForm({
              ...form,
              artform: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
              },
            });
          }
        }
      );
    }
    if (value?.name === "type") {
      callingGetTypesData({
        artformId: value?.artform,
        type: value?.type,
      });
    }
    if (value?.name === "category") {
      callingGetCategoryData({
        typeId: value?.type,
        category: value?.category,
      });
    }
    if (value?.name === "subCategory") {
      callingGetSubCategory({
        categoryId: value?.category,
        subCategory: value?.subCategory,
      });
    }
    if (value?.name === "weaveType") {
      callingGetWeaveType({
        subCategoryId: value?.subCategory,
        weaveType: value?.weaveType,
      });
    }
    let flag = handleNameFlag;
    if (value.name === "designing") {
      getCreatorsData({
        userId: value?.userId,
        name: "designing",
      });
      flag = {
        ...flag,
        designing: true,
      };
    }
    if (value.name === "weaving") {
      getCreatorsData({
        userId: value?.userId,
        name: "weaving",
      });
      flag = {
        ...flag,
        weaving: true,
      };
    }
    if (value.name === "dying") {
      getCreatorsData({
        userId: value?.userId,
        name: "dying",
      });
      flag = {
        ...flag,
        dying: true,
      };
    }
    if (value.name === "finishing") {
      getCreatorsData({
        userId: value?.userId,
        name: "finishing",
      });
      flag = {
        ...flag,
        finishing: true,
      };
    }
    setHandleNameFlag(flag);
  };
  const handleOpenArtform = () => {
    setOpenModal({
      artform: true,
    });
  };
  const handleOpenType = () => {
    setOpenModal({
      type: true,
    });
  };
  const handleOpenCategory = () => {
    setOpenModal({
      category: true,
    });
  };
  const handleOpenSubCategory = () => {
    setOpenModal({
      subCategory: true,
    });
  };
  const handleOpenWeaveType = () => {
    setOpenModal({
      weaveType: true,
    });
  };
  const handleOpenModal = (value: any) => {
    setOpenModal({
      [value?.name]: value?.value,
    });
  };
  const onSetIndexDesign = (type: any) => {
    if (type?.name === "designing") {
      setBaseIndex({
        designing: type,
      });
    }
    if (type?.name === "weaving") {
      setBaseIndex({
        weaving: type,
      });
    }
    if (type?.name === "dying") {
      setBaseIndex({
        dying: type,
      });
    }
    if (type?.name === "finishing") {
      setBaseIndex({
        finishing: type,
      });
    }
    if (type?.name === "logo") {
      setBaseIndex({
        logo: type,
      });
    }
  };
  const onSetPin = (type: any) => {
    setBaseIndexForLogo(type);
  };
  useEffect(() => {
    // if (baseindexForLogo?.length) {
    if (baseImageLogo?.length) {
      let arrayPin: any = [];
      let arrayUnpin: any = [];
      let arrayPinSelected: any = [];
      let arrayUnpinSelected: any = [];
      selectedFile.find((ele: any, i: any) => {
        if (i === baseindexForLogo?.index) {
          arrayPinSelected.push(ele);
        } else {
          arrayPinSelected.push(ele);
        }
        setSelectedFile([...arrayPinSelected]);
      });
      baseImageLogo.find((ele: any, i: any) => {
        if (i === baseindexForLogo?.index) {
          arrayPin.push({
            type: ele?.type,
            blobImage: ele?.blobImage,
            fileName: ele?.fileName,
            show: ele?.show,
            pin: true,
          });
        } else {
          arrayPin.push({
            type: ele?.type,
            blobImage: ele?.blobImage,
            fileName: ele?.fileName,
            show: ele?.show,
            pin: false,
          });
        }
        setBaseImageLogo([...arrayPin]);
      });
    }
    // }
  }, [baseindexForLogo]);
  useEffect(() => {
    if (baseindex?.finishing) {
      if (baseImageFinisher?.length) {
        let showImageData = {
          type: baseImageFinisher[baseindex?.finishing?.index]?.type,
          blobImage: baseImageFinisher[baseindex?.finishing?.index]?.blobImage,
          fileName: baseImageFinisher[baseindex?.finishing?.index]?.fileName,
          show: baseindex?.finishing?.show,
        };
        baseImageFinisher.find((ele: any, i: any) => {
          if (i === baseindex?.finishing?.index) {
            let showm = [...baseImageFinisher];
            showm[i] = showImageData;
            setBaseImageFinisher([...showm]);
          }
        });
      }
    }
    if (baseindex?.dying) {
      if (baseImageDying?.length) {
        let showImageData = {
          type: baseImageDying[baseindex?.dying?.index]?.type,
          blobImage: baseImageDying[baseindex?.dying?.index]?.blobImage,
          fileName: baseImageDying[baseindex?.dying?.index]?.fileName,
          show: baseindex?.dying?.show,
        };
        baseImageDying.find((ele: any, i: any) => {
          if (i === baseindex?.dying?.index) {
            let showm = [...baseImageDying];
            showm[i] = showImageData;
            setBaseImageDying([...showm]);
          }
        });
      }
    }
    if (baseindex?.weaving) {
      if (baseImageWeaver?.length) {
        let showImageData = {
          type: baseImageWeaver[baseindex?.weaving?.index]?.type,
          blobImage: baseImageWeaver[baseindex?.weaving?.index]?.blobImage,
          fileName: baseImageWeaver[baseindex?.weaving?.index]?.fileName,
          show: baseindex?.weaving?.show,
        };
        baseImageWeaver.find((ele: any, i: any) => {
          if (i === baseindex?.weaving?.index) {
            let showm = [...baseImageWeaver];
            showm[i] = showImageData;
            setBaseImageWeaver([...showm]);
          }
        });
      }
    }
    if (baseindex?.designing) {
      if (baseImageDesigner?.length) {
        let showImageData = {
          // ...baseImageDesigner,
          type: baseImageDesigner[baseindex?.designing?.index]?.type,
          blobImage: baseImageDesigner[baseindex?.designing?.index]?.blobImage,
          fileName: baseImageDesigner[baseindex?.designing?.index]?.fileName,
          show: baseindex?.designing?.show,
        };
        baseImageDesigner.find((ele: any, i: any) => {
          if (i === baseindex?.designing?.index) {
            let showm = [...baseImageDesigner];
            showm[i] = showImageData;
            setBaseImageDesigner([...showm]);
          }
        });
      }
    }
    if (baseindex?.logo) {
      if (baseImageLogo?.length) {
        let showImageData = {
          type: baseImageLogo[baseindex?.logo?.index]?.type,
          blobImage: baseImageLogo[baseindex?.logo?.index]?.blobImage,
          fileName: baseImageLogo[baseindex?.logo?.index]?.fileName,
          show: baseindex?.logo?.show,
          pin: baseImageLogo[baseindex?.logo?.index]?.pin,
        };
        baseImageLogo.find((ele: any, i: any) => {
          if (i === baseindex?.logo?.index) {
            let showm = [...baseImageLogo];
            showm[i] = showImageData;
            setBaseImageLogo([...showm]);
          }
        });
      }
    }
  }, [baseindex]);
  const handleOnchangeProductFilter = (type: any) => {
    let data = type?.value?.value;
    if (type?.name === "productFilter") {
      setSelectedProduct(type?.value);
      setForm({
        ...form,
        artform: {
          label: data?.artform?.name,
          value: data?.artform?._id,
        },
        type: {
          label: data?.type?.name,
          value: data?.type?._id,
        },
        category: {
          label: data?.category?.name,
          value: data?.category?._id,
        },
        subCategory: {
          label: data?.subCategory?.name,
          value: data?.subCategory?._id,
        },
        weaveType: {
          label: data?.weaveType?.name,
          value: data?.weaveType?._id,
        },
        productName: data?.productName,
        tags: data?.tags,
      });
    }
  };
  const handleResetData = () => {
    setForm({
      artform: "",
      type: "",
      category: "",
      subCategory: "",
      weaveType: "",
      productName: "",
      designer: "",
      designerMedia: [],
      weaver: "",
      weaverMedia: [],
      dyer: "",
      dyerMedia: [],
      finisher: "",
      finisherMedia: [],
      profileURL: "",
      tags: [],
      singleTag: "",
      variantCode: "",
      variantImages: [],
    });
    setSelectedProduct("");
    setSwitchButton(false);
  };
  const handleReset = (type: any) => {
    if (type?.name === "designing") {
      setForm({
        ...form,
        designer: "",
      });
    }
    if (type?.name === "weaving") {
      setForm({
        ...form,
        weaver: "",
      });
    }
    if (type?.name === "dying") {
      setForm({
        ...form,
        dyer: "",
      });
    }
    if (type?.name === "finishing") {
      setForm({
        ...form,
        finisher: "",
      });
    }
  };
  // create variant
  useEffect(() => {
    if (location?.state?.name === "variant") {
      if (
        location?.state?.productDetails !== null ||
        location?.state?.productDetails !== undefined
      ) {
        let editDetails = location?.state?.productDetails;
        setForm({
          id: editDetails?._id,
          artform: {
            value: editDetails?.artform?._id,
            label: editDetails?.artform?.name,
          },
          type: {
            value: editDetails?.type?._id,
            label: editDetails?.type?.name,
          },
          category: {
            value: editDetails?.category?._id,
            label: editDetails?.category?.name,
          },
          subCategory: {
            value: editDetails?.subcategory?._id,
            label: editDetails?.subcategory?.name,
          },
          weaveType: {
            value: editDetails?.weavetype?._id,
            label: editDetails?.weavetype?.name,
          },
          productName: editDetails?.name,
          designer:
            editDetails?.designingDetails?.karigar?.name?.length > 0
              ? {
                label: editDetails?.designingDetails?.karigar?.name,
                value: editDetails?.designingDetails?.karigar?._id,
              }
              : "",

          weaver:
            editDetails?.weavingDetails?.karigar?.name?.length > 0
              ? {
                label: editDetails?.weavingDetails?.karigar?.name,
                value: editDetails?.weavingDetails?.karigar?._id,
              }
              : "",

          dyer:
            editDetails?.dyingDetails?.karigar?.name?.length > 0
              ? {
                label: editDetails?.dyingDetails?.karigar?.name,
                value: editDetails?.dyingDetails?.karigar?._id,
              }
              : "",

          finisher:
            editDetails?.finishingDetails?.karigar?.name?.length > 0
              ? {
                label: editDetails?.finishingDetails?.karigar?.name,
                value: editDetails?.finishingDetails?.karigar?._id,
              }
              : "",

          productOwner: editDetails?.productOwner,
          tags: editDetails?.tags ? editDetails?.tags : [],
          status: editDetails.status,
          variantCode: editDetails?.color,
          //new
          nameOfBrand: editDetails?.brand,
          nameOfWeaver: editDetails?.weaver,
          handloomMark: editDetails?.handloom,
          silkMark: editDetails?.silk,
          zariUsed: { label: editDetails?.zari, value: editDetails?.purity },
          weightOfSaree: editDetails?.sareeWeight,
          weightOfZariUsed: editDetails?.zariWeight,
          purity: editDetails?.purity,
          madeInIndia: editDetails?.madeIn === "true" ? true : false,
          yarnType: editDetails?.yarnType
            ? {
              label: editDetails?.yarnType,
              value: editDetails?.yarnType,
            }
            : "",
          workingHours: editDetails?.workingHours,
          loomNum: editDetails?.loomNum,
          xuid: editDetails?.xuid,
          // new
          // SrNo: editDetails?.srNo,
          weavingCenter: editDetails?.weavingCenter || form?.weavingCenter,
          typeOfSareeByDesign: editDetails?.typeOfSareeByDesign
            ? {
              label: editDetails?.typeOfSareeByDesign,
              value: editDetails?.name,
            }
            : "",
          productionTimeInMonths: {
            label: editDetails?.productionTimeInMonths,
            value: editDetails?.workingHours,
          },
        });
      }
    }
  }, [location?.state?.name === "variant"]);

  return (
    <>
      <CreateUserModal
        {...props}
        openModal={openModal?.designing}
        handleOpenModal={handleOpenModal}
        closeModal={closeModal}
        handleName={handleName}
        name="Product"
        modalName={"designing"}
      />
      <CreateUserModal
        {...props}
        openModal={openModal?.weaving}
        handleOpenModal={handleOpenModal}
        closeModal={closeModal}
        handleName={handleName}
        name="Product"
        modalName={"weaving"}
      />
      <CreateUserModal
        {...props}
        openModal={openModal?.dying}
        handleOpenModal={handleOpenModal}
        closeModal={closeModal}
        handleName={handleName}
        name="Product"
        modalName={"dying"}
      />
      <CreateUserModal
        {...props}
        openModal={openModal?.finishing}
        handleOpenModal={handleOpenModal}
        closeModal={closeModal}
        handleName={handleName}
        name="Product"
        modalName={"finishing"}
      />
      <ArtformModal
        {...props}
        openModal={openModal?.artform}
        closeModal={closeModal}
        getAllData={getArtformData}
        handleName={handleName}
      />
      <TypeModal
        {...props}
        openModal={openModal?.type}
        form={form}
        closeModal={closeModal}
        getAllData={getTypesData}
        handleName={handleName}
      />
      <CategoryModal
        {...props}
        form={form}
        openModal={openModal?.category}
        closeModal={closeModal}
        getAllData={getCategoryData}
        handleName={handleName}
      />
      <SubCategoryModal
        {...props}
        form={form}
        openModal={openModal?.subCategory}
        closeModal={closeModal}
        getAllData={getSubCategoryData}
        handleName={handleName}
      />
      <WeaveTypeModal
        {...props}
        form={form}
        openModal={openModal?.weaveType}
        closeModal={closeModal}
        getAllData={getWeaveTypeData}
        handleName={handleName}
      />
      {loading ? (
        <div className="LoaderDiv">
          <Loader />
        </div>
      ) : (
        <>
          <CreateProductHeader
            {...props}
            handleOnInputChangeFilters={handleOnInputChangeFilters}
            handleOnchangeProductFilter={handleOnchangeProductFilter}
            productOptions={productOptions}
            selectedProduct={selectedProduct}
            handleResetData={handleResetData}
            handleSwitchButton={handleSwitchButton}
            switchButton={switchButton}
          />
          <div className="createProduct CreateProductDetails">
            <div className="form">
              <div className="UserDetailsDiv">
                <div className="UserHeader">Product Details</div>
                <div className="userPd">
                  <Label>Upload Files</Label>
                  <span className="ms-2">
                    Photo (jpg, jpeg, png) / Video (mp4 , ogg, webm) / PDF
                  </span>
                  <div className="">
                    {baseImageLogo?.length > 0 ? (
                      <>
                        <div className="imageDiv1">
                          <span className="pl-2 pr-2 ">
                            <div className="divAddIcon">
                              <input
                                type="file"
                                name="Image"
                                multiple={true}
                                accept=".png,.jpg,.jpeg,.heic,.mp4,.webm,.ogg,.bmp"
                                style={{ display: "none" }}
                                ref={fileInputProfile}
                                onChange={(e) => {
                                  uploadFiles({
                                    fileArr: fileArrayLogo,
                                    fileObject: fileObjLogo,
                                    name: "logo",
                                    files: e.target.files,
                                  });
                                }}
                              />
                              <ImageAddIcon
                                {...props}
                                fileUploadTrigger={fileUploadTrigger}
                              />
                            </div>
                            <div className="d-flex">
                              {baseImageLogo.length > 0 ? (
                                <div className="PreviewDivPart">
                                  {baseImageLogo.map((item: any, i: any) => {
                                    return (
                                      <React.Fragment key={i}>
                                        {loader ? (
                                          <div className="CircleLoder">
                                            <CircleLoader />
                                          </div>
                                        ) : (
                                          <div className="ArryyIcon">
                                            <ImageClose
                                              {...props}
                                              i={i}
                                              item={item}
                                              close={handleDeleteLogo}
                                            />
                                            {item?.pin === false ? (
                                              <Pin
                                                onClick={onSetPin}
                                                index={i}
                                              />
                                            ) : (
                                              <Unpin
                                                onClick={onSetPin}
                                                index={i}
                                              />
                                            )}

                                            {item?.show === true ? (
                                              <ShowIcon
                                                onClick={onSetIndexDesign}
                                                index={i}
                                                name="logo"
                                              />
                                            ) : (
                                              <HideIcon
                                                onClick={onSetIndexDesign}
                                                index={i}
                                                name="logo"
                                              />
                                            )}

                                            <FilePreviewPart
                                              {...props}
                                              item={item}
                                            />
                                          </div>
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <ImageDivPart
                          {...props}
                          fileUploadTrigger={fileUploadTrigger}
                          fileInput={fileInputProfile}
                          fileUpload={uploadFiles}
                          name="logo"
                          fileArr={fileArrayLogo}
                          fileObject={fileObjLogo}
                          content="Click to upload product images"
                        />
                      </>
                    )}
                  </div>

                  <div className="d-flex justify-content-start pt-2">
                    <span className="me-2">
                      <b>Note:</b>
                    </span>
                    Please pin image otherwise will consider as default image as
                    first image
                  </div>
                  <div className="formDiv ReactSelectDiv">
                    <Row>
                      <Col xs={12} md={4}>
                        <Label>
                          HKPtagNo<span>*</span>
                        </Label>
                        <Input
                          name="SrNo"
                          id="SrNo"
                          className="form-control"
                          placeholder="SrNo"
                          value={form?.SrNo}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "SrNo",
                              value: e.target.value,
                            });
                          }}
                        />
                        {Error.SrNo && !form?.SrNo ? (
                          <p className="RequiredField">{Error?.SrNo} </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>
                          Artform <span>*</span>
                        </Label>
                        <div className="formDivSub">
                          <Select
                            className="React_selector text-white"
                            classNamePrefix="react-select"
                            placeholder="Select"
                            // isSearchable
                            name="artform"
                            id="artform"
                            value={form?.artform}
                            options={artformOptions}
                            // components={{ MenuList: SelectMenuButton }}
                            onChange={(e: any) => {
                              handleOnInputChange({
                                name: "artform",
                                value: e,
                              });
                            }}
                            onInputChange={(e) => {
                              handleOnInputChangeFilters({
                                name: "artform",
                                value: e,
                              });
                            }}
                          />
                          <div className="DivSep"></div>
                          <div className="formAddDiv">
                            <AddIcon openModal={handleOpenArtform} />
                          </div>
                        </div>

                        {Error.artform && !form?.artform ? (
                          <p className="RequiredField">{Error?.artform} </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>
                          Type <span>*</span>
                        </Label>
                        <div className="formDivSub">
                          <Select
                            className="React_selector text-white"
                            classNamePrefix="react-select"
                            placeholder="Select"
                            // isSearchable
                            name="type"
                            id="type"
                            value={form?.type}
                            options={
                              form?.artform?.value
                                ? typeOptions
                                : [
                                  {
                                    label: (
                                      <p className="req">
                                        ***Please select Artform***
                                      </p>
                                    ),
                                    value: "",
                                  },
                                ]
                            }
                            // components={
                            //   form?.artform?.value && {
                            //     MenuList: SelectMenuButtonForType,
                            //   }
                            // }
                            onChange={(e: any) => {
                              handleOnInputChange({
                                name: "type",
                                value: e,
                              });
                            }}
                            onInputChange={(e) =>
                              handleOnInputChangeFilters({
                                name: "type",
                                value: e,
                              })
                            }
                          />
                          <div className="DivSep"></div>
                          <div className="formAddDiv">
                            <AddIcon openModal={handleOpenType} />
                          </div>
                        </div>
                        {Error.type && !form?.type ? (
                          <p className="RequiredField">{Error?.type} </p>
                        ) : (
                          ""
                        )}
                        {/* {filterValue?.type &&
                        new Map(
                          typeOptions.map((obj: any) => {
                            return [obj.value, obj.labe];
                          })
                        ).has(filterValue?.type) == false ? (
                          <p className="RequiredField">
                            Type is not a part of selected artform
                          </p>
                        ) : (
                          ""
                        )} */}
                      </Col>
                    </Row>
                  </div>
                  <div className="formDiv">
                    <Row>
                      <Col xs={12} md={4}>
                        <Label>
                          Category <span>*</span>
                        </Label>
                        <div className="formDivSub">
                          <Select
                            className="React_selector text-white"
                            classNamePrefix="react-select"
                            placeholder="Select"
                            isSearchable
                            name="Category"
                            id="Category"
                            value={form?.category}
                            options={
                              form?.type?.value
                                ? categoryOptions
                                : [
                                  {
                                    label: (
                                      <p className="req">
                                        ***Please select type***
                                      </p>
                                    ),
                                    value: "",
                                  },
                                ]
                            }
                            // components={
                            //   form?.type?.value && {
                            //     MenuList: SelectMenuButtonForCategory,
                            //   }
                            // }
                            onChange={(e: any) => {
                              handleOnInputChange({
                                name: "category",
                                value: e,
                              });
                            }}
                            onInputChange={(e) =>
                              handleOnInputChangeFilters({
                                name: "category",
                                value: e,
                              })
                            }
                          />
                          <div className="DivSep"></div>
                          <div className="formAddDiv">
                            <AddIcon openModal={handleOpenCategory} />
                          </div>
                        </div>
                        {Error.category && !form?.category ? (
                          <p className="RequiredField">{Error?.category} </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>
                          Sub-Category <span>*</span>
                        </Label>
                        <div className="formDivSub">
                          <Select
                            className="React_selector text-white"
                            classNamePrefix="react-select"
                            placeholder="Select"
                            // isSearchable
                            name="subCategory"
                            id="subCategory"
                            value={form?.subCategory}
                            options={
                              form?.category?.value
                                ? subCategoryOptions
                                : [
                                  {
                                    label: (
                                      <p className="req">
                                        ***Please select category***
                                      </p>
                                    ),
                                    value: "",
                                  },
                                ]
                            }
                            // components={
                            //   form?.category?.value && {
                            //     MenuList: SelectMenuButtonForSubCategory,
                            //   }
                            // }
                            onChange={(e: any) => {
                              handleOnInputChange({
                                name: "subCategory",
                                value: e,
                              });
                            }}
                            onInputChange={(e) =>
                              handleOnInputChangeFilters({
                                name: "subCategory",
                                value: e,
                              })
                            }
                          />
                          <div className="DivSep"></div>
                          <div className="formAddDiv">
                            <AddIcon openModal={handleOpenSubCategory} />
                          </div>
                        </div>
                        {Error.subCategory && !form?.subCategory ? (
                          <p className="RequiredField">{Error?.subCategory} </p>
                        ) : (
                          ""
                        )}
                        {/* {filterValue?.subCategory &&
                        new Map(
                          subCategoryOptions.map((obj: any) => {
                            return [obj.value, obj.labe];
                          })
                        ).has(filterValue?.subCategory) == false ? (
                          <p className="RequiredField">
                            Sub-Category is not a part of selected category
                          </p>
                        ) : (
                          ""
                        )} */}
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>
                          Weave Type <span>*</span>
                        </Label>
                        <div className="formDivSub">
                          <Select
                            className="React_selector text-white"
                            classNamePrefix="react-select"
                            placeholder="Select"
                            // isSearchable
                            name="weaveType"
                            id="weaveType"
                            value={form?.weaveType}
                            options={
                              form?.subCategory?.value
                                ? weaveTypeOptions
                                : [
                                  {
                                    label: (
                                      <p className="req">
                                        ***Please select sub-category***
                                      </p>
                                    ),
                                    value: "",
                                  },
                                ]
                            }
                            onChange={(e: any) => {
                              handleOnInputChange({
                                name: "weaveType",
                                value: e,
                              });
                            }}
                            onInputChange={(e) =>
                              handleOnInputChangeFilters({
                                name: "weaveType",
                                value: e,
                              })
                            }
                          />
                          <div className="DivSep"></div>
                          <div className="formAddDiv">
                            <AddIcon openModal={handleOpenWeaveType} />
                          </div>
                        </div>
                        {Error.weaveType && !form?.weaveType ? (
                          <p className="RequiredField">{Error?.weaveType} </p>
                        ) : (
                          ""
                        )}
                        {/* {filterValue?.weaveType &&
                        new Map(
                          weaveTypeOptions.map((obj: any) => {
                            return [obj.value, obj.labe];
                          })
                        ).has(filterValue?.weaveType) == false ? (
                          <p className="RequiredField">
                            Weave type name is not a part of selected
                            sub-category
                          </p>
                        ) : (
                          ""
                        )} */}
                      </Col>
                    </Row>
                  </div>
                  <div className="formDiv">
                    <Row>
                      <Col xs={12} md={4}>
                        <Label>Types of sarees by design</Label>
                        <div className="formDivSub">
                          <Select
                            className="React_selector text-white"
                            classNamePrefix="react-select"
                            placeholder="Select"
                            // isSearchable
                            name="typeOfSareeByDesign"
                            value={form?.typeOfSareeByDesign}
                            options={typeOfSareeByDesignOptions}
                            onChange={(e: any) => {
                              handleOnInputChange({
                                name: "typeOfSareeByDesign",
                                value: e,
                              });
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>
                          Product<span>*</span>
                        </Label>
                        <Input
                          name="productName"
                          id="productName"
                          className="form-control"
                          placeholder="Type Name"
                          value={form?.productName}
                          // disabled={
                          //   form?.typeOfSareeByDesign?.value?.length > 0
                          //     ? true
                          //     : false
                          // }
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "productName",
                              value: e.target.value,
                            });
                            handleChangeDebounce({
                              name: "product",
                              artform: form?.artform?.value,
                              type: form?.type?.value,
                              category: form?.category?.value,
                              subCategory: form?.subCategory?.value,
                              weaveType: form?.weaveType?.value,
                              productName: e.target.value,
                            });
                          }}
                        />
                        {Error.product && !form?.productName ? (
                          <p className="RequiredField">{Error?.product} </p>
                        ) : (
                          ""
                        )}
                        {form?.productName &&
                          form?.productName === filterProduct ? (
                          // filterValue?.product === ""
                          <p className="RequiredField">
                            Product name is already exist's
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>
                          Color of Product<span>*</span>
                        </Label>
                        <Input
                          name="variantColor"
                          id="variantColor"
                          className="form-control"
                          placeholder="Color of Product"
                          value={form?.variantCode}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "variantCode",
                              value: e.target.value,
                            });
                          }}
                        />
                        {Error.variantCode && !form?.variantCode ? (
                          <p className="RequiredField">{Error?.variantCode} </p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="formDiv">
                    <Row>
                      <Col xs={12} md={4}>
                        <Label>Weaving Center</Label>
                        <Input
                          name="weavingCenter"
                          className="form-control"
                          placeholder="Weaving Center"
                          value={form?.weavingCenter}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "weavingCenter",
                              value: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>Add Tags</Label>
                        <ReactChipInput
                          {...props}
                          classes="class1 class2"
                          chips={form.tags ? form?.tags : []}
                          onSubmit={(value: any) => {
                            addChip(value);
                          }}
                          onRemove={(index: any) => removeChip(index)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <Label>Name of Brand</Label>
                        <Input
                          name="nameOfBrand"
                          id="nameOfBrand"
                          className="form-control"
                          placeholder="Name Of Brand"
                          value={form?.nameOfBrand}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "nameOfBrand",
                              value: e.target.value,
                            });
                          }}
                        />
                        {Error.nameOfBrand && !form?.nameOfBrand ? (
                          <p className="RequiredField">{Error?.nameOfBrand} </p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="formDiv">
                    <Row>
                      <Col xs={12} md={4}>
                        <Label>Name of weaver</Label>
                        <Input
                          name="nameOfWeaver"
                          id="nameOfWeaver"
                          className="form-control"
                          placeholder="Name Of Weaver"
                          value={form?.nameOfWeaver}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "nameOfWeaver",
                              value: e.target.value,
                            });
                          }}
                        />
                        {Error.nameOfWeaver && !form?.nameOfWeaver ? (
                          <p className="RequiredField">
                            {Error?.nameOfWeaver}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>Zari Used</Label>
                        <div className="formDivSub">
                          <Select
                            className="React_selector text-white"
                            classNamePrefix="react-select"
                            placeholder="Select"
                            isSearchable
                            name="zariUsed"
                            id="zariUsed"
                            value={
                              form?.zariUsed?.label === "None"
                                ? ""
                                : form?.zariUsed
                            }
                            options={zariUsedOptions}
                            onChange={(e: any) => {
                              handleOnInputChange({
                                name: "zariUsed",
                                value: e,
                              });
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>Weight of Saree(in Grams)</Label>
                        <Input
                          name="weightOfSaree"
                          id="weightOfSaree"
                          className="form-control"
                          placeholder="Weight Of Saree"
                          value={form?.weightOfSaree}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "weightOfSaree",
                              value: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="formDiv">
                    <Row>
                      <Col xs={12} md={4}>
                        <Label>Weight of Zari used(in Grams)</Label>
                        <Input
                          type="text"
                          name="weightOfZariUsed"
                          id="weightOfZariUsed"
                          className="form-control"
                          placeholder="Weight of Zari used"
                          value={form?.weightOfZariUsed}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "weightOfZariUsed",
                              value: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>Purity of Zari</Label>
                        <Input
                          type="textarea"
                          name="purityOfZari"
                          id="purityOfZari"
                          className="form-control"
                          placeholder="Purity Of Zari"
                          value={form?.zariUsed?.value}
                        // onChange={(e: any) => {
                        //   handleOnInputChange({
                        //     name: "purityOfZarir",
                        //     value: e.target.value,
                        //   });
                        // }}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>Yarn type </Label>
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isSearchable
                          name="yarnType"
                          value={form?.yarnType}
                          options={yarnUsedOptions}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "yarnType",
                              value: e,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="formDiv">
                    <Row>
                      <Col xs={12} md={4}>
                        <Label>Loom Number </Label>
                        <Input
                          type="text"
                          name="loomNumber"
                          className="form-control"
                          placeholder="Loom Number"
                          value={form?.loomNum}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "loomNum",
                              value: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>HKP TagID </Label>
                        <Input
                          style={{
                            textTransform: "none",
                          }}
                          type="text"
                          name="xuid"
                          className="form-control"
                          placeholder="HKP TagID"
                          value={form?.xuid}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "xuid",
                              value: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>Time Taken in months </Label>
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isSearchable
                          name="timeTakenInMonths"
                          value={form?.productionTimeInMonths}
                          options={timeTakenInMonthsOptions}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "timeTakenInMonths",
                              value: e,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="formDiv">
                    <Row>
                      <Col xs={12} md={4}>
                        <Label>Working Hours </Label>
                        <Input
                          type="text"
                          name="workingHours"
                          id="workingHours"
                          className="form-control"
                          placeholder="Working Hours"
                          value={form?.workingHours}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "workingHours",
                              value: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={6} md={4} lg={4}>
                        <Label>Silk Mark Number</Label>
                        <Input
                          type="text"
                          name="silkMark"
                          className="form-control"
                          placeholder="Slik mark number"
                          value={form?.silkMark}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "silkMark",
                              value: e.target.value,
                            });
                          }}
                        />
                        {/* <FormGroup>
                          <Toggle
                            size="lg"
                            // defaultChecked={true}
                            checked={form?.silkMark}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={(checked: boolean, event) => {
                              handleOnInputChange({
                                name: "silkMark",
                                value: checked,
                              });
                            }}
                          />{" "}
                        </FormGroup> */}
                      </Col>
                      <Col xs={6} md={4} lg={4}>
                        <Label>Handloom Mark Number</Label>
                        <Input
                          type="text"
                          name="handloomMark"
                          className="form-control"
                          placeholder="Handloom mark number"
                          value={form?.handloomMark}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "handloomMark",
                              value: e.target.value,
                            });
                          }}
                        />
                        {/* <FormGroup>
                          <Toggle
                            size="lg"
                            // defaultChecked={true}
                            checked={form?.handloomMark}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={(checked: boolean, event) => {
                              handleOnInputChange({
                                name: "handloomMark",
                                value: checked,
                              });
                            }}
                          />
                        </FormGroup> */}
                      </Col>
                      <Col xs={6} md={4} lg={2} className="mt-3">
                        <Label>Made in India</Label>
                        <FormGroup>
                          <Toggle
                            size="lg"
                            // defaultChecked={true}
                            checked={form?.madeInIndia}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={(checked: boolean, event) => {
                              handleOnInputChange({
                                name: "madeInIndia",
                                value: checked,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className="UserDetailsDiv mb-5">
                <div className="UserHeader">Upload Process</div>
                <div className="icons">
                  <div className="LineColr"></div>
                  <p className="TooltipP">
                    <ProductDesignIcon />
                  </p>
                  <div
                    className={
                      selectTab1 === "weaveDying" || selectTab1 === "finishing"
                        ? "LineColr"
                        : "Line"
                    }
                  ></div>
                  <span className="TooltipW">
                    <ProductWeaveIcon />
                  </span>
                  <div
                    className={selectTab1 === "finishing" ? "LineColr" : "Line"}
                  ></div>
                  <span className="TooltipF">
                    <ProductFinishIcon />
                  </span>
                </div>
                {selectTab1 === "designing" ||
                  (selectTab1 !== "dying" &&
                    selectTab1 !== "weaving" &&
                    selectTab1 !== "finishing" &&
                    selectTab1 !== "designing") ? (
                  <>
                    {" "}
                    <div className="UserHeader headerDisplay">
                      <div>Designing</div>
                    </div>
                    <div className="userPd">
                      <div className="formDiv">
                        <Row>
                          <Col xs={10} md={6} lg={4}>
                            <Label>Artisan</Label>
                            <div className="formDivSub">
                              <Select
                                className="React_selector text-white"
                                classNamePrefix="react-select"
                                placeholder="Select"
                                isSearchable
                                name="userRole"
                                id="userRole"
                                value={form?.designer}
                                options={designerOptions}
                                onChange={(e: any) => {
                                  handleOnInputChange({
                                    name: "designer",
                                    value: e,
                                  });
                                }}
                                onInputChange={(e) =>
                                  handleOnInputChangeFilters({
                                    name: "designing",
                                    value: e,
                                  })
                                }
                              />
                              <div className="DivSep"></div>
                              <div className="formAddDiv">
                                <AddIcon
                                  openModal={handleOpenModal}
                                  name="designing"
                                  value={true}
                                />
                              </div>
                            </div>
                            {/* {handleNameFlag?.designing &&
                            form?.designer?.label?.length &&
                            form?.designer?.value?.roleId !==
                              "63c64da18667bd64a57c62fe" ? (
                              <p className="RequiredField">
                                Selected User is not a part of artisan
                              </p>
                            ) : (
                              ""
                            )} */}
                          </Col>
                          <Col xs={2} md={3} lg={2}>
                            <Button
                              className="resetForProduct"
                              onClick={() => {
                                handleReset({
                                  name: "designing",
                                });
                              }}
                            >
                              <span className="DesktopReset">Reset</span>
                              <span className="mobileReset">
                                <ResetIcon />
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <Label>Upload Files</Label>
                      <span className="ms-2">
                        Photo (jpg, jpeg, png) / Video (mp4 , ogg, webm) / PDF
                      </span>
                      <div className="">
                        {baseImageDesigner?.length > 0 ? (
                          <>
                            <div className="imageDiv1">
                              <span className="pl-2 pr-2 ">
                                <div className="divAddIcon">
                                  <input
                                    type="file"
                                    name="Image"
                                    multiple={true}
                                    accept=".png,.jpg,.jpeg,.heic,.doc,.docx,.pdf,.mp4,.webm,.ogg,.bmp"
                                    style={{ display: "none" }}
                                    ref={fileInputDesigner}
                                    onChange={(e) => {
                                      uploadFiles({
                                        fileArr: fileArray,
                                        fileObject: fileObjDesign,
                                        name: "designing",
                                        files: e.target.files,
                                      });
                                    }}
                                  />
                                  <ImageAddIcon
                                    {...props}
                                    fileUploadTrigger={
                                      fileUploadTriggerDesigner
                                    }
                                  />
                                </div>
                                <div className="d-flex">
                                  {baseImageDesigner.length > 0 ? (
                                    <div className="PreviewDivPart">
                                      {baseImageDesigner.map(
                                        (item: any, i: any) => {
                                          return (
                                            <React.Fragment key={i}>
                                              {loader ? (
                                                <div className="CircleLoder">
                                                  <CircleLoader />
                                                </div>
                                              ) : (
                                                <div className="ArryyIcon">
                                                  <ImageClose
                                                    {...props}
                                                    i={i}
                                                    item={item}
                                                    close={handleDeleteDesigner}
                                                  />
                                                  {item?.show === true ? (
                                                    <ShowIcon
                                                      onClick={onSetIndexDesign}
                                                      index={i}
                                                      name="designing"
                                                    />
                                                  ) : (
                                                    <HideIcon
                                                      onClick={onSetIndexDesign}
                                                      index={i}
                                                      name="designing"
                                                    />
                                                  )}
                                                  <FilePreviewPart
                                                    {...props}
                                                    item={item}
                                                  />
                                                </div>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <ImageDivPart
                              {...props}
                              fileUploadTrigger={fileUploadTriggerDesigner}
                              fileInput={fileInputDesigner}
                              fileUpload={uploadFiles}
                              name="designing"
                              fileArr={fileArray}
                              fileObject={fileObjDesign}
                              content="Click to upload designing images or files"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="dbtn">
                      <Button className="Previous" disabled={true}>
                        Previous
                      </Button>
                      <Button
                        className="Next"
                        onClick={() => {
                          setSelectTab1("dying");
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {selectTab1 === "dying" ? (
                  <>
                    {" "}
                    <div className="UserHeader headerDisplay">
                      <div>Dying</div>
                    </div>
                    <div className="userPd">
                      <div className="formDiv">
                        <Row>
                          <Col xs={10} md={6} lg={4}>
                            <Label>Karigar</Label>
                            <div className="formDivSub">
                              <Select
                                className="React_selector text-white"
                                classNamePrefix="react-select"
                                placeholder="Select"
                                isSearchable
                                name="userRole"
                                id="userRole"
                                value={form?.dyer}
                                options={dyerOptions}
                                onChange={(e: any) => {
                                  handleOnInputChange({
                                    name: "dyer",
                                    value: e,
                                  });
                                }}
                                onInputChange={(e) =>
                                  handleOnInputChangeFilters({
                                    name: "dying",
                                    value: e,
                                  })
                                }
                              />
                              <div className="DivSep"></div>
                              <div className="formAddDiv">
                                <AddIcon
                                  openModal={handleOpenModal}
                                  name="dying"
                                  value={true}
                                />
                              </div>
                            </div>
                            {/* {handleNameFlag?.dying &&
                            form?.dyer?.label?.length &&
                            form?.dyer?.value?.roleId !==
                              "63c64da18667bd64a57c6300" ? (
                              <p className="RequiredField">
                                Selected User is not a part of karigar
                              </p>
                            ) : (
                              ""
                            )} */}
                          </Col>
                          <Col xs={2} md={3} lg={2}>
                            <Button
                              className="resetForProduct"
                              onClick={() => {
                                handleReset({
                                  name: "dying",
                                });
                              }}
                            >
                              <span className="DesktopReset">Reset</span>
                              <span className="mobileReset">
                                <ResetIcon />
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <Label>Upload Files</Label>
                      <span className="ms-2">
                        Photo (jpg, jpeg, png) / Video (mp4 , ogg, webm) / PDF
                      </span>
                      <div className="d-flex justify-content-center">
                        {baseImageDying?.length > 0 ? (
                          <>
                            <div className="imageDiv1">
                              <span className="pl-2 pr-2 ">
                                <div className="divAddIcon">
                                  <input
                                    type="file"
                                    name="Image"
                                    multiple={true}
                                    accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,.mp4,.webm,.ogg,.heic,.bmp"
                                    style={{ display: "none" }}
                                    ref={fileInputUploadDying}
                                    onChange={(e) => {
                                      uploadFiles({
                                        fileArr: fileArrayDying,
                                        fileObject: fileObjDying,
                                        name: "dying",
                                        files: e.target.files,
                                      });
                                    }}
                                  />
                                  <ImageAddIcon
                                    {...props}
                                    fileUploadTrigger={fileUploadTriggerDying}
                                  />
                                </div>
                                <div className="d-flex">
                                  {baseImageDying?.length > 0 ? (
                                    <div className="PreviewDivPart">
                                      {baseImageDying.map(
                                        (item: any, i: any) => {
                                          return (
                                            <React.Fragment key={i}>
                                              {loader ? (
                                                <div className="CircleLoder">
                                                  <CircleLoader />
                                                </div>
                                              ) : (
                                                <div className="ArryyIcon">
                                                  <ImageClose
                                                    {...props}
                                                    i={i}
                                                    item={item}
                                                    close={handleDeleteDying}
                                                  />
                                                  {item?.show === true ? (
                                                    <ShowIcon
                                                      onClick={onSetIndexDesign}
                                                      index={i}
                                                      name="dying"
                                                    />
                                                  ) : (
                                                    <HideIcon
                                                      onClick={onSetIndexDesign}
                                                      index={i}
                                                      name="dying"
                                                    />
                                                  )}
                                                  <FilePreviewPart
                                                    {...props}
                                                    item={item}
                                                  />
                                                </div>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <ImageDivPart
                              {...props}
                              fileUploadTrigger={fileUploadTriggerDying}
                              fileInput={fileInputUploadDying}
                              fileUpload={uploadFiles}
                              name="dying"
                              fileArr={fileArrayDying}
                              fileObject={fileObjDying}
                              content="Click to upload dying images or files"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="dbtn1">
                      <Button
                        className="Previous"
                        onClick={() => {
                          setSelectTab1("designing");
                        }}
                      >
                        Previous
                      </Button>
                      <Button
                        className="Next"
                        onClick={() => {
                          setSelectTab1("weaving");
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {selectTab1 === "weaving" ? (
                  <>
                    {" "}
                    <div className="UserHeader headerDisplay">
                      <div>Weaving</div>
                    </div>
                    <div className="userPd">
                      <div className="formDiv">
                        <Row>
                          <Col xs={10} md={6} lg={4}>
                            <Label>Karigar</Label>
                            <div className="formDivSub">
                              <Select
                                className="React_selector text-white"
                                classNamePrefix="react-select"
                                placeholder="Select"
                                isSearchable
                                name="weaver"
                                id="weaver"
                                value={form?.weaver}
                                options={weaverOptions}
                                onChange={(e: any) => {
                                  handleOnInputChange({
                                    name: "weaver",
                                    value: e,
                                  });
                                }}
                                onInputChange={(e) =>
                                  handleOnInputChangeFilters({
                                    name: "weaving",
                                    value: e,
                                  })
                                }
                              />
                              <div className="DivSep"></div>
                              <div className="formAddDiv">
                                <AddIcon
                                  openModal={handleOpenModal}
                                  name="weaving"
                                  value={true}
                                />
                              </div>
                            </div>
                            {/* {handleNameFlag?.weaving &&
                            form?.weaver?.label?.length &&
                            form?.weaver?.value?.roleId !==
                              "63c64da18667bd64a57c62ff" ? (
                              <p className="RequiredField">
                                Selected User is not a part of karigar
                              </p>
                            ) : (
                              ""
                            )} */}
                          </Col>
                          <Col xs={2} md={3} lg={2}>
                            <Button
                              className="resetForProduct "
                              onClick={() => {
                                handleReset({
                                  name: "weaving",
                                });
                              }}
                            >
                              <span className="DesktopReset">Reset</span>
                              <span className="mobileReset">
                                <ResetIcon />
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <Label>Upload Files</Label>
                      <span className="ms-2">
                        Photo (jpg, jpeg, png) / Video (mp4 , ogg, webm) / PDF
                      </span>
                      <div className="">
                        {baseImageWeaver?.length > 0 ? (
                          <>
                            <div className="imageDiv1">
                              <span className="pl-2 pr-2 ">
                                <div className="divAddIcon">
                                  <input
                                    type="file"
                                    name="Image"
                                    multiple={true}
                                    accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,.mp4,.webm,.ogg,.heic,.bmp"
                                    style={{ display: "none" }}
                                    ref={fileInputWeaver}
                                    onChange={(e) => {
                                      uploadFiles({
                                        fileArr: fileArrayWeaving,
                                        fileObject: fileObjWeaving,
                                        name: "weaving",
                                        files: e.target.files,
                                      });
                                    }}
                                  />
                                  <ImageAddIcon
                                    {...props}
                                    fileUploadTrigger={fileUploadTriggerWeaver}
                                  />
                                </div>
                                <div className="d-flex">
                                  {baseImageWeaver?.length > 0 ? (
                                    <div className="PreviewDivPart">
                                      {baseImageWeaver.map(
                                        (item: any, i: any) => {
                                          return (
                                            <React.Fragment key={i}>
                                              {loader ? (
                                                <div className="CircleLoder">
                                                  <CircleLoader />
                                                </div>
                                              ) : (
                                                <div className="ArryyIcon">
                                                  <ImageClose
                                                    {...props}
                                                    i={i}
                                                    item={item}
                                                    close={handleDeleteWeaver}
                                                  />
                                                  {item?.show === true ? (
                                                    <ShowIcon
                                                      onClick={onSetIndexDesign}
                                                      index={i}
                                                      name="weaving"
                                                    />
                                                  ) : (
                                                    <HideIcon
                                                      onClick={onSetIndexDesign}
                                                      index={i}
                                                      name="weaving"
                                                    />
                                                  )}
                                                  <FilePreviewPart
                                                    {...props}
                                                    item={item}
                                                  />
                                                </div>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <ImageDivPart
                              {...props}
                              fileUploadTrigger={fileUploadTriggerWeaver}
                              fileInput={fileInputWeaver}
                              fileUpload={uploadFiles}
                              name="weaving"
                              fileArr={fileArrayWeaving}
                              fileObject={fileObjWeaving}
                              content="Click to upload weaving images or files"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="dbtn1">
                      <Button
                        className="Previous"
                        onClick={() => {
                          setSelectTab1("dying");
                        }}
                      >
                        Previous
                      </Button>
                      <Button
                        className="Next"
                        onClick={() => {
                          setSelectTab1("finishing");
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {selectTab1 === "finishing" ? (
                  <>
                    {" "}
                    <div className="UserHeader headerDisplay">
                      <div>Finishing</div>
                    </div>
                    <div className="userPd">
                      <div className="formDiv">
                        <Row>
                          <Col xs={10} md={6} lg={4}>
                            <Label>Karigar</Label>
                            <div className="formDivSub">
                              <Select
                                className="React_selector text-white"
                                classNamePrefix="react-select"
                                placeholder="Select"
                                isSearchable
                                name="userRole"
                                id="userRole"
                                value={form?.finisher}
                                options={finisherOptions}
                                onChange={(e: any) => {
                                  handleOnInputChange({
                                    name: "finisher",
                                    value: e,
                                  });
                                }}
                                onInputChange={(e) =>
                                  handleOnInputChangeFilters({
                                    name: "finishing",
                                    value: e,
                                  })
                                }
                              />
                              <div className="DivSep"></div>
                              <div className="formAddDiv">
                                <AddIcon
                                  openModal={handleOpenModal}
                                  name="finishing"
                                  value={true}
                                />
                              </div>
                            </div>
                            {/* {handleNameFlag?.finishing &&
                            form?.finisher?.label?.length &&
                            (form?.finisher?.value?.roleId !==
                              "63c64da18667bd64a57c6303" ||
                              form?.finisher?.value?.roleId !==
                                "63c64da18667bd64a57c6302" ||
                              form?.finisher?.value?.roleId !==
                                "63c64da18667bd64a57c6301") ? (
                              <p className="RequiredField">
                                Selected User is not a part of karigar
                              </p>
                            ) : (
                              ""
                            )} */}
                          </Col>
                          <Col xs={2} md={3} lg={2}>
                            <Button
                              className="resetForProduct"
                              onClick={() => {
                                handleReset({
                                  name: "finishing",
                                });
                              }}
                            >
                              <span className="DesktopReset">Reset</span>
                              <span className="mobileReset">
                                <ResetIcon />
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <Label>Upload Files</Label>
                      <span className="ms-2">
                        Photo (jpg, jpeg, png) / Video (mp4 , ogg, webm) / PDF
                      </span>
                      <div className="d-flex justify-content-center">
                        {baseImageFinisher?.length > 0 ? (
                          <>
                            <div className="imageDiv1">
                              <span className="pl-2 pr-2 ">
                                <div className="divAddIcon">
                                  <input
                                    type="file"
                                    name="Image"
                                    multiple={true}
                                    accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,.mp4,.webm,.ogg,.heic,.bmp"
                                    style={{ display: "none" }}
                                    ref={fileInputFinisher}
                                    onChange={(e) => {
                                      uploadFiles({
                                        fileArr: fileArrayFinishing,
                                        fileObject: fileObjFinishing,
                                        name: "finishing",
                                        files: e.target.files,
                                      });
                                    }}
                                  />
                                  <ImageAddIcon
                                    {...props}
                                    fileUploadTrigger={
                                      fileUploadTriggerFinisher
                                    }
                                  />
                                </div>
                                <div className="d-flex">
                                  {baseImageFinisher?.length > 0 ? (
                                    <div className="PreviewDivPart">
                                      {baseImageFinisher.map(
                                        (item: any, i: any) => {
                                          return (
                                            <React.Fragment key={i}>
                                              {loader ? (
                                                <div className="CircleLoder">
                                                  <CircleLoader />
                                                </div>
                                              ) : (
                                                <div className="ArryyIcon">
                                                  <ImageClose
                                                    {...props}
                                                    i={i}
                                                    item={item}
                                                    close={handleDeleteFinisher}
                                                  />
                                                  {item?.show === true ? (
                                                    <ShowIcon
                                                      onClick={onSetIndexDesign}
                                                      index={i}
                                                      name="finishing"
                                                    />
                                                  ) : (
                                                    <HideIcon
                                                      onClick={onSetIndexDesign}
                                                      index={i}
                                                      name="finishing"
                                                    />
                                                  )}
                                                  <FilePreviewPart
                                                    {...props}
                                                    item={item}
                                                  />
                                                </div>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <ImageDivPart
                              {...props}
                              fileUploadTrigger={fileUploadTriggerFinisher}
                              fileInput={fileInputFinisher}
                              fileUpload={uploadFiles}
                              name="finishing"
                              fileArr={fileArrayFinishing}
                              fileObject={fileObjFinishing}
                              content="Click to upload finishing images or files"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="dbtn3">
                      <Button
                        className="Previous"
                        onClick={() => {
                          setSelectTab1("weaving");
                        }}
                      >
                        Previous
                      </Button>
                      <Button className="Next" disabled={true}>
                        Next
                      </Button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>

              {selectTab1 === "finishing" ? (
                <>
                  <div>
                    <Button
                      type="button"
                      className="save"
                      disabled={
                        loading || loader
                          ? // loader?.desinging ||
                          // loader?.dying ||
                          // loader?.weaving ||
                          // loader?.finishing ||
                          // loader?.variants
                          true
                          : false
                      }
                      onClick={() => handleSubmit({ status: 1 })}
                    >
                      Publish
                    </Button>
                    <Button
                      type="button"
                      className="save"
                      disabled={
                        loading || loader
                          ? // loader?.desinging ||
                          // loader?.dying ||
                          // loader?.weaving ||
                          // loader?.finishing ||
                          // loader?.variants
                          true
                          : false
                      }
                      onClick={() => handleSubmit({ status: 0 })}
                    >
                      Save
                    </Button>
                  </div>
                </>
              ) : (
                <Button
                  className="save"
                  onClick={() => {
                    handleSubmit({ status: 0 });
                  }}
                  disabled={
                    loading || loader
                      ? // loader?.desinging ||
                      // loader?.dying ||
                      // loader?.weaving ||
                      // loader?.finishing ||
                      // loader?.variants
                      true
                      : false
                  }
                >
                  Save
                </Button>
              )}
            </div>
            {/* </form> */}
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  getUsers,
  getArtforms,
  getTypes,
  getCategory,
  getSubCategorys,
  getWeaveType,
  createUser,
  updateUsers,
  uploadImages1,
  uploadImages,
  fetchProfilePresignedUrl,
  fetchProductWeavingPresignedUrl,
  fetchProductDesigningPresignedUrl,
  fetchProductFinishingPresignedUrl,
  fetchProductDyingPresignedUrl,
  createProduct,
  getProducts,
})(CreateProduct);
