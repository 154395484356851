import React, { useEffect, useState } from "react";
import { Label, Input, Button } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";

//components
import { debounce } from "../../common/commonFiles/debounce";
import Loader from "../../common/Components/Loader/Loader";
import notifications from "../../common/Components/Notifications/Notification";
// actions
import { getArtforms } from "../../Store/artform/actions";
import {
  createType,
  updateType,
  getTypesFilters,
} from "../../Store/type/actions";

let artformOptions: any = [];
var formDetails: any = [];
const AddOrEditTypeForm = (props: any) => {
  const [loading, setLoading]: any = React.useState(false);
  const [artformName, setArtformName]: any = useState("");
  const [filterValue, setFilterValue]: any = useState("");
  const [form, setForm]: any = React.useState(
    props.editData?._id
      ? {
          artform: {
            label: props.editData?.artform?.name,
            value: props.editData?.artform?._id,
          },
          type: props.editData?.name,
        }
      : props?.form?.artform
      ? {
          artform: {
            label: props?.form?.artform?.label,
            value: props?.form?.artform?.value,
          },
          type: "",
        }
      : {
          artform: "",
          type: "",
        }
  );
  const [Error, setError]: any = React.useState({
    artform: "",
    type: "",
  });
  useEffect(() => {
    if (!form?.artform?.value) {
      getArtformData();
    }
  }, []);

  const getArtformData = (data?: any) => {
    props.getArtforms(
      {
        offset: 0,
        limit: 10,
        artformName: artformName || data?.value,
      },
      (res: any) => {
        artformOptions = [];
        res?.data?.docs.map((ele: any) => {
          artformOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const validate = () => {
    let error = "";
    let err = Error;
    if (!form?.artform) {
      error = "Required";
      err = {
        ...err,
        artform: "ArtForm is Required!",
      };
    }
    if (!form?.type) {
      error = "Required";
      err = {
        ...err,
        type: "Type is Required!",
      };
    }
    setError(err);
    return error;
  };
  const handleSubmitChange = (type: any) => {
    setForm({ ...form, [type.name]: type?.value });
    formDetails = { ...formDetails, [type.name]: type?.value };
  };
  const callingGetTypes = (data: any) => {
    props.getTypesFilters(
      {
        offset: 0,
        limit: 10,
        artFormId: formDetails?.artform?.value || data?.artform,
        typeName: data?.value,
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          res.data?.docs.map((ele: any, i: any) => {
            if (data?.value === ele?.name) {
              setFilterValue(ele?.name);
            }
          });
        }
      }
    );
  };
  const handleChangeDebounce = React.useCallback(
    debounce((data: any) => {
      if (data?.name === "artform") {
        getArtformData(data);
      }
      if (data?.name === "type") {
        callingGetTypes({
          artform: data?.artform,
          value: data?.value,
        });
      }
    }, 1000),
    []
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let result: any = validate();
    if (result?.length === 0) {
      setLoading(true);
      props?.editData?._id
        ? props.updateType(
            {
              typeId: props?.editData?._id,
              artFormId: form?.artform?.value,
              name: form?.type,
            },
            (res: any) => {
              setLoading(false);
              if (res.success) {
                notifications({
                  title: "Success!",
                  message: res?.message,
                  type: "success",
                });
                props.closeModal();
                props.getAllData();
              } else {
                notifications({
                  title: "Error!",
                  message: res?.message,
                  type: "danger",
                });
              }
            }
          )
        : props.createType(
            {
              name: form?.type,
              artFormId: form?.artform?.value,
            },
            (res: any) => {
              setLoading(false);
              if (res.success) {
                notifications({
                  title: "Success!",
                  message: res?.message,
                  type: "success",
                });
                props.closeModal();
                props.getAllData();
                props.handleName({
                  name: "type",
                  artform: form?.artform?.value,
                  type: form?.type,
                });
              } else {
                notifications({
                  title: "Error!",
                  message: res?.message,
                  type: "danger",
                });
              }
            }
          );
    }
  };
  const handleOnInputChange = (data: any) => {
    if (data?.name === "artform") {
      if (data?.value?.length >= 2) {
        handleChangeDebounce({
          name: "artform",
          value: data.value,
        });
      } else if (data?.value?.length === 0 && !form?.artform?.value) {
        handleChangeDebounce({
          name: "artform",
          value: data.value,
        });
      }
    }
  };
  return (
    <>
      <div className="AddTypeForm">
        <div className="form">
          {loading ? (
            <div className="LoaderDivModal">
              <Loader />
            </div>
          ) : (
            <>
              <form onSubmit={(e: any) => handleSubmit(e)}>
                <div className="formDiv">
                  <Label>
                    Artform<span>*</span>
                  </Label>

                  <Select
                    className="React_selector text-white"
                    classNamePrefix="react-select"
                    placeholder="Select Artform"
                    autoFocus={false}
                    isSearchable
                    name="artform"
                    id="artform"
                    value={form?.artform}
                    options={artformOptions}
                    onChange={(e: any) => {
                      handleSubmitChange({
                        name: "artform",
                        value: e,
                      });
                    }}
                    onInputChange={(e) =>
                      handleOnInputChange({
                        name: "artform",
                        value: e,
                      })
                    }
                  />
                  {Error && !form?.artform ? (
                    <p className="RequiredField">{Error?.artform} </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="formDiv">
                  <Label>
                    Type<span>*</span>
                  </Label>
                  <Input
                    name="Type"
                    id="type"
                    className="form-control"
                    placeholder="Type Name"
                    value={form?.type}
                    onChange={(e: any) => {
                      handleSubmitChange({
                        name: "type",
                        value: e.target.value,
                      });
                      handleChangeDebounce({
                        name: "type",
                        value: e.target.value,
                        artform: form?.artform?.value,
                      });
                    }}
                  />
                  {Error.type && !form?.type ? (
                    <p className="RequiredField">{Error?.type} </p>
                  ) : (
                    ""
                  )}
                  {form?.type && form?.type === filterValue ? (
                    // filterValue?.product === ""
                    <p className="RequiredField">Type is already exist's</p>
                  ) : (
                    ""
                  )}
                </div>
                <Button className="artButton" type="submit">
                  {loading ? "Processing" : "Save"}
                </Button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  createType,
  getArtforms,
  updateType,
  getTypesFilters,
})(AddOrEditTypeForm);
