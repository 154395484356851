import React ,{useState,useEffect}from 'react'
import { Modal, ModalHeader, ModalBody,Row,Col} from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
// actions
import { getArtforms } from "../../Store/artform/actions";
//componets
import Loader from '../../common/Components/Loader/Loader';

const ViewArtfom = (props:any) => {
    const{closeModal,openModal,viewData}:any=props
   
  return (
    <Modal
        isOpen={openModal}
        toggle={closeModal}
        size="lg"
        fade={false}
        className="ArtFormModal ViewModal"
      >
        <ModalHeader>
          <div className="Heading">
            <h3>View Artform</h3>
            <svg
              onClick={() => {closeModal()
                // setEditData("")
              }}
              width="20"
              height="20"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L1 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1L8 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className='ViewDiv'>
           <Row>
            <Col xs={6} md={4}>
              <span className='spanH1'>Artform :</span>
            </Col>
            <Col xs={6} md={8}>
              <span>{viewData?.name}</span>
            </Col>
           </Row>
           <Row>
            <Col xs={6} md={4}>
              <span className='spanH1'>Created By:</span>
            </Col>
            <Col xs={6} md={8}>
              <span>{viewData?.createdBy?.role ? viewData?.createdBy?.role :"N/A"}</span>
            </Col>
           </Row>
           <Row>
            <Col xs={6} md={4}>
              <span className='spanH1'>Created At:</span>
            </Col>
            <Col xs={6} md={8}>
              <span>{viewData?.createdBy?._id?.date ? moment(new Date(viewData?.createdBy?._id?.date)).format("YYYY-MM-DD hh:mm A"):"N/A"}
              </span>
            </Col>
           </Row>
           <Row>
            <Col xs={6} md={4}>
              <span className='spanH1'>Update By:</span>
            </Col>
            <Col xs={6} md={8}>
              <span>{viewData?.createdBy?.role ? viewData?.createdBy?.role :"N/A"}</span>
            </Col>
           </Row>
           <Row>
            <Col xs={6} md={4}>
              <span className='spanH1'>Updated At:</span>
            </Col>
            <Col xs={6} md={8}>
            <span>{viewData?.createdBy?._id?.date ? moment(new Date(viewData?.createdBy?._id?.date)).format("YYYY-MM-DD hh:mm A"):"N/A"}
            </span>
            </Col>
           </Row>
          </div>
        </ModalBody>
      </Modal>
  )
}

const mapStateToProps = (state: any) => {
  return {
    ...state,
    artFormDetails: state?.artformReducer?.getArtformDetails,
  };
};
export default connect(mapStateToProps, {
  getArtforms,
})(ViewArtfom);