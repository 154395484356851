import { types } from "./actionTypes";
const initialState = {
  getCategoryDetails: null,
  updateCategoryDetails: null,
  createCategoryDetails: null,
  deleteCategoryDetails: null,
  getCategoryFilterDetails: null,
};
const categoryReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.GET_CATEGORY_SUCCESS:
      state = {
        ...state,
        getCategoryDetails: action.payload,
      };
      return state;
    case types.GET_CATEGORY_FILTERS_SUCCESS:
      state = {
        ...state,
        getCategoryFilterDetails: action.payload,
      };
      return state;
    case types.CREATE_CATEGORY_SUCCESS:
      state = {
        ...state,
        createCategoryDetails: action.payload,
      };
      return state;
    case types.UPDATE_CATEGORY_SUCCESS:
      state = {
        ...state,
        updateCategoryDetails: action.payload,
      };
      return state;
    case types.DELETE_CATEGORY_SUCCESS:
      state = {
        ...state,
        deleteCategoryDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default categoryReducer;
