let zariUsedOptions = [
  {
    label: "None",
    value: "",
  },
  {
    label: "Real zari",
    value:
      "Cotton base - Wrapped with 99.8% silver electroplated with 1.5% 24 carat gold",
  },
  {
    label: "Semi real zari",
    value: "Cotton base - 8% silver electroplated with 1.5% 24 carat gold",
  },
  {
    label: "Tested zari",
    value: "Cotton base - Wrapped with brass Metal ",
  },
  {
    label: "Artificial Zari",
    value: "Cotton yarn Wrapped with Copper",
  },
  {
    label: "No Zari",
    value: " ",
  },
];
let yarnUsedOptions = [
  {
    label: "Katan Silk",
    value: "Katan Silk",
  },
  {
    label: "Katan Silk * Georgette Silk",
    value: "Katan Silk * Georgette Silk",
  },
  {
    label: "Organza/Kora silk",
    value: "Organza/Kora silk",
  },
  {
    label: "Chiniya/Spun silk",
    value: "Chiniya/Spun silk",
  },
  {
    label: "Tissue",
    value: "Tissue",
  },
];
let typeOfSareeByDesignOptions = [
  {
    label: "Floral booties or jaal",
    value: "BAAGHBAAN",
  },
  {
    label: "Diagonal",
    value: "RANGTAARI",
  },
  {
    label: "Skirt with booties or jaal",
    value: "GHOOMAR",
  },
  {
    label: "Geometric jaal",
    value: "NAKSHA",
  },
  {
    label: "Animal / bird",
    value: "VAN-SHRINGAAR",
  },
  {
    label: "Jungla",
    value: "KEHKASHAAN",
  },
  {
    label: "Vertical/ horizontal stripes",
    value: "GHAZAL",
  },
  {
    label: "Artificial zari / no zari",
    value: "GUL-E-BAHAAR",
  },
];
let timeTakenInMonthsOptions = [
  {
    label: "2.75",
    value: "660",
  },
  {
    label: "4",
    value: "960",
  },
  {
    label: "5.5",
    value: "1320",
  },
  {
    label: "6.75",
    value: "1620",
  },
  {
    label: "1.75",
    value: "420",
  },
  {
    label: "1.9",
    value: "456",
  },
];

export {
  zariUsedOptions,
  timeTakenInMonthsOptions,
  typeOfSareeByDesignOptions,
  yarnUsedOptions,
};
