import React, { useState, useEffect } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import AppRoute from "./route/route";
import VerticalLayout from "./common/authLayout/VerticalLayout";
import NonAuthLayout from "./common/nonAuthLayout/NonAuthLayout";
import { publicRoutes, privateRoutes } from "./route/index";
import { ReactNotifications } from "react-notifications-component";
import { connect } from "react-redux";
// store
import { getApplication } from "./Store/application/actions";
import { getUsersPrpfileLogo } from "./Store/users/actions";
//css
import "./App.css";
import "./theme.scss";
const App = (props: any) => {
  const getLayout = () => {
    let layoutCls = VerticalLayout;
    return layoutCls;
  };
  const Layout = getLayout();

  return (
    <>
      <Router>
        <ReactNotifications />
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
          {privateRoutes?.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))}
        </Switch>
      </Router>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  getUsersPrpfileLogo,
  getApplication,
})(App);
