import React from "react";
import reported from "../assets/images/reported.png";
import HkvBadge from "../assets/images/HKP Badge.png";

const ProductReportedPage = () => {
  return (
    <div className="productfakeAlert">
      <div className="fakealDiv1">
        <img src={reported} alt="Fake Alert" />
      </div>
      <div className="fakeAlInfo">
        <h3>This product has been reported</h3>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <div className="HkvDiv4">
        <img className="HkvBadge" src={HkvBadge} alt="HKV badge" />
      </div>
    </div>
  );
};

export default ProductReportedPage;
