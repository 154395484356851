import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import  { AxiosResponse } from "axios";
//medthods
import {
  postApi,
  getApi,
  deleteApi,
  putApi
} from "../../helpers/auth_helpers";

// workers
function* workerGetTypes({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset :0;
  let limit = filters?.limit ? filters?.limit : 10;
  let typeId = filters?.typeId ? filters?.typeId : "";
  let typeName = filters?.typeName ? filters?.typeName : "";
 let artFormId = filters?.artFormId ? filters?.artFormId :"";
 let startDate = filters?.startDate ? filters?.startDate : "";
 let endDate = filters?.endDate ? filters?.endDate :"";
 let sortBy = filters?.sortBy ? filters?.sortBy :"createdAt";
 let orderBy = filters?.orderBy ? filters?.orderBy :"-1";
 let createdBy = filters?.createdBy ? filters?.createdBy :""

  try {
    const url: string =
    process.env.REACT_APP_SERVERURL +
    "/type/types?offset=" +
    offset +
    "&limit=" +
    limit +
    "&typeId="+
    typeId+
    "&artFormId="+
    artFormId+
    "&name=" +
    typeName+
    "&sortBy="+
    sortBy+
    "&orderBy="+
    orderBy+
    "&startDate="+
    startDate+
    "&endDate="+
    endDate+
    "&createdBy="+
    createdBy;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_TYPES_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetTypesFilters({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset :0;
  let limit = filters?.limit ? filters?.limit : 10;
  let typeName = filters?.typeName ? filters?.typeName : "";
 let artFormId = filters?.artFormId ? filters?.artFormId :"";


  try {
    const url: string =
    process.env.REACT_APP_SERVERURL +
    "/type/types?offset=" +
    offset +
    "&limit=" +
    limit +
    "&artFormId="+
    artFormId+
    "&name=" +
    typeName;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_TYPES_FILTERS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateType({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/type/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_TYPES_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateType({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/type/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_TYPES_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteType({ payload, callback }: any) {
    try {
      const url: string = process.env.REACT_APP_SERVERURL + "/type/delete";
      const response: AxiosResponse = yield call(deleteApi, url, payload);
      callback(response);
      yield put({
        type: types.DELETE_TYPES_SUCCESS,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
  }

// watchers
export function* watchGetType() {
  yield takeEvery(types.GET_TYPES, workerGetTypes);
}
export function* watchGetTypeFilters() {
  yield takeEvery(types.GET_TYPES_FILTERS, workerGetTypesFilters);
}
export function* watchCreateType() {
  yield takeEvery(types.CREATE_TYPES, workerCreateType);
}
export function* watchUpdateType() {
  yield takeEvery(types.UPDATE_TYPES, workerUpdateType);
}
export function* watchDeleteType() {
    yield takeEvery(types.DELETE_TYPES, workerDeleteType);
  }

function* typeSaga() {
  yield all([fork(watchGetType)]);
  yield all([fork(watchGetTypeFilters)]);
  yield all([fork(watchCreateType)]);
  yield all([fork(watchUpdateType)]);
  yield all([fork(watchDeleteType)]);
}
export default typeSaga;
