import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
// actions
import { changePasswordPostLogin } from "../../Store/auth/login/actions";
// components
import ModalCloseIcon from "../../common/Components/Icons/ModalCloseIcon";
import Loader from "../../common/Components/Loader/Loader";
import notifications from "../../common/Components/Notifications/Notification";
import HideIcon from "../../common/Components/Icons/HideIcon";
import ShowIcon from "../../common/Components/Icons/ShowIcon";
const ChangePassword = (props: any) => {
  const { openModal, closeModal, userId } = props;
  const [loading, setLoading]: any = useState(false);
  const [form, setForm]: any = useState({
    oldPswd: "",
    newPswd: "",
  });
  const [show, setShow]: any = useState(false);
  const [showNewPswd, setShowNewPswd]: any = useState(false);
  const handleSetShow = (value: any) => {
    setShow(value);
  };
  const handleSetShowNewPswd = (value: any) => {
    setShowNewPswd(value);
  };
  const handleSubmit = () => {
    setLoading(true);
    let payload: any = {
      userId,
      oldPassword: form?.oldPswd,
      newPassword: form?.newPswd,
    };
    props.changePasswordPostLogin(payload, (res: any) => {
      setLoading(false);
      if (res?.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        closeChangePasswordModal();
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  const closeChangePasswordModal = () => {
    closeModal();
    setForm({
      oldPswd: "",
      newPswd: "",
    });
    setShowNewPswd(false);
    setShow(false);
  };
  return (
    <>
      <Modal
        isOpen={openModal}
        toggle={closeChangePasswordModal}
        size="lg"
        className="ArtFormModal changePswd "
        fade={false}
      >
        <ModalHeader>
          <div className="Heading">
            <h3>Change Password</h3>
            <ModalCloseIcon {...props} closeModal={closeChangePasswordModal} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="AddArtform">
            <form>
              <div className="form">
                {loading ? (
                  <div className="LoaderDivModal">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <div className="formDiv">
                      <Label>
                        Old Password<span>*</span>
                      </Label>
                      <Input
                        style={{
                          textTransform: "none",
                        }}
                        className="inputField"
                        type={show ? "text" : "password"}
                        placeholder="Old Password"
                        name="oldPswd"
                        value={form?.oldPswd}
                        onChange={(e: any) =>
                          setForm({
                            ...form,
                            oldPswd: e.target.value,
                          })
                        }
                      />
                      <div className="icon">
                        {show ? (
                          <HideIcon onClick={() => handleSetShow(false)} />
                        ) : (
                          <ShowIcon onClick={() => handleSetShow(true)} />
                        )}
                      </div>
                    </div>
                    <div className="formDiv">
                      <Label>
                        New Password<span>*</span>
                      </Label>
                      <Input
                        style={{
                          textTransform: "none",
                        }}
                        className="inputField"
                        type={showNewPswd ? "text" : "password"}
                        placeholder="New Password"
                        name="newPswd"
                        value={form?.newPswd}
                        onChange={(e: any) =>
                          setForm({
                            ...form,
                            newPswd: e.target.value,
                          })
                        }
                      />
                      <div className="icon">
                        {showNewPswd ? (
                          <HideIcon
                            onClick={() => handleSetShowNewPswd(false)}
                          />
                        ) : (
                          <ShowIcon
                            onClick={() => handleSetShowNewPswd(true)}
                          />
                        )}
                      </div>
                    </div>
                    <div className="formDiv"></div>
                  </>
                )}
                <Button
                  className="artButton"
                  disabled={
                    form?.oldPswd?.length > 0 && form?.newPswd?.length > 0
                      ? false
                      : true
                  }
                  onClick={() => handleSubmit()}
                >
                  {loading ? "Processing" : "Save"}
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  changePasswordPostLogin,
})(ChangePassword);
