import React from "react";
import { Label, Input, Button, Row, Col } from "reactstrap";
import FilePreviewPart from "./FilePreviewPart";
import ImageDivPart from "./ImageDivPart";

import ImageAddIcon from "../../common/Components/Icons/ImageAddIcon";
import ImageClose from "../../common/Components/Icons/ImageClose";
import CircleLoader from "../../common/Components/Loader/CircleLoader";
import DownloadIcon from "../../common/Components/Icons/DownloadIcon";
const CreateVariant = (props: any) => {
  const {
    variantForm,
    baseImageVariant,
    fileInputVariant,
    fileArrayVariant,
    fileObjVariant,
    uploadFiles,
    fileUploadTriggerVariant,
    handleDeleteVariant,
    handleSubmitVariant,
    onChangeVarient,
    Error,
    validateForVariant,
    loader,
    loading,
  } = props;
  return (
    <div className="createProduct CreateProductDetails">
      <div className="form">
        <div className="UserDetailsDiv mb-5">
          {/* <div className="UserHeader headerDisplay">
          <div>Create Product Variants</div>
        </div> */}
          <div className="userPd">
            <div className="formDiv">
              <Row>
                <Col xs={12} md={4}>
                  <Label>
                    Product Color<span>*</span>
                  </Label>
                  <Input
                    name="variantColor"
                    id="variantColor"
                    className="form-control"
                    placeholder="Color"
                    value={variantForm?.variantCode}
                    onChange={(e: any) => onChangeVarient(e)}
                  />
                  {Error.color && !variantForm?.variantCode ? (
                    <p className="RequiredField">{Error?.color} </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
            <Label>Upload Files</Label>
            <span className="ms-2">
              Photo (jpg, png, gif) / Video (not more that 10mb)/ PDF (not more
              that 5mb)
            </span>
            <div className="">
              {baseImageVariant?.length > 0 ? (
                <>
                  <div className="imageDiv1">
                    <span className="pl-2 pr-2 ">
                      <div className="divAddIcon">
                        <input
                          type="file"
                          name="Image"
                          multiple={true}
                          accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,.mp4,.webm,.ogg,.bmp"
                          style={{ display: "none" }}
                          ref={fileInputVariant}
                          onChange={(e) => {
                            uploadFiles({
                              fileArr: fileArrayVariant,
                              fileObject: fileObjVariant,
                              name: "variant",
                              files: e.target.files,
                            });
                          }}
                        />
                        <ImageAddIcon
                          {...props}
                          fileUploadTrigger={fileUploadTriggerVariant}
                        />
                      </div>
                      <div className="d-flex">
                        {baseImageVariant.length > 0 ? (
                          <div className="PreviewDivPart">
                            {baseImageVariant.map((item: any, i: any) => {
                              return (
                                <React.Fragment key={i}>
                                  {loader ? (
                                    <div className="CircleLoder">
                                      <CircleLoader />
                                    </div>
                                  ) : (
                                    <div className="ArryyIcon">
                                      <DownloadIcon
                                        {...props}
                                        i={i}
                                        item={item}
                                        name="variant"
                                        placePosition={"first"}
                                      />
                                      <ImageClose
                                        {...props}
                                        i={i}
                                        item={item}
                                        close={handleDeleteVariant}
                                      />
                                      <FilePreviewPart {...props} item={item} />
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <ImageDivPart
                    {...props}
                    fileUploadTrigger={fileUploadTriggerVariant}
                    fileInput={fileInputVariant}
                    fileUpload={uploadFiles}
                    name="variant"
                    fileArr={fileArrayVariant}
                    fileObject={fileObjVariant}
                    content="Click to upload variant images or files"
                  />
                </>
              )}
            </div>
            <div className="mt-2 mb-2 d-flex justify-content-end">
              <Button
                style={{
                  backgroundColor: "#000000",
                }}
                disabled={loader || loading ? true : false}
                onClick={(e: any) => {
                  let result: any = validateForVariant();
                  if (result?.length === 0) {
                    handleSubmitVariant(e);
                  }
                }}
              >
                Add Variant
              </Button>
            </div>
          </div>
          {/* <div>
        <div className="d-flex">
          {baseimgesVariantArry.length > 0 ? (
            <div className="imageDiv12 ml-2 mr-2">
              {baseimgesVariantArry.map((item: any, i: any) => {
                return (
                  <React.Fragment key={i}>
                    <div className="varientCode">
                      Variant Code : {item?.variantCode}
                    </div>
                    <div className="variantImageDiv">
                      {item?.images.map((ele: any, i: any) => {
                        return (
                          <React.Fragment key={i}>
                            <div className="PreviewDivPart1">
                              <div className="ArryyIcon">
                                <ImageClose
                                  {...props}
                                  i={i}
                                  item={item}
                                  // close={handleDeleteVariant}
                                />
                                <FilePreviewPart {...props} item={ele} />
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      </div> */}
        </div>
      </div>
    </div>
  );
};

export default CreateVariant;
