import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
// components
import DeleteConfirm from "../../common/Components/DeleteConfirm";
import Index from "../../common/Components/Index";
import { HeaderData, HeaderDataManufacturers } from "./Header";
import QuickFilter from "./QuickFilter";
import AddOrEditOrganisation from "./AddOrEditOrganisation";
import notifications from "../../common/Components/Notifications/Notification";
import ViewOrganization from "./ViewOrganization";

//files
import { debounce } from "../../common/commonFiles/debounce";
// store
import {
  deleteOrganisation,
  getOrganisation,
  changeOrganisationStatus,
  connectOrg,
  getAssocciationsOrg,
} from "../../Store/organisation/actions";
import { isArray } from "lodash";

var offset: any = 0;
var limit: any = 10;
var totPages = 0;
var totCnt: any = 0;
var toVal: any = 0;
var fromVal: any = 0;
var gstOptions: any = [];
const Organisation = (props: any) => {
  const location: any = useLocation();
  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  const [loading, setLoading]: any = useState(false);
  const [openModal, setOpenModal]: any = useState(false);
  const [editData, setEditData]: any = useState("");
  const [openModalView, setOpenModalView]: any = useState(false);
  const [viewData, setViewData]: any = useState("");
  const [deleteData, setDeleteData]: any = useState("");
  const [filters, setFilters]: any = useState({
    organization: "",
    phone: "",
    address: "",
    orgType: "",
  });
  const [sortName, setSortName]: any = useState({
    name: "",
    order: "",
  });
  const [warningModal, setWarningModal]: any = useState(false);
  const openModalPopUp = () => {
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
    setEditData("");
  };
  const openModalPopUpView = () => {
    setOpenModalView(true);
  };
  const closeModalView = () => {
    setOpenModalView(false);
    setViewData("");
  };
  useEffect(() => {
    getAllData();
  }, []);
  useEffect(() => {
    if (sortName?.name?.length > 0) {
      getAllData();
    }
  }, [sortName]);
  useEffect(() => {
    if (
      filters?.organization?.length ||
      filters?.phone?.length ||
      filters?.address?.length ||
      filters?.orgType?.label?.length
    ) {
      handleChangeDebounce({ ...filters });
    }
  }, [filters]);
  const handleChangeDebounce = React.useCallback(
    debounce((data: any) => {
      getAllData(data);
    }, 1000),
    []
  );
  const getAllData = (filters?: any) => {
    let orderByAsc = sortName?.order === "asc" ? "1" : "";
    let orderByDesc = sortName?.order === "desc" ? "-1" : "";
    let payload: any = {
      offset: filters?.offset ? filters?.offset : offset,
      limit: limit,
      label: filters?.organization,
      phone: filters?.phone,
      address: filters?.address,
      sortBy: sortName?.name,
      orderBy: orderByAsc || orderByDesc,
      organizationType:
        authUser?.users?.role === "ORGANIZATION_ADMIN" ||
        (authUser?.users?.role === "ORGANIZATION_MANAGER" &&
          authUser?.users?.organizationType === "Design Agency")
          ? "Manufacturer"
          : filters?.orgType
          ? filters?.orgType?.label
          : "",
    };
    setLoading(true);
    props.getOrganisation(payload, () => {
      setLoading(false);
    });
    setEditData("");
  };
  const handlePageChange = (event: any, value: any) => {
    offset = (value - 1) * 1;
    getAllData({
      ...filters,
      offset,
    });
  };
  var data: any = [];

  let deleteDataWithConfirm = (deleteData: any) => {
    props.deleteOrganisation(
      {
        organizationId: deleteData?._id,
      },
      (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          getAllData();
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  const handleChangeStatus = (payload: any) => {
    props.changeOrganisationStatus(payload, (res: any) => {
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        getAllData();
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  if (props.organisationDetails !== undefined) {
    if (
      isArray(props?.organisationDetails?.data?.docs) &&
      props?.organisationDetails?.data?.docs?.length > 0
    ) {
      totCnt = props?.organisationDetails?.data?.length;
      totPages = Math.ceil(totCnt / 10);
      fromVal = totCnt !== 0 ? offset * 10 + 1 : fromVal;
      toVal =
        props?.organisationDetails?.data?.offset * 10 +
        props?.organisationDetails?.data?.docs?.length;
      props?.organisationDetails?.data?.docs.map((ele: any, i: any) => {
        data.push({
          organization: ele?.name,
          address: ele?.address?.addressLane,
          phone: ele?.phone,
          orgType: ele?.organizationType ? ele?.organizationType : "N/A",
          gst: ele?.gst ? (
            <span style={{ textTransform: "uppercase" }}>{ele?.gst}</span>
          ) : (
            "N/A"
          ),
          createdBy: ele?.createdBy?.role,
          createdAt: ele?.createdAt
            ? moment(new Date(ele?.createdAt))
                .subtract(5, "hours")
                .subtract(30, "minutes")
                .format("YYYY-MM-DD LT")
            : "NA",
          actions: (
            <div className="d-flex align-items-center tableBtns">
              <span
                className="btns"
                onClick={() => {
                  setViewData(ele);
                  openModalPopUpView();
                }}
              >
                View
              </span>
              <span className="actionLineHeight me-2"></span>
              <span
                className="btns"
                onClick={() => {
                  setEditData(ele);
                  openModalPopUp();
                }}
              >
                Edit
              </span>
              <span className="actionLineHeight"></span>
              &nbsp;&nbsp;
              {ele?.active ? (
                <span
                  className="btns"
                  onClick={() => {
                    handleChangeStatus({
                      organizationId: ele?._id,
                      active: false,
                    });
                  }}
                >
                  DeActivate
                </span>
              ) : (
                <span
                  className="btns"
                  onClick={() => {
                    handleChangeStatus({
                      organizationId: ele?._id,
                      active: true,
                    });
                  }}
                >
                  Activate
                </span>
              )}
              {/* <span
                className="btns"
                onClick={() => {
                  setDeleteData(ele);
                  setWarningModal(true);
                }}
              >
                Delete
              </span> */}
            </div>
          ),
        });
      });
    } else {
      totCnt = 0;
      totPages = 0;
    }
  }
  const handleSort = (data: any) => {
    if (data?.name === "address") {
      setSortName({
        name: "address",
        order: data?.sort,
      });
    } else if (data?.name === "organization") {
      setSortName({
        name: "organization",
        order: data?.sort,
      });
    } else if (data?.name === "phone") {
      setSortName({
        name: "phone",
        order: data?.sort,
      });
    }
  };
  const handleOnchangeFilter = (type: any) => {
    offset = 0;

    setFilters({
      ...filters,
      [type.name]: type?.value,
    });
  };
  const handleResetData = () => {
    setFilters({
      organization: "",
      address: "",
      phone: "",
      orgType: "",
    });
    props.getOrganisation(
      {
        offset: offset,
        limit: limit,
      },
      () => {
        setLoading(false);
      }
    );
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      getAllData();
    }
  };
  const warningToggle = () => {
    setWarningModal(false);
  };

  return (
    <>
      <DeleteConfirm
        {...props}
        warningModal={warningModal}
        warningToggle={warningToggle}
        deleteDataWithConfirm={deleteDataWithConfirm}
        deleteData={deleteData}
        getAllData={getAllData}
        name={"Organisation"}
      />
      <Modal
        isOpen={openModal}
        toggle={closeModal}
        size="lg"
        className="OrganizationModal"
        fade={false}
      >
        <ModalHeader>
          <div className="Heading">
            <h3>
              {editData?._id ? "Edit Organization" : "Add New Organization"}
            </h3>
            <svg
              onClick={() => closeModal()}
              width="20"
              height="20"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L1 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1L8 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalHeader>
        <ModalBody>
          <AddOrEditOrganisation
            {...props}
            closeModal={closeModal}
            editData={editData}
            getAllData={getAllData}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openModalView}
        toggle={closeModalView}
        size="lg"
        fade={false}
        className="OrganizationModal"
      >
        <ModalHeader>
          <div className="Heading">
            <h3>View Organization</h3>
            <svg
              onClick={() => closeModalView()}
              width="20"
              height="20"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L1 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1L8 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalHeader>
        <ModalBody>
          <ViewOrganization
            {...props}
            closeModal={closeModalView}
            editData={viewData}
            getAllData={getAllData}
          />
        </ModalBody>
      </Modal>

      <QuickFilter
        {...props}
        location={location}
        gstOptions={gstOptions}
        openModalPopUp={openModalPopUp}
        handleResetData={handleResetData}
        getAllData={getAllData}
        filters={filters}
        handleOnchangeFilter={handleOnchangeFilter}
        handleKeyDown={handleKeyDown}
      />

      <Index
        {...props}
        data={data}
        headerData={
          location?.pathname === "/organisation"
            ? HeaderData
            : HeaderDataManufacturers
        }
        limit={limit}
        handleChange={handlePageChange}
        offset={offset}
        totPages={totPages}
        loading={loading}
        handleSort={handleSort}
        fromVal={fromVal}
        toVal={toVal}
        totCnt={totCnt}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    organisationDetails: state?.organisationReducer?.getOrganisationDetails,
  };
};
export default connect(mapStateToProps, {
  deleteOrganisation,
  getOrganisation,
  changeOrganisationStatus,
})(Organisation);
