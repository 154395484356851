import React from "react";

const ClientIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#EAD6C0" />
      <path
        d="M8 16.3067C8.02188 16.2192 8.04011 16.1317 8.06563 16.0442C8.2771 15.2712 8.9735 14.7097 9.77564 14.6915C10.1731 14.6842 10.5741 14.7097 10.9715 14.7535C11.2304 14.7827 11.4565 14.9285 11.6643 15.1035C11.6315 15.1436 11.6023 15.1764 11.5732 15.2093C10.9861 15.8327 10.6543 16.5765 10.545 17.4224C10.4538 18.1115 10.4757 18.8043 10.4866 19.497C10.4903 19.7596 10.4866 20.0257 10.4866 20.3065C10.4283 20.3138 10.3663 20.3247 10.3043 20.3247C9.82304 20.3247 9.33811 20.3283 8.85683 20.3211C8.43024 20.3174 8.16407 20.1169 8.02917 19.7049C8.02552 19.6939 8.01458 19.683 8.00729 19.672C8 18.5491 8 17.4297 8 16.3067Z"
        fill="#C59C6C"
      />
      <path
        d="M14.8403 21.1961C14.0053 21.1961 13.174 21.1998 12.3391 21.1961C11.8468 21.1925 11.4895 20.9445 11.3583 20.5216C11.3328 20.445 11.3145 20.3612 11.3145 20.281C11.3109 19.5007 11.289 18.7241 11.3182 17.9438C11.3692 16.613 11.9818 15.6213 13.185 15.016C13.597 14.8082 14.0418 14.7098 14.5012 14.6806C15.0846 14.6478 15.6716 14.6368 16.2404 14.7754C17.5019 15.0853 18.5739 16.3067 18.6614 17.6084C18.7233 18.5053 18.6905 19.4095 18.6869 20.3138C18.6869 20.4414 18.6285 20.569 18.5775 20.6893C18.4791 20.9336 18.2931 21.0904 18.0342 21.1159C17.5712 21.1597 17.1081 21.1925 16.6451 21.2034C16.0435 21.218 15.4419 21.2071 14.8403 21.2071C14.8403 21.2034 14.8403 21.1998 14.8403 21.1961Z"
        fill="#C59C6C"
      />
      <path
        d="M15.0005 9C16.3204 9 17.3814 10.0647 17.3778 11.3809C17.3778 12.6935 16.3022 13.7618 14.9896 13.7545C13.6806 13.7472 12.6196 12.6825 12.6196 11.3772C12.6196 10.061 13.6843 9 15.0005 9Z"
        fill="#C59C6C"
      />
      <path
        d="M18.3403 15.1035C18.6138 14.8665 18.9274 14.7316 19.2737 14.7061C19.7185 14.6733 20.167 14.6368 20.6045 14.7462C21.4249 14.9504 21.9937 15.6796 21.9973 16.5218C22.001 17.5026 22.001 18.4798 21.9973 19.4606C21.9973 19.9163 21.6984 20.2809 21.2535 20.3028C20.703 20.332 20.1524 20.3138 19.6019 20.3174C19.5764 20.3174 19.5508 20.3065 19.5107 20.2955C19.5107 20.2481 19.5107 20.1971 19.5107 20.1497C19.5107 19.4423 19.5253 18.7387 19.5071 18.0313C19.4852 17.1198 19.2737 16.263 18.6976 15.5301C18.5883 15.3879 18.4679 15.253 18.3403 15.1035Z"
        fill="#C59C6C"
      />
      <path
        d="M10.1877 10.7501C11.019 10.7501 11.6936 11.421 11.6936 12.2487C11.6936 13.0763 11.019 13.7545 10.1914 13.7545C9.36373 13.7545 8.68556 13.08 8.68556 12.2523C8.68192 11.421 9.35644 10.7501 10.1877 10.7501Z"
        fill="#C59C6C"
      />
      <path
        d="M19.8098 10.7501C20.6411 10.7501 21.3156 11.4174 21.3156 12.2487C21.3156 13.0763 20.6447 13.7508 19.8134 13.7545C18.9894 13.7581 18.3076 13.08 18.3076 12.2523C18.3076 11.4246 18.9785 10.7501 19.8098 10.7501Z"
        fill="#C59C6C"
      />
    </svg>
  );
};

export default ClientIcon;
