import React, { useState } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { productSendOtp, productKyc } from "../../Store/products/actions";
import { Label, Input, Button, Row, Col } from "reactstrap";
import Logo from "../../assets/images/D-3 1.jpg";
import notifications from "../../common/Components/Notifications/Notification";
import Loader from "../../common/Components/Loader/Loader";
import jwt from "jwt-decode";

const EMailVerification = (props: any) => {
  let tokenDataRes: any = localStorage.getItem("tokenData");
  let tokenResData: any = JSON.parse(tokenDataRes) || "";
  const [sentOtp, setSendOtpform]: any = useState(false);

  const [value, setValues]: any = useState({
    name: tokenResData ? tokenResData?.users?.name : "",
    mobile: tokenResData ? tokenResData?.users?.phone : "",
    otp: "",
  });
  const [Error, setError]: any = useState({
    name: "",
    mobile: "",
    otp: "",
  });
  const [loading, setLoading]: any = useState(false);
  const [loading1, setLoading1]: any = useState(false);
  const validate1 = () => {
    let error = "";
    if (value?.mobile === "") {
      error = "Required";
      setError({
        ...Error,
        mobile: "Phone is Required!",
      });
    }
    return error;
  };
  const validate = () => {
    let error = "";
    if (value?.mobile === "") {
      error = "Required";
      setError({
        ...Error,
        mobile: "Phone is Required!",
      });
    }
    if (value?.otp === "") {
      error = "Required";
      setError({
        ...Error,
        otp: "Otp is Required!",
      });
    }
    if (value?.name === "") {
      error = "Required";
      setError({
        ...Error,
        name: "Name is Required!",
      });
    }

    return error;
  };
  const handleSendNotification = () => {
    let result = validate1();
    if (result?.length === 0) {
      // setSendOtpform(true);
      setLoading1(true);
      props.productSendOtp(
        {
          phone: value.mobile,
        },
        (res: any) => {
          setLoading1(false);
          if (res.success) {
            notifications({
              title: "Success!",
              message: res?.message,
              type: "success",
            });
            setSendOtpform(true);
          } else {
            notifications({
              title: "Error!",
              message: res?.message,
              type: "danger",
            });
          }
        }
      );
    }
    // localStorage.setItem("authUser",JSON.stringify(value?.email))
  };
  const handleKyc = () => {
    let result = validate();
    if (result?.length === 0) {
      setLoading1(true);
      props.productKyc(
        {
          name: value?.name,
          phone: value?.mobile,
          otp: value?.otp,
        },
        (res: any) => {
          setLoading1(false);
          if (res.success) {
            notifications({
              title: "Success!",
              message: res?.message,
              type: "success",
            });
            let resPro: any = jwt(res?.data?.token);
            localStorage.setItem("tokenData", JSON.stringify(resPro));
            localStorage.setItem("productVerificationToken", res?.data?.token);
            localStorage.setItem(
              "productVerificationrefreshToken",
              res?.data?.refreshToken
            );
            props.closeModal(false);
            props.kycFlagFun(true);
            props.getAllData();
          } else {
            notifications({
              title: "Error!",
              message: res?.message,
              type: "danger",
            });
          }
        }
      );
    }
  };
  // const handleSubmitNotification = () => {
  //   let result = validate();
  //   if (result?.length === 0) {
  //     setLoading(true);
  //     props.productVerifyOtp(
  //       {
  //         mobile: value.mobile,
  //         otp: value?.otp,
  //       },
  //       (res: any) => {
  //         setLoading(false);
  //         setSendOtpform(true);
  //         if (res.type === "success") {
  //           notifications({
  //             title: "Success!",
  //             message: res?.message,
  //             type: "success",
  //           });
  //           handleKyc();
  //           // history.push(`/viewProduct/${ProductId}`);
  //         } else {
  //           notifications({
  //             title: "Error!",
  //             message: res?.message,
  //             type: "danger",
  //           });
  //         }
  //       }
  //     );
  //   }
  // };
  return (
    <>
      {loading1 ? (
        <div className="LoaderDivModal">
          <Loader />
        </div>
      ) : (
        <>
          <div className="EmailVerifcationPage">
            <div className="form">
              {sentOtp && (
                <>
                  <div className="formDiv">
                    <Label>
                      Name
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      placeholder="Type Name "
                      value={value?.name}
                      // maxLength={10}
                      onChange={(e: any) => {
                        setValues({
                          ...value,
                          name: e.target.value,
                        });
                      }}
                    />
                    {Error.name && !value.name ? (
                      <p className="RequiredField">{Error?.name} </p>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
              <div className="formDiv">
                <Label>
                  Phone
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </Label>
                <Input
                  type="number"
                  name="phone"
                  id="phone"
                  className="form-control"
                  placeholder="Enter 10 digits mobile number "
                  value={value?.mobile}
                  min={9}
                  max={10}
                  pattern="[1-9]{1}[0-9]{9}"
                  onChange={(e: any) => {
                    setValues({
                      ...value,
                      mobile: e.target.value,
                    });
                  }}
                />
                {value?.mobile?.length === 9 || value?.mobile?.length > 10
                  ? !/^[6-9]\d{9}$/.test(value?.mobile) && (
                      <p className="RequiredField">Invalid phone number! </p>
                    )
                  : ""}

                {Error.mobile && !value.mobile ? (
                  <p className="RequiredField">{Error?.mobile} </p>
                ) : (
                  ""
                )}
              </div>
              {sentOtp ? (
                <>
                  <div className="formDiv">
                    <Label>
                      Otp{" "}
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      placeholder="Enter Otp"
                      maxLength={4}
                      value={value?.otp}
                      onChange={(e: any) => {
                        setValues({
                          ...value,
                          otp: e.target.value,
                        });
                      }}
                    />
                    {Error.otp && !value.otp ? (
                      <p className="RequiredField">{Error?.otp} </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="formDiv d-flex">
                    <Button
                      className="sendBtn"
                      onClick={() => {
                        // handleSubmitNotification();
                        handleKyc();
                      }}
                    >
                      {"Verify"}
                    </Button>
                    {/* <Button
                        className="sendBtn1"
                        // onClick={() => {
                        //   handleSendNotification();
                        // }}
                      >
                        {"Re-Send OTP"}
                      </Button> */}
                  </div>
                </>
              ) : (
                <div className="formDiv d-flex">
                  <Button
                    className="sendBtn"
                    onClick={() => {
                      handleSendNotification();
                    }}
                  >
                    {"Get Otp"}
                  </Button>
                  <Button
                    className="sendBtn1"
                    // onClick={() => {
                    //   handleSendNotification();
                    // }}
                  >
                    {"Re-Send OTP"}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  productSendOtp,
  productKyc,
})(EMailVerification);
