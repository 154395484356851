import React from "react";

const EmailIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5 11.25V24.8864C27.5 25.6395 26.9404 26.25 26.25 26.25H3.75C3.05964 26.25 2.5 25.6395 2.5 24.8864V11.25L15 19.375L27.5 11.25Z"
        fill="#C59C6C"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 11.1149L15 2.5L27.5 11.1149"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
