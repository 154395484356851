import React from "react";
import DocxIcon from "../../common/Components/Icons/DocxIcon";
import { Button } from "reactstrap";
const FilePreviewPart = (props: any) => {
  const { item, handleOpen, handleSetImage, pauseVideo } = props;
  return (
    <div
      onClick={() => {
        handleOpen();
        handleSetImage(item);
      }}
    >
      {(item?.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        item.type === "application/msword") && (
        // item.toLowerCase().endsWith(".docx") ||
        // item.toLowerCase().endsWith(".doc")
        <div className="preview">
          <DocxIcon />
          <div className="d-block">
            <p>{item?.fileName}</p>
          </div>
        </div>
      )}

      {(item?.type === "image/png" ||
        item?.type === "image/jpg" ||
        item?.type === "image/jpeg" || item?.type === "image/bmp") && (
        // item.toLowerCase().endsWith(".jpg") ||
        // item.toLowerCase().endsWith(".jepg") ||
        // item.toLowerCase().endsWith(".png")
        <>
          <img
            src={item?.blobImage}
            width="200px"
            height={"200px"}
            alt="designImage"
          />
          {/* <Button>Hide</Button>
        <Button>Show</Button> */}
        </>
      )}
      {(item?.type === "video/mp4" ||
        item?.type === "video/webm" ||
        item?.type === "video/ogg") && (
        <video height="180px" width="210px" controls muted>
          <source src={item?.blobImage} type="video/mp4" />
        </video>
      )}
      {item?.type === "application/pdf" && (
        <iframe
          title={item?.name}
          src={item?.blobImage}
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
};

export default FilePreviewPart;
