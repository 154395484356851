import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import Loader from "../../common/Components/Loader/Loader";
import ShowIcon from "../../common/Components/Icons/ShowIcon";
import HideIcon from "../../common/Components/Icons/HideIcon";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import notifications from "../../common/Components/Notifications/Notification";

// store
import { forgetPasswordPostLogin } from "../../Store/auth/login/actions";
// images
import LogoBanner from "../../assets/images/login.png";
// let regExpForMail: any = /^[w-.]+@([w-]+.)+[w-]{2,4}$/;
let regExpForPhone: any = /^[7-9][0-9]{9}$/;

const ForgetPassword = (props: any) => {
  const history = useHistory();

  const [loading, setLoading]: any = useState(false);
  const [emailOrPhone, setEmailOrPhone]: any = useState("");
  const [error, setError]: any = useState("");
  const validation = () => {
    let error = "";

    if (emailOrPhone.trim() === "") {
      error = "required";
      setEmailOrPhone(() => "EMail or phone is required!");
    }
    // else if (regExpForMail.test(emailOrPhone)) {
    //   setEmailOrPhone(() => "Invalid email!");
    // }
    // else if (regExpForPhone.test(emailOrPhone)) {
    //   setEmailOrPhone(() => "Invalid phone!");
    // }
    return error;
  };
  const handleSubmit = () => {
    const result: any = validation();
    console.log(result, error);
    if (result?.length === 0) {
      history.push("/reset-password", {
        emailOrPhone,
      });

      setLoading(true);
      props.forgetPasswordPostLogin(
        {
          emailorphone: emailOrPhone,
        },
        (res: any) => {
          setLoading(false);
          if (res.success) {
            notifications({
              title: "Success!",
              message: res?.message,
              type: "success",
            });
            history.push("/reset-password", {
              emailOrPhone,
            });
          } else {
            notifications({
              title: "Error!",
              message: res?.message,
              type: "danger",
            });
          }
        }
      );
    }
  };
  return (
    <>
      <Grid container spacing={0} className="LoginpageNew">
        <Grid item xs={12} md={6} lg={6} className="LoginpageGrid ">
          <>
            {loading ? (
              <div className="LoaderDiv1">
                <Loader />
              </div>
            ) : (
              <div>
                <div className="parentForm">
                  <div className="MainHeading">
                    <span className="sp1">Hastkala</span>{" "}
                    <span className="sp2">Paramanak</span>
                    <p>Supporting the makers, Protecting the buyers</p>
                  </div>

                  <h4>Forget Password</h4>
                  <div className="enterEmail">
                    Enter your email or phone to change the password.
                  </div>
                  {/* {response === "User does not   Exist!" ? (
                    <>
                      <div className="responseMsg">
                        Incorrect email or phone number or User does not exist's
                      </div>
                    </>
                  ) : response === "Authentication failed!" ? (
                    <>
                      <div className="responseMsg">Incorrect password</div>
                    </>
                  ) : (
                    <>
                      <div className="responseMsgSucces">{response}</div>
                    </>
                  )} */}
                  <label className="labelName">
                    Email Or Phone<span>*</span>
                  </label>
                  <br />
                  <input
                    placeholder="Enter Email Or Phone"
                    type="text"
                    id="emaillogin"
                    name="emaillogin"
                    className="inputField"
                    value={emailOrPhone}
                    onChange={(e: any) => setEmailOrPhone(e.target.value)}
                  />
                  <br />
                  {/* {Errors.email && !value.email ? (
                    <p className="RequiredField">{Errors?.email} </p>
                  ) : (
                    ""
                  )} */}

                  <Button className="loginBtn" onClick={() => handleSubmit()}>
                    {loading ? "Proccessing" : "Submit"}
                  </Button>
                </div>
              </div>
            )}
          </>
        </Grid>
        <Grid item xs={1} md={6} lg={6} className="displayDesktop">
          <img
            className="logoBanner"
            src={LogoBanner}
            width="100%"
            height={"900px"}
            alt="LogoBanner"
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  forgetPasswordPostLogin,
})(ForgetPassword);
