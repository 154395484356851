export const types = {
GET_USER_PROFILE_PRESIGNED_URL:"GET_USER_PROFILE_PRESIGNED_URL",
GET_USER_PROFILE_PRESIGNED_URL_SUCCESS:"GET_USER_PROFILE_PRESIGNED_URL_SUCCESS",
GET_PRODUCT_WEAVING_PRESIGNED_URL_SUCCESS:"GET_PRODUCT_WEAVING_PRESIGNED_URL_SUCCESS",
GET_PRODUCT_WEAVING_PRESIGNED_URL:"GET_PRODUCT_WEAVING_PRESIGNED_URL",
GET_PRODUCT_PRESIGNED_URL:"GET_PRODUCT_PRESIGNED_URL",
GET_PRODUCT_PRESIGNED_URL_SUCCESS:"GET_PRODUCT_PRESIGNED_URL_SUCCESS",
GET_PRODUCT_FINISHING_PRESIGNED_URL_SUCCESS:"GET_PRODUCT_FINISHING_PRESIGNED_URL_SUCCESS",
GET_PRODUCT_FINISHING_PRESIGNED_URL:"GET_PRODUCT_FINISHING_PRESIGNED_URL",
GET_PRODUCT_DESIGNING_PRESIGNED_URL_SUCCESS:"GET_PRODUCT_DESIGNING_PRESIGNED_URL_SUCCESS",
GET_PRODUCT_DESIGNING_PRESIGNED_URL:"GET_PRODUCT_DESIGNING_PRESIGNED_URL",
UPLOAD_IMAGE:"UPLOAD_IMAGE",
UPLOAD_IMAGE_SUCCESS:"UPLOAD_IMAGE_SUCCESS",
UPLOAD_IMAGE1:"UPLOAD_IMAGE1",
UPLOAD_IMAGE_SUCCESS1:"UPLOAD_IMAGE_SUCCESS1",
GET_PRODUCT_DYER_PRESIGNED_URL_SUCCESS:"GET_PRODUCT_DYER_PRESIGNED_URL_SUCCESS",
GET_PRODUCT_DYER_PRESIGNED_URL:"GET_PRODUCT_DYER_PRESIGNED_URL",
GET_MAIN_LOGO_PRESIGNED_URL:"GET_MAIN_LOGO_PRESIGNED_URL",
GET_MAIN_LOGO_PRESIGNED_URL_SUCCESS:"GET_MAIN_LOGO_PRESIGNED_URL_SUCCESS"
}