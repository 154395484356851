import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

// actions
import { getProducts } from "../../Store/products/actions";

import QRCodeProduct from "./common components/QRCodeProduct";
const PreviewProduct = (props: any) => {
  const { t } = useTranslation();
  const location: any = useLocation();
  const ProductId: any = location?.pathname.split("/")[2];
  const [loading, setLoading]: any = useState(false);
  useEffect(() => {
    if (ProductId) {
      getAllData();
    }
  }, [ProductId]);
  let editDetails: any;
  const getAllData = () => {
    setLoading(true);
    props.getProducts(
      {
        offset: 0,
        limit: 10,
        ProductId: ProductId,
        // ProductsName:ProductName
      },
      (res: any) => {
        setLoading(false);
        if (res?.data) {
          editDetails = res?.data?.docs[0];
          if (editDetails?.length) {
          }
        }
      }
    );
  };

  if (props.ViewProductDetails !== undefined) {
    editDetails = props.ViewProductDetails?.data?.docs[0];
  }
  return (
    <>
      <QRCodeProduct {...props} loading={loading} editDetails={editDetails} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    ViewProductDetails: state?.productReducer?.getProductDetails,
  };
};
export default connect(mapStateToProps, {
  // getProductById,
  getProducts,
})(PreviewProduct);
