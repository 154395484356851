import { types } from "./actionTypes";

// creating action

export const checkLoginUser = (payload: any, callback: any) => {
  return {
    type: types.CHECK_LOGIN,
    payload,
    callback,
  };
};
export const changePasswordPostLogin = (payload: any, callback: any) => {
  return {
    type: types.CHANGE_PASSWORD,
    payload,
    callback,
  };
};
export const forgetPasswordPostLogin = (payload: any, callback: any) => {
  return {
    type: types.FORGET_PASSWORD,
    payload,
    callback,
  };
};
export const resetPasswordPostLogin = (payload: any, callback: any) => {
  return {
    type: types.RESET_PASSWORD,
    payload,
    callback,
  };
};
