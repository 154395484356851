import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import  { AxiosResponse } from "axios";
//medthods
import {
  postApi,
  getApi,
  deleteApi,
  putApi
} from "../../helpers/auth_helpers";

// workers
function* workerGetSubCategory({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset :0;
  let limit = filters?.limit ? filters?.limit :10;
  let categoryId=filters?.categoryId ? filters?.categoryId :"";
  let subCategoryId = filters?.subCategoryId ? filters?.subCategoryId : "";
  let subCategoryName = filters?.subCategoryName ? filters?.subCategoryName : "";
  let artformId = filters?.artformId ? filters?.artformId : "";
  let typeId = filters?.typeId ? filters?.typeId : "";
  let startDate = filters?.startDate ? filters?.startDate : "";
  let endDate = filters?.endDate ? filters?.endDate :"";
  let sortBy = filters?.sortBy ? filters?.sortBy :"createdAt";
  let orderBy = filters?.orderBy ? filters?.orderBy :"-1";
  let createdBy = filters?.createdBy ? filters?.createdBy :""

  try {
    const url: string =
    process.env.REACT_APP_SERVERURL +
    "/subcategory/subcategories?offset=" +
    offset +
    "&limit=" +
    limit +
    "&artFormId=" +
    artformId +
    "&typeId=" +
    typeId +
    "&categoryId="+
    categoryId+
    "&id="+
    subCategoryId+
    "&name=" +
    subCategoryName+
    "&sortBy="+
    sortBy+
    "&orderBy="+
    orderBy+
    "&startDate="+
    startDate+
    "&endDate="+
    endDate+
    "&createdBy="+
    createdBy;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_SUB_CATEGORY_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetSubCategoryFilter({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset :0;
  let limit = filters?.limit ? filters?.limit :10;
  let categoryId=filters?.categoryId ? filters?.categoryId :"";
  let subCategoryName = filters?.subCategoryName ? filters?.subCategoryName : "";
  let artformId = filters?.artformId ? filters?.artformId : "";
  let typeId = filters?.typeId ? filters?.typeId : "";


  try {
    const url: string =
    process.env.REACT_APP_SERVERURL +
    "/subcategory/subcategories?offset=" +
    offset +
    "&limit=" +
    limit +
    "&artFormId=" +
    artformId +
    "&typeId=" +
    typeId +
    "&categoryId="+
    categoryId+
    "&name=" +
    subCategoryName;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_SUB_CATEGORY_FILETRS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateSubCategory({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/subcategory/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_SUB_CATEGORY_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateSubCategory({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/subcategory/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_SUB_CATEGORY_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteSubCategory({ payload, callback }: any) {
    try {
      const url: string = process.env.REACT_APP_SERVERURL + "/subcategory/delete";
      const response: AxiosResponse = yield call(deleteApi, url, payload);
      callback(response);
      yield put({
        type: types.DELETE_SUB_CATEGORY_SUCCESS,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
  }

// watchers
export function* watchGetSubCategory() {
  yield takeEvery(types.GET_SUB_CATEGORY, workerGetSubCategory);
}
export function* watchGetSubCategoryFilter() {
  yield takeEvery(types.GET_SUB_CATEGORY_FILETRS, workerGetSubCategoryFilter);
}
export function* watchCreateSubCategory() {
  yield takeEvery(types.CREATE_SUB_CATEGORY, workerCreateSubCategory);
}
export function* watchUpdateSubCategory() {
  yield takeEvery(types.UPDATE_SUB_CATEGORY, workerUpdateSubCategory);
}
export function* watchDeleteSubCategory() {
    yield takeEvery(types.DELETE_SUB_CATEGORY, workerDeleteSubCategory);
  }

function* subCategorySaga() {
  yield all([fork(watchGetSubCategory)]);
  yield all([fork(watchGetSubCategoryFilter)]);
  yield all([fork(watchCreateSubCategory)]);
  yield all([fork(watchUpdateSubCategory)]);
  yield all([fork(watchDeleteSubCategory)]);
}
export default subCategorySaga;
