import React, { useState, useEffect } from "react";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from "reactstrap";
import { Toggle } from "rsuite";
import { connect } from "react-redux";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import ReactChipInput from "react-chip-input";
//components
import CreateUserModal from "../users/CreateUserModal";
import ShowIcon from "../../common/Components/Icons/ShowIcon";
import HideIcon from "../../common/Components/Icons/HideIcon";
import ImageDivPart from "./ImageDivPart";
import { debounce } from "../../common/commonFiles/debounce";
import notifications from "../../common/Components/Notifications/Notification";
// import FilePreview from "./FilePreviewProduct";
import Loader from "../../common/Components/Loader/Loader";
import ArtformModal from "../artform/ArtformModal";
import TypeModal from "../type/TypeModal";
import CategoryModal from "../category/CategoryModal";
import SubCategoryModal from "../sub-category/SubCategoryModal";
import WeaveTypeModal from "../weave-type/WeaveTypeModal";
import { baseImageCovert, baseImageCovertForLogoPin } from "./baseImageConver";
// actions
import { getArtforms } from "../../Store/artform/actions";
import { createUser, updateUsers } from "../../Store/users/actions";
import {
  fetchProfilePresignedUrl,
  fetchProductWeavingPresignedUrl,
  fetchProductDesigningPresignedUrl,
  fetchProductFinishingPresignedUrl,
  fetchProductDyingPresignedUrl,
  uploadImages,
  uploadImages1,
} from "../../Store/uploadModule/actions";
import { getUsers } from "../../Store/users/actions";
import { getCategory } from "../../Store/actions";
import { getSubCategorys } from "../../Store/actions";
import { getTypes } from "../../Store/type/actions";
import { getWeaveType } from "../../Store/weave-type/actions";
import {
  updateProducts,
  getProducts,
  createVariantForProduct,
} from "../../Store/products/actions";
import FilePreviewPart from "./FilePreviewPart";
import ImageClose from "../../common/Components/Icons/ImageClose";
import ImageAddIcon from "../../common/Components/Icons/ImageAddIcon";
import { AddIcon } from "../../common/Components/Icons/AddIcon";
import CreateVariant from "./CreateVariant";
import ModalCloseIcon from "../../common/Components/Icons/ModalCloseIcon";
import CircleLoader from "../../common/Components/Loader/CircleLoader";
import DownloadIcon from "../../common/Components/Icons/DownloadIcon";
import Pin from "../../common/Components/Icons/Pin";
import Unpin from "../../common/Components/Icons/Unpin";
import ResetIcon from "../../common/Components/Icons/ResetIcon";
import {
  zariUsedOptions,
  timeTakenInMonthsOptions,
  yarnUsedOptions,
  typeOfSareeByDesignOptions,
} from "./options";
import ProductImagePreView from "./ProductImagePreView";
import ViewEyeIcon from "../../common/Components/Icons/ViewEyeIcon";

// selector options
var artformOptions: any = [];
var typeOptions: any = [];
var categoryOptions: any = [];
var designerOptions: any = [];
var weaverOptions: any = [];
var dyerOptions: any = [];
var finisherOptions: any = [];
let subCategoryOptions: any = [];
let weaveTypeOptions: any = [];

// logo
let fileObjLogo: any = [];
let fileArrayLogo: any = [];
let promiseForLogo: any = [];
let promiseLogoUrl: any = [];
let promiseLogoStatusCode: any = [];

// designing
let fileObjDesign: any = [];
let fileArray: any = [];
let promiseForDesign: any = [];
let promiseDesignUrl: any = [];
let promiseDesignStatusCode: any = [];
// weaving
let fileObjWeaving: any = [];
let fileArrayWeaving: any = [];
let promiseForWeaving: any = [];
let proWeavingUrl: any = [];
let proWeavingCodes: any = [];
// dying
let fileObjDying: any = [];
let fileArrayDying: any = [];
let promiseForDying: any = [];
let proDyingUrl: any = [];
let proDyingCodes: any = [];
// finishing
let fileObjFinishing: any = [];
let fileArrayFinishing: any = [];
let promiseForFinishing: any = [];
let proFinishingUrl: any = [];
let proFinishingUCodes: any = [];
// variant
let fileObjVariant: any = [];
let fileArrayVariant: any = [];
let promiseForVariant: any = [];
let promiseVariantUrl: any = [];
let promiseVariantStatusCode: any = [];

let allStatusCodes: any = [];
let fileObjImages: any = [];
// let uniqueArr: any = [];

var productMainImageStatusCode: any = [];
const EditProduct = (props: any) => {
  const history = useHistory();
  const location: any = useLocation();
  const ProductId: any = location?.pathname.split("/")[2];
  const ProductName: any = location?.state?.productDetails?.name;
  const [form, setForm]: any = useState({
    artform: "",
    type: "",
    category: "",
    subCategory: "",
    weaveType: "",
    productName: "",
    designer: "",
    designerMedia: [],
    weaver: "",
    weaverMedia: [],
    dyer: "",
    dyerMedia: [],
    finisher: "",
    finisherMedia: [],
    profileURL: [],
    tags: [],
    singleTag: "",
    id: "",
    status: "",
    variantCode: "",
    // new
    nameOfBrand: "",
    nameOfWeaver: "",
    weightOfSaree: "",
    zariUsed: "",
    silkMark: true,
    handloomMark: true,
    madeInIndia: true,
    weightOfZariUsed: "",
    yarnType: "",
    workingHours: "",
    xuid: "",
    // new fields
    SrNo: "",
    weavingCenter: "Varanasi",
    typeOfSareeByDesign: "",
    productionTimeInMonths: "",
  });
  const [loader, setLoader]: any = useState(false);
  const [loaderVariant, setLoaderVariant]: any = useState({
    submit: false,
    images: false,
  });
  const [variantForm, setVariantForm]: any = useState({
    variantCode: "",
    images: [],
  });
  const [baseindex, setBaseIndex]: any = useState({
    designing: "",
    dying: "",
    weaving: "",
    finishing: "",
    logo: "",
  });
  const [Error, setError]: any = React.useState({
    artform: "",
    type: "",
    category: "",
    subCategory: "",
    weaveType: "",
    product: "",
    SrNo: "",
  });
  const [ErrorForVariant, setErrorForVariant]: any = useState({
    color: "",
  });
  const [filterValue, setFilterValue]: any = useState({
    type: "",
    category: "",
    subCategory: "",
    weaveType: "",
    product: "",
  });
  const [openModal, setOpenModal]: any = useState({
    artform: false,
    type: false,
    category: false,
    subCategory: false,
    weaveType: false,
    variant: false,
    designing: false,
    dying: false,
    weaving: false,
    finishing: false,
  });
  const [handleNameFlag, setHandleNameFlag]: any = useState({
    designing: false,
    weaving: false,
    dying: false,
    finishing: false,
  });
  const [deleteIndexLogo, setDeleteIndexLogo]: any = useState();
  const [baseindexForLogo, setBaseIndexForLogo]: any = useState("");

  const [mainImageLoader, setMainImageLoader]: any = useState(false);

  const [filterProduct, setFilterProduct]: any = useState("");

  const [loading, setLoading]: any = useState(false);
  const [baseImageLogo, setBaseImageLogo]: any = useState([]);
  const [baseImageDying, setBaseImageDying]: any = useState([]);
  const [baseImageDesigner, setBaseImageDesigner]: any = useState([]);
  const [baseImageVariant, setBaseImageVariant]: any = useState([]);

  const [deleteIndexDesigner, setDeleteIndexDesigner]: any = useState();
  const [deleteIndexWeaver, setDeleteIndexWeaver]: any = useState();
  const [deleteIndexDying, setDeleteIndexDying]: any = useState();
  const [deleteIndexFinisher, setDeleteIndexFinisher]: any = useState();
  const [deleteIndexVariant, setDeleteIndexVariant]: any = useState();

  const [baseImageWeaver, setBaseImageWeaver]: any = useState([]);
  const [baseImageFinisher, setBaseImageFinisher]: any = useState([]);

  const [selectedFile, setSelectedFile]: any = useState();
  const [selectedFileDying, setSelectedFileDying]: any = useState();
  const [selectedFileWeaver, setSelectedFileWeaver]: any = useState();
  const [selectedFileDesigner, setSelectedFileDesigner]: any = useState();
  const [selectedFileFinisher, setSelectedFileFinisher]: any = useState();
  const [selectedFileVariant, setSelectedFileVariant]: any = useState();
  const [isOpen, setIsOpen]: any = useState(false);
  const [image, setImage]: any = useState("");

  const [selectTab, setSelectTab]: any = useState("");
  const [selectTab1, setSelectTab1]: any = useState("");

  const fileInputProfile: any = React.useRef();
  const fileInputDesigner: any = React.useRef();
  const fileInputWeaver: any = React.useRef();
  const fileInputUploadDying: any = React.useRef();
  const fileInputFinisher: any = React.useRef();
  const fileInputVariant: any = React.useRef();

  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  let organizationId =
    authUser?.users?.role === "ORGANIZATION_MANAGER" ||
      authUser?.users?.role === "ORGANIZATION_ADMIN"
      ? authUser?.users?.organizationResponse?._id
      : "";

  const fileUploadTriggerVariant = () => {
    fileInputVariant.current.click();
  };
  const fileUploadTrigger = () => {
    fileInputProfile.current.click();
  };
  const fileUploadTriggerWeaver = () => {
    fileInputWeaver.current.click();
  };
  const fileUploadTriggerDesigner = () => {
    fileInputDesigner.current.click();
  };

  const fileUploadTriggerFinisher = () => {
    fileInputFinisher.current.click();
  };

  const fileUploadTriggerDying = () => {
    fileInputUploadDying.current.click();
  };
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleSetImage = (image: any) => {
    setImage(image);
  };
  useEffect(() => {
    if (location.pathname.split("/")[1] === "edit-product") {
      resetVariables();
      fileArray = [];
      fileArrayDying = [];
      fileArrayFinishing = [];
      fileArrayWeaving = [];
      fileObjDesign = [];
      fileObjDying = [];
      fileObjLogo = [];
      fileObjFinishing = [];
      fileObjWeaving = [];
      fileObjVariant = [];
      productMainImageStatusCode = [];

      // uniqueArr = [];
    }
  }, []);
  useEffect(() => {
    if (ProductId) {
      getAllData();
    }
  }, [ProductId]);

  const getAllData = () => {
    setLoading(true);
    props.getProducts(
      {
        offset: 0,
        limit: 10,
        ProductId: ProductId,
        // ProductsName:ProductName
      },
      (res: any) => {
        setLoading(false);
        if (res?.data) {
          let editDetails: any = res?.data?.docs[0];
          if (editDetails?.thumbnailDetails?.media?.length > 0) {
            setBaseImageLogo(
              baseImageCovertForLogoPin(editDetails?.thumbnailDetails?.media)
            );
          } else if (
            // editDetails?.thumbnailDetails === null &&
            editDetails?.thumbnail?.length > 0
          ) {
            setBaseImageLogo(
              baseImageCovertForLogoPin([
                {
                  mediaURL: editDetails?.thumbnail,
                  show: "1",
                  pin: "1",
                },
              ])
            );
          }
          if (editDetails?.designingDetails?.media) {
            setBaseImageDesigner(
              baseImageCovert(editDetails?.designingDetails?.media)
            );
          }
          if (editDetails?.dyingDetails?.media) {
            setBaseImageDying(
              baseImageCovert(editDetails?.dyingDetails?.media)
            );
          }
          if (editDetails?.weavingDetails?.media) {
            setBaseImageWeaver(
              baseImageCovert(editDetails?.weavingDetails?.media)
            );
          }
          if (editDetails?.finishingDetails?.media) {
            setBaseImageFinisher(
              baseImageCovert(editDetails?.finishingDetails?.media)
            );
          }
          setForm({
            id: editDetails?._id,
            artform: {
              value: editDetails?.artform?._id,
              label: editDetails?.artform?.name,
            },
            type: {
              value: editDetails?.type?._id,
              label: editDetails?.type?.name,
            },
            category: {
              value: editDetails?.category?._id,
              label: editDetails?.category?.name,
            },
            subCategory: {
              value: editDetails?.subcategory?._id,
              label: editDetails?.subcategory?.name,
            },
            weaveType: {
              value: editDetails?.weavetype?._id,
              label: editDetails?.weavetype?.name,
            },
            productName: editDetails?.name,
            designer:
              editDetails?.designingDetails?.karigar?.name?.length > 0
                ? {
                  label: editDetails?.designingDetails?.karigar?.name,
                  value: editDetails?.designingDetails?.karigar?._id,
                }
                : "",
            designerMedia:
              editDetails?.designingDetails?.media[0]?.mediaURL?.length > 0 &&
                editDetails?.designingDetails?.media?.length > 0
                ? editDetails?.designingDetails?.media
                  .filter((ele: any) => {
                    if (ele?.mediaURL?.length > 0) {
                      return {
                        mediaUrl: ele?.mediaURL,
                        show: ele?.show === "1" ? 1 : 0,
                      };
                    }
                  })
                  .map((ele1: any, i: any) => {
                    return {
                      mediaUrl: ele1?.mediaURL,
                      show: ele1?.show === "1" ? 1 : 0,
                    };
                  })
                : [],
            weaver:
              editDetails?.weavingDetails?.karigar?.name?.length > 0
                ? {
                  label: editDetails?.weavingDetails?.karigar?.name,
                  value: editDetails?.weavingDetails?.karigar?._id,
                }
                : "",
            weaverMedia:
              editDetails?.weavingDetails?.media?.length > 0 &&
                editDetails?.weavingDetails?.media[0]?.mediaURL?.length > 0
                ? editDetails?.weavingDetails?.media
                  .filter((ele: any) => {
                    if (ele?.mediaURL?.length > 0) {
                      return {
                        mediaUrl: ele?.mediaURL,
                        show: ele?.show === "1" ? 1 : 0,
                      };
                    }
                  })
                  .map((ele1: any, i: any) => {
                    return {
                      mediaUrl: ele1?.mediaURL,
                      show: ele1?.show === "1" ? 1 : 0,
                    };
                  })
                : [],
            dyer:
              editDetails?.dyingDetails?.karigar?.name?.length > 0
                ? {
                  label: editDetails?.dyingDetails?.karigar?.name,
                  value: editDetails?.dyingDetails?.karigar?._id,
                }
                : "",
            dyerMedia:
              editDetails?.dyingDetails?.media?.length > 0 &&
                editDetails?.dyingDetails?.media[0]?.mediaURL?.length > 0
                ? editDetails?.dyingDetails?.media
                  .filter((ele: any) => {
                    if (ele?.mediaURL?.length > 0) {
                      return {
                        mediaUrl: ele?.mediaURL,
                        show: ele?.show === "1" ? 1 : 0,
                      };
                    }
                  })
                  .map((ele1: any, i: any) => {
                    return {
                      mediaUrl: ele1?.mediaURL,
                      show: ele1?.show === "1" ? 1 : 0,
                    };
                  })
                : [],
            finisher:
              editDetails?.finishingDetails?.karigar?.name?.length > 0
                ? {
                  label: editDetails?.finishingDetails?.karigar?.name,
                  value: editDetails?.finishingDetails?.karigar?._id,
                }
                : "",
            finisherMedia:
              editDetails?.finishingDetails?.media?.length > 0 &&
                editDetails?.finishingDetails?.media[0]?.mediaURL?.length > 0
                ? editDetails?.finishingDetails?.media
                  .filter((ele: any) => {
                    if (ele?.mediaURL?.length > 0) {
                      return {
                        mediaUrl: ele?.mediaURL,
                        show: ele?.show === "1" ? 1 : 0,
                      };
                    }
                  })
                  .map((ele1: any, i: any) => {
                    return {
                      mediaUrl: ele1?.mediaURL,
                      show: ele1?.show === "1" ? 1 : 0,
                    };
                  })
                : [],
            profileURL:
              editDetails?.thumbnailDetails?.media?.length > 0 &&
                editDetails?.thumbnailDetails?.media[0]?.mediaURL?.length > 0
                ? editDetails?.thumbnailDetails?.media
                  .filter((ele: any) => {
                    if (ele?.mediaURL?.length > 0) {
                      return {
                        mediaUrl: ele?.mediaURL,
                        show: ele?.show === "1" ? 1 : 0,
                        pin: ele?.pin === "1" ? 1 : 0,
                      };
                    }
                  })
                  .map((ele1: any, i: any) => {
                    return {
                      mediaUrl: ele1?.mediaURL,
                      show: ele1?.show === "1" ? 1 : 0,
                      pin: ele1?.pin === "1" ? 1 : 0,
                    };
                  })
                : editDetails?.thumbnail?.length > 0
                  ? [
                    {
                      mediaUrl: editDetails?.thumbnail,
                      show: 1,
                      pin: 1,
                    },
                  ]
                  : [],
            productOwner: editDetails?.productOwner,
            tags: editDetails?.tags ? editDetails?.tags : [],
            status: editDetails.status,
            variantCode: editDetails?.color,
            //new
            nameOfBrand: editDetails?.brand,
            nameOfWeaver: editDetails?.weaver,
            handloomMark: editDetails?.handloom,
            silkMark: editDetails?.silk,
            zariUsed: { label: editDetails?.zari, value: editDetails?.purity },
            weightOfSaree: editDetails?.sareeWeight,
            weightOfZariUsed: editDetails?.zariWeight,
            purity: editDetails?.purity,
            madeInIndia: editDetails?.madeIn === "true" ? true : false,
            yarnType: editDetails?.yarnType
              ? {
                label: editDetails?.yarnType,
                value: editDetails?.yarnType,
              }
              : "",
            workingHours: editDetails?.workingHours,
            loomNum: editDetails?.loomNum,
            xuid: editDetails?.xuid,
            // new
            SrNo: editDetails?.srNo,
            weavingCenter: editDetails?.weavingCenter || form?.weavingCenter,
            typeOfSareeByDesign: editDetails?.typeOfSareeByDesign
              ? {
                label: editDetails?.typeOfSareeByDesign,
                value: editDetails?.name,
              }
              : "",
            productionTimeInMonths: {
              label: editDetails?.productionTimeInMonths,
              value: editDetails?.workingHours,
            },
          });
        }
      }
    );
  };

  const getWeaveTypeData = (data?: any) => {
    props.getWeaveType(
      {
        offset: 0,
        limit: 10,
        artformId: form?.artform?.value || data?.artform,
        typeId: form?.type?.value || data?.type,
        categoryId: form?.category?.value || data?.category,
        subCategoryId: form?.subCategory?.value || data?.subCategory,
        weaveTypeId: form?.weaveType?.value,
        weaveTypeName: data?.value,
      },
      (res: any) => {
        weaveTypeOptions = [];
        res?.data?.docs.map((ele: any) => {
          weaveTypeOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getSubCategoryData = (data?: any) => {
    props.getSubCategorys(
      {
        offset: 0,
        limit: 10,
        typeId: form?.type?.value || data?.type,
        artformId: form?.artform?.value || data.artform,
        categoryId: form?.category?.value || data?.category,
        subCategoryName: data?.value,
      },
      (res: any) => {
        subCategoryOptions = [];
        res?.data?.docs.map((ele: any) => {
          subCategoryOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getCreatorsData = (data?: any) => {
    props.getUsers(
      {
        offset: 0,
        limit: 10,
        organizationId,
        userId: data?.userId,
      },
      (res: any) => {
        designerOptions = [];
        res?.data?.docs.map((ele: any) => {
          let formValues = form;
          if (data?.name === "designing") {
            formValues = {
              ...formValues,
              designer: {
                label: res?.data?.docs[0]?.name,
                value: {
                  id: res?.data?.docs[0]?._id,
                  roleId: res?.data?.docs[0]?.businessRoleDetails[0]?._id,
                },
              },
            };
            getCreatorsDataDesignerOption();
          }
          if (data?.name === "weaving") {
            formValues = {
              ...formValues,
              weaver: {
                label: res?.data?.docs[0]?.name,
                value: {
                  id: res?.data?.docs[0]?._id,
                  roleId: res?.data?.docs[0]?.businessRoleDetails[0]?._id,
                },
              },
            };
            getCreatorsDataWeaverOption();
          }
          if (data?.name === "dying") {
            formValues = {
              ...formValues,
              dyer: {
                label: res?.data?.docs[0]?.name,
                value: {
                  id: res?.data?.docs[0]?._id,
                  roleId: res?.data?.docs[0]?.businessRoleDetails[0]?._id,
                },
              },
            };
            getCreatorsDataDyingOption();
          }
          if (data?.name === "finishing") {
            formValues = {
              ...formValues,
              finisher: {
                label: res?.data?.docs[0]?.name,
                value: {
                  id: res?.data?.docs[0]?._id,
                  roleId: res?.data?.docs[0]?.businessRoleDetails[0]?._id,
                },
              },
            };
            getCreatorsDataFinishingOption();
          }
          setForm(formValues);
        });
      }
    );
  };
  const getCreatorsDataDesignerOption = (name?: any) => {
    props.getUsers(
      {
        offset: 0,
        limit: 10,
        organizationId,
        usersName: name,
        role: "63c64da18667bd64a57c62fe",
      },
      (res: any) => {
        designerOptions = [];
        res?.data?.docs.map((ele: any) => {
          if (ele?.businessRoleDetails[0]?.role === "NAQSEBAND") {
            designerOptions.push({
              label: ele?.name,
              value: ele?._id,
            });
          }
        });
      }
    );
  };
  const getCreatorsDataWeaverOption = (name?: any) => {
    props.getUsers(
      {
        offset: 0,
        limit: 10,
        organizationId,
        usersName: name,
        role: "63c64da18667bd64a57c62ff",
      },
      (res: any) => {
        weaverOptions = [];
        res?.data?.docs.map((ele: any) => {
          if (ele?.businessRoleDetails[0]?.role === "WEAVER") {
            weaverOptions.push({
              label: ele?.name,
              value: ele?._id,
            });
          }
        });
      }
    );
  };
  const getCreatorsDataDyingOption = (name?: any) => {
    props.getUsers(
      {
        offset: 0,
        limit: 10,
        organizationId,
        usersName: name,
        role: "63c64da18667bd64a57c6300",
      },
      (res: any) => {
        dyerOptions = [];
        res?.data?.docs.map((ele: any) => {
          if (ele?.businessRoleDetails[0]?.role === "DYER") {
            dyerOptions.push({
              label: ele?.name,
              value: ele?._id,
            });
          }
        });
      }
    );
  };
  const getCreatorsDataFinishingOption = (name?: any) => {
    props.getUsers(
      {
        offset: 0,
        limit: 10,
        organizationId,
        usersName: name,
        role: [
          "63c64da18667bd64a57c6303",
          "63c64da18667bd64a57c6302",
          "63c64da18667bd64a57c6301",
        ],
      },
      (res: any) => {
        finisherOptions = [];
        res?.data?.docs.map((ele: any) => {
          if (
            ele?.businessRoleDetails[0]?.role === "RAFUGAR" ||
            ele?.businessRoleDetails[0]?.role === "STAIN_MASTER" ||
            ele?.businessRoleDetails[0]?.role === "TASSEL"
          ) {
            finisherOptions.push({
              label: ele?.name,
              value: ele?._id,
            });
          }
        });
      }
    );
  };
  const getCategoryData = (data?: any) => {
    props.getCategory(
      {
        offset: 0,
        limit: 10,
        typeId: form?.type?.value || data?.type,
        artformId: form?.artform?.value || data.artform,
        categoryName: data?.value,
      },
      (res: any) => {
        categoryOptions = [];
        res?.data?.docs.map((ele: any) => {
          categoryOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getTypesData = (data?: any) => {
    props.getTypes(
      {
        offset: 0,
        limit: 10,
        artFormId: form?.artform?.value || data?.artform,
        typeId: form?.type?.value || data?.type,
        typeName: data?.value,
      },
      (res: any) => {
        typeOptions = [];
        res?.data?.docs.map((ele: any) => {
          typeOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getArtformData = (data?: any) => {
    props.getArtforms(
      {
        offset: 0,
        limit: 10,
        artformName: data?.value,
      },
      (res: any) => {
        artformOptions = [];
        res?.data?.docs.map((ele: any) => {
          artformOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };

  const CallingGettProduct = (value: any) => {
    props.getProducts(
      {
        offset: 0,
        limit: 10,
        ProductsName: value?.productName,
        artformId: value?.artform,
        typeId: value?.type,
        categoryId: value?.category,
        subCategoryId: value?.subCategory,
        weaveTypeId: value?.weaveType,
      },
      (res: any) => {
        setLoading(false);
        if (res?.data?.docs?.length) {
          res.data?.docs.map((ele: any, i: any) => {
            if (value.productName.match(ele?.name)) {
              setFilterProduct(ele?.name);
            }
          });
        }
      }
    );
  };
  const callingGetTypesData = (value: any) => {
    props.getTypes(
      {
        offset: 0,
        limit: 1,
        artFormId: value?.artformId,
        typeName: value?.type,
      },
      (res: any) => {
        // let newValues={...filterValue}
        if (res?.data?.docs?.length) {
          res.data?.docs.map((ele: any, i: any) => {
            setForm({
              ...form,
              type: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
              },
            });
            setFilterValue({
              ...filterValue,
              type: ele?._id,
            });
          });
        }
      }
    );
  };
  const callingGetCategoryData = (value: any) => {
    props.getCategory(
      {
        offset: 0,
        limit: 10,
        typeId: value?.typeId,
        categoryName: value?.category,
        artformId: value?.artformId,
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          res?.data?.docs.map((ele: any, i: any) => {
            setForm({
              ...form,
              category: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
              },
            });
            setFilterValue({
              ...filterValue,
              category: ele?._id,
            });
          });
        }
      }
    );
  };
  const callingGetSubCategory = (value: any) => {
    props.getSubCategorys(
      {
        offset: 0,
        limit: 1,
        // category:value?.category,
        subCategoryName: value?.subCategory,
        artformId: value?.artformId,
        typeId: value?.typeId,
        categoryId: value?.categoryId,
        subCategoryId: value?.subCategoryId,
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          res?.data?.docs.map((ele: any, i: any) => {
            setForm({
              ...form,
              subCategory: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
              },
            });
            setFilterValue({
              ...filterValue,
              subCategory: ele?._id,
            });
          });
        }
      }
    );
  };

  const callingGetWeaveType = (value: any) => {
    props.getWeaveType(
      {
        offset: 0,
        limit: 10,
        artformId: value?.artformId,
        typeId: value?.typeId,
        categoryId: value?.categoryId,
        subCategoryId: value?.subCategoryId,
        weaveTypeId: value?.weaveTypeId,
        weaveTypeName: value.weaveType,
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          res?.data?.docs.map((ele: any, i: any) => {
            setForm({
              ...form,
              weaveType: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
              },
            });
            setFilterValue({
              ...filterValue,
              weaveType: ele?._id,
            });
          });
        }
      }
    );
  };
  useEffect(() => {
    getArtformData();
    getCreatorsDataDesignerOption();
    getCreatorsDataDyingOption();
    getCreatorsDataFinishingOption();
    getCreatorsDataWeaverOption();
  }, []);
  useEffect(() => {
    if (form?.artform?.value?.length) {
      getTypesData();
    }
  }, [form?.artform?.value]);
  useEffect(() => {
    if (form?.type?.value?.length) {
      getCategoryData();
    }
  }, [form?.type?.value]);
  useEffect(() => {
    if (form?.category?.value?.length) {
      getSubCategoryData();
    }
  }, [form?.category?.value]);
  useEffect(() => {
    if (form?.subCategory?.value?.length) {
      getWeaveTypeData();
    }
  }, [form?.subCategory?.value]);

  useEffect(() => {
    deleteImagesByIndex({
      deleteIndex: deleteIndexVariant,
      baseImage: baseImageVariant,
      media: form?.variantImages,
      selectedFiles: selectedFileVariant,
      fileArr: fileObjVariant,
      name: "variant",
    });
  }, [deleteIndexVariant]);

  useEffect(() => {
    deleteImagesByIndex({
      deleteIndex: deleteIndexDesigner,
      baseImage: baseImageDesigner,
      media: form?.designerMedia,
      selectedFiles: selectedFileDesigner,
      fileArr: fileObjDesign,
      name: "desinging",
    });
  }, [deleteIndexDesigner]);

  useEffect(() => {
    deleteImagesByIndex({
      deleteIndex: deleteIndexWeaver,
      baseImage: baseImageWeaver,
      media: form?.weaverMedia,
      selectedFiles: selectedFileWeaver,
      fileArr: fileObjWeaving,
      name: "weaving",
    });
  }, [deleteIndexWeaver]);

  useEffect(() => {
    deleteImagesByIndex({
      deleteIndex: deleteIndexDying,
      baseImage: baseImageDying,
      media: form?.dyerMedia,
      selectedFiles: selectedFileDying,
      fileArr: fileObjDying,
      name: "dying",
    });
  }, [deleteIndexDying]);

  useEffect(() => {
    deleteImagesByIndex({
      deleteIndex: deleteIndexFinisher,
      baseImage: baseImageFinisher,
      media: form?.finisherMedia,
      selectedFiles: selectedFileFinisher,
      fileArr: fileObjFinishing,
      name: "finishing",
    });
  }, [deleteIndexFinisher]);
  useEffect(() => {
    deleteImagesByIndex({
      deleteIndex: deleteIndexLogo,
      baseImage: baseImageLogo,
      media: form?.profileURL,
      selectedFiles: selectedFile,
      fileArr: fileObjLogo,
      name: "logo",
    });
  }, [deleteIndexLogo]);

  // delete data  reuse function

  const deleteImagesByIndex = (data: any) => {
    let { deleteIndex, baseImage, media, selectedFiles, name, fileArr } = data;
    let deleteBaseImage = baseImage?.length
      ? baseImage.filter((ele: any, i: any) => i !== deleteIndex?.i)
      : [];
    let deleteMedia =
      media?.length && media.filter((ele: any, i: any) => i !== deleteIndex?.i);
    let deletefileArray =
      fileArr?.length &&
      fileArr.filter(
        (ele: any, i: any) => ele?.name !== deleteIndex?.item?.fileName
      );
    let deleteSelectedFile =
      selectedFiles?.length &&
      selectedFiles.filter(
        (ele: any, i: any) => ele?.name !== deleteIndex?.item?.fileName
      );
    if (name === "desinging") {
      fileObjDesign = deletefileArray?.length ? deletefileArray : [];
      setForm({
        ...form,
        designerMedia: deleteMedia,
      });
      setSelectedFileDesigner(deleteSelectedFile);
      setBaseImageDesigner([...deleteBaseImage]);
    } else if (name === "weaving") {
      fileObjWeaving = deletefileArray?.length ? deletefileArray : [];
      setSelectedFileWeaver(deleteSelectedFile);
      setForm({
        ...form,
        weaverMedia: deleteMedia,
      });
      setBaseImageWeaver([...deleteBaseImage]);
    } else if (name === "dying") {
      fileObjDying = deletefileArray?.length ? deletefileArray : [];
      setForm({
        ...form,
        dyerMedia: deleteMedia,
      });
      setBaseImageDying([...deleteBaseImage]);
      setSelectedFileDying(deleteSelectedFile);
    } else if (name === "finishing") {
      fileObjFinishing = deletefileArray?.length ? deletefileArray : [];
      setSelectedFileFinisher(deleteSelectedFile);
      setForm({
        ...form,
        finisherMedia: deleteMedia,
      });
      setBaseImageFinisher([...deleteBaseImage]);
    } else if (name === "variant") {
      fileObjVariant = deletefileArray?.length ? deletefileArray : [];
      setSelectedFileVariant(deleteSelectedFile);
      setForm({
        ...form,
        variantImages: deleteMedia,
      });
      setBaseImageVariant([...deleteBaseImage]);
    }
    if (name === "logo") {
      fileObjLogo = deletefileArray?.length ? deletefileArray : [];
      setSelectedFile(deleteSelectedFile);
      setForm({
        ...form,
        profileURL: deleteMedia,
      });
      setBaseImageLogo([...deleteBaseImage]);
    }
  };
  const validate = () => {
    let error = "";
    let err = Error;

    if (form?.artform === "") {
      error = "Required";
      err = {
        ...err,
        artform: "ArtForm is Required!",
      };
    }
    if (form?.type === "") {
      error = "Required";
      err = {
        ...err,
        type: "Type Name is Required!",
      };
    }
    if (form?.category === "") {
      error = "Required";
      err = {
        ...err,
        category: "Category Name is Required!",
      };
    }
    if (form?.subCategory === "") {
      error = "Required";
      err = {
        ...err,
        subCategory: "Sub-Category Name is Required!",
      };
    }
    if (form?.weaveType === "") {
      error = "Required";
      err = {
        ...err,
        weaveType: "Weave Type is Required!",
      };
    }
    if (form?.productName === "") {
      error = "Required";
      err = {
        ...err,
        product: "Product Name is Required!",
      };
    }
    if (form?.SrNo === "") {
      error = "Required";
      err = {
        ...err,
        SrNo: "SrNo is Required!",
      };
    }
    if (form?.variantCode === "") {
      error = "Required";
      setError({
        ...Error,
      });
      err = {
        ...err,
        variantCode: "Color Name is Required!",
      };
    }
    setError(err);
    return error;
  };
  const validateForVariant = () => {
    let error = "";
    let err = ErrorForVariant;
    if (variantForm?.variantCode === "") {
      error = "Required";
      err = {
        ...err,
        color: "Color Name is Required!",
      };
    }
    setErrorForVariant(err);
    return error;
  };
  const handleSubmit = async (status: any) => {
    let result: any = validate();
    if (result?.length === 0) {
      if (selectedFile?.length > 0) {
        setLoader(true);
        const resultForLogo: any = await S3UploadForLogo();

        resultForLogo?.length > 0 &&
          resultForLogo.forEach((ele: any, i: any) => {
            promiseLogoStatusCode.push(ele?.statusCode);
            allStatusCodes.push(ele?.statusCode);
            if (ele?.statusCode === 200) {
              promiseLogoUrl.push({
                mediaUrl: ele?.url,
                show:
                  baseImageLogo[
                    form?.profileURL?.length > 0
                      ? form?.profileURL?.length
                      : 0 + i
                  ]?.show === true
                    ? 1
                    : 0,
                pin:
                  baseImageLogo[
                    form?.profileURL?.length > 0
                      ? form?.profileURL?.length
                      : 0 + i
                  ]?.pin === true
                    ? 1
                    : 0,
              });
            }
          });
        promiseLogoStatusCode.map((ele: any, i: any) => {
          if (ele === 200) {
          } else {
            notifications({
              title: "Error!",
              message: `Media ${Number(
                form?.profileURL?.length > 0 ? form?.profileURL?.length : 0
              ) +
                i +
                1
                } upload failed into server under logo section !`,
              type: "danger",
            });
          }
        });
        setLoader(false);
      }
      if (selectedFileDesigner?.length > 0) {
        setLoader(true);

        const resultForDesigning = await S3UploadDesigner();
        resultForDesigning?.length > 0 &&
          resultForDesigning.forEach((ele: any, i: any) => {
            promiseDesignStatusCode.push(ele?.statusCode);
            allStatusCodes.push(ele?.statusCode);

            if (ele?.statusCode === 200) {
              promiseDesignUrl.push({
                mediaUrl: ele?.url,
                show:
                  baseImageDesigner[
                    form?.designerMedia?.length > 0
                      ? form?.designerMedia?.length
                      : 0 + i
                  ]?.show === true
                    ? 1
                    : 0,
              });
            }
          });
        promiseDesignStatusCode.map((ele: any, i: any) => {
          if (ele === 200) {
            // notifications({
            //   title: "Success!",
            //   message: `Image Upload successfully`,
            //   type: "success",
            // });
          } else {
            notifications({
              title: "Error!",
              message: `Media ${Number(
                form?.designerMedia?.length > 0
                  ? form?.designerMedia?.length
                  : 0
              ) +
                i +
                1
                } upload failed into server under designing section !`,
              type: "danger",
            });
          }
        });
        setLoader(false);
      }
      if (selectedFileWeaver?.length > 0) {
        setLoader(true);

        const resultForWeaving: any = await S3UploadWeaving();
        resultForWeaving?.length > 0 &&
          resultForWeaving.forEach((ele: any, i: any) => {
            proWeavingCodes.push(ele?.statusCode);
            allStatusCodes.push(ele?.statusCode);

            if (ele?.statusCode === 200) {
              proWeavingUrl.push({
                mediaUrl: ele?.url,
                show:
                  baseImageWeaver[
                    form?.weaverMedia?.length > 0
                      ? form?.weaverMedia?.length
                      : 0 + i
                  ]?.show === true
                    ? 1
                    : 0,
              });
            }
          });
        proWeavingCodes.map((ele: any, i: any) => {
          if (ele === 200) {
            // notifications({
            //   title: "Success!",
            //   message: `Image Upload successfully`,
            //   type: "success",
            // });
          } else {
            notifications({
              title: "Error!",
              message: `Media ${Number(
                form?.weaverMedia?.length > 0 ? form?.weaverMedia?.length : 0
              ) +
                i +
                1
                } upload failed into server under weaving section !`,
              type: "danger",
            });
          }
        });
        setLoader(false);
      }
      if (selectedFileDying?.length > 0) {
        setLoader(true);

        const resultForDying: any = await S3UploadDying();
        resultForDying?.length > 0 &&
          resultForDying.forEach((ele: any, i: any) => {
            proDyingCodes.push(ele?.statusCode);
            allStatusCodes.push(ele?.statusCode);
            if (ele?.statusCode === 200) {
              proDyingUrl.push({
                mediaUrl: ele?.url,
                show:
                  baseImageDying[
                    form?.dyerMedia?.length > 0
                      ? form?.dyerMedia?.length
                      : 0 + i
                  ]?.show === true
                    ? 1
                    : 0,
              });
            }
          });
        proDyingCodes.map((ele: any, i: any) => {
          if (ele === 200) {
            // notifications({
            //   title: "Success!",
            //   message: `Image Upload successfully`,
            //   type: "success",
            // });
          } else {
            notifications({
              title: "Error!",
              message: `Media ${Number(
                form?.dyerMedia?.length > 0 ? form?.dyerMedia?.length : 0
              ) +
                i +
                1
                } upload failed into server under dying section !`,
              type: "danger",
            });
          }
        });
        setLoader(false);
      }
      if (selectedFileFinisher?.length > 0) {
        setLoader(true);

        const resultForFinishing: any = await S3UploadFinishing();
        resultForFinishing?.length > 0 &&
          resultForFinishing.forEach((ele: any, i: any) => {
            proFinishingUCodes.push(ele?.statusCode);
            allStatusCodes.push(ele?.statusCode);
            if (ele?.statusCode === 200) {
              proFinishingUrl.push({
                mediaUrl: ele?.url,
                show:
                  baseImageFinisher[
                    form?.finisherMedia?.length > 0
                      ? form?.finisherMedia?.length
                      : 0 + i
                  ]?.show === true
                    ? 1
                    : 0,
              });
            }
          });
        proFinishingUCodes.map((ele: any, i: any) => {
          if (ele === 200) {
            // notifications({
            //   title: "Success!",
            //   message: `File ${
            //     Number(
            //       form?.finisherMedia?.length > 0
            //         ? form?.finisherMedia?.length
            //         : 0
            //     ) +
            //     i +
            //     1
            //   } upload failed under finisher section`,
            //   type: "success",
            // });
          } else {
            notifications({
              title: "Error!",
              message: `Media ${Number(
                form?.finisherMedia?.length > 0
                  ? form?.finisherMedia?.length
                  : 0
              ) +
                i +
                1
                } upload failed into server under finishing section !`,
              type: "danger",
            });
          }
        });
        setLoader(false);
      }
      let payload: any = {
        id: location?.state?.productDetails?._id,
        name: form?.productName,
        xuid: form?.xuid?.length ? form?.xuid.trim() : "",
        tags: form?.tags,
        thumbnails: {
          media:
            form?.profileURL?.length > 0 && promiseLogoUrl
              ? [...form?.profileURL, ...promiseLogoUrl]
              : form?.profileURL?.length > 0
                ? form?.profileURL
                : promiseLogoUrl?.length > 0
                  ? promiseLogoUrl
                  : [
                    {
                      mediaUrl: "",
                      show: 0,
                      pin: 0,
                    },
                  ],
        },
        artformId: form?.artform?.value,
        typeId: form?.type?.value,
        categoryId: form?.category?.value,
        subcategoryId: form?.subCategory?.value,
        weavetypeId: form?.weaveType?.value,
        // associationId: "",
        designing: {
          media:
            form?.designerMedia?.length > 0 && promiseDesignUrl?.length > 0
              ? [...form?.designerMedia, ...promiseDesignUrl]
              : form?.designerMedia?.length > 0
                ? form?.designerMedia
                : promiseDesignUrl?.length > 0
                  ? promiseDesignUrl
                  : [
                    {
                      mediaUrl: "",
                      show: 0,
                    },
                  ],
          karigarId: form?.designer?.value ? form?.designer?.value : "",
        },
        weaving: {
          media:
            form?.weaverMedia?.length > 0 && proWeavingUrl?.length > 0
              ? [...form?.weaverMedia, ...proWeavingUrl]
              : form?.weaverMedia?.length > 0
                ? form?.weaverMedia
                : proWeavingUrl?.length > 0
                  ? proWeavingUrl
                  : [
                    {
                      mediaUrl: "",
                      show: 0,
                    },
                  ],
          karigarId: form?.weaver?.value ? form?.weaver?.value : "",
        },
        dying: {
          media:
            form?.dyerMedia?.length > 0 && proDyingUrl?.length > 0
              ? [...form?.dyerMedia, ...proDyingUrl]
              : form?.dyerMedia?.length > 0
                ? form?.dyerMedia
                : proDyingUrl?.length > 0
                  ? proDyingUrl
                  : [
                    {
                      mediaUrl: "",
                      show: 0,
                    },
                  ],
          karigarId: form?.dyer?.value ? form?.dyer?.value : "",
        },
        finishing: {
          media:
            form?.finisherMedia?.length > 0 && proFinishingUrl?.length > 0
              ? [...form?.finisherMedia, ...proFinishingUrl]
              : form?.finisherMedia?.length > 0
                ? form?.finisherMedia
                : proFinishingUrl?.length > 0
                  ? proFinishingUrl
                  : [
                    {
                      mediaUrl: "",
                      show: 0,
                    },
                  ],
          karigarId: form?.finisher?.value ? form?.finisher?.value : "",
        },
        status: status?.status,
        color: form?.variantCode,
        //new
        brand: form?.nameOfBrand,
        weaver: form?.nameOfWeaver,
        handloom: form?.handloomMark,
        silk: form?.silkMark,
        zari: form?.zariUsed?.label === "None" ? "" : form?.zariUsed?.label,
        sareeWeight: form?.weightOfSaree,
        zariWeight: form?.weightOfZariUsed,
        purity: form?.zariUsed?.value,
        madeIn: form?.madeInIndia,
        yarnType: form?.yarnType?.label ? form?.yarnType?.label : "",
        workingHours: form?.workingHours,
        loomNum: form?.loomNum,
        // new
        // new
        srNo: form?.SrNo,
        weavingCenter: form?.weavingCenter,
        typeOfSareeByDesign: form?.typeOfSareeByDesign?.label,
        productionTimeInMonths: form?.productionTimeInMonths?.label,
        // productOwner: "tantuvi by smriti morarka",
      };
      if (allStatusCodes?.length > 0) {
        let codes = allStatusCodes.every((ele: any) => ele === 200);
        if (codes === true) {
          callingUpdateProduct(payload);
        } else {
          callingUpdateProduct(
            payload,
            "Kindly edit and add the media again !"
          );
        }
      }

      if (
        !selectedFile &&
        !selectedFileDesigner &&
        !selectedFileDying &&
        !selectedFileWeaver &&
        !selectedFileFinisher
      ) {
        callingUpdateProduct(payload);
      }
    }
  };
  const resetVariables = () => {
    // selector options
    artformOptions = [];
    typeOptions = [];
    categoryOptions = [];
    designerOptions = [];
    weaverOptions = [];
    dyerOptions = [];
    finisherOptions = [];
    subCategoryOptions = [];
    weaveTypeOptions = [];

    // logo
    fileObjLogo = [];
    fileArrayLogo = [];
    promiseForLogo = [];
    promiseLogoUrl = [];
    promiseLogoStatusCode = [];

    // designing
    fileObjDesign = [];
    fileArray = [];
    promiseForDesign = [];
    promiseDesignUrl = [];
    promiseDesignStatusCode = [];
    // weaving
    fileObjWeaving = [];
    fileArrayWeaving = [];
    promiseForWeaving = [];
    proWeavingUrl = [];
    proWeavingCodes = [];
    // dying
    fileObjDying = [];
    fileArrayDying = [];
    promiseForDying = [];
    proDyingUrl = [];
    proDyingCodes = [];
    // finishing
    fileObjFinishing = [];
    fileArrayFinishing = [];
    promiseForFinishing = [];
    proFinishingUrl = [];
    proFinishingUCodes = [];
    // variant
    fileObjVariant = [];
    fileArrayVariant = [];
    promiseForVariant = [];
    promiseVariantUrl = [];
    promiseVariantStatusCode = [];

    allStatusCodes = [];
    fileObjImages = [];
    // uniqueArr = [];

    productMainImageStatusCode = [];
  };
  const callingUpdateProduct = (payload: any, msg?: any) => {
    // let result: any = validate();
    // if (result?.length === 0) {
    setLoading(true);
    props.updateProducts(payload, (res: any) => {
      setLoading(false);
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message + " " + (msg?.length > 0 ? msg : ""),
          type: "success",
        });
        history.push("/products");
      } else if (res?.status === 400) {
        notifications({
          title: "Error!",
          message: res?.detail,
          type: "danger",
        });
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
      resetVariables();
      //logo
      fileObjLogo = [];
      fileArrayLogo = [];
      promiseForLogo = [];
      promiseLogoUrl = [];
      promiseLogoStatusCode = [];

      fileObjDesign = [];
      fileObjDying = [];
      fileObjFinishing = [];
      fileObjWeaving = [];
      fileObjVariant = [];
      allStatusCodes = [];
      proFinishingUCodes = [];
      proDyingCodes = [];
      promiseDesignStatusCode = [];
      proWeavingCodes = [];
      proDyingUrl = [];
      proWeavingUrl = [];
      promiseDesignUrl = [];
      proFinishingUrl = [];
      promiseForDesign = [];
      promiseForDying = [];
      promiseForFinishing = [];
      promiseForWeaving = [];
      promiseForVariant = [];
      promiseVariantUrl = [];
      promiseVariantStatusCode = [];
      fileArrayVariant = [];
      // uniqueArr = [];
    });
    // }
  };
  const handleOnInputChange = (type: any) => {
    setHandleNameFlag({
      designing: false,
      weaving: false,
      dying: false,
      finishing: false,
    });
    let formValues = form;
    if (type?.name === "artform") {
      formValues = {
        ...formValues,
        type: "",
        category: "",
        subCategory: "",
        weaveType: "",
        artform: type?.value,
        productName: "",
      };
    } else if (type?.name === "type") {
      formValues = {
        ...formValues,
        type: type?.value,
        category: "",
        subCategory: "",
        weaveType: "",
        productName: "",
      };
    } else if (type?.name === "category") {
      formValues = {
        ...formValues,
        category: type?.value,
        subCategory: "",
        weaveType: "",
        productName: "",
      };
    } else if (type?.name === "subCategory") {
      formValues = {
        ...formValues,
        subCategory: type?.value,
        weaveType: "",
        productName: "",
      };
    } else if (type?.name === "weaveType") {
      formValues = {
        ...formValues,
        weaveType: type?.value,
        productName: "",
      };
    } else {
      if (
        authUser?.users?.organizationResponse?.name ===
        "tantuvi by smriti morarka" &&
        type?.name === "typeOfSareeByDesign"
      ) {
        formValues = {
          ...formValues,
          productName: type?.value?.value,
          typeOfSareeByDesign: type?.value,
        };
      } else if (type?.name === "timeTakenInMonths") {
        formValues = {
          ...formValues,
          workingHours: type?.value?.value,
          productionTimeInMonths: type?.value,
        };
      } else {
        formValues = {
          ...formValues,
          [type.name]: type?.value,
        };
      }
    }
    setForm(formValues);
    setFilterValue({
      type: "",
      category: "",
      subCategory: "",
      weaveType: "",
    });
  };
  // useEffect(() => {
  //   if (selectedFile) {
  //     S3Upload();
  //   }
  // }, [selectedFile]);

  const gettingFileType = (file: any) => {
    let fileType: any;
    if (
      file ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      fileType = "application/docx";
    } else if (file === "application/msword") {
      fileType = "application/doc";
    } else {
      fileType = file;
    }
    return fileType;
  };
  // S3 upload into awd
  const S3UploadVariants = async () => {
    let softCopyArry: any = [];
    for (let i = 0; i < selectedFileVariant?.length; i++) {
      let fileType: any;
      fileType = gettingFileType(selectedFileVariant[i]?.type);
      const promise = new Promise((resolve: any, reject: any) => {
        props.fetchProductDesigningPresignedUrl(
          { fileType: fileType },
          (res: any) => {
            const formData = new FormData();
            formData.append(
              selectedFileVariant[i],
              selectedFileVariant[i].name
            );
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", selectedFileVariant[i].type);
            props.uploadImages1(
              res.data,
              selectedFileVariant[i],
              myHeaders,
              (resSerever: any) => {
                softCopyArry.push({
                  statusCode: resSerever?.status,
                  url: resSerever?.url.split("?")[0],
                });
                resolve(softCopyArry);
              }
            );
          }
        );
      });
      promiseForVariant.push(promise);
    }
    let t: any = [];
    t = await Promise.all(promiseForVariant);
    return t[0];
  };
  const S3UploadForLogo = async () => {
    let softCopyArry: any = [];

    for (let i = 0; i < selectedFile?.length; i++) {
      let fileType: any;
      fileType = gettingFileType(selectedFile[i]?.type);
      const promise = new Promise((resolve: any, reject: any) => {
        props.fetchProfilePresignedUrl({ fileType: fileType }, (res: any) => {
          const formData = new FormData();
          formData.append(selectedFile[i], selectedFile[i].name);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", selectedFile[i].type);
          props.uploadImages1(
            res.data,
            selectedFile[i],
            myHeaders,
            (resSerever: any) => {
              softCopyArry.push({
                statusCode: resSerever?.status,
                url: resSerever?.url.split("?")[0],
              });
              resolve(softCopyArry);
            }
          );
        });
      });
      promiseForLogo.push(promise);
    }
    let t: any = [];
    t = await Promise.all(promiseForLogo);
    return t[0];
  };
  const S3UploadDesigner = async () => {
    let softCopyArry: any = [];
    for (let i = 0; i < selectedFileDesigner?.length; i++) {
      let fileType: any;
      fileType = gettingFileType(selectedFileDesigner[i]?.type);

      const promise = new Promise((resolve: any, reject: any) => {
        props.fetchProductDesigningPresignedUrl(
          { fileType: fileType },
          (res: any) => {
            const formData = new FormData();
            formData.append(
              selectedFileDesigner[i],
              selectedFileDesigner[i].name
            );
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", selectedFileDesigner[i].type);
            props.uploadImages1(
              res.data,
              selectedFileDesigner[i],
              myHeaders,
              (resSerever: any) => {
                softCopyArry.push({
                  statusCode: resSerever?.status,
                  url: resSerever?.url.split("?")[0],
                });
                resolve(softCopyArry);
              }
            );
          }
        );
      });
      promiseForDesign.push(promise);
    }
    let t: any = [];
    t = await Promise.all(promiseForDesign);
    return t[0];
  };
  const S3UploadDying = async () => {
    let softCopyArryForDying: any = [];
    for (let i = 0; i < selectedFileDying?.length; i++) {
      let fileType: any;
      fileType = gettingFileType(selectedFileDying[i]?.type);
      const promise = new Promise((resolve: any, reject: any) => {
        props.fetchProductDyingPresignedUrl(
          { fileType: fileType },
          (res: any) => {
            const formData = new FormData();
            formData.append(selectedFileDying[i], selectedFileDying[i].name);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", selectedFileDying[i].type);
            props.uploadImages1(
              res.data,
              selectedFileDying[i],
              myHeaders,
              (resSerever: any) => {
                softCopyArryForDying.push({
                  statusCode: resSerever?.status,
                  url: resSerever?.url.split("?")[0],
                });
                resolve(softCopyArryForDying);
              }
            );
          }
        );
      });
      promiseForDying.push(promise);
    }
    let t: any = [];
    t = await Promise.all(promiseForDying);
    return t[0];
  };
  const S3UploadFinishing = async () => {
    let softCopyArryFinishing: any = [];
    for (let i = 0; i < selectedFileFinisher?.length; i++) {
      let fileType: any;
      fileType = gettingFileType(selectedFileFinisher[i]?.type);
      const promise = new Promise((resolve: any, reject: any) => {
        props.fetchProductFinishingPresignedUrl(
          { fileType: fileType },
          (res: any) => {
            const formData = new FormData();
            formData.append(
              selectedFileFinisher[i],
              selectedFileFinisher[i].name
            );
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", selectedFileFinisher[i].type);
            props.uploadImages1(
              res.data,
              selectedFileFinisher[i],
              myHeaders,
              (resSerever: any) => {
                softCopyArryFinishing.push({
                  statusCode: resSerever?.status,
                  url: resSerever?.url.split("?")[0],
                });
                resolve(softCopyArryFinishing);
              }
            );
          }
        );
      });
      promiseForFinishing.push(promise);
    }
    let t: any = [];
    t = await Promise.all(promiseForFinishing);
    return t[0];
  };
  const S3UploadWeaving = async () => {
    let softCopyArryWeaving: any = [];
    for (let i = 0; i < selectedFileWeaver?.length; i++) {
      let fileType: any;
      fileType = gettingFileType(selectedFileWeaver[i]?.type);
      const promise = new Promise((resolve: any, reject: any) => {
        props.fetchProductWeavingPresignedUrl(
          { fileType: fileType },
          (res: any) => {
            const formData = new FormData();
            formData.append(selectedFileWeaver[i], selectedFileWeaver[i].name);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", selectedFileWeaver[i].type);
            props.uploadImages1(
              res.data,
              selectedFileWeaver[i],
              myHeaders,
              (resSerever: any) => {
                softCopyArryWeaving.push({
                  statusCode: resSerever?.status,
                  url: resSerever?.url.split("?")[0],
                });
                resolve(softCopyArryWeaving);
              }
            );
          }
        );
      });
      promiseForWeaving.push(promise);
    }
    let t: any = [];
    t = await Promise.all(promiseForWeaving);
    return t[0];
  };

  // file upload method start reuseable function
  const uploadFiles = (data: any) => {
    let { fileObject, name, files } = data;
    if (files.length) {
      fileObject.push(Array.prototype.slice.call(files));
      fileObjImages = fileObject.flatMap((e: any) => e);
    }

    let uniqueArr = fileObjImages.filter((obj: any, index: any, self: any) => {
      return index === self.findIndex((t: any) => t.name === obj.name);
    });
    if (name === "designing") {
      uploadImageArray({
        name,
        uniqueArr,
        baseImage: baseImageDesigner,
      });
      setSelectedFileDesigner(uniqueArr);
      fileObjDesign = uniqueArr;
    } else if (name === "dying") {
      uploadImageArray({
        name,
        uniqueArr,
        baseImage: baseImageDying,
      });
      setSelectedFileDying(uniqueArr);
      fileObjDying = uniqueArr;
    } else if (name === "weaving") {
      setSelectedFileWeaver(uniqueArr);
      uploadImageArray({
        name,
        uniqueArr,
        baseImage: baseImageWeaver,
      });
      fileObjWeaving = uniqueArr;
    } else if (name === "finishing") {
      setSelectedFileFinisher(uniqueArr);
      uploadImageArray({
        name,
        uniqueArr,
        baseImage: baseImageFinisher,
      });
      fileObjFinishing = uniqueArr;
    } else if (name === "variant") {
      setSelectedFileVariant(uniqueArr);
      uploadImageArray({
        name,
        uniqueArr,
        baseImage: baseImageVariant,
      });
      fileObjVariant = uniqueArr;
    } else if (name === "logo") {
      setSelectedFile(uniqueArr);
      uploadImageArray({
        name,
        uniqueArr,
        baseImage: baseImageLogo,
      });
      fileObjLogo = uniqueArr;
    }
  };
  // base image reuseable function
  const uploadImageArray = async (data: any) => {
    let { name, uniqueArr, baseImage } = data;
    let base64Files: any = [];
    if (name === "logo") {
      if (baseImage?.length) {
        baseImage.map((ele: any) => {
          if (ele?.blobImage.startsWith("https")) {
            base64Files.push({
              type: ele?.type,
              blobImage: ele?.blobImage,
              show: ele?.show,
              pin: ele?.pin,
            });
          }
        });
      }
      for (let i = 0; i < uniqueArr.length; i++) {
        base64Files.push({
          type: uniqueArr[i]?.type,
          blobImage:
            uniqueArr[i]?.type === uniqueArr[i]?.type.includes("image")
              ? await convertBase64(uniqueArr[i])
              : await URL.createObjectURL(uniqueArr[i]),
          fileName: uniqueArr[i]?.name,
          show: true,
          pin: false,
        });
      }
    } else {
      if (baseImage?.length) {
        baseImage.map((ele: any) => {
          if (ele?.blobImage.startsWith("https")) {
            base64Files.push({
              type: ele?.type,
              blobImage: ele?.blobImage,
              show: ele?.show,
            });
          }
        });
      }
      for (let i = 0; i < uniqueArr.length; i++) {
        base64Files.push({
          type: uniqueArr[i]?.type,
          blobImage:
            uniqueArr[i]?.type === uniqueArr[i]?.type.includes("image")
              ? await convertBase64(uniqueArr[i])
              : await URL.createObjectURL(uniqueArr[i]),
          fileName: uniqueArr[i]?.name,
          show: true,
        });
      }
    }
    if (name === "designing") {
      setBaseImageDesigner([...base64Files]);
    } else if (name === "dying") {
      setBaseImageDying([...base64Files]);
    } else if (name === "weaving") {
      setBaseImageWeaver([...base64Files]);
    } else if (name === "finishing") {
      setBaseImageFinisher([...base64Files]);
    } else if (name === "variant") {
      setBaseImageVariant([...base64Files]);
    } else if (name === "logo") {
      setBaseImageLogo([...base64Files]);
    }
  };
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const addChip = (value: any) => {
    const chips = form.tags.slice();
    chips.push(value);
    setForm({
      ...form,
      tags: chips,
    });
  };
  const removeChip = (index: any) => {
    const chips = form.tags.slice();
    chips.splice(index, 1);
    setForm({
      ...form,
      tags: chips,
    });
  };
  const handleDeleteFinisher = (i: any, item: any) => {
    setDeleteIndexFinisher({
      i,
      item,
    });
  };
  const handleDeleteWeaver = (i: any, item: any) => {
    setDeleteIndexWeaver({
      i,
      item,
    });
  };
  const handleDeleteDying = (i: any, item: any) => {
    setDeleteIndexDying({
      i,
      item,
    });
  };
  const handleDeleteDesigner = (i: any, item: any) => {
    setDeleteIndexDesigner({
      i,
      item,
    });
  };
  const handleDeleteLogo = (i: any, item: any) => {
    setDeleteIndexLogo({
      i,
      item,
    });
  };
  const handleDeleteVariant = (i: any, item: any) => {
    setDeleteIndexVariant({
      i,
      item,
    });
  };
  const onSetPin = (type: any) => {
    setBaseIndexForLogo(type);
  };
  useEffect(() => {
    // if (baseindexForLogo?.length) {
    if (baseImageLogo?.length) {
      let arrayPin: any = [];
      let arrayPinSelected: any = [];
      let formProfileURL: any = [];
      if (selectedFile?.length > 0) {
        selectedFile.find((ele: any, i: any) => {
          if (i === baseindexForLogo?.index) {
            arrayPinSelected.push(ele);
          } else {
            arrayPinSelected.push(ele);
          }
          setSelectedFile([...arrayPinSelected]);
        });
      }
      baseImageLogo.find((ele: any, i: any) => {
        if (i === baseindexForLogo?.index) {
          arrayPin.push({
            type: ele?.type,
            blobImage: ele?.blobImage,
            fileName: ele?.fileName,
            show: ele?.show,
            pin: true,
          });
          formProfileURL.push({
            mediaUrl: ele?.blobImage,
            show: ele?.show === true ? 1 : 0,
            pin: 1,
          });
        } else {
          arrayPin.push({
            type: ele?.type,
            blobImage: ele?.blobImage,
            fileName: ele?.fileName,
            show: ele?.show,
            pin: false,
          });
          formProfileURL.push({
            mediaUrl: ele?.blobImage,
            show: ele?.show === true ? 1 : 0,
            pin: 0,
          });
        }

        setBaseImageLogo([...arrayPin]);
        setForm({
          ...form,
          profileURL: formProfileURL,
        });
      });
    }
    // }
  }, [baseindexForLogo]);
  const handleName = (value: any) => {
    if (value?.name === "artform") {
      props.getArtforms(
        {
          offset: 0,
          limit: 1,
          artformName: value?.artform,
        },
        (res: any) => {
          if (res?.data?.docs?.length) {
            setForm({
              ...form,
              type: "",
              category: "",
              subCategory: "",
              weaveType: "",
              artform: {
                label: res?.data?.docs[0]?.name,
                value: res?.data?.docs[0]?._id,
              },
            });
          }
        }
      );
    }
    if (value?.name === "type") {
      callingGetTypesData({
        artformId: value?.artform,
        type: value?.type,
      });
    }
    if (value?.name === "category") {
      callingGetCategoryData({
        typeId: value?.type,
        category: value?.category,
      });
    }
    if (value?.name === "subCategory") {
      callingGetSubCategory({
        categoryId: value?.category,
        subCategory: value?.subCategory,
      });
    }
    if (value?.name === "weaveType") {
      callingGetWeaveType({
        subCategoryId: value?.subCategory,
        weaveType: value?.weaveType,
      });
    }
    let flag = handleNameFlag;
    if (value.name === "designing") {
      getCreatorsData({
        userId: value?.userId,
        name: "designing",
      });
      flag = {
        ...flag,
        designing: true,
      };
    }
    if (value.name === "weaving") {
      getCreatorsData({
        userId: value?.userId,
        name: "weaving",
      });
      flag = {
        ...flag,
        weaving: true,
      };
    }
    if (value.name === "dying") {
      getCreatorsData({
        userId: value?.userId,
        name: "dying",
      });
      flag = {
        ...flag,
        dying: true,
      };
    }
    if (value.name === "finishing") {
      getCreatorsData({
        userId: value?.userId,
        name: "finishing",
      });
      flag = {
        ...flag,
        finishing: true,
      };
    }
    setHandleNameFlag(flag);
  };
  const handleOpenArtform = () => {
    setOpenModal({
      artform: true,
    });
  };
  const handleOpenType = () => {
    setOpenModal({
      type: true,
    });
  };
  const handleOpenCategory = () => {
    setOpenModal({
      category: true,
    });
  };
  const handleOpenSubCategory = () => {
    setOpenModal({
      subCategory: true,
    });
  };
  const handleOpenWeaveType = () => {
    setOpenModal({
      weaveType: true,
    });
  };
  const handleOpenVariant = () => {
    setOpenModal({
      variant: true,
    });
  };
  const handleOpenModal = (value: any) => {
    setOpenModal({
      [value?.name]: value?.value,
    });
  };
  const handleChangeDebounce = React.useCallback(
    debounce((data: any) => {
      if (data?.name === "product") {
        CallingGettProduct(data);
      }
      if (data?.name === "artform") {
        getArtformData(data?.value);
      }
      if (data?.name === "type") {
        getTypesData(data);
      }
      if (data?.name === "category") {
        getCategoryData(data);
      }
      if (data?.name === "subCategory") {
        getSubCategoryData(data);
      }
      if (data?.name === "weaveType") {
        getWeaveTypeData(data);
      }
      if (data?.name === "designing") {
        getCreatorsDataDesignerOption(data?.value);
      }
      if (data?.name === "weaving") {
        getCreatorsDataWeaverOption(data?.value);
      }
      if (data?.name === "dying") {
        getCreatorsDataDyingOption(data?.value);
      }
      if (data?.name === "finishing") {
        getCreatorsDataFinishingOption(data?.value);
      }
    }, 1000),
    []
  );
  const closeModal = () => {
    setOpenModal({
      artform: false,
      type: false,
      category: false,
      subCategory: false,
      weaveType: false,
      variant: false,
      designing: false,
      dying: false,
      weaving: false,
      finishing: false,
    });
    setIsOpen(false);
  };
  let allvariantStatusCode: any = [];
  const handleSubmitVariant = async (e: any) => {
    e.preventDefault();
    if (selectedFileVariant?.length > 0) {
      setLoaderVariant({
        ...loaderVariant,
        images: true,
      });
      const resultForVariants: any = await S3UploadVariants();
      resultForVariants.forEach((ele: any) => {
        promiseVariantStatusCode.push(ele?.statusCode);
        allvariantStatusCode.push(ele?.statusCode);
        if (ele?.statusCode === 200) {
          promiseVariantUrl.push(ele?.url);
        }
      });
      promiseVariantStatusCode.map((ele: any, i: any) => {
        if (ele === 200) {
        } else {
          notifications({
            title: "Error!",
            message: `Media ${i + 1
              } upload failed into server under finishing section !`,
            type: "danger",
          });
        }
      });
      setLoaderVariant({
        ...loaderVariant,
        images: false,
      });
    }
    if (allvariantStatusCode?.length > 0) {
      let codes = allvariantStatusCode.every((ele: any) => ele === 200);
      let payload: any = {
        productId: form?.id,
        color: variantForm?.variantCode,
        additionalImages: promiseVariantUrl ? promiseVariantUrl : [],
      };
      if (codes) {
        setLoaderVariant({
          ...loaderVariant,
          submit: true,
        });
        props.createVariantForProduct(payload, (res: any) => {
          setLoaderVariant({
            ...loaderVariant,
            submit: false,
          });
          if (res.success) {
            notifications({
              title: "Success!",
              message: res?.message,
              type: "success",
            });
            closeModal();
            history.push(`/view-product/${ProductId}`);
          } else {
            notifications({
              title: "Error!",
              message: res?.message,
              type: "danger",
            });
          }
          promiseVariantUrl = [];
          setBaseImageVariant("");
          setSelectedFileVariant("");
          promiseVariantStatusCode = [];
          allvariantStatusCode = [];
          setVariantForm({
            images: "",
            variantCode: "",
          });
          fileObjVariant = [];
          promiseForVariant = [];
        });
      }
    }
  };
  const onSetIndexDesign = (type: any) => {
    if (type?.name === "designing") {
      setBaseIndex({
        designing: type,
      });
    }
    if (type?.name === "weaving") {
      setBaseIndex({
        weaving: type,
      });
    }
    if (type?.name === "dying") {
      setBaseIndex({
        dying: type,
      });
    }
    if (type?.name === "finishing") {
      setBaseIndex({
        finishing: type,
      });
    }
    if (type?.name === "logo") {
      setBaseIndex({
        logo: type,
      });
    }
  };
  useEffect(() => {
    if (baseindex?.finishing) {
      if (baseindex?.finishing?.index <= form?.finisherMedia?.length - 1) {
        let media = {
          mediaUrl: form?.finisherMedia[baseindex?.finishing?.index]?.mediaUrl,
          show: baseindex?.finishing?.show === true ? 1 : 0,
        };
        form?.finisherMedia &&
          form?.finisherMedia.find((ele: any, i: any) => {
            if (i === baseindex?.finishing?.index) {
              let showmedia = [...form?.finisherMedia];
              showmedia[i] = media;
              setForm({
                ...form,
                finisherMedia: [...showmedia],
              });
            }
          });
      }
      if (baseImageFinisher?.length) {
        let showImageData = {
          type: baseImageFinisher[baseindex?.finishing?.index]?.type,
          blobImage: baseImageFinisher[baseindex?.finishing?.index]?.blobImage,
          fileName: baseImageFinisher[baseindex?.finishing?.index]?.fileName,
          show: baseindex?.finishing?.show,
        };
        baseImageFinisher.find((ele: any, i: any) => {
          if (i === baseindex?.finishing?.index) {
            let showm = [...baseImageFinisher];
            showm[i] = showImageData;
            setBaseImageFinisher([...showm]);
          }
        });
      }
    }
    if (baseindex?.dying) {
      if (baseindex?.dying?.index <= form?.dyerMedia?.length - 1) {
        let media = {
          mediaUrl: form?.dyerMedia[baseindex?.dying?.index]?.mediaUrl,
          show: baseindex?.dying?.show === true ? 1 : 0,
        };
        form?.dyerMedia &&
          form?.dyerMedia.find((ele: any, i: any) => {
            if (i === baseindex?.dying?.index) {
              let showmedia = [...form?.dyerMedia];
              showmedia[i] = media;
              setForm({
                ...form,
                dyerMedia: [...showmedia],
              });
            }
          });
      }
      if (baseImageDying?.length) {
        let showImageData = {
          type: baseImageDying[baseindex?.dying?.index]?.type,
          blobImage: baseImageDying[baseindex?.dying?.index]?.blobImage,
          fileName: baseImageDying[baseindex?.dying?.index]?.fileName,
          show: baseindex?.dying?.show,
        };
        baseImageDying.find((ele: any, i: any) => {
          if (i === baseindex?.dying?.index) {
            let showm = [...baseImageDying];
            showm[i] = showImageData;
            setBaseImageDying([...showm]);
          }
        });
      }
    }
    if (baseindex?.weaving) {
      if (baseindex?.weaving?.index <= form?.weaverMedia?.length - 1) {
        let media = {
          mediaUrl: form.weaverMedia[baseindex?.weaving?.index]?.mediaUrl,
          show: baseindex?.weaving?.show === true ? 1 : 0,
        };
        form.weaverMedia &&
          form.weaverMedia.find((ele: any, i: any) => {
            if (i === baseindex?.weaving?.index) {
              let showmedia = [...form.weaverMedia];
              showmedia[i] = media;
              setForm({
                ...form,
                weaverMedia: [...showmedia],
              });
            }
          });
      }
      if (baseImageWeaver?.length) {
        let showImageData = {
          type: baseImageWeaver[baseindex?.weaving?.index]?.type,
          blobImage: baseImageWeaver[baseindex?.weaving?.index]?.blobImage,
          fileName: baseImageWeaver[baseindex?.weaving?.index]?.fileName,
          show: baseindex?.weaving?.show,
        };
        baseImageWeaver.find((ele: any, i: any) => {
          if (i === baseindex?.weaving?.index) {
            let showm = [...baseImageWeaver];
            showm[i] = showImageData;
            setBaseImageWeaver([...showm]);
          }
        });
      }
    }
    if (baseindex?.designing) {
      if (form?.designerMedia?.length > 0) {
        if (baseindex?.designing?.index <= form?.designerMedia?.length - 1) {
          let media = {
            mediaUrl: form.designerMedia[baseindex?.designing?.index]?.mediaUrl,
            show: baseindex?.designing?.show === true ? 1 : 0,
          };
          form.designerMedia &&
            form.designerMedia.find((ele: any, i: any) => {
              if (i === baseindex?.designing?.index) {
                let showmedia = [...form.designerMedia];
                showmedia[i] = media;
                setForm({
                  ...form,
                  designerMedia: [...showmedia],
                });
              }
            });
        }
      }
      if (baseImageDesigner?.length) {
        let showImageData = {
          // ...baseImageDesigner,
          type: baseImageDesigner[baseindex?.designing?.index]?.type,
          blobImage: baseImageDesigner[baseindex?.designing?.index]?.blobImage,
          fileName: baseImageDesigner[baseindex?.designing?.index]?.fileName,
          show: baseindex?.designing?.show,
        };
        baseImageDesigner.find((ele: any, i: any) => {
          if (i === baseindex?.designing?.index) {
            let showm = [...baseImageDesigner];
            showm[i] = showImageData;
            setBaseImageDesigner([...showm]);
          }
        });
      }
    }
    if (baseindex?.logo) {
      if (form?.profileURL?.length > 0) {
        if (baseindex?.logo?.index <= form?.profileURL?.length - 1) {
          let media = {
            mediaUrl: form.profileURL[baseindex?.logo?.index]?.mediaUrl,
            show: baseindex?.logo?.show === true ? 1 : 0,
            pin: baseindex?.logo?.pin === true ? 1 : 0,
          };
          form.profileURL &&
            form.profileURL.find((ele: any, i: any) => {
              if (i === baseindex?.logo?.index) {
                let showmedia = [...form.profileURL];
                showmedia[i] = media;
                setForm({
                  ...form,
                  profileURL: [...showmedia],
                });
              }
            });
        }
      }
      if (baseImageLogo?.length) {
        let showImageData = {
          // ...baseImageDesigner,
          type: baseImageLogo[baseindex?.logo?.index]?.type,
          blobImage: baseImageLogo[baseindex?.logo?.index]?.blobImage,
          fileName: baseImageLogo[baseindex?.logo?.index]?.fileName,
          show: baseindex?.logo?.show,
          pin: baseindex?.logo?.pin,
        };
        baseImageLogo.find((ele: any, i: any) => {
          if (i === baseindex?.logo?.index) {
            let showm = [...baseImageLogo];
            showm[i] = showImageData;
            setBaseImageLogo([...showm]);
          }
        });
      }
    }
  }, [baseindex]);
  const onChangeVarient = (e: any) => {
    setVariantForm({
      ...variantForm,
      variantCode: e.target.value,
    });
  };
  const handleOnInputChangeFilters = (type: any) => {
    if (type?.name === "designing") {
      if (type?.value?.length >= 2 || type?.value?.length == 0) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "weaving") {
      if (type?.value?.length >= 2 || type?.value?.length == 0) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "dying") {
      if (type?.value?.length >= 2 || type?.value?.length == 0) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "finishing") {
      if (type?.value?.length >= 2 || type?.value?.length == 0) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "artform") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
      if (
        type?.value.length === 0 &&
        !form?.artform?.value &&
        !form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "type") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.artform?.value &&
        !form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
        });
      }
    }
    if (type?.name === "category") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.type?.value &&
        !form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
        });
      }
    }
    if (type?.name === "subCategory") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
          category: form?.category?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.category?.value &&
        !form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
          category: form?.category?.value,
        });
      }
    }
    if (type?.name === "weaveType") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
          category: form?.category?.value,
          subCategory: form?.subCategory?.value,
        });
      } else if (
        type?.value.length === 0 &&
        form?.subCategory?.value &&
        !form?.weaveType?.value &&
        !form?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
          type: form?.type?.value,
          category: form?.category?.value,
          subCategory: form?.subCategory?.value,
        });
      }
    }
  };
  const handleReset = (type: any) => {
    if (type?.name === "designing") {
      setForm({
        ...form,
        designer: "",
      });
    }
    if (type?.name === "weaving") {
      setForm({
        ...form,
        weaver: "",
      });
    }
    if (type?.name === "dying") {
      setForm({
        ...form,
        dyer: "",
      });
    }
    if (type?.name === "finishing") {
      setForm({
        ...form,
        finisher: "",
      });
    }
  };
  return (
    <>
      {isOpen && (
        <ProductImagePreView
          {...props}
          openModal={isOpen}
          closeModal={closeModal}
          images={[image]}
          name="editProduct"
        />
      )}
      <CreateUserModal
        {...props}
        openModal={openModal?.designing}
        handleOpenModal={handleOpenModal}
        closeModal={closeModal}
        handleName={handleName}
        name="Product"
        modalName={"designing"}
      />
      <CreateUserModal
        {...props}
        openModal={openModal?.weaving}
        handleOpenModal={handleOpenModal}
        closeModal={closeModal}
        handleName={handleName}
        name="Product"
        modalName={"weaving"}
      />
      <CreateUserModal
        {...props}
        openModal={openModal?.dying}
        handleOpenModal={handleOpenModal}
        closeModal={closeModal}
        handleName={handleName}
        name="Product"
        modalName={"dying"}
      />
      <CreateUserModal
        {...props}
        openModal={openModal?.finishing}
        handleOpenModal={handleOpenModal}
        closeModal={closeModal}
        handleName={handleName}
        name="Product"
        modalName={"finishing"}
      />
      <Modal
        isOpen={openModal?.variant}
        toggle={closeModal}
        size="lg"
        fade={false}
        className="OrganizationModal"
      >
        <ModalHeader>
          <div className="Heading">
            <h3>Create Product Variant</h3>
            <ModalCloseIcon {...props} closeModal={closeModal} />
          </div>
        </ModalHeader>
        <ModalBody>
          {loaderVariant?.submit ? (
            <div className="LoaderDivModal">
              <Loader />
            </div>
          ) : (
            <CreateVariant
              {...props}
              variantForm={variantForm}
              baseImageVariant={baseImageVariant}
              fileInputVariant={fileInputVariant}
              fileArrayVariant={fileArrayVariant}
              fileObjVariant={fileObjVariant}
              uploadFiles={uploadFiles}
              fileUploadTriggerVariant={fileUploadTriggerVariant}
              handleDeleteVariant={handleDeleteVariant}
              handleSubmitVariant={handleSubmitVariant}
              onChangeVarient={onChangeVarient}
              Error={ErrorForVariant}
              validateForVariant={validateForVariant}
              loader={loaderVariant?.images}
              loading={loaderVariant?.submit}
            />
          )}
        </ModalBody>
      </Modal>
      <ArtformModal
        {...props}
        openModal={openModal?.artform}
        closeModal={closeModal}
        getAllData={getArtformData}
        handleName={handleName}
      />
      <TypeModal
        {...props}
        openModal={openModal?.type}
        closeModal={closeModal}
        getAllData={getTypesData}
        handleName={handleName}
        form={form}
      />
      <CategoryModal
        {...props}
        openModal={openModal?.category}
        closeModal={closeModal}
        getAllData={getCategoryData}
        handleName={handleName}
        form={form}
      />
      <SubCategoryModal
        {...props}
        openModal={openModal?.subCategory}
        closeModal={closeModal}
        getAllData={getSubCategoryData}
        handleName={handleName}
        form={form}
      />
      <WeaveTypeModal
        {...props}
        openModal={openModal?.weaveType}
        closeModal={closeModal}
        getAllData={getWeaveTypeData}
        handleName={handleName}
        form={form}
      />
      {loading ? (
        <div className="LoaderDiv">
          <Loader />
        </div>
      ) : (
        <div className="createProduct CreateProductDetails">
          <div className="form">
            <div className="UserDetailsDiv">
              <div className="UserHeader">Product Details</div>
              <div className="userPd">
                <Label>Upload Files</Label>
                <span className="ms-2">
                  Photo (jpg, jpeg, png) / Video (mp4 , ogg, webm) / PDF
                </span>
                <div className="">
                  {baseImageLogo?.length > 0 ? (
                    <>
                      <div className="imageDiv1">
                        <span className="pl-2 pr-2 ">
                          <div className="divAddIcon">
                            <input
                              type="file"
                              name="Image"
                              multiple={true}
                              accept=".png,.jpg,.jpeg,.heic,.mp4,.webm,.ogg,.bmp"
                              style={{ display: "none" }}
                              ref={fileInputProfile}
                              onChange={(e) => {
                                uploadFiles({
                                  fileArr: fileArrayLogo,
                                  fileObject: fileObjLogo,
                                  name: "logo",
                                  files: e.target.files,
                                });
                              }}
                            />
                            <ImageAddIcon
                              {...props}
                              fileUploadTrigger={fileUploadTrigger}
                            />
                          </div>

                          <div className="d-flex">
                            {baseImageLogo.length > 0 ? (
                              <div className="PreviewDivPart">
                                {baseImageLogo.map((item: any, i: any) => {
                                  return (
                                    <React.Fragment key={i}>
                                      {loader ? (
                                        <div className="CircleLoder">
                                          <CircleLoader />
                                        </div>
                                      ) : (
                                        <div className="ArryyIcon">
                                          <ImageClose
                                            {...props}
                                            i={i}
                                            item={item}
                                            close={handleDeleteLogo}
                                          />
                                          <DownloadIcon
                                            {...props}
                                            i={i}
                                            item={item}
                                            name={form?.productName + " logo"}
                                          />
                                          {item?.pin === false ? (
                                            <Pin
                                              onClick={onSetPin}
                                              index={i}
                                              name="view"
                                            />
                                          ) : (
                                            <Unpin
                                              onClick={onSetPin}
                                              index={i}
                                              name="view"
                                            />
                                          )}

                                          {item?.show === true ? (
                                            <ShowIcon
                                              onClick={onSetIndexDesign}
                                              index={i}
                                              name="logo"
                                              pin={item?.pin}
                                            />
                                          ) : (
                                            <HideIcon
                                              onClick={onSetIndexDesign}
                                              index={i}
                                              name="logo"
                                              pin={item?.pin}
                                            />
                                          )}
                                          {/* <div className="viewIconForPreview">
                                            <ViewEyeIcon
                                              {...props}
                                              onClick={() => {
                                                handleOpen();
                                                setImage(item);
                                              }}
                                            />
                                          </div> */}
                                          <FilePreviewPart
                                            {...props}
                                            item={item}
                                            handleOpen={handleOpen}
                                            handleSetImage={handleSetImage}
                                          />
                                        </div>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <ImageDivPart
                        {...props}
                        fileUploadTrigger={fileUploadTrigger}
                        fileInput={fileInputProfile}
                        fileUpload={uploadFiles}
                        name="logo"
                        fileArr={fileArrayLogo}
                        fileObject={fileObjLogo}
                        content="Click to upload product images"
                      />
                    </>
                  )}
                </div>
                {/* <div className="d-flex justify-content-center">
                  <div className="imageDiv">
                    <div className="editPen">
                      <svg
                        onClick={() => fileUploadTrigger()}
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="26" height="26" rx="4" fill="#262D22" />
                        <path
                          d="M16.3473 6.6267C16.546 6.42801 16.7819 6.2704 17.0415 6.16287C17.3011 6.05534 17.5793 6 17.8603 6C18.1413 6 18.4195 6.05534 18.6791 6.16287C18.9387 6.2704 19.1746 6.42801 19.3733 6.6267C19.572 6.82538 19.7296 7.06126 19.8371 7.32086C19.9447 7.58045 20 7.85869 20 8.13968C20 8.42066 19.9447 8.6989 19.8371 8.95849C19.7296 9.21809 19.572 9.45397 19.3733 9.65266L9.16069 19.8653L5 21L6.13473 16.8393L16.3473 6.6267Z"
                          stroke="#C69C6D"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <input
                        type="file"
                        name="Image"
                        multiple={true}
                        accept=".png,.jpg,.jpeg,.heic"
                        style={{ display: "none" }}
                        ref={fileInputProfile}
                        onChange={(e) => {
                          fileUpload({
                            target: {
                              name: "profileURL",
                              files: e.target.files,
                            },
                          });
                        }}
                      />
                    </div>

                    {form?.profileURL?.length || baseImageLogo ? (
                      <>
                        {mainImageLoader ? (
                          <CircleLoader />
                        ) : (
                          <>
                            <img
                              src={
                                baseImageLogo ? baseImageLogo : form?.profileURL
                              }
                              alt="logo"
                              width="200px"
                              height="200px"
                            />
                            <DownloadIcon
                              {...props}
                              // i={i}
                              image={form?.profileURL}
                              name={form?.productName + " productlogo"}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <div>No Preview Available</div>
                    )}
                  </div>
                </div> */}
                <div className="d-flex justify-content-center">
                  {productMainImageStatusCode?.length > 0 &&
                    productMainImageStatusCode[0] !== 200 ? (
                    <p className="RequiredField">
                      Image upload failed into server!
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>
                        HKPtagNo<span>*</span>
                      </Label>
                      <Input
                        name="SrNo"
                        id="SrNo"
                        className="form-control"
                        placeholder="SrNo"
                        value={form?.SrNo}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "SrNo",
                            value: e.target.value,
                          });
                        }}
                      />
                      {Error.SrNo && !form?.SrNo ? (
                        <p className="RequiredField">{Error?.SrNo} </p>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>
                        Artform<span>*</span>
                      </Label>
                      <div className="formDivSub">
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isSearchable
                          name="artform"
                          id="artform"
                          value={form?.artform}
                          options={artformOptions}
                          // components={{ MenuList: SelectMenuButton }}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "artform",
                              value: e,
                            });
                          }}
                          onInputChange={(e) =>
                            handleOnInputChangeFilters({
                              name: "artform",
                              value: e,
                            })
                          }
                        />
                        <div className="DivSep"></div>
                        <div className="formAddDiv">
                          <AddIcon openModal={handleOpenArtform} />
                        </div>
                      </div>
                      {Error.artform && !form?.artform ? (
                        <p className="RequiredField">{Error?.artform} </p>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>
                        Type<span>*</span>
                      </Label>
                      <div className="formDivSub">
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isSearchable
                          name="type"
                          id="type"
                          value={form?.type}
                          // components={
                          //   form?.artform?.value && {
                          //     MenuList: SelectMenuButtonForType,
                          //   }
                          // }
                          options={
                            form?.artform?.value
                              ? typeOptions
                              : [
                                {
                                  label: (
                                    <p className="req">
                                      ***Please select Artform***
                                    </p>
                                  ),
                                  value: "",
                                },
                              ]
                          }
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "type",
                              value: e,
                            });
                          }}
                          onInputChange={(e) =>
                            handleOnInputChangeFilters({
                              name: "type",
                              value: e,
                            })
                          }
                        />
                        <div className="DivSep"></div>
                        <div className="formAddDiv">
                          <AddIcon openModal={handleOpenType} />
                        </div>
                      </div>
                      {Error.type && !form?.type ? (
                        <p className="RequiredField">{Error?.type} </p>
                      ) : (
                        ""
                      )}
                      {/* {filterValue?.type &&
                      new Map(
                        typeOptions.map((obj: any) => {
                          return [obj.value, obj.labe];
                        })
                      ).has(filterValue?.type) == false ? (
                        <p className="RequiredField">
                          Type is not a part of selected artform
                        </p>
                      ) : (
                        ""
                      )} */}
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>
                        Category<span>*</span>
                      </Label>
                      <div className="formDivSub">
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isSearchable
                          name="Category"
                          id="Category"
                          value={form?.category}
                          // components={
                          //   form?.type?.value && {
                          //     MenuList: SelectMenuButtonForCategory,
                          //   }
                          // }
                          options={
                            form?.type?.value
                              ? categoryOptions
                              : [
                                {
                                  label: (
                                    <p className="req">
                                      ***Please select type***
                                    </p>
                                  ),
                                  value: "",
                                },
                              ]
                          }
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "category",
                              value: e,
                            });
                          }}
                          onInputChange={(e) =>
                            handleOnInputChangeFilters({
                              name: "category",
                              value: e,
                            })
                          }
                        />
                        <div className="DivSep"></div>
                        <div className="formAddDiv">
                          <AddIcon openModal={handleOpenCategory} />
                        </div>
                      </div>
                      {Error.category && !form?.category ? (
                        <p className="RequiredField">{Error?.category} </p>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>
                        Sub-Category<span>*</span>
                      </Label>
                      <div className="formDivSub">
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isSearchable
                          name="subCategory"
                          id="subCategory"
                          value={form?.subCategory}
                          // components={
                          //   form?.category?.value && {
                          //     MenuList: SelectMenuButtonForSubCategory,
                          //   }
                          // }
                          options={
                            form?.category?.value
                              ? subCategoryOptions
                              : [
                                {
                                  label: (
                                    <p className="req">
                                      ***Please select category***
                                    </p>
                                  ),
                                  value: "",
                                },
                              ]
                          }
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "subCategory",
                              value: e,
                            });
                          }}
                          onInputChange={(e) =>
                            handleOnInputChangeFilters({
                              name: "subCategory",
                              value: e,
                            })
                          }
                        />
                        <div className="DivSep"></div>
                        <div className="formAddDiv">
                          <AddIcon openModal={handleOpenSubCategory} />
                        </div>
                      </div>
                      {Error.subCategory && !form?.subCategory ? (
                        <p className="RequiredField">{Error?.subCategory} </p>
                      ) : (
                        ""
                      )}
                      {/* {filterValue?.subCategory &&
                      new Map(
                        subCategoryOptions.map((obj: any) => {
                          return [obj.value, obj.labe];
                        })
                      ).has(filterValue?.subCategory) == false ? (
                        <p className="RequiredField">
                          Sub-Category is not a part of selected category
                        </p>
                      ) : (
                        ""
                      )} */}
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>
                        Weave Type<span>*</span>
                      </Label>
                      <div className="formDivSub">
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isSearchable
                          name="weaveType"
                          id="weaveType"
                          value={form?.weaveType}
                          // components={
                          //   form?.subCategory?.value && {
                          //     MenuList: SelectMenuButtonForSubWeaveType,
                          //   }
                          // }
                          options={
                            form?.subCategory?.value
                              ? weaveTypeOptions
                              : [
                                {
                                  label: (
                                    <p className="req">
                                      ***Please select sub-category***
                                    </p>
                                  ),
                                  value: "",
                                },
                              ]
                          }
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "weaveType",
                              value: e,
                            });
                          }}
                          onInputChange={(e) =>
                            handleOnInputChangeFilters({
                              name: "weaveType",
                              value: e,
                            })
                          }
                        />
                        <div className="formAddDiv">
                          <AddIcon openModal={handleOpenWeaveType} />
                        </div>
                      </div>
                      {Error.weaveType && !form?.weaveType ? (
                        <p className="RequiredField">{Error?.weaveType} </p>
                      ) : (
                        ""
                      )}
                      {/* {filterValue?.weaveType &&
                      new Map(
                        weaveTypeOptions.map((obj: any) => {
                          return [obj.value, obj.labe];
                        })
                      ).has(filterValue?.weaveType) == false ? (
                        <p className="RequiredField">
                          Weave type name is not a part of selected sub-category
                        </p>
                      ) : (
                        ""
                      )} */}
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Types of sarees by design</Label>
                      <div className="formDivSub">
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          // isSearchable
                          name="typeOfSareeByDesign"
                          value={form?.typeOfSareeByDesign}
                          options={typeOfSareeByDesignOptions}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "typeOfSareeByDesign",
                              value: e,
                            });
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>
                        Product<span>*</span>
                      </Label>
                      <Input
                        name="productName"
                        id="productName"
                        className="form-control"
                        placeholder="Type Name"
                        value={form?.productName}
                        // disabled={
                        //   form?.typeOfSareeByDesign?.value?.length > 0
                        //     ? true
                        //     : false
                        // }
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "productName",
                            value: e.target.value,
                          });
                          handleChangeDebounce({
                            name: "product",
                            artform: form?.artform?.value,
                            type: form?.type?.value,
                            category: form?.category?.value,
                            subCategory: form?.subCategory?.value,
                            weaveType: form?.weaveType?.value,
                            productName: e.target.value,
                          });
                        }}
                      />
                      {Error.product && !form?.productName ? (
                        <p className="RequiredField">{Error?.product} </p>
                      ) : (
                        ""
                      )}
                      {form?.productName &&
                        form?.productName === filterProduct ? (
                        // filterValue?.product === ""
                        <p className="RequiredField">
                          Product name is already exist's
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>
                        Color of Product<span>*</span>
                      </Label>
                      <Input
                        name="variantColor"
                        id="variantColor"
                        className="form-control"
                        placeholder="Color of Product"
                        value={form?.variantCode}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "variantCode",
                            value: e.target.value,
                          });
                        }}
                      />
                      {Error.variantCode && !form?.variantCode ? (
                        <p className="RequiredField">{Error?.variantCode} </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Weaving Center</Label>
                      <Input
                        name="weavingCenter"
                        className="form-control"
                        placeholder="Weaving Center"
                        value={form?.weavingCenter}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "weavingCenter",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Add Tags</Label>
                      <ReactChipInput
                        {...props}
                        classes="class1 class2"
                        chips={form.tags ? form?.tags : []}
                        onSubmit={(value: any) => addChip(value)}
                        onRemove={(index: any) => removeChip(index)}
                      />
                    </Col>

                    <Col xs={12} md={4}>
                      <Label>Name of Brand</Label>
                      <Input
                        name="nameOfBrand"
                        id="nameOfBrand"
                        className="form-control"
                        placeholder="Name Of Brand"
                        value={form?.nameOfBrand}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "nameOfBrand",
                            value: e.target.value,
                          });
                        }}
                      />
                      {Error.nameOfBrand && !form?.nameOfBrand ? (
                        <p className="RequiredField">{Error?.nameOfBrand} </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Name of weaver</Label>
                      <Input
                        name="nameOfWeaver"
                        id="nameOfWeaver"
                        className="form-control"
                        placeholder="Name Of Weaver"
                        value={form?.nameOfWeaver}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "nameOfWeaver",
                            value: e.target.value,
                          });
                        }}
                      />
                      {Error.nameOfWeaver && !form?.nameOfWeaver ? (
                        <p className="RequiredField">{Error?.nameOfWeaver} </p>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Zari Used</Label>
                      <div className="formDivSub">
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          autoFocus={false}
                          isSearchable
                          name="zariUsed"
                          id="zariUsed"
                          value={
                            form?.zariUsed?.label === "None"
                              ? ""
                              : form?.zariUsed?.label?.length
                                ? form?.zariUsed
                                : ""
                          }
                          options={zariUsedOptions}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "zariUsed",
                              value: e,
                            });
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Weight of Saree(in Grams)</Label>
                      <Input
                        name="weightOfSaree"
                        id="weightOfSaree"
                        className="form-control"
                        placeholder="Weight Of Saree"
                        value={form?.weightOfSaree}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "weightOfSaree",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Weight of Zari used(in Grams)</Label>
                      <Input
                        type="text"
                        name="weightOfZariUsed"
                        id="weightOfZariUsed"
                        className="form-control"
                        placeholder="Weight of Zari used"
                        value={form?.weightOfZariUsed}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "weightOfZariUsed",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Purity of Zari</Label>
                      <Input
                        type="textarea"
                        name="purityOfZari"
                        id="purityOfZari"
                        className="form-control"
                        placeholder="Purity Of Zari"
                        defaultValue={form?.zariUsed?.value}
                      />
                    </Col>

                    <Col xs={12} md={4}>
                      <Label>Yarn type </Label>
                      <Select
                        className="React_selector text-white"
                        classNamePrefix="react-select"
                        placeholder="Select"
                        isSearchable
                        name="yarnType"
                        value={form?.yarnType}
                        options={yarnUsedOptions}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "yarnType",
                            value: e,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Working Hours </Label>
                      <Input
                        type="text"
                        name="workingHours"
                        id="workingHours"
                        className="form-control"
                        placeholder="Working Hours"
                        value={form?.workingHours}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "workingHours",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Loom Number </Label>
                      <Input
                        type="text"
                        name="loomNumber"
                        className="form-control"
                        placeholder="Loom Number"
                        value={form?.loomNum}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "loomNum",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>HKP TagID </Label>
                      <Input
                        style={{
                          textTransform: "none",
                        }}
                        type="text"
                        name="HKP TagID"
                        className="form-control"
                        placeholder="HKP TagID"
                        value={form?.xuid}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "xuid",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={6} md={4} lg={4}>
                      <Label>Silk Mark Number</Label>
                      <Input
                        type="text"
                        name="silkMark"
                        className="form-control"
                        placeholder="Slik mark number"
                        value={form?.silkMark}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "silkMark",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={6} md={4} lg={4}>
                      <Label>Handloom Mark Number</Label>
                      <Input
                        type="text"
                        name="handloomMark"
                        className="form-control"
                        placeholder="Handloom mark number"
                        value={form?.handloomMark}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "handloomMark",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                      <Label>Made in India</Label>
                      <FormGroup>
                        <Toggle
                          size="lg"
                          // defaultChecked={true}
                          checked={form?.madeInIndia}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          onChange={(checked: boolean, event) => {
                            handleOnInputChange({
                              name: "madeInIndia",
                              value: checked,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label></Label>

                      <Button
                        className="variantBtn"
                        onClick={() => handleOpenVariant()}
                      >
                        Create Variant
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="UserDetailsDiv mb-5">
              {selectTab1 === "designing" ||
                (selectTab1 !== "dying" &&
                  selectTab1 !== "weaving" &&
                  selectTab1 !== "finishing" &&
                  selectTab1 !== "designing") ? (
                <>
                  {" "}
                  <div className="UserHeader headerDisplay">
                    <div>Designing</div>
                  </div>
                  <div className="userPd">
                    <div className="formDiv">
                      <Row>
                        <Col xs={10} md={6} lg={4}>
                          <Label>Artisan</Label>
                          <div className="formDivSub">
                            <Select
                              className="React_selector text-white"
                              classNamePrefix="react-select"
                              placeholder="Select"
                              isSearchable
                              name="userRole"
                              id="userRole"
                              value={form?.designer}
                              options={designerOptions}
                              onChange={(e: any) => {
                                handleOnInputChange({
                                  name: "designer",
                                  value: e,
                                });
                              }}
                              onInputChange={(e) =>
                                handleOnInputChangeFilters({
                                  name: "designing",
                                  value: e,
                                })
                              }
                            />
                            <div className="DivSep"></div>
                            <div className="formAddDiv">
                              <AddIcon
                                openModal={handleOpenModal}
                                name="designing"
                                value={true}
                              />
                            </div>
                          </div>
                          {/* {handleNameFlag?.designing &&
                          form?.designer?.label?.length &&
                          form?.designer?.value?.roleId !==
                            "63c64da18667bd64a57c62fe" ? (
                            <p className="RequiredField">
                              Selected User is not a part of artisan
                            </p>
                          ) : (
                            ""
                          )} */}
                        </Col>
                        <Col xs={2} md={3} lg={2}>
                          <Button
                            className="resetForProduct"
                            onClick={() => {
                              handleReset({
                                name: "designing",
                              });
                            }}
                          >
                            <span className="DesktopReset">Reset</span>
                            <span className="mobileReset">
                              <ResetIcon />
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <Label>Upload Files</Label>
                    <span className="ms-2">
                      Photo (jpg, jpeg, png) / Video (mp4 , ogg, webm) / PDF
                    </span>
                    <div className="">
                      <div
                        className="imageDiv1"
                        style={{
                          height:
                            baseImageDesigner?.length == 0 ? "250px" : "auto",
                        }}
                      >
                        {baseImageDesigner?.length > 0 ? (
                          <>
                            <span className="pl-2 pr-2 ">
                              <div className="divAddIcon">
                                <input
                                  type="file"
                                  name="Image"
                                  multiple={true}
                                  accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,.mp4,.webm,.ogg,.heic,.bmp"
                                  style={{ display: "none" }}
                                  ref={fileInputDesigner}
                                  onChange={(e) => {
                                    uploadFiles({
                                      fileArr: fileArray,
                                      fileObject: fileObjDesign,
                                      name: "designing",
                                      files: e.target.files,
                                    });
                                  }}
                                />
                                <ImageAddIcon
                                  {...props}
                                  fileUploadTrigger={fileUploadTriggerDesigner}
                                />
                              </div>
                              <div className="d-flex">
                                {baseImageDesigner?.length > 0 ? (
                                  <>
                                    <div className="PreviewDivPart">
                                      {baseImageDesigner.map(
                                        (item: any, i: any) => {
                                          return (
                                            <React.Fragment key={i}>
                                              {loader ? (
                                                <div className="CircleLoder">
                                                  <CircleLoader />
                                                </div>
                                              ) : (
                                                <div className="ArryyIcon">
                                                  <ImageClose
                                                    {...props}
                                                    i={i}
                                                    item={item}
                                                    close={handleDeleteDesigner}
                                                  />
                                                  <DownloadIcon
                                                    {...props}
                                                    i={i}
                                                    item={item}
                                                    name={
                                                      form?.productName +
                                                      " designing"
                                                    }
                                                  />

                                                  {item?.show === true ? (
                                                    <ShowIcon
                                                      onClick={onSetIndexDesign}
                                                      index={i}
                                                      name="designing"
                                                    />
                                                  ) : (
                                                    <HideIcon
                                                      onClick={onSetIndexDesign}
                                                      index={i}
                                                      name="designing"
                                                    />
                                                  )}
                                                  {/* <div className="viewIconForPreview">
                                                    <ViewEyeIcon
                                                      {...props}
                                                      onClick={() => {
                                                        handleOpen();
                                                        setImage(item);
                                                      }}
                                                    />
                                                  </div> */}
                                                  <FilePreviewPart
                                                    {...props}
                                                    item={item}
                                                    handleOpen={handleOpen}
                                                    handleSetImage={
                                                      handleSetImage
                                                    }
                                                  />
                                                </div>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </span>
                          </>
                        ) : (
                          <ImageDivPart
                            {...props}
                            fileUploadTrigger={fileUploadTriggerDesigner}
                            fileInput={fileInputDesigner}
                            fileUpload={uploadFiles}
                            name="designing"
                            fileArr={fileArray}
                            fileObject={fileObjDesign}
                            content="Click to upload designing images or files"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="dbtn">
                    <Button className="Previous" disabled={true}>
                      Previous
                    </Button>
                    <Button
                      className="Next"
                      onClick={() => {
                        setSelectTab1("dying");
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : (
                ""
              )}
              {selectTab1 === "dying" ? (
                <>
                  {" "}
                  <div className="UserHeader headerDisplay">
                    <div>Dying</div>
                  </div>
                  <div className="userPd">
                    <div className="formDiv">
                      <Row>
                        <Col xs={10} md={6} lg={4}>
                          <Label>Karigar</Label>
                          <div className="formDivSub">
                            <Select
                              className="React_selector text-white"
                              classNamePrefix="react-select"
                              placeholder="Select"
                              isSearchable
                              name="dyer"
                              id="dyer"
                              value={form?.dyer}
                              options={dyerOptions}
                              onChange={(e: any) => {
                                handleOnInputChange({
                                  name: "dyer",
                                  value: e,
                                });
                              }}
                              onInputChange={(e) =>
                                handleOnInputChangeFilters({
                                  name: "dying",
                                  value: e,
                                })
                              }
                            />
                            <div className="DivSep"></div>
                            <div className="formAddDiv">
                              <AddIcon
                                openModal={handleOpenModal}
                                name="dying"
                                value={true}
                              />
                            </div>
                          </div>
                          {/* {handleNameFlag?.dying &&
                          form?.dyer?.label?.length &&
                          form?.dyer?.value?.roleId !==
                            "63c64da18667bd64a57c6300" ? (
                            <p className="RequiredField">
                              Selected User is not a part of karigar
                            </p>
                          ) : (
                            ""
                          )} */}
                        </Col>
                        <Col xs={2} md={3} lg={2}>
                          <Button
                            className="resetForProduct"
                            onClick={() => {
                              handleReset({
                                name: "dying",
                              });
                            }}
                          >
                            <span className="DesktopReset">Reset</span>
                            <span className="mobileReset">
                              <ResetIcon />
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <Label>Upload Files</Label>
                    <span className="ms-2">
                      Photo (jpg, jpeg, png) / Video (mp4 , ogg, webm) / PDF
                    </span>
                    <div className="d-flex justify-content-center">
                      <div className="imageDiv1">
                        {baseImageDying?.length > 0 ? (
                          <>
                            <span className="pl-2 pr-2 ">
                              <div className="divAddIcon">
                                <input
                                  type="file"
                                  name="Image"
                                  multiple={true}
                                  accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,.mp4,.webm,.ogg,.heic,.bmp"
                                  style={{ display: "none" }}
                                  ref={fileInputUploadDying}
                                  onChange={(e) => {
                                    uploadFiles({
                                      fileArr: fileArrayDying,
                                      fileObject: fileObjDying,
                                      name: "dying",
                                      files: e.target.files,
                                    });
                                  }}
                                />
                                <ImageAddIcon
                                  {...props}
                                  fileUploadTrigger={fileUploadTriggerDying}
                                />
                              </div>
                              <div className="d-flex">
                                <div className="PreviewDivPart">
                                  {baseImageDying.map((item: any, i: any) => {
                                    return (
                                      <React.Fragment key={i}>
                                        {loader ? (
                                          <div className="CircleLoder">
                                            <CircleLoader />
                                          </div>
                                        ) : (
                                          <div className="ArryyIcon">
                                            <ImageClose
                                              {...props}
                                              i={i}
                                              item={item}
                                              close={handleDeleteDying}
                                            />
                                            <DownloadIcon
                                              {...props}
                                              i={i}
                                              item={item}
                                              name={
                                                form?.productName + " dying"
                                              }
                                            />
                                            {item?.show === true ? (
                                              <ShowIcon
                                                onClick={onSetIndexDesign}
                                                index={i}
                                                name="dying"
                                              />
                                            ) : (
                                              <HideIcon
                                                onClick={onSetIndexDesign}
                                                index={i}
                                                name="dying"
                                              />
                                            )}
                                            {/* <div className="viewIconForPreview">
                                              <ViewEyeIcon
                                                {...props}
                                                onClick={() => {
                                                  handleOpen();
                                                  setImage(item);
                                                }}
                                              />
                                            </div> */}
                                            {/* <div className="viewIconForPreview">
                                              <ViewEyeIcon
                                                {...props}
                                                onClick={() => {
                                                  handleOpen();
                                                  setImage(item);
                                                }}
                                              />
                                            </div> */}

                                            <FilePreviewPart
                                              {...props}
                                              item={item}
                                              handleOpen={handleOpen}
                                              handleSetImage={handleSetImage}
                                            />
                                          </div>
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </div>
                              </div>
                            </span>
                          </>
                        ) : (
                          <ImageDivPart
                            {...props}
                            fileUploadTrigger={fileUploadTriggerDying}
                            fileInput={fileInputUploadDying}
                            fileUpload={uploadFiles}
                            name="dying"
                            fileArr={fileArrayDying}
                            fileObject={fileObjDying}
                            content="Click to upload dying images or files"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="dbtn1">
                    <Button
                      className="Previous"
                      onClick={() => {
                        setSelectTab1("designing");
                      }}
                    >
                      Previous
                    </Button>
                    <Button
                      className="Next"
                      onClick={() => {
                        setSelectTab1("weaving");
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : (
                ""
              )}
              {selectTab1 === "weaving" ? (
                <>
                  {" "}
                  <div className="UserHeader headerDisplay">
                    <div>Weaving</div>
                  </div>
                  <div className="userPd">
                    <div className="formDiv">
                      <Row>
                        <Col xs={10} md={6} lg={4}>
                          <Label>Karigar</Label>
                          <div className="formDivSub">
                            <Select
                              className="React_selector text-white"
                              classNamePrefix="react-select"
                              placeholder="Select"
                              isSearchable
                              name="weaver"
                              id="weaver"
                              value={form?.weaver}
                              options={weaverOptions}
                              onChange={(e: any) => {
                                handleOnInputChange({
                                  name: "weaver",
                                  value: e,
                                });
                              }}
                              onInputChange={(e) =>
                                handleOnInputChangeFilters({
                                  name: "weaving",
                                  value: e,
                                })
                              }
                            />
                            <div className="DivSep"></div>
                            <div className="formAddDiv">
                              <AddIcon
                                openModal={handleOpenModal}
                                name="weaving"
                                value={true}
                              />
                            </div>
                          </div>
                          {/* {handleNameFlag?.weaving &&
                          form?.weaver?.label?.length &&
                          form?.weaver?.value?.roleId !==
                            "63c64da18667bd64a57c62ff" ? (
                            <p className="RequiredField">
                              Selected User is not a part of karigar
                            </p>
                          ) : (
                            ""
                          )} */}
                        </Col>
                        <Col xs={2} md={3} lg={2}>
                          <Button
                            className="resetForProduct"
                            onClick={() => {
                              handleReset({
                                name: "weaving",
                              });
                            }}
                          >
                            <span className="DesktopReset">Reset</span>
                            <span className="mobileReset">
                              <ResetIcon />
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <Label>Upload Files</Label>
                    <span className="ms-2">
                      Photo (jpg, jpeg, png) / Video (mp4 , ogg, webm) / PDF
                    </span>
                    <div className="">
                      <div
                        className="imageDiv1"
                        style={{
                          height:
                            baseImageWeaver?.length == 0 ? "250px" : "auto",
                        }}
                      >
                        {baseImageWeaver?.length > 0 ? (
                          <>
                            <span className="pl-2 pr-2 ">
                              <div className="divAddIcon">
                                <input
                                  type="file"
                                  name="Image"
                                  multiple={true}
                                  accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,.mp4,.webm,.ogg,.heic,.bmp"
                                  style={{ display: "none" }}
                                  ref={fileInputWeaver}
                                  onChange={(e) => {
                                    uploadFiles({
                                      fileArr: fileArrayWeaving,
                                      fileObject: fileObjWeaving,
                                      name: "weaving",
                                      files: e.target.files,
                                    });
                                  }}
                                />
                                <ImageAddIcon
                                  {...props}
                                  fileUploadTrigger={fileUploadTriggerWeaver}
                                />
                              </div>
                              <div className="d-flex">
                                <div className="PreviewDivPart">
                                  {baseImageWeaver.map((item: any, i: any) => {
                                    return (
                                      <React.Fragment key={i}>
                                        {loader ? (
                                          <div className="CircleLoder">
                                            <CircleLoader />
                                          </div>
                                        ) : (
                                          <div className="ArryyIcon">
                                            <ImageClose
                                              {...props}
                                              i={i}
                                              item={item}
                                              close={handleDeleteWeaver}
                                            />
                                            <DownloadIcon
                                              {...props}
                                              i={i}
                                              item={item}
                                              name={
                                                form?.productName + " weaving"
                                              }
                                            />
                                            {item?.show === true ? (
                                              <ShowIcon
                                                onClick={onSetIndexDesign}
                                                index={i}
                                                name="weaving"
                                              />
                                            ) : (
                                              <HideIcon
                                                onClick={onSetIndexDesign}
                                                index={i}
                                                name="weaving"
                                              />
                                            )}
                                            {/* <div className="viewIconForPreview">
                                              <ViewEyeIcon
                                                {...props}
                                                onClick={() => {
                                                  handleOpen();
                                                  setImage(item);
                                                }}
                                              />
                                            </div> */}
                                            <FilePreviewPart
                                              {...props}
                                              item={item}
                                              handleOpen={handleOpen}
                                              handleSetImage={handleSetImage}
                                            />
                                          </div>
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </div>
                              </div>
                            </span>
                          </>
                        ) : (
                          <ImageDivPart
                            {...props}
                            fileUploadTrigger={fileUploadTriggerWeaver}
                            fileInput={fileInputWeaver}
                            fileUpload={uploadFiles}
                            name="weaving"
                            fileArr={fileArrayWeaving}
                            fileObject={fileObjWeaving}
                            content="Click to upload weaving images or files"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="dbtn1">
                    <Button
                      className="Previous"
                      onClick={() => {
                        setSelectTab1("dying");
                      }}
                    >
                      Previous
                    </Button>
                    <Button
                      className="Next"
                      onClick={() => {
                        setSelectTab1("finishing");
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : (
                ""
              )}

              {selectTab1 === "finishing" ? (
                <>
                  {" "}
                  <div className="UserHeader headerDisplay">
                    <div>Finishing</div>
                  </div>
                  <div className="userPd">
                    <div className="formDiv">
                      <Row>
                        <Col xs={10} md={6} lg={4}>
                          <Label>Karigar</Label>
                          <div className="formDivSub">
                            <Select
                              className="React_selector text-white"
                              classNamePrefix="react-select"
                              placeholder="Select"
                              isSearchable
                              name="finisher"
                              value={form?.finisher}
                              options={finisherOptions}
                              onChange={(e: any) => {
                                handleOnInputChange({
                                  name: "finisher",
                                  value: e,
                                });
                              }}
                              onInputChange={(e) =>
                                handleOnInputChangeFilters({
                                  name: "finishing",
                                  value: e,
                                })
                              }
                            />
                            <div className="DivSep"></div>
                            <div className="formAddDiv">
                              <AddIcon
                                openModal={handleOpenModal}
                                name="finishing"
                                value={true}
                              />
                            </div>
                          </div>
                          {/* {handleNameFlag?.finishing &&
                          form?.finisher?.label?.length &&
                          (form?.finisher?.value?.roleId !==
                            "63c64da18667bd64a57c6303" ||
                            form?.finisher?.value?.roleId !==
                              "63c64da18667bd64a57c6302" ||
                            form?.finisher?.value?.roleId !==
                              "63c64da18667bd64a57c6301") ? (
                            <p className="RequiredField">
                              Selected User is not a part of karigar
                            </p>
                          ) : (
                            ""
                          )} */}
                        </Col>
                        <Col xs={2} md={3} lg={2}>
                          <Button
                            className="resetForProduct"
                            onClick={() => {
                              handleReset({
                                name: "finishing",
                              });
                            }}
                          >
                            <span className="DesktopReset">Reset</span>
                            <span className="mobileReset">
                              <ResetIcon />
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <Label>Upload Files</Label>
                    <span className="ms-2">
                      Photo (jpg, jpeg, png) / Video (mp4 , ogg, webm) / PDF
                    </span>
                    <div className="d-flex justify-content-center">
                      <div className="imageDiv1">
                        {baseImageFinisher?.length > 0 ? (
                          <>
                            <span className="pl-2 pr-2 ">
                              <div className="divAddIcon">
                                <input
                                  type="file"
                                  name="Image"
                                  multiple={true}
                                  accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,.mp4,.webm,.ogg,.heic,.bmp"
                                  style={{ display: "none" }}
                                  ref={fileInputFinisher}
                                  onChange={(e) => {
                                    uploadFiles({
                                      fileArr: fileArrayFinishing,
                                      fileObject: fileObjFinishing,
                                      name: "finishing",
                                      files: e.target.files,
                                    });
                                  }}
                                />
                                <ImageAddIcon
                                  {...props}
                                  fileUploadTrigger={fileUploadTriggerFinisher}
                                />
                              </div>
                              <div className="d-flex">
                                <div className="PreviewDivPart">
                                  {baseImageFinisher.map(
                                    (item: any, i: any) => {
                                      return (
                                        <React.Fragment key={i}>
                                          {loader ? (
                                            <div className="CircleLoder">
                                              <CircleLoader />
                                            </div>
                                          ) : (
                                            <div className="ArryyIcon">
                                              <ImageClose
                                                {...props}
                                                i={i}
                                                item={item}
                                                close={handleDeleteFinisher}
                                              />
                                              {item?.show === true ? (
                                                <ShowIcon
                                                  onClick={onSetIndexDesign}
                                                  index={i}
                                                  name="finishing"
                                                />
                                              ) : (
                                                <HideIcon
                                                  onClick={onSetIndexDesign}
                                                  index={i}
                                                  name="finishing"
                                                />
                                              )}
                                              <DownloadIcon
                                                {...props}
                                                i={i}
                                                item={item}
                                                name={
                                                  form?.productName +
                                                  " finishing"
                                                }
                                              />
                                              {/* <div className="viewIconForPreview">
                                                <ViewEyeIcon
                                                  {...props}
                                                  onClick={() => {
                                                    handleOpen();
                                                    setImage(item);
                                                  }}
                                                />
                                              </div> */}
                                              <FilePreviewPart
                                                {...props}
                                                item={item}
                                                handleOpen={handleOpen}
                                                handleSetImage={handleSetImage}
                                              />
                                            </div>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </span>
                          </>
                        ) : (
                          <ImageDivPart
                            {...props}
                            fileUploadTrigger={fileUploadTriggerFinisher}
                            fileInput={fileInputFinisher}
                            fileUpload={uploadFiles}
                            name="finishing"
                            fileArr={fileArrayFinishing}
                            fileObject={fileObjFinishing}
                            content="Click to upload finishing images or files"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="dbtn3">
                    <Button
                      className="Previous"
                      onClick={() => {
                        setSelectTab1("weaving");
                      }}
                    >
                      Previous
                    </Button>
                    <Button className="Next" disabled={true}>
                      Next
                    </Button>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            {selectTab1 === "finishing" ? (
              <>
                <div>
                  <Button
                    className="save"
                    onClick={() => handleSubmit({ status: 1 })}
                    disabled={loader || loading ? true : false}
                  >
                    Publish
                  </Button>
                  {/* <Button
                    className="save"
                    onClick={() => handleSubmit({ status: 0 })}
                  >
                    Update
                  </Button> */}
                </div>
              </>
            ) : (
              <>
                <div>
                  <Button
                    className="save"
                    onClick={() => handleSubmit({ status: 1 })}
                    disabled={loader || loading ? true : false}
                  >
                    Publish
                  </Button>
                  {/* <Button
                    className="save"
                    onClick={() => handleSubmit({ status: 0 })}
                  >
                    Update
                  </Button> */}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  getProducts,
  getArtforms,
  getTypes,
  getCategory,
  getSubCategorys,
  getWeaveType,
  getUsers,
  createUser,
  updateUsers,
  uploadImages,
  fetchProfilePresignedUrl,
  fetchProductWeavingPresignedUrl,
  fetchProductDesigningPresignedUrl,
  fetchProductFinishingPresignedUrl,
  fetchProductDyingPresignedUrl,
  updateProducts,
  uploadImages1,
  createVariantForProduct,
})(EditProduct);
