import React from "react";

const QRFinishingIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30" r="30" fill="#C59C6C" />
      <path
        d="M18.5683 43.0001C18.1277 42.797 18 42.4479 18 41.9781C18.0128 37.5602 18.0064 33.1486 18.0064 28.7306C18.0064 28.0324 18.2746 27.7658 18.9769 27.7658C19.9028 27.7658 20.835 27.7594 21.7608 27.7658C22.8974 27.7721 23.7658 28.4196 24.0531 29.4923C24.117 29.7272 24.1297 29.9747 24.1297 30.2223C24.1361 33.6564 24.117 37.0905 24.1425 40.5245C24.1489 41.8068 23.3252 42.8097 22.1376 42.9747C22.1248 42.9747 22.1056 42.9938 22.0929 43.0001C20.918 43.0001 19.7431 43.0001 18.5683 43.0001ZM21.8439 38.4298C21.4288 38.4235 21.0776 38.7662 21.0713 39.1852C21.0649 39.5978 21.4097 39.9469 21.8311 39.9532C22.2461 39.9596 22.5973 39.6168 22.6037 39.1979C22.6037 38.7853 22.2653 38.4362 21.8439 38.4298Z"
        fill="white"
      />
      <path
        d="M25.6748 40.188C25.6748 36.5254 25.6748 32.8628 25.6748 29.1875C25.8919 29.1177 26.1154 29.0542 26.3261 28.9717C27.5648 28.4766 28.3949 27.5435 29.0334 26.4263C29.7613 25.1441 30.1891 23.7413 30.5722 22.3321C30.7063 21.8243 30.9745 21.6338 31.5108 21.6212C32.36 21.6021 33.088 21.8433 33.6243 22.5289C33.9691 22.9669 34.084 23.4747 34.1032 24.0206C34.167 25.7471 33.8286 27.3975 33.2731 29.0225C33.254 29.086 33.2284 29.1495 33.2157 29.2129C33.2093 29.2256 33.2157 29.2447 33.2157 29.2891C33.305 29.2891 33.3944 29.2891 33.4838 29.2891C35.4505 29.2891 37.4171 29.2891 39.3837 29.2891C40.1052 29.2891 40.6799 29.67 40.9034 30.2793C41.2035 31.1109 40.782 31.9742 39.9392 32.2535C39.8817 32.2725 39.8307 32.2915 39.7157 32.3296C40.5075 32.5708 40.9991 33.0533 40.9928 33.8594C40.9928 34.6592 40.5203 35.148 39.7093 35.3765C39.8179 35.4209 39.8754 35.44 39.9328 35.4654C40.6352 35.7002 41.0758 36.3731 40.9864 37.0713C40.8906 37.814 40.3159 38.3726 39.5561 38.417C39.1347 38.4424 38.7069 38.4234 38.2408 38.4234C38.4579 38.5313 38.6494 38.5948 38.809 38.7154C39.339 39.1026 39.5625 39.6294 39.422 40.2705C39.2815 40.9117 38.8665 41.3052 38.228 41.4385C38.0747 41.4702 37.9087 41.4766 37.7491 41.4766C35.4951 41.4766 33.2412 41.4702 30.9809 41.4829C30.0614 41.4829 29.1675 41.356 28.2927 41.0703C27.469 40.8037 26.6453 40.5308 25.828 40.2578C25.7706 40.2388 25.7259 40.2134 25.6748 40.188Z"
        fill="white"
      />
      <path
        d="M32.5639 18.5615C32.5639 18.8154 32.5703 19.0693 32.5639 19.3232C32.5512 19.7803 32.2255 20.0977 31.7913 20.0977C31.3699 20.0913 31.0443 19.7803 31.0379 19.3359C31.0251 18.8154 31.0251 18.2886 31.0379 17.7681C31.0443 17.3174 31.3699 17 31.7913 17C32.2191 17 32.5448 17.311 32.5576 17.749C32.5703 18.022 32.5639 18.2886 32.5639 18.5615Z"
        fill="white"
      />
      <path
        d="M26.1348 19.8437C26.1667 19.5263 26.3008 19.2915 26.5753 19.1518C26.8435 19.0122 27.1436 19.0058 27.3671 19.2153C27.8077 19.6152 28.2291 20.0341 28.625 20.4721C28.874 20.7514 28.8101 21.1767 28.542 21.4433C28.2738 21.7036 27.846 21.7671 27.565 21.5131C27.1245 21.1196 26.7094 20.688 26.3008 20.2563C26.2114 20.1484 26.1922 19.977 26.1348 19.8437Z"
        fill="white"
      />
      <path
        d="M37.4872 19.8374C37.3978 20.0088 37.3339 20.2119 37.2062 20.3579C36.8614 20.7324 36.4975 21.0879 36.1272 21.437C35.8015 21.7417 35.3482 21.7354 35.0481 21.4307C34.7543 21.1387 34.7416 20.6943 35.0353 20.377C35.3992 19.9961 35.7696 19.6279 36.1527 19.2661C36.3889 19.0376 36.6827 18.9995 36.9828 19.1328C37.2765 19.2534 37.4233 19.4946 37.4872 19.8374Z"
        fill="white"
      />
    </svg>
  );
};

export default QRFinishingIcon;
