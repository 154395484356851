import React from "react";
import VerticalMenuBar from "../authLayout/VerticalMenuBar";

const NonAuthLayout = ({ children }: any) => {
  return <React.Fragment>
    <div className=" w-100">
    {children}
    </div>
    </React.Fragment>;
};

export default NonAuthLayout;
