import React from "react";
import { useHistory } from "react-router-dom";
import fakeAlert from "../assets/images/Visual.png";
import { Button } from "reactstrap";
const ProductFakeAlert = () => {
  let history: any = useHistory();
  return (
    <div className="productfakeAlert pt-5 mt-3">
      <div className="fakealDiv">
        <img src={fakeAlert} alt="Fake Alert" />
      </div>
      <div className="fakeAlInfo ">
        <h3>Fake alert</h3>
        <br />
        <span>
          Hastkala Pramanak do not certify <br /> the authenticity of this
          product
        </span>
        <Button
          onClick={() => {
            history.push("/product-fake-alert-user-information");
          }}
        >
          Report this product ?
        </Button>
      </div>
    </div>
  );
};

export default ProductFakeAlert;
