import { types } from "./actionTypes";
const initialState = {
  getDashboardDetails: null,
};
const dashboardReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.GET_DASHBOARD_SUCCESS:
      state = {
        ...state,
        getDashboardDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default dashboardReducer;
