import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const DeleteConfirm = (props: any) => {
  const {
    warningModal,
    deleteData,
    warningToggle,
    deleteDataWithConfirm,
    getAllData,
    name,
  } = props;
  return (
    <Modal
      isOpen={warningModal}
      toggle={warningToggle}
      size="ms"
      className="ConfirmModal"
      fade={false}
    >
      <ModalHeader>
        <div className="Heading">
          <h3>Warning</h3>
          <svg
            onClick={() => warningToggle()}
            width="20"
            height="20"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 1L1 8"
              stroke="#C59C6C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1L8 8"
              stroke="#C59C6C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="ContentDiv">Are you sure want to delete?</div>
        <div className="NameHeader">
          {name}:{" "}
          <span className="textCapitalize">{props?.deleteData?.name}</span>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="BtnFlex">
          <Button
            className="btnY1"
            onClick={() => {
              if (warningModal && deleteData) {
                deleteDataWithConfirm(deleteData);
                getAllData();
                warningToggle();
              }
            }}
          >
            Yes
          </Button>
          <Button className="btnN1" onClick={() => warningToggle()}>
            No
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConfirm;
