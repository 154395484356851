import React, { useState, useEffect } from "react";
import { Label, Input, Button, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { useHistory, useLocation, Link } from "react-router-dom";
//components
import { debounce } from "../../common/commonFiles/debounce";
import { statesList } from "./stateList";
import Loader from "../../common/Components/Loader/Loader";
import Backbutton from "../../common/Components/Backbutton";
import notifications from "../../common/Components/Notifications/Notification";
// actions
import { getUserRoles } from "../../Store/users/actions";
import { getOrganisation } from "../../Store/organisation/actions";
import { getArtforms } from "../../Store/artform/actions";
import {
  createUser,
  updateUsers,
  registerUser,
  getUsers,
  createUserRole,
} from "../../Store/users/actions";
import {
  fetchProfilePresignedUrl,
  uploadImages1,
} from "../../Store/uploadModule/actions";
import { isArray } from "lodash";
import CircleLoader from "../../common/Components/Loader/CircleLoader";
import HideIcon from "../../common/Components/Icons/HideIcon";
import ShowIcon from "../../common/Components/Icons/ShowIcon";
import { AddIcon } from "../../common/Components/Icons/AddIcon";
import CreateRole from "./CreateRole";
var organisationOption: any;
var userRolesOptions: any;
var artformOptions: any;
let productMainImageStatusCode: any = [];
var regexpForAdhar = /^[2-9]{4} [0-9]{4} [0-9]{4}$/;
let regex: any = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
let regexForPinCode = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;

const CreateUser = (props: any) => {
  const location = useLocation();

  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";

  const history = useHistory();
  const [errorMobile, setMobileError]: any = useState("");
  const [errorPanCard, setPanCard]: any = useState("");
  const [errorAdharCard, setAdharCard]: any = useState("");
  const [errorPinCard, setPinCard]: any = useState("");
  const [openRoleModal, setRoleModal]: any = useState(false);
  const [roleLoader, setRoleLoader]: any = useState(false);
  const [filterValue, setFilterValue]: any = useState({
    email: "",
    phone: "",
  });
  const [mainImageLoader, setMainImageLoader]: any = useState(false);

  const [form, setForm]: any = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    state: "",
    pinCode: 0,
    adharCard: "",
    panCard: "",
    artform: "",
    role: "",
    organization: "",
    domain: "",
    artisanCard: "",
    profileURL: "",
  });
  const [Error, setError]: any = React.useState({
    name: "",
    phone: "",
    email: "",
    artform: "",
    role: "",
    organization: "",
  });
  const [loading, setLoading]: any = React.useState(false);
  const [baseImageLogo, setBaseImageLogo]: any = useState("");
  const [selectedFile, setSelectedFile]: any = useState();
  const fileInputProfile: any = React.useRef();
  const fileUploadTrigger = () => {
    fileInputProfile.current.click();
  };
  const [show, setShow]: any = useState(false);
  const handleSetShow = (value: any) => {
    setShow(value);
  };
  const handleOpenRoleModal = () => {
    setRoleModal(true);
  };
  const handleCloseModal = () => {
    setRoleModal(false);
  };
  useEffect(() => {
    if (form?.phone?.length === 0 || form?.phone?.length === 10) {
      setMobileError("");
    }
  }, [errorMobile, form?.phone]);
  useEffect(() => {
    if (
      form?.panCard?.length === 0 ||
      (form?.panCard?.length === 10 && regex.test(form?.panCard))
    ) {
      setPanCard("");
    }
  }, [errorPanCard, form?.panCard]);
  useEffect(() => {
    if (
      form?.adharCard?.length === 0 ||
      (form?.adharCard?.length === 14 && regexpForAdhar.test(form?.adharCard))
    ) {
      setAdharCard("");
    }
  }, [errorAdharCard, form?.adharCard]);
  useEffect(() => {
    if (
      form?.pinCode?.length === 0 ||
      (form?.pinCode?.length === 6 && regexForPinCode.test(form?.pinCode))
    ) {
      setPinCard("");
    }
  }, [errorPinCard, form?.pinCode]);
  useEffect(() => {
    if (
      authUser &&
      (authUser?.users?.role === "ORGANIZATION_MANAGER" ||
        authUser?.users?.role === "ORGANIZATION_ADMIN")
    ) {
      setForm({
        ...form,
        organization:
          form?.role?.label === "NAQSEBAND"
            ? [
                {
                  value: authUser?.users?.organizationResponse?._id,
                  label: authUser?.users?.organizationResponse?.name,
                },
              ]
            : {
                value: authUser?.users?.organizationResponse?._id,
                label: authUser?.users?.organizationResponse?.name,
              },
      });
    }
    getUserRolesData();
    if (authUser?.users?.role === "SUPER_ADMIN") {
      getOrganizationData();
    }
    getArtformData();
    productMainImageStatusCode = [];
  }, []);
  const validate = () => {
    let error = "";
    let err = Error;
    if (!form?.name) {
      error = "Required";
      err = {
        ...err,
        name: "Name is Required!",
      };
    }
    if (
      form?.role?.label === "ORGANIZATION ADMIN" ||
      form?.role?.label === "ORGANIZATION MANAGER" ||
      form?.role?.label === "SUPER ADMIN" ||
      form?.role?.label === "ADMIN"
    ) {
      if (!form?.phone) {
        error = "Required";
        err = {
          ...err,
          phone: "Phone Number is Required!",
        };
      }
      if (!form?.email) {
        error = "Required";
        err = {
          ...err,
          email: "Email is Required!",
        };
      }
    }
    if (!form?.role?.value) {
      error = "Required";
      err = {
        ...err,
        role: "Role is Required!",
      };
    }
    if (form?.role?.label !== "NAQSEBAND") {
      if (!form?.organization?.value) {
        error = "Required";
        err = {
          ...err,
          organization: "Organization is Required!",
        };
      }
    } else {
      if (isArray(!form?.organization[0]?.value)) {
        error = "Required";
        err = {
          ...err,
          organization: "Organization is Required!",
        };
      }
    }
    if (!form?.artform?.value) {
      error = "Required";
      err = {
        ...err,
        artform: "Artform is Required!",
      };
    }
    setError(err);
    return error;
  };
  const handleSubmit = () => {
    // let result: any = validate();
    // if (result?.length === 0) {
    let organisationIds: any = [];
    if (form?.role?.label === "NAQSEBAND") {
      if (
        authUser?.users?.role === "ORGANIZATION_MANAGER" ||
        authUser?.users?.role === "ORGANIZATION_ADMIN"
      ) {
        organisationIds.push(authUser?.users?.organizationResponse?._id);
      } else {
        form?.organization?.length > 0 &&
          form?.organization.forEach((ele: any) => {
            organisationIds.push(ele?.value);
          });
      }
    }
    let payload: any = {
      name: form?.name,
      role: form?.role?.value,
      phone: form?.phone,
      email: form?.email,
      profileURL: form?.profileURL,
      city: " ",
      addressLane: form?.address,
      state: form?.state?.value,
      // pinCode: form?.pinCode,
      pinCode: form?.pinCode,
      aadharCard: form?.adharCard,
      panCard: form?.panCard,
      password: form?.password ? form?.password.trim() : "",
      businessDetails: {
        art: form?.artform?.value,
        role: form?.role?.value,
        organization:
          form?.role?.label === "NAQSEBAND"
            ? organisationIds
            : [form?.organization?.value],
        domain: form?.domain?.value,
        artisanCard: form?.artisanCard,
      },
    };
    let result: any = validate();
    if (result?.length === 0) {
      setLoading(true);
      props.createUser(payload, (res: any) => {
        setLoading(false);
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          if (props.name !== "Product") {
            history.push("/users");
          } else {
            props.handleName({ name: props.modalName, userId: res?.data?._id });
            props.closeModal();
          }
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      });
    }

    // }
  };

  const formatAadhar = (input: any) => {
    const aadhar = input.replace(/\D/g, "");
    let formattedAadhar = "";
    for (let i = 0; i < aadhar.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedAadhar += " ";
      }
      formattedAadhar += aadhar[i];
    }
    handleOnInputChange({
      name: "adharCard",
      value: formattedAadhar,
    });
  };
  const formatPinCode = (input: any) => {
    const pinCode = input.replace(/\D/g, "");
    let formattedPinCode = "";
    for (let i = 0; i < pinCode.length; i++) {
      if (i > 0 && i % 3 === 0) {
        formattedPinCode += " ";
      }
      formattedPinCode += pinCode[i];
    }
    // handleOnInputChange({
    //   name: "pinCode",
    //   value: formattedPinCode,
    // });
    handleOnInputChange({
      name: "pinCode",
      value: input,
    });
  };
  const handleOnInputChange = (type: any) => {
    if (authUser?.users?.role === "SUPER_ADMIN") {
      if (type?.name === "role") {
        setForm({
          ...form,
          role: type?.value,
          organization: "",
        });
      } else {
        setForm({
          ...form,
          [type.name]: type?.value,
        });
      }
    }
    if (
      authUser?.users?.role === "ORGANIZATION_MANAGER" ||
      authUser?.users?.role === "ORGANIZATION_ADMIN"
    ) {
      setForm({
        ...form,
        [type.name]: type?.value,
      });
    }
  };
  const getUserRolesData = () => {
    props.getUserRoles(
      {
        offset: 0,
        limit: 1000,
      },
      (res: any) => {
        userRolesOptions = [];
        res?.data?.docs.map((ele: any) => {
          if (
            // authUser?.users?.role === "ORGANIZATION_MANAGER" ||
            authUser?.users?.role === "ORGANIZATION_ADMIN" &&
            props.modalName !== "designing" &&
            props.modalName !== "weaving" &&
            props.modalName !== "dying" &&
            props.modalName !== "finishing"
          ) {
            if (
              ele?.role !== "ORGANIZATION_ADMIN" &&
              // ele?.role !== "ORGANIZATION_MANAGER" &&
              ele?.role !== "SUPER_ADMIN" &&
              ele?.role !== "ADMIN"
            ) {
              userRolesOptions.push({
                label: ele?.role?.replace("_", " "),
                value: ele?._id,
              });
            }
          } else if (props.modalName === "designing") {
            if (ele?.role === "NAQSEBAND") {
              userRolesOptions.push({
                label: ele?.role?.replace("_", " "),
                value: ele?._id,
              });
            }
          } else if (props.modalName === "weaving") {
            if (ele?.role === "WEAVER") {
              userRolesOptions.push({
                label: ele?.role?.replace("_", " "),
                value: ele?._id,
              });
            }
          } else if (props.modalName === "dying") {
            if (ele?.role === "DYER") {
              userRolesOptions.push({
                label: ele?.role?.replace("_", " "),
                value: ele?._id,
              });
            }
          } else if (props.modalName === "finishing") {
            if (
              ele?.role === "RAFUGAR" ||
              ele?.role === "STAIN_MASTER" ||
              ele?.role === "TASSEL"
            ) {
              userRolesOptions.push({
                label: ele?.role?.replace("_", " "),
                value: ele?._id,
              });
            }
          } else {
            userRolesOptions.push({
              label: ele?.role?.replace("_", " "),
              value: ele?._id,
            });
          }
        });
      }
    );
  };

  const getArtformData = () => {
    props.getArtforms(
      {
        offset: 0,
        limit: 1000,
      },
      (res: any) => {
        artformOptions = [];
        res?.data?.docs.map((ele: any) => {
          artformOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getOrganizationData = () => {
    props.getOrganisation(
      {
        offset: 0,
        limit: 10,
      },
      (res: any) => {
        organisationOption = [];
        res?.data?.docs.map((ele: any) => {
          organisationOption.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  useEffect(() => {
    if (selectedFile) {
      S3Upload();
    }
  }, [selectedFile]);
  const S3Upload = async () => {
    setMainImageLoader(true);
    if (selectedFile) {
      props.fetchProfilePresignedUrl(
        { fileType: selectedFile?.type },
        (res: any) => {
          let softCopy = "";

          softCopy = res.data.split("?")[0];
          setForm({ ...form, profileURL: softCopy });
          const formData = new FormData();
          formData.append(selectedFile, selectedFile.name);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", selectedFile.type);
          props.uploadImages1(
            res.data + "&x-amz-acl=public-read",
            selectedFile,
            myHeaders,
            (resServer: any) => {
              setMainImageLoader(false);
              productMainImageStatusCode[0] = resServer?.status;
            }
          );
        }
      );
    }
  };

  const fileUpload = (event: any) => {
    let name = event.target.name;
    if (name === "profileURL") {
      uploadImage(event);
      setSelectedFile(event.target.files[0]);
    }
  };
  const uploadImage = async (e: any) => {
    const name = e.target.name;
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    setBaseImageLogo(base64);
  };
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const callingGetUsers = (value: any) => {
    props.getUsers(
      {
        offset: 0,
        limit: 1,
        phone: value?.name === "phone" ? value?.value : "",
        email: value?.name === "email" ? value?.value : "",
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          setFilterValue({
            ...filterValue,
            email: value?.name === "email" ? res?.data?.docs[0]?.email : "",
            phone: value?.name === "phone" ? res?.data?.docs[0]?.phone : "",
          });
        }
      }
    );
  };
  const handleOnInputChange1 = (type: any) => {
    callingGetUsers(type);
  };
  const handleChangeDebounce = React.useCallback(
    debounce(handleOnInputChange1, 1000),
    []
  );
  const handleChangeDebounceForMobile = React.useCallback(
    debounce((data: any) => {
      if (data?.name === "phone") {
        setMobileError("Invalid mobile number!");
      }
      if (data?.name === "pinCode") {
        if (regexForPinCode.test(form?.pinCode)) {
          setPinCard(" ");
        } else {
          setPinCard("Invalid pincode!");
        }
      }
      if (data?.name === "adharCard") {
        if (regexpForAdhar.test(form?.adharCard)) {
          setAdharCard("");
        } else {
          setAdharCard("Invalid Aadhaar number!");
        }
      }
      if (data?.name === "panCard") {
        if (regex.test(form?.panCard)) {
          setPanCard("");
        } else {
          setPanCard("Invalid pancard!");
        }
      }
    }, 1000),
    []
  );
  return (
    <>
      <CreateRole
        {...props}
        openModal={openRoleModal}
        closeModal={handleCloseModal}
        handleOpenModal={handleOpenRoleModal}
        getUserRolesData={getUserRolesData}
      />
      {loading ? (
        <div className="LoaderDiv">
          <Loader />
        </div>
      ) : (
        <div className="createUser">
          <div className="form">
            <form>
              <div className="UserDetailsDiv">
                <div className="UserHeader">User Details</div>
                <div className="userPd">
                  <div className="d-flex justify-content-center">
                    <div className="imageDiv">
                      {baseImageLogo ? (
                        <>
                          <div className="editPen">
                            <svg
                              onClick={() => fileUploadTrigger()}
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="26"
                                height="26"
                                rx="4"
                                fill="#262D22"
                              />
                              <path
                                d="M16.3473 6.6267C16.546 6.42801 16.7819 6.2704 17.0415 6.16287C17.3011 6.05534 17.5793 6 17.8603 6C18.1413 6 18.4195 6.05534 18.6791 6.16287C18.9387 6.2704 19.1746 6.42801 19.3733 6.6267C19.572 6.82538 19.7296 7.06126 19.8371 7.32086C19.9447 7.58045 20 7.85869 20 8.13968C20 8.42066 19.9447 8.6989 19.8371 8.95849C19.7296 9.21809 19.572 9.45397 19.3733 9.65266L9.16069 19.8653L5 21L6.13473 16.8393L16.3473 6.6267Z"
                                stroke="#C69C6D"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <input
                              type="file"
                              name="Image"
                              multiple={true}
                              accept=".png,.jpg,jpeg,.heic,.bmp"
                              style={{ display: "none" }}
                              ref={fileInputProfile}
                              onChange={(e) => {
                                fileUpload({
                                  target: {
                                    name: "profileURL",
                                    files: e.target.files,
                                  },
                                });
                              }}
                            />
                          </div>
                          {mainImageLoader ? (
                            <CircleLoader />
                          ) : (
                            <img
                              src={baseImageLogo}
                              width="200px"
                              height={"200px"}
                              alt="profile"
                            />
                          )}
                        </>
                      ) : (
                        <div className="d-block">
                          <svg
                            onClick={() => fileUploadTrigger()}
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 31.6534C0.0758962 31.2107 0.126494 30.768 0.227689 30.3253C0.809559 27.6309 2.23894 25.5185 4.51582 23.9753C4.65497 23.8741 4.80676 23.7729 4.9712 23.659C4.17429 20.9521 4.57907 18.4855 6.60297 16.4616C8.61422 14.4503 11.0808 13.9949 13.7372 14.8045C17.0893 8.92253 22.0225 6.15232 28.7773 7.22752C33.9256 8.04973 39.1245 12.4517 39.9214 19.3709C40.2376 19.4468 40.5665 19.4974 40.8827 19.5986C44.8167 20.7876 47.511 23.3428 48.8265 27.2009C50.6607 32.5515 48.7633 37.9781 44.0704 41.1531C42.2995 42.3548 40.3262 42.9873 38.1631 42.9873C35.0008 42.9873 31.8511 42.9873 28.6888 42.9873C28.499 42.9873 28.3093 42.9873 28.0942 42.9873C28.0942 39.3949 28.0942 35.8657 28.0942 32.2733C28.2587 32.2606 28.4105 32.248 28.5623 32.248C29.4351 32.248 30.3079 32.2353 31.168 32.248C31.7246 32.2606 32.2432 32.2227 32.5342 31.6534C32.8378 31.0842 32.5595 30.6415 32.2306 30.1988C31.0415 28.5543 29.8525 26.9099 28.6635 25.2655C27.7021 23.9373 26.7408 22.5965 25.7794 21.2683C25.1343 20.3829 24.35 20.3702 23.7176 21.2683C21.5419 24.2662 19.3788 27.2768 17.2031 30.2747C17.1525 30.3379 17.1146 30.4012 17.064 30.4771C16.811 30.8565 16.7351 31.2487 16.9501 31.6661C17.1652 32.0709 17.5194 32.2606 17.9874 32.2606C19.1132 32.2606 20.239 32.2606 21.4027 32.2606C21.4027 35.853 21.4027 39.3949 21.4027 42.9999C21.2003 42.9999 21.0106 42.9999 20.8209 42.9999C17.3043 42.9999 13.7878 42.9873 10.2839 42.9999C6.78006 43.0126 4.11104 41.4314 2.0745 38.6865C0.974001 37.1939 0.328883 35.5115 0.0885456 33.6647C0.0758962 33.5761 0.0252987 33.4876 0 33.3991C0 32.8172 0 32.2353 0 31.6534Z"
                              fill="#C6C6C6"
                            />
                          </svg>
                          <input
                            type="file"
                            name="Image"
                            multiple={true}
                            accept=".png,.jpg,jpeg,.heic,.bmp"
                            style={{ display: "none" }}
                            ref={fileInputProfile}
                            onChange={(e) => {
                              fileUpload({
                                target: {
                                  name: "profileURL",
                                  files: e.target.files,
                                },
                              });
                            }}
                          />
                          <span className="clickText">Click to upload</span>
                          <br />
                          <span className="clickText"> profile image</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    {productMainImageStatusCode?.length > 0 &&
                    productMainImageStatusCode[0] !== 200 ? (
                      <p className="RequiredField">
                        Image upload failed into server!
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="formDiv">
                    <Row>
                      <Col xs={12} md={4}>
                        <Label>
                          Name<span>*</span>
                        </Label>
                        <Input
                          name="name"
                          id="name"
                          className="form-control"
                          placeholder="Enter Name"
                          value={form?.name || ""}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "name",
                              value: e.target.value,
                            });
                          }}
                        />
                        {Error.name && !form?.name ? (
                          <p className="RequiredField">{Error?.name} </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>
                          Phone
                          <span>
                            {form?.role?.label === "ORGANIZATION ADMIN" ||
                            form?.role?.label === "ORGANIZATION MANAGER" ||
                            form?.role?.label === "SUPER ADMIN" ||
                            form?.role?.label === "ADMIN"
                              ? "*"
                              : ""}
                          </span>
                        </Label>
                        <Input
                          name="Phone"
                          id="Phone"
                          type="number"
                          className="form-control"
                          placeholder="Ex: 7993149592"
                          value={form?.phone}
                          maxLength={10}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "phone",
                              value:
                                e.target.value?.length <= 10
                                  ? e.target.value
                                  : form?.phone,
                            });
                            handleChangeDebounce({
                              name: "phone",
                              value: e.target.value,
                            });
                            if (
                              e.target.value?.length <= 9 ||
                              e.target.value?.length > 1
                            ) {
                              handleChangeDebounceForMobile({
                                name: "phone",
                              });
                            }
                            if (
                              e.target.value?.length === 0 ||
                              e.target.value?.length === 10
                            ) {
                              setMobileError("");
                            }
                          }}
                        />
                        {Error.phone && !form?.phone ? (
                          <p className="RequiredField">{Error?.phone} </p>
                        ) : (
                          ""
                        )}
                        <p className="RequiredField">{errorMobile}</p>

                        {form?.phone && form?.phone === filterValue?.phone ? (
                          <p className="RequiredField">
                            Phone numer already exists!
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>
                          Email{" "}
                          <span>
                            {form?.role?.label === "ORGANIZATION ADMIN" ||
                            form?.role?.label === "ORGANIZATION MANAGER" ||
                            form?.role?.label === "SUPER ADMIN" ||
                            form?.role?.label === "ADMIN"
                              ? "*"
                              : ""}
                          </span>
                        </Label>
                        <Input
                          type="text"
                          autoComplete="none"
                          // name="EmailUser"
                          // id="EmailUser"
                          className="form-control textLower"
                          placeholder="Enter Email"
                          value={form?.email}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "email",
                              value: e.target.value,
                            });
                            handleChangeDebounce({
                              name: "email",
                              value: e.target.value,
                            });
                          }}
                        />
                        {Error.email && !form?.email ? (
                          <p className="RequiredField">{Error?.email} </p>
                        ) : (
                          ""
                        )}
                        {form?.email &&
                        form?.email.toLowerCase() ===
                          filterValue?.email.toLowerCase() ? (
                          <p className="RequiredField">Email already exists!</p>
                        ) : (
                          ""
                        )}
                        {form?.email?.length
                          ? !form?.email.match(/\S+@\S+\.\S+/) && (
                              <p className="RequiredField">Invalid email! </p>
                            )
                          : ""}
                      </Col>
                    </Row>
                  </div>
                  <div className="formDiv">
                    <Row>
                      <Col xs={12} md={8}>
                        <Label>Address</Label>
                        <Input
                          name="address"
                          id="address"
                          className="form-control"
                          placeholder="Your address"
                          value={form?.address}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "address",
                              value: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>State</Label>
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isSearchable
                          name="state"
                          id="state"
                          value={form?.state}
                          options={statesList}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "state",
                              value: e,
                            });
                          }}
                          //   onInputChange={(e) => handleOnInputChange(e)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="formDiv">
                    <Row>
                      <Col xs={12} md={4}>
                        <Label>Pin Code</Label>
                        <Input
                          name="pincode"
                          id="pincode"
                          type="text"
                          className="form-control"
                          placeholder="Ex: 524302"
                          maxLength={7}
                          value={form?.pinCode === 0 ? "" : form?.pinCode}
                          onChange={(e: any) => {
                            formatPinCode(e.target.value);
                            // handleOnInputChange({
                            //   name: "pinCode",
                            //   value:
                            //     e.target.value?.length <= 6
                            //       ? e.target.value
                            //       : form?.pinCode,
                            // });
                            if (
                              e.target.value?.length <= 6 ||
                              e.target.value?.length > 1
                            ) {
                              handleChangeDebounceForMobile({
                                name: "pinCode",
                              });
                            }
                            if (
                              e.target.value?.length === 0 ||
                              (e.target.value?.length === 6 &&
                                regexForPinCode.test(form?.pinCode))
                            ) {
                              setPinCard("");
                            }
                          }}
                        />
                        <p className="RequiredField">{errorPinCard}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>Aadhaar Card</Label>
                        <Input
                          name="AdharCard"
                          id="AdharCard"
                          type="text"
                          className="form-control"
                          placeholder="Ex: 9554 0465 4956"
                          maxLength={14}
                          value={form?.adharCard}
                          onChange={(e: any) => {
                            formatAadhar(e.target.value);
                            // handleOnInputChange({
                            //   name: "adharCard",
                            //   value:
                            //     e.target.value?.length <= 12
                            //       ? e.target.value
                            //       : form?.adharCard,
                            // });
                            if (
                              e.target.value?.length > 1 &&
                              e.target.value?.length <= 14
                            ) {
                              handleChangeDebounceForMobile({
                                name: "adharCard",
                              });
                            }
                            if (
                              e.target.value?.length === 0 ||
                              (e.target.value?.length === 14 &&
                                regexpForAdhar.test(form?.adharCard))
                            ) {
                              setAdharCard("");
                            }
                          }}
                        />
                        <p className="RequiredField">{errorAdharCard}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>Pan Card</Label>

                        <Input
                          {...props}
                          type="text"
                          // name="PanCard"
                          // id="PanCard"

                          autoComplete="none"
                          className="form-control textUpper"
                          placeholder="Ex: HIPPK2631L"
                          value={form?.panCard}
                          // maxLength={10}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "panCard",
                              value:
                                e.target.value?.length <= 10
                                  ? e.target.value
                                  : form?.panCard,
                            });
                            if (
                              e.target.value?.length > 1 &&
                              e.target.value?.length <= 10
                            ) {
                              handleChangeDebounceForMobile({
                                name: "panCard",
                              });
                            }
                            if (
                              e.target.value?.length === 0 ||
                              (e.target.value?.length === 10 &&
                                regex.test(form?.panCard))
                            ) {
                              setPanCard("");
                            }
                          }}
                        />
                        <p className="RequiredField">{errorPanCard}</p>
                      </Col>
                    </Row>
                  </div>
                  <div className="formDiv">
                    <Row>
                      <Col xs={12} md={4}>
                        <Label>Artisan Card</Label>
                        <Input
                          name="artisanCard"
                          id="artisanCard"
                          className="form-control"
                          placeholder="Enter artisan number"
                          value={form?.artisanCard}
                          onChange={(e: any) =>
                            handleOnInputChange({
                              name: "artisanCard",
                              value: e.target.value,
                            })
                          }
                        />
                      </Col>
                      {form?.role?.label === "ADMIN" ||
                      form?.role?.label === "ORGANIZATION ADMIN" ||
                      form?.role?.label === "ORGANIZATION MANAGER" ? (
                        <Col xs={12} md={4}>
                          <Label>Password</Label>
                          <div style={{ position: "relative" }}>
                            {" "}
                            <Input
                              style={{
                                textTransform: "none",
                              }}
                              placeholder="Enter Password"
                              type={show === true ? "text" : "password"}
                              value={form?.password}
                              className="inputField"
                              onChange={(e: any) =>
                                handleOnInputChange({
                                  name: "password",
                                  value: e.target.value,
                                })
                              }
                            />
                            <div className="icon">
                              {show ? (
                                <HideIcon
                                  onClick={() => handleSetShow(false)}
                                />
                              ) : (
                                <ShowIcon onClick={() => handleSetShow(true)} />
                              )}
                            </div>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </div>
                </div>
              </div>
              <div className="UserDetailsDiv mb-5">
                <div className="UserHeader">Business Details</div>
                <div className="userPd">
                  <div className="formDiv">
                    <Row>
                      <Col xs={12} md={4}>
                        <Label>
                          Artform<span>*</span>
                        </Label>
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isSearchable
                          name="artform"
                          id="artform"
                          value={form?.artform}
                          options={artformOptions}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "artform",
                              value: e,
                            });
                          }}
                          //   onInputChange={(e) => handleOnInputChange(e)}
                        />
                        {Error.artform && !form?.artform?.value ? (
                          <p className="RequiredField">{Error?.artform} </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>
                          Role<span>*</span>
                        </Label>
                        <div className="formDivSub">
                          <Select
                            className="React_selector text-white"
                            classNamePrefix="react-select"
                            placeholder="Select"
                            isSearchable
                            name="Role"
                            id="Role"
                            value={form?.role}
                            options={userRolesOptions}
                            onChange={(e: any) => {
                              handleOnInputChange({
                                name: "role",
                                value: e,
                              });
                            }}
                            //   onInputChange={(e) => handleOnInputChange(e)}
                          />
                          <div className="DivSep"></div>
                          <div className="formAddDiv">
                            <AddIcon openModal={handleOpenRoleModal} />
                          </div>
                        </div>

                        {Error.role && !form?.role?.value ? (
                          <p className="RequiredField">{Error?.role} </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12} md={4}>
                        <Label>
                          Organization Name<span>*</span>
                        </Label>
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isSearchable
                          isDisabled={
                            authUser?.users?.role === "ORGANIZATION_MANAGER" ||
                            authUser?.users?.role === "ORGANIZATION_ADMIN"
                              ? true
                              : false
                          }
                          name="Organization"
                          id="Organization"
                          isMulti={
                            form?.role?.label === "NAQSEBAND" ? true : false
                          }
                          value={form?.organization}
                          options={organisationOption}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "organization",
                              value: e,
                            });
                          }}
                          //   onInputChange={(e) => handleOnInputChange(e)}
                        />
                        {form?.role?.label === "NAQSEBAND" ? (
                          Error.organization &&
                          !form?.organization[0]?.value ? (
                            <p className="RequiredField">
                              {Error?.organization}{" "}
                            </p>
                          ) : (
                            ""
                          )
                        ) : Error.organization && !form?.organization?.value ? (
                          <p className="RequiredField">
                            {Error?.organization}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="formDiv">
                    <Row>
                      {/* <Col xs={12} md={4}>
                        <Label>Domain</Label>
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isSearchable
                          name="Domain"
                          id="Domain"
                          value={form?.domain}
                          options={[
                            {
                              value: "Domain A",
                              label: "Domain A",
                            },
                            {
                              value: "Domain B",
                              label: "Domain B",
                            },
                            {
                              value: "Domain C",
                              label: "Domain C",
                            },
                          ]}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "domain",
                              value: e,
                            });
                          }}
                          //   onInputChange={(e) => handleOnInputChange(e)}
                        />
                      </Col> */}
                      {/* <Col xs={12} md={4}>
                        <Label>Artisan Card</Label>
                        <Input
                          name="artisanCard"
                          id="artisanCard"
                          className="form-control"
                          placeholder="Enter Number"
                          value={form?.artisanCard}
                          onChange={(e: any) =>
                            handleOnInputChange({
                              name: "artisanCard",
                              value: e.target.value,
                            })
                          }
                        />
                      </Col> */}
                    </Row>
                    <Button
                      type="button"
                      className="artButton"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  getUserRoles,
  getOrganisation,
  getArtforms,
  createUser,
  updateUsers,
  fetchProfilePresignedUrl,
  uploadImages1,
  registerUser,
  getUsers,
  createUserRole,
})(CreateUser);
