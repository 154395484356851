import React, { useState, useEffect } from "react";
import { Label, Input, Button, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { useHistory, useLocation, Link } from "react-router-dom";
import { isArray } from "lodash";

//components
import { debounce } from "../../common/commonFiles/debounce";
import { statesList } from "./stateList";
import Loader from "../../common/Components/Loader/Loader";
import notifications from "../../common/Components/Notifications/Notification";
// actions
import { getUserRoles } from "../../Store/users/actions";
import { getOrganisation } from "../../Store/organisation/actions";
import { getArtforms } from "../../Store/artform/actions";
import {
  updateUsers,
  getUsers,
  createUserRole,
} from "../../Store/users/actions";
import {
  fetchProfilePresignedUrl,
  uploadImages1,
} from "../../Store/uploadModule/actions";
import CircleLoader from "../../common/Components/Loader/CircleLoader";
import HideIcon from "../../common/Components/Icons/HideIcon";
import ShowIcon from "../../common/Components/Icons/ShowIcon";
import { AddIcon } from "../../common/Components/Icons/AddIcon";
import CreateRole from "./CreateRole";
var organisationOption: any;
var userRolesOptions: any;
var artformOptions: any;
let productMainImageStatusCode: any = [];
var regexpForAdhar = /^[2-9]{4} [0-9]{4} [0-9]{4}$/;
let regex: any = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
let regexForPinCode = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;
const EditUser = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const UserId = location?.pathname.split("/")[2];
  const [loading, setLoading]: any = useState(false);
  const [errorMobile, setMobileError]: any = useState("");
  const [errorPanCard, setPanCard]: any = useState("");
  const [errorAdharCard, setAdharCard]: any = useState("");
  const [errorPinCard, setPinCard]: any = useState("");
  const [mainImageLoader, setMainImageLoader]: any = useState(false);

  const [baseImageLogo, setBaseImageLogo]: any = useState("");
  const [selectedFile, setSelectedFile]: any = useState();
  const [form, setForm]: any = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    state: "",
    pinCode: 0,
    adharCard: "",
    panCard: "",
    artform: "",
    role: "",
    organization: "",
    domain: "",
    artisanCard: "",
    profileURL: "",
  });
  const [Error, setError]: any = React.useState({
    name: "",
    phone: "",
    email: "",
    artform: "",
    role: "",
    organization: "",
  });
  const [filterValue, setFilterValue]: any = useState({
    email: "",
    phone: "",
  });
  const [show, setShow]: any = useState(false);
  const [openRoleModal, setRoleModal]: any = useState(false);
  const [roleLoader, setRoleLoader]: any = useState(false);
  const handleSetShow = (value: any) => {
    setShow(value);
  };
  const fileInputProfile: any = React.useRef();
  const fileUploadTrigger = () => {
    fileInputProfile.current.click();
  };
  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  // useEffect(() => {
  //   if (location) window.scrollTo(0, -300);
  // }, []);
  useEffect(() => {
    if (form?.phone?.length === 0 || form?.phone?.length === 10) {
      setMobileError("");
    }
  }, [errorMobile]);
  useEffect(() => {
    if (
      form?.panCard?.length === 0 ||
      (form?.panCard?.length === 10 && regex.test(form?.panCard))
    ) {
      setPanCard("");
    }
  }, [errorPanCard, form?.panCard]);
  useEffect(() => {
    if (
      form?.adharCard?.length === 0 ||
      (form?.adharCard?.length === 14 && regexpForAdhar.test(form?.adharCard))
    ) {
      setAdharCard("");
    }
  }, [errorAdharCard, form?.adharCard]);
  useEffect(() => {
    if (
      form?.pinCode?.length === 0 ||
      (form?.pinCode?.length === 6 && regexForPinCode.test(form?.pinCode))
    ) {
      setPinCard("");
    }
  }, [errorPinCard, form?.pinCode]);

  useEffect(() => {
    getUserRolesData();
    if (
      authUser &&
      (authUser?.users?.role !== "ORGANIZATION_MANAGER" ||
        authUser?.users?.role !== "ORGANIZATION_ADMIN")
    ) {
      getOrganizationData();
    }
    getArtformData();
    // getUserDataById();
    productMainImageStatusCode = [];
  }, []);
  useEffect(() => {
    if (UserId?.length) {
      getUserDataById();
    }
  }, [UserId]);
  const getUserDataById = () => {
    setLoading(true);
    props.getUsers(
      {
        userId: UserId,
      },
      (res: any) => {
        setLoading(false);
        let formOrganisationDetails: any = [];
        let editDetails: any = res?.data?.docs[0];
        if (editDetails?.businessRoleDetails?.length) {
          if (editDetails?.businessRoleDetails[0]?.role === "NAQSEBAND") {
            editDetails?.organizationDetails.forEach((ele: any) => {
              formOrganisationDetails.push({
                label: ele?.name,
                value: ele?._id,
              });
            });
          }
        }
        setForm({
          name: editDetails?.name,
          phone: editDetails?.phone,
          email: editDetails?.email,
          address: editDetails?.address?.addressLane,
          state: editDetails?.address?.state
            ? {
                value: editDetails?.address?.state,
                label: editDetails?.address?.state,
              }
            : "",
          pinCode: editDetails?.address?.pinCode,
          adharCard: editDetails?.aadharCard,
          panCard: editDetails?.panCard,
          artform: editDetails?.artFormDetails?.length
            ? {
                value: editDetails?.artFormDetails[0]?._id,
                label: editDetails?.artFormDetails[0]?.name,
              }
            : "",
          role: editDetails?.businessRoleDetails?.length
            ? {
                value: editDetails?.businessRoleDetails[0]?._id,
                label: editDetails?.businessRoleDetails[0]?.role,
              }
            : "",
          organization: editDetails?.businessRoleDetails?.length
            ? editDetails?.businessRoleDetails[0]?.role === "NAQSEBAND"
              ? formOrganisationDetails
              : {
                  value: editDetails?.organizationDetails[0]?._id,
                  label: editDetails?.organizationDetails[0]?.name,
                }
            : "",
          // domain: {
          //   value: editDetails?.businessDetails?.domain,
          //   label: editDetails?.businessDetails?.domain,
          // },
          artisanCard: editDetails?.businessDetails?.artisanCard,
          profileURL: editDetails?.profileURL,
        });
      }
    );
  };
  const handleOpenRoleModal = () => {
    setRoleModal(true);
  };
  const handleCloseModal = () => {
    setRoleModal(false);
  };
  const validate = () => {
    let error = "";
    let err = Error;
    if (!form?.name) {
      error = "Required";
      err = {
        ...err,
        name: "Name is Required!",
      };
    }
    if (
      form?.role?.label === "ORGANIZATION ADMIN" ||
      form?.role?.label === "ORGANIZATION MANAGER" ||
      form?.role?.label === "SUPER ADMIN" ||
      form?.role?.label === "ADMIN"
    ) {
      if (!form?.phone) {
        error = "Required";
        err = {
          ...err,
          phone: "Phone Number is Required!",
        };
      }
      if (!form?.email) {
        error = "Required";
        err = {
          ...err,
          email: "Email is Required!",
        };
      }
    }
    if (!form?.role?.value) {
      error = "Required";
      err = {
        ...err,
        role: "Role is Required!",
      };
    }
    if (form?.role?.label !== "NAQSEBAND") {
      if (!form?.organization?.value) {
        error = "Required";
        err = {
          ...err,
          organization: "Organization is Required!",
        };
      }
    } else {
      if (isArray(!form?.organization[0]?.value)) {
        error = "Required";
        err = {
          ...err,
          organization: "Organization is Required!",
        };
      }
    }
    if (!form?.artform?.value) {
      error = "Required";
      err = {
        ...err,
        artform: "Artform is Required!",
      };
    }
    setError(err);
    return error;
  };
  const handleSubmit = () => {
    let organisationIds: any = [];
    if (form?.role?.label === "NAQSEBAND") {
      form?.organization?.length > 0 &&
        form?.organization.forEach((ele: any) => {
          organisationIds.push(ele?.value);
        });
    }
    let payload: any = {
      userId: UserId,
      name: form?.name,
      role: form?.role?.value,
      phone: form?.phone,
      email: form?.email,
      password: form?.password ? form?.password.trim() : "",
      profileURL: form?.profileURL,
      city: form?.city,
      addressLane: form?.address,
      state: form?.state?.value,
      // pinCode: form?.pinCode,
      pinCode: form?.pinCode,
      // aadharCard: form?.adharCard,
      aadharCard: form?.adharCard ? form?.adharCard?.replace(" ", "") : "",
      panCard: form?.panCard,
      businessDetails: {
        art: form?.artform?.value,
        role: form?.role?.value,
        organization:
          form?.role?.label === "NAQSEBAND"
            ? organisationIds
            : [form?.organization?.value],
        domain: form?.domain?.value,
        artisanCard: form?.artisanCard,
      },
    };
    let result: any = validate();
    if (result?.length === 0) {
      props.updateUsers(payload, (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          history.push("/users");
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      });
    }
    // }
  };
  const formatAadhar = (input: any) => {
    const aadhar = input.replace(/\D/g, "");
    let formattedAadhar = "";
    for (let i = 0; i < aadhar.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedAadhar += " ";
      }
      formattedAadhar += aadhar[i];
    }
    handleOnInputChange({
      name: "adharCard",
      value: formattedAadhar,
    });
  };
  const formatPinCode = (input: any) => {
    const pinCode = input.replace(/\D/g, "");
    let formattedPinCode = "";
    for (let i = 0; i < pinCode.length; i++) {
      if (i > 0 && i % 3 === 0) {
        formattedPinCode += " ";
      }
      formattedPinCode += pinCode[i];
    }
    handleOnInputChange({
      name: "pinCode",
      value: input,
    });
  };
  const handleOnInputChange = (type: any) => {
    if (type?.name === "role") {
      setForm({
        ...form,
        role: type?.value,
        organization:
          authUser?.users?.role === "ORGANIZATION_MANAGER" ||
          authUser?.users?.role === "ORGANIZATION_ADMIN"
            ? type?.value?.label !== "NAQSEBAND"
              ? {
                  value: authUser?.users?.organizationResponse?._id,
                  label: authUser?.users?.organizationResponse?.name,
                }
              : [
                  {
                    value: authUser?.users?.organizationResponse?._id,
                    label: authUser?.users?.organizationResponse?.name,
                  },
                ]
            : "",
      });
    } else {
      setForm({
        ...form,
        [type.name]: type?.value,
      });
    }
  };
  const getUserRolesData = () => {
    props.getUserRoles(
      {
        offset: 0,
        limit: 1000,
      },
      (res: any) => {
        userRolesOptions = [];
        res?.data?.docs.map((ele: any) => {
          if (
            // authUser?.users?.role === "ORGANIZATION_MANAGER" ||
            authUser?.users?.role === "ORGANIZATION_ADMIN"
          ) {
            if (
              ele?.role !== "ORGANIZATION_ADMIN" &&
              // ele?.role !== "ORGANIZATION_MANAGER" &&
              ele?.role !== "SUPER_ADMIN" &&
              ele?.role !== "ADMIN"
            ) {
              userRolesOptions.push({
                label: ele?.role?.replace("_", " "),
                value: ele?._id,
              });
            }
          } else {
            userRolesOptions.push({
              label: ele?.role?.replace("_", " "),
              value: ele?._id,
            });
          }
        });
      }
    );
  };
  const getArtformData = () => {
    props.getArtforms(
      {
        offset: 0,
        limit: 1000,
      },
      (res: any) => {
        artformOptions = [];
        res?.data?.docs.map((ele: any) => {
          artformOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getOrganizationData = () => {
    props.getOrganisation(
      {
        offset: 0,
        limit: 10,
      },
      (res: any) => {
        organisationOption = [];
        res?.data?.docs.map((ele: any) => {
          organisationOption.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  useEffect(() => {
    if (selectedFile) {
      S3Upload();
    }
  }, [selectedFile]);
  const S3Upload = async () => {
    setMainImageLoader(true);
    props.fetchProfilePresignedUrl(
      { fileType: selectedFile?.type },
      (res: any) => {
        if (selectedFile) {
          let softCopy = "";
          softCopy = res.data.split("?")[0];
          setForm({ ...form, profileURL: softCopy });
          const formData = new FormData();
          formData.append(selectedFile, selectedFile.name);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", selectedFile.type);
          props.uploadImages1(
            res.data,
            selectedFile,
            myHeaders,
            (resServer: any) => {
              setMainImageLoader(false);
              productMainImageStatusCode[0] = resServer?.status;
            }
          );
        }
      }
    );
  };

  const fileUpload = (event: any) => {
    let name = event.target.name;
    if (name === "profileURL") {
      uploadImage(event);
      setSelectedFile(event.target.files[0]);
    }
  };
  const uploadImage = async (e: any) => {
    const name = e.target.name;
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    setBaseImageLogo(base64);
  };
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const callingGetUsers = (value: any) => {
    props.getUsers(
      {
        offset: 0,
        limit: 1,
        phone: value?.name === "phone" ? value?.value : "",
        email: value?.name === "email" ? value?.value : "",
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          setFilterValue({
            ...filterValue,
            email: value?.name === "email" ? res?.data?.docs[0]?.email : "",
            phone: value?.name === "phone" ? res?.data?.docs[0]?.phone : "",
          });
        }
      }
    );
  };
  const handleOnInputChange1 = (type: any) => {
    callingGetUsers(type);
  };
  const handleChangeDebounce = React.useCallback(
    debounce(handleOnInputChange1, 1000),
    []
  );
  const handleChangeDebounceForMobile = React.useCallback(
    debounce((data: any) => {
      if (data?.name === "phone") {
        setMobileError("Invalid mobile number!");
      }
      if (data?.name === "pinCode") {
        if (regexForPinCode.test(form?.pinCode)) {
          setPinCard(" ");
        } else {
          setPinCard("Invalid pincode!");
        }
      }
      if (data?.name === "adharCard") {
        if (regexpForAdhar.test(form?.adharCard)) {
          setAdharCard("");
        } else {
          setAdharCard("Invalid Aadhaar number!");
        }
      }
      if (data?.name === "panCard") {
        if (regex.test(form?.panCard)) {
          setPanCard("");
        } else {
          setPanCard("Invalid pancard!");
        }
      }
    }, 1000),
    []
  );

  return (
    <>
      <CreateRole
        {...props}
        openModal={openRoleModal}
        closeModal={handleCloseModal}
        handleOpenModal={handleOpenRoleModal}
        getUserRolesData={getUserRolesData}
      />
      ;
      {loading ? (
        <div className="LoaderDiv">
          <Loader />
        </div>
      ) : (
        <div className="createUser">
          <div className="form">
            <div className="UserDetailsDiv">
              <div className="UserHeader">User Details</div>
              <div className="userPd">
                <div className="d-flex justify-content-center">
                  <div className="imageDiv">
                    <div className="editPen">
                      <svg
                        onClick={() => fileUploadTrigger()}
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="26" height="26" rx="4" fill="#262D22" />
                        <path
                          d="M16.3473 6.6267C16.546 6.42801 16.7819 6.2704 17.0415 6.16287C17.3011 6.05534 17.5793 6 17.8603 6C18.1413 6 18.4195 6.05534 18.6791 6.16287C18.9387 6.2704 19.1746 6.42801 19.3733 6.6267C19.572 6.82538 19.7296 7.06126 19.8371 7.32086C19.9447 7.58045 20 7.85869 20 8.13968C20 8.42066 19.9447 8.6989 19.8371 8.95849C19.7296 9.21809 19.572 9.45397 19.3733 9.65266L9.16069 19.8653L5 21L6.13473 16.8393L16.3473 6.6267Z"
                          stroke="#C69C6D"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <input
                        type="file"
                        name="Image"
                        multiple={true}
                        accept=".png,.jpg,jpeg,.heic,.bmp"
                        style={{ display: "none" }}
                        ref={fileInputProfile}
                        onChange={(e) => {
                          fileUpload({
                            target: {
                              name: "profileURL",
                              files: e.target.files,
                            },
                          });
                        }}
                      />
                    </div>
                    {form?.profileURL?.length || baseImageLogo ? (
                      <>
                        {" "}
                        {mainImageLoader ? (
                          <CircleLoader />
                        ) : (
                          <img
                            src={
                              baseImageLogo ? baseImageLogo : form?.profileURL
                            }
                            alt="profile"
                            width="200px"
                            height={"200px"}
                          />
                        )}
                      </>
                    ) : (
                      <div>No Preview Available</div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  {productMainImageStatusCode?.length > 0 &&
                  productMainImageStatusCode[0] !== 200 ? (
                    <p className="RequiredField">
                      Image upload failed into server!
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>
                        Name<span>*</span>
                      </Label>
                      <Input
                        name="name"
                        id="name"
                        className="form-control"
                        placeholder="Enter Name"
                        value={form?.name}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "name",
                            value: e.target.value,
                          });
                        }}
                      />
                      {Error.name && !form?.name ? (
                        <p className="RequiredField">{Error?.name} </p>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>
                        Phone{" "}
                        <span>
                          {form?.role?.label === "ORGANIZATION ADMIN" ||
                          form?.role?.label === "ORGANIZATION MANAGER" ||
                          form?.role?.label === "SUPER ADMIN" ||
                          form?.role?.label === "ADMIN"
                            ? "*"
                            : ""}
                        </span>
                      </Label>
                      <Input
                        name="Phone"
                        id="Phone"
                        type="number"
                        className="form-control"
                        placeholder="Ex: 7993149592"
                        value={form?.phone}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "phone",
                            value:
                              e.target.value?.length <= 10
                                ? e.target.value
                                : form?.phone,
                          });
                          handleChangeDebounce({
                            name: "phone",
                            value: e.target.value,
                          });
                          if (
                            e.target.value?.length <= 9 ||
                            e.target.value?.length > 1
                          ) {
                            handleChangeDebounceForMobile();
                          }
                          if (
                            e.target.value?.length === 0 ||
                            e.target.value?.length === 10
                          ) {
                            setMobileError("");
                          }
                        }}
                      />
                      {Error.phone && !form?.phone ? (
                        <p className="RequiredField">{Error?.phone} </p>
                      ) : (
                        ""
                      )}
                      <p className="RequiredField">{errorMobile}</p>

                      {form?.phone && form?.phone === filterValue?.phone ? (
                        <p className="RequiredField">
                          Phone numer already exists!
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>
                        Email{" "}
                        <span>
                          {form?.role?.label === "ORGANIZATION ADMIN" ||
                          form?.role?.label === "ORGANIZATION MANAGER" ||
                          form?.role?.label === "SUPER ADMIN" ||
                          form?.role?.label === "ADMIN"
                            ? "*"
                            : ""}
                        </span>
                      </Label>
                      <Input
                        // type="email"
                        // name="email"
                        // id="email"
                        className="form-control textLower"
                        placeholder="Enter Email"
                        value={form?.email?.length > 0 ? form?.email : ""}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "email",
                            value: e.target.value,
                          });
                        }}
                      />
                      {Error.email && !form?.email ? (
                        <p className="RequiredField">{Error?.email} </p>
                      ) : (
                        ""
                      )}
                      {form?.email &&
                      form?.email.toLowerCase() ===
                        filterValue?.email.toLowerCase() ? (
                        <p className="RequiredField">Email already exists!</p>
                      ) : (
                        ""
                      )}
                      {form?.email?.length
                        ? !form?.email.match(/\S+@\S+\.\S+/) && (
                            <p className="RequiredField">Invalid email! </p>
                          )
                        : ""}
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={8}>
                      <Label>Address</Label>
                      <Input
                        name="address"
                        id="address"
                        className="form-control"
                        placeholder="Enter Company Address"
                        value={form?.address}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "address",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>State</Label>
                      <Select
                        className="React_selector text-white"
                        classNamePrefix="react-select"
                        placeholder="Select"
                        isSearchable
                        name="state"
                        id="state"
                        value={form?.state}
                        options={statesList}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "state",
                            value: e,
                          });
                        }}
                        //   onInputChange={(e) => handleOnInputChange(e)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Pin Code</Label>
                      <Input
                        name="pincode"
                        id="pincode"
                        type="text"
                        maxLength={7}
                        className="form-control"
                        placeholder="Ex: 524302"
                        value={form?.pinCode === 0 ? "" : form?.pinCode}
                        onChange={(e: any) => {
                          // handleOnInputChange({
                          //   name: "pinCode",
                          //   value:
                          //     e.target.value?.length <= 6
                          //       ? e.target.value
                          //       : form?.pinCode,
                          // });
                          formatPinCode(e.target.value);

                          if (
                            e.target.value?.length <= 7 ||
                            e.target.value?.length > 1
                          ) {
                            handleChangeDebounceForMobile({
                              name: "pinCode",
                            });
                          }
                          if (
                            e.target.value?.length === 0 ||
                            (e.target.value?.length === 6 &&
                              regexForPinCode.test(form?.pinCode))
                          ) {
                            setPinCard("");
                          }
                        }}
                      />
                      <p className="RequiredField">{errorPinCard}</p>
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Aadhaar Card</Label>
                      <Input
                        name="AdharCard"
                        id="AdharCard"
                        type="text"
                        maxLength={14}
                        className="form-control"
                        placeholder="Ex: 9554 0465 4956"
                        value={form?.adharCard}
                        onChange={(e: any) => {
                          formatAadhar(e.target.value);

                          // handleOnInputChange({
                          //   name: "adharCard",
                          //   value:
                          //     e.target.value?.length <= 12
                          //       ? e.target.value
                          //       : form?.adharCard,
                          // });
                          if (
                            e.target.value?.length <= 14 ||
                            e.target.value?.length > 1
                          ) {
                            handleChangeDebounceForMobile({
                              name: "adharCard",
                            });
                          }
                          if (
                            e.target.value?.length === 0 ||
                            (e.target.value?.length === 14 &&
                              regexpForAdhar.test(form?.adharCard))
                          ) {
                            setAdharCard("");
                          }
                        }}
                      />
                      <p className="RequiredField">{errorAdharCard}</p>
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Pan Card</Label>
                      <Input
                        name="PanCard"
                        id="PanCard"
                        className="form-control textUpper"
                        placeholder="Ex: HIPPK2631L"
                        value={form?.panCard}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "panCard",
                            value:
                              e.target.value?.length <= 10
                                ? e.target.value
                                : form?.panCard,
                          });
                          if (
                            e.target.value?.length <= 10 ||
                            e.target.value?.length > 1
                          ) {
                            handleChangeDebounceForMobile({
                              name: "panCard",
                            });
                          }
                          if (
                            e.target.value?.length === 0 ||
                            (e.target.value?.length === 10 &&
                              regex.test(form?.panCard))
                          ) {
                            setPanCard("");
                          }
                        }}
                      />
                      <p className="RequiredField">{errorPanCard}</p>
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  {" "}
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Artisan Card</Label>
                      <Input
                        name="artisanCard"
                        id="artisanCard"
                        className="form-control"
                        placeholder="Enter Number"
                        value={form?.artisanCard}
                        onChange={(e: any) =>
                          handleOnInputChange({
                            name: "artisanCard",
                            value: e.target.value,
                          })
                        }
                      />
                    </Col>
                    {form?.role?.label === "ADMIN" ||
                    form?.role?.label === "ORGANIZATION ADMIN" ||
                    form?.role?.label === "ORGANIZATION MANAGER" ? (
                      <Col xs={12} md={4} className="">
                        <Label>Password</Label>
                        <div style={{ position: "relative" }}>
                          {" "}
                          <Input
                            style={{
                              textTransform: "none",
                            }}
                            placeholder="Enter Password"
                            type={show === true ? "text" : "password"}
                            value={form?.password}
                            className="inputField"
                            onChange={(e: any) =>
                              handleOnInputChange({
                                name: "password",
                                value: e.target.value,
                              })
                            }
                          />
                          <div className="icon">
                            {show ? (
                              <HideIcon onClick={() => handleSetShow(false)} />
                            ) : (
                              <ShowIcon onClick={() => handleSetShow(true)} />
                            )}
                          </div>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>
              </div>
            </div>
            <div className="UserDetailsDiv mb-5">
              <div className="UserHeader">Business Details</div>
              <div className="userPd">
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>
                        Artform<span>*</span>
                      </Label>
                      <Select
                        className="React_selector text-white"
                        classNamePrefix="react-select"
                        placeholder="Select"
                        //
                        isSearchable
                        name="artform"
                        id="artform"
                        value={form?.artform}
                        options={artformOptions}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "artform",
                            value: e,
                          });
                        }}
                        //   onInputChange={(e) => handleOnInputChange(e)}
                      />
                      {Error.artform && !form?.artform?.value ? (
                        <p className="RequiredField">{Error?.artform} </p>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>
                        Role<span>*</span>
                      </Label>
                      <div className="formDivSub">
                        <Select
                          className="React_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isSearchable
                          name="Role"
                          id="Role"
                          value={form?.role}
                          options={userRolesOptions}
                          onChange={(e: any) => {
                            handleOnInputChange({
                              name: "role",
                              value: e,
                            });
                          }}
                          //   onInputChange={(e) => handleOnInputChange(e)}
                        />
                        <div className="DivSep"></div>
                        <div className="formAddDiv">
                          <AddIcon openModal={handleOpenRoleModal} />
                        </div>
                      </div>
                      {Error.role && !form?.role?.value ? (
                        <p className="RequiredField">{Error?.role} </p>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>
                        Organization<span>*</span>
                      </Label>
                      <Select
                        className="React_selector text-white"
                        classNamePrefix="react-select"
                        placeholder="Select"
                        //
                        isSearchable
                        isDisabled={
                          authUser?.users?.role === "ORGANIZATION_MANAGER" ||
                          authUser?.users?.role === "ORGANIZATION_ADMIN"
                            ? true
                            : false
                        }
                        name="Organization"
                        id="Organization"
                        isMulti={
                          form?.role?.label === "NAQSEBAND" ? true : false
                        }
                        value={form?.organization}
                        options={organisationOption}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "organization",
                            value: e,
                          });
                        }}
                        //   onInputChange={(e) => handleOnInputChange(e)}
                      />
                      {form?.role?.label === "NAQSEBAND" ? (
                        Error.organization && !form?.organization[0]?.value ? (
                          <p className="RequiredField">
                            {Error?.organization}{" "}
                          </p>
                        ) : (
                          ""
                        )
                      ) : Error.organization && !form?.organization?.value ? (
                        <p className="RequiredField">{Error?.organization} </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    {/* <Col xs={12} md={4}>
                      <Label>Domain</Label>
                      <Select
                        className="React_selector text-white"
                        classNamePrefix="react-select"
                        placeholder="Select"
                        //
                        isSearchable
                        name="Domain"
                        id="Domain"
                        value={form?.domain}
                        options={[
                          {
                            value: "Domain A",
                            label: "Domain A",
                          },
                          {
                            value: "Domain B",
                            label: "Domain B",
                          },
                          {
                            value: "Domain C",
                            label: "Domain C",
                          },
                        ]}
                        onChange={(e: any) => {
                          handleOnInputChange({
                            name: "domain",
                            value: e,
                          });
                        }}
                        //   onInputChange={(e) => handleOnInputChange(e)}
                      />
                    </Col> */}
                  </Row>
                  <Button className="artButton" onClick={() => handleSubmit()}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    userDetails: state?.usersReducer?.getUsersDetails,
  };
};
export default connect(mapStateToProps, {
  getUserRoles,
  getOrganisation,
  getArtforms,
  updateUsers,
  fetchProfilePresignedUrl,
  uploadImages1,
  getUsers,
  createUserRole,
})(EditUser);
