import React from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
const DateRangeSearchFilter = (props: any) => {
  const { onChange }: any = props;
  const MyIcon = React.forwardRef((props, ref) => {
    return (
      <svg
        className="MyIcon"
        width="20"
        height="20"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 6.31131C14 6.38134 14 6.43823 14 6.49951C14 8.27643 14 10.0533 14 11.8346C14 12.8763 13.3658 13.7079 12.3949 13.9398C12.2156 13.9836 12.0275 13.9967 11.8438 13.9967C8.62052 14.0011 5.39286 14.0011 2.16957 13.9967C1.06745 13.9967 0.223357 13.2921 0.0352953 12.2242C0.0134277 12.106 0.0134277 11.9835 0.0134277 11.8653C0.0134277 10.0665 0.0134277 8.26767 0.0134277 6.46449C0.0134277 6.41635 0.0178013 6.36821 0.0221748 6.31131C4.67123 6.31131 9.32028 6.31131 14 6.31131Z"
          fill="#404B3B"
        />
        <path
          d="M11.2009 2.10098C11.4371 2.10098 11.6514 2.10098 11.8657 2.10098C13.0903 2.10973 13.9913 3.01132 14 4.24116C14 4.45562 14 4.6657 14 4.88891C9.33345 4.88891 4.6844 4.88891 0.0265932 4.88891C-0.0565037 4.02233 0.0222197 3.20827 0.743851 2.59992C1.09811 2.30231 1.50484 2.12724 1.96844 2.10536C2.2396 2.09223 2.51075 2.10536 2.80378 2.10536C2.80378 1.86026 2.80378 1.63268 2.80378 1.40509C2.80378 1.18188 2.80378 0.958675 2.80378 0.731089C2.80815 0.315307 3.09681 0.0089414 3.49042 0.000188097C3.89279 -0.00856521 4.19456 0.289047 4.20331 0.713582C4.21205 1.10748 4.20331 1.50138 4.20331 1.89528C4.20331 1.95655 4.20331 2.01345 4.20331 2.08785C6.0708 2.08785 7.92518 2.08785 9.79704 2.08785C9.79704 1.95655 9.79704 1.83401 9.79704 1.70708C9.79704 1.37883 9.79267 1.05058 9.79704 0.722336C9.80579 0.2978 10.1032 -0.00418855 10.5056 0.000188097C10.9035 0.00456475 11.1966 0.31093 11.2009 0.735465C11.2053 1.18188 11.2009 1.63268 11.2009 2.10098Z"
          fill="#404B3B"
        />
      </svg>
    );
  });
  return (
    <>
      <DateRangePicker
        {...props}
        // appearance="subtle"
        placeholder="Start Date ~ End Date"
        onChange={(datevalues) => {
          onChange(datevalues);
        }}
        caretAs={MyIcon}
      />
    </>
  );
};

export default DateRangeSearchFilter;
