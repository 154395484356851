import React, { useState, useEffect } from "react";
import QRCodeProduct from "./common components/QRCodeProduct";
import EMailVerification from "./EMailVerification";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  getProductByIdProductCertification,
  getProductByIdAfterKycToken,
} from "../../Store/products/actions";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

const ProductVerification = (props: any) => {
  let productVerficationCode = localStorage.getItem("productVerificationToken");

  const [kycFlag, setKycFlag]: any = useState(false);
  const [openModal, setOpenModal]: any = useState(
    productVerficationCode?.length ? false : true
  );
  const closeModal = (value: any) => {
    setOpenModal(value);
  };
  const location = useLocation();

  const [loading, setLoading]: any = useState(false);

  const ProductId: any = location?.pathname.split("/")[2];

  const kycFlagFun = (value: any) => {
    setKycFlag(value);
  };
  useEffect(() => {
    if (productVerficationCode?.length) {
      if (ProductId && kycFlag === false) {
        getAllData();
      }
    }
  }, [ProductId, productVerficationCode]);

  const getAllDataFromGetProducts = () => {
    setLoading(true);
    props.getProductByIdAfterKycToken(
      {
        offset: 0,
        limit: 10,
        ProductId: ProductId,
        // ProductsName:ProductName
      },
      (res: any) => {
        setLoading(false);
      }
    );
  };
  let editDetails: any;
  const getAllData = () => {
    setLoading(true);
    props.getProductByIdProductCertification(
      {
        offset: 0,
        limit: 10,
        ProductId: ProductId,
        // ProductsName: ProductName,
      },
      (res: any) => {
        setLoading(false);
        if (
          res.status === 401 ||
          res.message === "UnAuthorized!" ||
          res.message === "Unauthorized!"
        ) {
          setOpenModal(true);
        }
      }
    );
  };

  if ((props.ViewProductDetails || props.ViewProductDetails1) !== undefined) {
    editDetails =
      props.ViewProductDetails?.data?.docs[0] ||
      props.ViewProductDetails1?.data?.docs[0];
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        // toggle={closeModal}
        size="lg"
        className="ArtFormModal"
      >
        <ModalHeader>
          <div className="Heading">
            <h3>Sign Up</h3>
          </div>
        </ModalHeader>
        <ModalBody>
          <EMailVerification
            {...props}
            closeModal={closeModal}
            getAllData={getAllDataFromGetProducts}
            kycFlagFun={kycFlagFun}
          />
        </ModalBody>
      </Modal>
      <QRCodeProduct {...props} loading={loading} editDetails={editDetails} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    ViewProductDetails: state?.productReducer?.productCertificationDetails,
    ViewProductDetails1: state?.productReducer?.productDetailAfterToken,
  };
};
export default connect(mapStateToProps, {
  getProductByIdProductCertification,
  getProductByIdAfterKycToken,
})(ProductVerification);
