import React, { useState, useEffect } from "react";
import { Button, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import notifications from "../../../common/Components/Notifications/Notification";
import { createFakeAlertDetails } from "../../../Store/products/actions";
import { useHistory } from "react-router-dom";
import fakeAlert from "../assets/images/Visual 80 80.png";
import HkvBadge from "../assets/images/HKP Badge.png";
const ProductFakeAlertInfomation = (props: any) => {
  const history: any = useHistory();
  const [form, setForm]: any = useState({
    name: "",
    address: "",
    comment: "",
  });
  const [Error, setError]: any = useState({
    name: "",
    address: "",
  });
  const validate = () => {
    let error = "";
    let err = Error;
    if (!form?.name) {
      error = "Required";
      err = {
        ...err,
        name: " Shop name is required!",
      };
    }
    if (!form?.address) {
      error = "Required";
      err = {
        ...err,
        address: " Shop address is required!",
      };
    }

    setError(err);
    return error;
  };
  const handleChangeValues = (type: any) => {
    setForm({
      ...form,
      [type?.name]: type?.value,
    });
  };
  const handleSubmit = () => {
    let payload: any = {
      shopName: form?.name,
      shopAddress: form?.address,
      comment: form?.comment,
    };
    let result: any = validate();
    if (result?.length === 0) {
      props.createFakeAlertDetails(payload, (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          history.push("/product-reported");
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      });
    }
  };
  return (
    <div className="productfakeAlert">
      <div className="fakealDiv1">
        <img src={fakeAlert} alt="Fake Alert" />
      </div>
      <div className="fakeAlInfo">
        <h3>Fake alert</h3>
        <br />
        <span>
          Hastkala Pramanak do not certify <br /> the authenticity of this
          product
        </span>
      </div>
      <div className="reportAndLine">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="350"
          height="2"
          viewBox="0 0 350 2"
          fill="none"
        >
          <path d="M0 1H350" stroke="#E2E8F0" />
        </svg>
        <h4>Report product</h4>
      </div>
      <div>
        <div className="InputFormDiv">
          <Label>Shop name</Label>
          <Input
            type="text"
            placeholder="Enter shop name"
            value={form?.name}
            onChange={(e: any) =>
              handleChangeValues({
                name: "name",
                value: e.target.value,
              })
            }
          />
          {Error.name && !form?.name ? (
            <p className="RequiredField">{Error?.name} </p>
          ) : (
            ""
          )}
        </div>
        <div className="InputFormDiv">
          <div className="inputform">
            <Label>Shop address</Label>
            <Input
              style={{
                paddingRight: "55px",
              }}
              type="text"
              placeholder="Shop address"
              value={form?.address}
              onChange={(e: any) =>
                handleChangeValues({
                  name: "address",
                  value: e.target.value,
                })
              }
            />

            <div className="locationIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
                  fill="#0084FF"
                />
                <path
                  d="M23 11H21.949C21.479 6.283 17.717 2.521 13 2.051V1C13 0.448 12.553 0 12 0C11.447 0 11 0.448 11 1V2.051C6.283 2.521 2.521 6.283 2.051 11H1C0.447 11 0 11.448 0 12C0 12.552 0.447 13 1 13H2.051C2.522 17.717 6.283 21.479 11 21.949V23C11 23.552 11.447 24 12 24C12.553 24 13 23.552 13 23V21.949C17.717 21.478 21.479 17.717 21.949 13H23C23.553 13 24 12.552 24 12C24 11.448 23.553 11 23 11ZM12.986 19.933C12.95 19.414 12.529 19 12 19C11.471 19 11.05 19.414 11.014 19.933C7.392 19.485 4.516 16.609 4.068 12.987C4.587 12.95 5 12.528 5 12C5 11.472 4.587 11.05 4.067 11.014C4.515 7.392 7.391 4.516 11.013 4.068C11.05 4.586 11.471 5 12 5C12.529 5 12.95 4.586 12.986 4.067C16.608 4.515 19.484 7.391 19.932 11.013C19.413 11.05 19 11.472 19 12C19 12.528 19.413 12.95 19.933 12.986C19.484 16.609 16.609 19.484 12.986 19.933Z"
                  fill="#0084FF"
                />
              </svg>
            </div>
          </div>{" "}
          {Error.address && !form?.address ? (
            <p className="RequiredField">{Error?.address} </p>
          ) : (
            ""
          )}
        </div>
        <div className="InputFormDiv">
          <Label>Comment</Label>
          <Input
            type="textarea"
            placeholder="Enter the product name/type, price, seller details, etc."
            value={form?.comment}
            onChange={(e: any) =>
              handleChangeValues({
                name: "comment",
                value: e.target.value,
              })
            }
          />
        </div>
        <div
          className="sendButtonDiv"
          style={{
            height: "200px",
          }}
        >
          <Button
            style={{
              opacity: form?.name && form?.address && form?.comment ? 1 : 0.5,
            }}
            disabled={
              form?.name && form?.address && form?.comment ? false : true
            }
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
          <div className="HkvDiv3">
            <img className="HkvBadge" src={HkvBadge} alt="HKV badge" />
          </div>{" "}
        </div>{" "}
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, { createFakeAlertDetails })(
  ProductFakeAlertInfomation
);
