import {types } from "./actionTypes";

// creating action
export const getApplication = (callback:any) =>{
    return {
        type:types.GET_APPLICATION,
        callback
    }
}

export const createApplication = (payload:any, callback:any) =>{
    return {
        type:types.CREATE_APPLICATION,
        payload,
        callback
    }
}
export const updateApplication = (payload:any, callback:any) =>{
    return {
        type:types.UPDATE_APPLICATION,
        payload,
        callback
    }
}
export const deleteApplication = (payload:any, callback:any) =>{
    return {
        type:types.DELETE_APPLICATION,
        payload,
        callback
    }
}