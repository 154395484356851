import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import AddOrEditArtForm from "./AddOrEditArtForm";
import ModalCloseIcon from "../../common/Components/Icons/ModalCloseIcon";
const ArtformModal = (props: any) => {
  const { openModal, closeModal, editData, getAllData,handleName} = props;
  return (
    <Modal
      isOpen={openModal}
      toggle={closeModal}
      size="lg"
      className="ArtFormModal"
      fade={false}
    >
      <ModalHeader>
        <div className="Heading">
          <h3>{editData?._id ? "Edit Artform" : "Add New Artform"}</h3>
          <ModalCloseIcon {...props} closeModal={closeModal} />
        </div>
      </ModalHeader>
      <ModalBody>
        <AddOrEditArtForm
          {...props}
          closeModal={closeModal}
          editData={editData}
          getAllData={getAllData}
          handleName={handleName}
        />
      </ModalBody>
    </Modal>
  );
};

export default ArtformModal;
