import React from 'react'

const SortDescendingIcon = (props:any) => {
  return (
    <svg
    onClick={props.handleSort}
     className="cursor-pointer"
     width="10"
     height="15"
     viewBox="0 0 7 13"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
   >
     <path
       d="M4 1C4 0.723858 3.77614 0.5 3.5 0.5C3.22386 0.5 3 0.723858 3 1H4ZM3.5 12.5L6.38675 7.5H0.613249L3.5 12.5ZM3 1V8H4V1H3Z"
       fill="#C59C6C"
     />
   </svg>
  )
}

export default SortDescendingIcon