import { types } from "./actionTypes";
const initialState = {
  gettypeDetails: null,
  updatetypeDetails: null,
  createtypeDetails: null,
  deletetypeDetails: null,
  gettypeFilterDetails: null,

};
const typeReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.GET_TYPES_SUCCESS:
      state = {
        ...state,
        gettypeDetails: action.payload,
      };
      return state;
      case types.GET_TYPES_FILTERS_SUCCESS:
      state = {
        ...state,
        gettypeFilterDetails: action.payload,
      };
      return state;
    case types.CREATE_TYPES_SUCCESS:
      state = {
        ...state,
        createtypeDetails: action.payload,
      };
      return state;
    case types.UPDATE_TYPES_SUCCESS:
      state = {
        ...state,
        updatetypeDetails: action.payload,
      };
      return state;
    case types.DELETE_TYPES_SUCCESS:
      state = {
        ...state,
        deletetypeDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default typeReducer;
