import { types } from "./actionTypes";
const initialState = {
  getProductDetails: null,
  getProductByIdDetails: null,
  updateProductDetails: null,
  createProductDetails: null,
  deleteProductDetails: null,
  productCertificationDetails: null,
  productDetailAfterToken: null,
};
const productReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.GET_PRODUCTS_SUCCESS:
      state = {
        ...state,
        getProductDetails: action.payload,
      };
      return state;
    case types.GET_PRODUCTS_BY_ID_SUCCESS:
      state = {
        ...state,
        getProductByIdDetails: action.payload,
      };
      return state;
    case types.GET_PRODUCTS_BY_ID_SUCCESS_PRODUCT_CERTIFICATION:
      state = {
        ...state,
        productCertificationDetails: action.payload,
      };
      return state;
    case types.GET_PRODUCT_BY_ID_AFTER_KYC_TOKEN_SUCCESS:
      state = {
        ...state,
        productDetailAfterToken: action.payload,
      };
      return state;
    case types.CREATE_PRODUCTS_SUCCESS:
      state = {
        ...state,
        createProductDetails: action.payload,
      };
      return state;
    case types.UPDATE_PRODUCTS_SUCCESS:
      state = {
        ...state,
        updateProductDetails: action.payload,
      };
      return state;
    case types.DELETE_PRODUCTS_SUCCESS:
      state = {
        ...state,
        deleteProductDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default productReducer;
