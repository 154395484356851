import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { createUserRole } from "../../Store/users/actions";
import ModalCloseIcon from "../../common/Components/Icons/ModalCloseIcon";
import Loader from "../../common/Components/Loader/Loader";
import notifications from "../../common/Components/Notifications/Notification";

const CreateRole = (props: any) => {
  const { openModal, closeModal, getUserRolesData } = props;
  const [loading, setLoading]: any = useState(false);
  const [form, setForm]: any = useState({
    role: "",
    roleDescription: "",
  });
  const [Error, setError]: any = useState({
    role: "",
  });
  const handleChange = (type: any) => {
    setForm({
      ...form,
      [type?.name]: type.value,
    });
  };
  const validate = () => {
    let error = "";
    if (form?.role === "") {
      error = "Required";
      setError({
        role: "Role is Required!",
      });
    }
    return error;
  };
  const handleSubmit = () => {
    let result: any = validate();
    if (result?.length === 0) {
      let payload = {
        role: form?.role,
        desc: form?.roleDescription,
      };
      props.createUserRole(payload, (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          setLoading(false);
          getUserRolesData();
          closeModal();
          setForm("");
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      });
    }
  };
  return (
    <Modal
      isOpen={openModal}
      toggle={closeModal}
      size="lg"
      className="ArtFormModal"
      fade={false}
    >
      <ModalHeader>
        <div className="Heading">
          <h3>Add Role</h3>
          <ModalCloseIcon {...props} closeModal={closeModal} />
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="AddArtform">
          <div className="form">
            {loading ? (
              <div className="LoaderDivModal">
                <Loader />
              </div>
            ) : (
              <>
                <div className="formDiv">
                  <Label>
                    Role <span>*</span>
                  </Label>
                  <Input
                    name="role"
                    className="form-control"
                    placeholder="Role"
                    value={form?.role}
                    onChange={(e: any) => {
                      handleChange({
                        name: "role",
                        value: e.target.value,
                      });
                    }}
                  />
                  {Error.role && !form?.role ? (
                    <p className="RequiredField">{Error?.role} </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="formDiv">
                  <Label>Description</Label>
                  <Input
                    name="artform"
                    id="artform"
                    className="form-control"
                    placeholder="Description"
                    value={form?.roleDescription}
                    onChange={(e: any) => {
                      handleChange({
                        name: "roleDescription",
                        value: e.target.value,
                      });
                    }}
                  />
                </div>
              </>
            )}
            <Button className="artButton" onClick={() => handleSubmit()}>
              {loading ? "Processing" : "Add"}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  createUserRole,
})(CreateRole);
