import React from "react";

const DocxIcon = (props: any) => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.20249 0.883789C10.7098 0.883789 14.2171 0.883789 17.7244 0.883789C17.7244 3.63953 17.7244 6.39527 17.7244 9.15101C17.7244 9.3389 17.7244 9.46416 17.787 9.65206C20.6054 9.65206 23.4238 9.65206 26.1795 9.65206C26.1795 9.83995 26.2422 9.96521 26.2422 10.1531C26.2422 15.6646 26.2422 21.2387 26.2422 26.7502C26.2422 26.8754 26.2422 27.0633 26.2422 27.1886C26.1169 29.2554 24.4885 30.8838 22.3591 30.8838C17.4739 30.8838 12.5261 30.8838 7.6409 30.8838C6.76408 30.8838 5.94988 30.5706 5.26094 30.007C4.25886 29.1928 3.75781 28.128 3.75781 26.8754C3.75781 19.5477 3.75781 12.1573 3.75781 4.82951C3.75781 4.70425 3.75781 4.64162 3.75781 4.51636C3.82044 2.7627 5.07305 1.3222 6.76408 0.94642C6.95197 0.94642 7.07723 0.94642 7.20249 0.883789ZM7.45301 17.293C7.45301 19.0466 7.45301 20.675 7.45301 22.3034C8.20458 22.3034 8.89351 22.366 9.58245 22.3034C10.8977 22.1782 11.7119 21.364 11.7745 20.0487C11.8371 18.7335 11.2108 17.7314 10.0209 17.4809C9.20666 17.3556 8.32984 17.3556 7.45301 17.293ZM14.8434 17.293C13.3403 17.293 12.2756 18.3577 12.2129 19.7982C12.2129 21.3013 13.2776 22.366 14.7808 22.366C16.2839 22.366 17.3486 21.3013 17.3486 19.8608C17.4113 18.3577 16.2839 17.293 14.8434 17.293ZM22.6096 19.0466C22.547 18.3577 22.2338 17.9193 21.6701 17.6061C20.9812 17.2303 20.2296 17.1677 19.4781 17.4182C18.2255 17.794 17.5365 19.0466 17.8497 20.4871C18.0376 21.4266 18.6012 21.9903 19.4781 22.2408C20.4175 22.4913 21.357 22.4287 22.0459 21.6771C22.2964 21.4266 22.4217 21.1134 22.6096 20.8003C22.1085 20.675 21.6701 20.6124 21.2943 20.4871C21.1065 21.0508 20.7933 21.3013 20.2296 21.3013C19.7286 21.3013 19.3528 21.0508 19.1649 20.5498C18.977 20.0487 18.977 19.5477 19.1649 19.0466C19.3528 18.6082 19.7286 18.3577 20.2296 18.3577C20.7307 18.3577 21.0438 18.6082 21.2317 19.0466C21.2317 19.1093 21.2943 19.1719 21.2943 19.2345C21.7954 19.2345 22.1712 19.1093 22.6096 19.0466Z"
        fill="#A6753E"
      />
      <path
        d="M17.7872 0.883789C18.7893 1.94851 19.7914 3.01323 20.7935 4.01531C22.5471 5.8316 24.3008 7.64788 26.0544 9.46416C26.1171 9.52679 26.1797 9.58943 26.2423 9.65206C23.4866 9.65206 20.6682 9.65206 17.8498 9.65206C17.8498 9.46416 17.7872 9.27627 17.7872 9.15101C17.7246 6.39527 17.7246 3.63953 17.7872 0.883789Z"
        fill="#8C673E"
      />
      <path
        d="M7.45312 17.293C8.32995 17.3556 9.20678 17.293 10.021 17.4809C11.211 17.7314 11.8999 18.7335 11.7746 20.0487C11.712 21.3013 10.8352 22.1781 9.58256 22.3034C8.89363 22.366 8.20469 22.3034 7.45312 22.3034C7.45312 20.675 7.45312 19.0466 7.45312 17.293ZM8.70573 18.4203C8.70573 19.3598 8.70573 20.2992 8.70573 21.2387C9.33204 21.2387 9.95834 21.2387 10.3341 20.675C10.6473 20.174 10.6473 19.5477 10.3341 19.0466C9.95834 18.4203 9.33204 18.4203 8.70573 18.4203Z"
        fill="#FEFEFE"
      />
      <path
        d="M14.8428 17.293C16.3459 17.293 17.4106 18.3577 17.4106 19.8608C17.4106 21.3013 16.3459 22.366 14.8428 22.366C13.3397 22.366 12.2749 21.3013 12.2749 19.7982C12.2123 18.3577 13.3397 17.293 14.8428 17.293ZM13.4649 19.8608C13.4649 20.5498 13.8407 21.1134 14.4044 21.2387C15.1559 21.4266 15.7823 21.0508 16.0328 20.2992C16.2833 19.485 15.9075 18.6082 15.156 18.4203C14.2791 18.1698 13.4649 18.8587 13.4649 19.8608Z"
        fill="#FEFEFE"
      />
      <path
        d="M22.6092 19.0466C22.1708 19.1093 21.795 19.2345 21.4193 19.2972C21.3566 19.2345 21.3566 19.1719 21.3566 19.1093C21.1687 18.6709 20.8556 18.4203 20.3545 18.4203C19.8535 18.4203 19.4777 18.6709 19.2898 19.1093C19.1019 19.6103 19.1019 20.1114 19.2898 20.6124C19.4777 21.0508 19.8535 21.3013 20.3545 21.364C20.8556 21.364 21.2314 21.1134 21.4193 20.5498C21.8577 20.6124 22.2334 20.7377 22.7345 20.8629C22.5466 21.2387 22.3587 21.5519 22.1708 21.7398C21.4193 22.4913 20.5424 22.5539 19.603 22.3034C18.7261 22.0529 18.1625 21.4266 17.9746 20.5498C17.6614 19.1093 18.3504 17.8567 19.603 17.4809C20.3545 17.2304 21.1061 17.293 21.795 17.6688C22.2334 17.9193 22.5466 18.3577 22.6092 19.0466Z"
        fill="#FEFEFE"
      />
      <path
        d="M8.70521 18.4203C9.33151 18.3577 9.95782 18.4203 10.271 18.984C10.5841 19.485 10.5841 20.1113 10.271 20.6124C9.89519 21.1761 9.33151 21.2387 8.64258 21.1761C8.70521 20.2992 8.70521 19.4224 8.70521 18.4203Z"
        fill="#A6753E"
      />
      <path
        d="M13.4658 19.8608C13.4658 18.8587 14.2174 18.1698 15.1568 18.4203C15.9084 18.6082 16.2842 19.485 16.0337 20.2992C15.8458 21.0508 15.1568 21.4266 14.4053 21.2387C13.8416 21.1134 13.4658 20.5498 13.4658 19.8608Z"
        fill="#A6753E"
      />
    </svg>
  );
};

export default DocxIcon;
