import React from "react";

const InputSearch = (props: any) => {
  const { name, placeholder, onChange, onKeyDown,value,id }: any = props;
  return (
    <div className="InputDiv">
      <input
        type="text"
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {/* <svg
        className="InputSvg"
        width="18"
        height="18"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.66277 10.6016C7.16712 11.4531 5.59397 11.6466 3.95883 11.128C2.67242 10.7215 1.65336 9.93581 0.924906 8.79784C-0.605619 6.42127 -0.202645 3.37121 1.88197 1.46685C3.87359 -0.356216 6.98888 -0.491688 9.13549 1.12624C11.4255 2.84867 12.1462 6.04582 10.6156 8.64688C10.6583 8.67398 10.7009 8.70107 10.7435 8.72817C11.6076 9.23909 12.4368 9.79646 13.2117 10.4351C13.7271 10.8609 14.0254 11.395 13.9983 12.0724C13.9634 12.9007 13.5566 13.5123 12.7894 13.8297C12.0222 14.1471 11.286 14.0232 10.697 13.4387C10.325 13.071 10.0151 12.6375 9.70895 12.2079C9.3641 11.724 9.058 11.2131 8.73639 10.7138C8.71315 10.6751 8.6899 10.6403 8.66277 10.6016ZM2.12995 5.70133C2.12995 7.67535 3.74185 9.28167 5.71797 9.28167C7.69021 9.28167 9.29436 7.67922 9.29823 5.70907C9.30211 3.73118 7.69796 2.12486 5.71797 2.12099C3.74572 2.11712 2.12995 3.72731 2.12995 5.70133Z"
          fill="#404B3B"
        />
      </svg> */}
    </div>
  );
};

export default InputSearch;
