import React from "react";
import HkvBadge from "../assets/images/HKP Badge.png";
export const ProductSomethingWentToWrong = () => {
  return (
    <div className="productfakeAlert">
      <div className="fakeAlertSubDIV">
        <div className="fakealDiv">
          <img src={HkvBadge} alt="Fake Alert" />
        </div>
        <div className="fakealDiv2">
          <h3>
            we are experiencing <br />
            some issues, <br />
            Please try again later.
          </h3>
        </div>
      </div>
    </div>
  );
};
