import React, { useState, useEffect } from "react";
import { Label, Input, Button, Row, Col, FormGroup } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import QRCode from "react-qr-code";
import ReactChipInput from "react-chip-input";
import { Toggle } from "rsuite";

//components
import Loader from "../../common/Components/Loader/Loader";
import FilePreview from "./FilePreviewProduct";
import { baseImageCovert, baseImageCovertForLogoPin } from "./baseImageConver";
import Pin from "../../common/Components/Icons/Pin";
import Unpin from "../../common/Components/Icons/Unpin";

// actions
import { getProducts } from "../../Store/products/actions";
import EditIcon from "../../common/Components/Icons/EditIcon";
import DownloadIcon from "../../common/Components/Icons/DownloadIcon";
import FilePreviewPart from "./FilePreviewPart";
import ShowIcon from "../../common/Components/Icons/ShowIcon";
import HideIcon from "../../common/Components/Icons/HideIcon";
import ViewEyeIcon from "../../common/Components/Icons/ViewEyeIcon";
import ProductImagePreView from "./ProductImagePreView";

const EditProduct = (props: any) => {
  const location: any = useLocation();
  const ProductId: any = location?.pathname.split("/")[2];
  const ProductName: any = location?.state?.productDetails?.name;
  const [loading, setLoading]: any = useState(false);
  const [isOpen, setIsOpen]: any = useState(false);
  const [image, setImage]: any = useState("");
  const history = useHistory();
  const [form, setForm]: any = useState({
    productUniqueId: "",
    artform: "",
    type: "",
    category: "",
    subCategory: "",
    weaveType: "",
    productName: "",
    designer: "",
    designerMedia: [],
    weaver: "",
    weaverMedia: [],
    dyer: "",
    dyerMedia: [],
    finisher: "",
    finisherMedia: [],
    profileURL: "",
    tags: [],
    variants: [],
    yarnType: "",
    workingHours: "",
    loomNum: "",
    color: "",
    productOwner: "",
  });
  useEffect(() => {
    if (ProductId) {
      getAllData();
    }
  }, [ProductId]);
  const getAllData = () => {
    setLoading(true);
    props.getProducts(
      {
        offset: 0,
        limit: 10,
        ProductId: ProductId,
        // ProductsName: ProductName,
      },
      (res: any) => {
        setLoading(false);
        if (res?.data?.docs?.length) {
          let editDetails: any = res?.data?.docs[0];
          let variantArry: any = [];
          let variantBaseDetails = {
            color: editDetails?.color,
            additionalImages: editDetails?.additionalImages?.length
              ? editDetails?.additionalImages
              : [],
          };
          variantArry.push(variantBaseDetails);
          setForm({
            _id: editDetails?._id,
            org: editDetails?.organizations?.length
              ? editDetails?.organizations[0]?.name
              : "",
            productOwner: editDetails?.productOwner,

            productUniqueId: editDetails?.productUniqueId,
            artform: editDetails?.artform?.name,
            type: editDetails?.type?.name,
            category: editDetails?.category?.name,
            subCategory: editDetails?.subcategory?.name,
            weaveType: editDetails?.weavetype?.name,
            productName: editDetails?.name,
            designer: editDetails?.designingDetails?.karigar?.name,
            designerMedia: editDetails?.designingDetails?.media?.length
              ? baseImageCovert(editDetails?.designingDetails?.media)
              : "",
            weaver: editDetails?.weavingDetails?.karigar?.name,
            weaverMedia: editDetails?.weavingDetails?.media?.length
              ? baseImageCovert(editDetails?.weavingDetails?.media)
              : "",
            dyer: editDetails?.dyingDetails?.karigar?.name,
            dyerMedia: editDetails?.dyingDetails?.media?.length
              ? baseImageCovert(editDetails?.dyingDetails?.media)
              : "",
            finisher: editDetails?.finishingDetails?.karigar?.name,
            finisherMedia: editDetails?.finishingDetails?.media?.length
              ? baseImageCovert(editDetails?.finishingDetails?.media)
              : "",
            profileURL: editDetails?.thumbnailDetails?.media?.length
              ? baseImageCovertForLogoPin(editDetails?.thumbnailDetails?.media)
              : editDetails?.thumbnailDetails === null &&
                editDetails?.thumbnail?.length > 0
              ? baseImageCovertForLogoPin([
                  {
                    mediaURL: editDetails?.thumbnail,
                    show: "1",
                    pin: "1",
                  },
                ])
              : [],
            tags: editDetails?.tags ? editDetails?.tags : "",
            variants:
              editDetails?.varients?.length > 0
                ? [...variantArry, ...editDetails?.varients]
                : [...variantArry],
            //new
            nameOfBrand: editDetails?.brand,
            nameOfWeaver: editDetails?.weaver,
            handloomMark: editDetails?.handloom === "true" ? true : false,
            silkMark: editDetails?.silk === "true" ? true : false,
            zariUsed: { label: editDetails?.zari, value: editDetails?.purity },
            weightOfSaree: editDetails?.sareeWeight,
            weightOfZariUsed: editDetails?.zariWeight,
            purity: editDetails?.purity,
            madeInIndia: editDetails?.madeIn === "true" ? true : false,
            yarnType: editDetails?.yarnType,
            workingHours: editDetails?.workingHours,
            loomNum: editDetails?.loomNum,
            xuid: editDetails?.xuid,
            SrNo: editDetails?.srNo,
            weavingCenter: editDetails?.weavingCenter || form?.weavingCenter,
            typeOfSareeByDesign: editDetails?.typeOfSareeByDesign,
            productionTimeInMonths: editDetails?.productionTimeInMonths,
            color: editDetails?.color,
          });
        }
      }
    );
  };
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleSetImage = (image: any) => {
    setImage(image);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      {isOpen && (
        <ProductImagePreView
          {...props}
          openModal={isOpen}
          closeModal={closeModal}
          images={[image?.blobImage || image]}
        />
      )}
      {loading ? (
        <div className="LoaderDiv">
          <Loader />
        </div>
      ) : (
        <div className="createProduct">
          <div className="form">
            <div className="UserDetailsDiv">
              <div className="UserHeader">
                Product Details
                <span
                  className="editBtn"
                  onClick={() => {
                    history.push(`/edit-product/${form?.productUniqueId}`, {
                      productDetails: form,
                    });
                  }}
                >
                  {/* <EditIcon /> */}
                  <Button>Edit Product Details</Button>
                </span>
              </div>
              <div className="userPd">
                <div className="ViewImageQrCode">
                  {/* <div className="imageDiv">
                    {form?.profileURL?.length ? (
                      <>
                        <img
                          src={form?.profileURL}
                          alt="logo"
                          width="200px"
                          height="200px"
                        />
                        <DownloadIcon
                          {...props}
                          // i={i}
                          image={form?.profileURL}
                          name={form?.productName + " productlogo"}
                        />
                      </>
                    ) : (
                      <div>No Preview Available</div>
                    )}
                  </div> */}
                  <div
                    style={{
                      height: "200px",
                      marginBottom: "15px",
                      // margin: "0 auto",
                      // maxWidth: 64,
                      // width: "100%",
                    }}
                  >
                    <QRCode
                      size={256}
                      style={{ height: "200px" }}
                      value={
                        form?.productOwner === "tantuvi by smriti morarka"
                          ? process.env.REACT_APP_DEV_URL +
                            `/product-certification-tantuvi-new/${ProductId}`
                          : process.env.REACT_APP_DEV_URL +
                            `/product-verifing/${ProductId}`
                      }
                      viewBox={`0 0 556 556`}
                      level="L"
                    />
                  </div>
                </div>
                <div className="">
                  <div className="imageDiv1">
                    {form?.profileURL?.length > 0 ? (
                      <div className="d-flex">
                        <div className="PreviewDivPart">
                          {form?.profileURL?.length > 0 &&
                            form?.profileURL.map((item: any, i: any) => {
                              return (
                                <React.Fragment key={i}>
                                  <div className="ArryyIcon">
                                    <DownloadIcon
                                      {...props}
                                      i={i}
                                      item={item}
                                      name={form?.productName + " logo"}
                                    />
                                    {item?.show === true ? (
                                      <ShowIcon index={i} name="logo" />
                                    ) : (
                                      <HideIcon index={i} name="logo" />
                                    )}
                                    {item?.pin === false ? (
                                      <Pin index={i} name="view" />
                                    ) : (
                                      <Unpin index={i} name="view" />
                                    )}
                                    {/* <div className="viewIconForPreview">
                                      <ViewEyeIcon
                                        {...props}
                                        onClick={() => {
                                          handleOpen();
                                          setImage(item);
                                        }}
                                      />
                                    </div> */}
                                    <FilePreviewPart
                                      {...props}
                                      item={item}
                                      handleOpen={handleOpen}
                                      handleSetImage={handleSetImage}
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div className="PreIm">
                        {" "}
                        No Preview Images or Files Available for logo
                      </div>
                    )}
                  </div>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label> SrNo</Label>
                      <Input name="SrNo" value={form?.SrNo} readOnly />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Artform</Label>
                      <Input
                        name="artform"
                        id="artform"
                        value={form?.artform}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Type</Label>
                      <Input
                        name="type"
                        id="type"
                        value={form?.type}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Category</Label>
                      <Input
                        name="Category"
                        id="Category"
                        value={form?.category}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Sub-Category</Label>
                      <Input
                        name="subCategory"
                        id="subCategory"
                        value={form?.subCategory}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Weave Type</Label>
                      <Input
                        name="weaveType"
                        id="weaveType"
                        value={form?.weaveType}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Types of sarees by design</Label>
                      <Input
                        className="form-control"
                        placeholder="Types of sarees by design"
                        value={form?.typeOfSareeByDesign}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Product</Label>
                      <Input
                        name="productName"
                        id="productName"
                        className="form-control"
                        placeholder="productName"
                        value={form?.productName}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Color of product</Label>
                      <Input
                        name="productName"
                        id="productName"
                        className="form-control"
                        placeholder="productName"
                        value={form?.color}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Weaving Center</Label>
                      <Input
                        className="form-control"
                        placeholder="Weaving Center"
                        value={form?.weavingCenter}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Add Tags</Label>
                      <ReactChipInput
                        {...props}
                        classes="class1 class2"
                        chips={form.tags}
                        disabled={true}
                        onSubmit={(value: any) => {}}
                        onRemove={(index: any) => {}}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Name of Brand</Label>
                      <Input
                        name="nameOfBrand"
                        id="nameOfBrand"
                        className="form-control"
                        placeholder="Name Of Brand"
                        value={form?.nameOfBrand}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Name of weaver</Label>
                      <Input
                        name="nameOfWeaver"
                        id="nameOfWeaver"
                        className="form-control"
                        placeholder="Name Of Weaver"
                        value={form?.nameOfWeaver}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Zari Used</Label>
                      <div className="formDivSub">
                        <Input
                          className="form-control"
                          placeholder="Zari Used"
                          value={form?.zariUsed?.label}
                          readOnly
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Weight of Saree(in Grams)</Label>
                      <Input
                        name="weightOfSaree"
                        id="weightOfSaree"
                        className="form-control"
                        placeholder="Weight Of Saree"
                        value={form?.weightOfSaree}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Weight of Zari used</Label>
                      <Input
                        type="text"
                        name="weightOfZariUsed"
                        id="weightOfZariUsed"
                        className="form-control"
                        placeholder="Weight of Zari used"
                        value={form?.weightOfZariUsed}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Purity of Zari</Label>
                      <Input
                        type="textarea"
                        name="purityOfZari"
                        id="purityOfZari"
                        className="form-control"
                        placeholder="Purity Of Zari"
                        value={form?.zariUsed?.value}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Yarn type </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Yarn type"
                        value={form?.yarnType}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Time Taken in months </Label>
                      <Input
                        className="form-control"
                        placeholder="Time Taken in months"
                        value={form?.productionTimeInMonths}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Working Hours </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Working Hours"
                        value={form?.workingHours}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Loom Number </Label>
                      <Input
                        className="form-control"
                        placeholder="Loom Number"
                        value={form?.loomNum}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>HKP TagID </Label>
                      <Input
                        className="form-control"
                        placeholder="HKP TagID"
                        value={form?.xuid}
                        readOnly
                      />
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                      <Label>Silk Mark</Label>
                      <FormGroup>
                        <Toggle
                          size="lg"
                          defaultChecked={true}
                          checked={form?.silkMark}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                        />{" "}
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                      <Label>Handloom Mark</Label>
                      <FormGroup>
                        <Toggle
                          size="lg"
                          defaultChecked={true}
                          checked={form?.handloomMark}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                      <Label>Made in India</Label>
                      <FormGroup>
                        <Toggle
                          size="lg"
                          defaultChecked={true}
                          checked={form?.madeInIndia}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="UserDetailsDiv mb-5">
              <div className="UserHeader headerDisplay">
                <div>Designing</div>
              </div>
              <div className="userPd">
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Artisan</Label>
                      <Input
                        name="userRole"
                        id="userRole"
                        value={form?.designer}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
                <Label>Previewing files or images</Label>
                {/* <span className="ms-2">
                  Photo (jpg, png, gif) / Video (not more that 10mb)/ PDF (not
                  more that 5mb)
                </span> */}
                <div className="">
                  <div className="imageDiv1">
                    {form?.designerMedia?.length > 0 ? (
                      <div className="d-flex">
                        <div className="PreviewDivPart">
                          {form?.designerMedia?.length > 0 &&
                            form?.designerMedia.map((item: any, i: any) => {
                              return (
                                <React.Fragment key={i}>
                                  <div
                                    className="ArryyIcon"
                                    // onClick={() => {
                                    //   handleOpen();
                                    //   setImage(item);
                                    // }}
                                  >
                                    <DownloadIcon
                                      {...props}
                                      i={i}
                                      item={item}
                                      name={form?.productName + " designing"}
                                    />
                                    {item?.show === true ? (
                                      <ShowIcon index={i} name="designing" />
                                    ) : (
                                      <HideIcon index={i} name="designing" />
                                    )}
                                    {/* <div className="viewIconForPreview">
                                      <ViewEyeIcon
                                        {...props}
                                        onClick={() => {
                                          handleOpen();
                                          setImage(item);
                                        }}
                                      />
                                    </div> */}
                                    <FilePreviewPart
                                      {...props}
                                      item={item}
                                      handleOpen={handleOpen}
                                      handleSetImage={handleSetImage}
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div className="PreIm">
                        {" "}
                        No Preview Images or Files Available for Designing
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="UserHeader headerDisplay">
                <div>Dying</div>
              </div>
              <div className="userPd">
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Karigar</Label>
                      <Input name="userRole" id="userRole" value={form?.dyer} />
                    </Col>
                  </Row>
                </div>
                <Label>Previewing files or images</Label>
                {/* <span className="ms-2">
                  Photo (jpg, png, gif) / Video (not more that 10mb)/ PDF (not
                  more that 5mb)
                </span> */}
                <div className="d-flex justify-content-center">
                  <div className="imageDiv1">
                    {form?.dyerMedia?.length > 0 ? (
                      <div className="d-flex">
                        <div className="PreviewDivPart">
                          {form?.dyerMedia?.length > 0 ? (
                            form?.dyerMedia.map((item: any, i: any) => {
                              return (
                                <React.Fragment key={i}>
                                  <div className="ArryyIcon">
                                    <DownloadIcon
                                      {...props}
                                      i={i}
                                      item={item}
                                      name={form?.productName + " dying"}
                                    />
                                    {item?.show === true ? (
                                      <ShowIcon index={i} name="dying" />
                                    ) : (
                                      <HideIcon index={i} name="dying" />
                                    )}
                                    {/* <div className="viewIconForPreview">
                                      <ViewEyeIcon
                                        {...props}
                                        onClick={() => {
                                          handleOpen();
                                          setImage(item);
                                        }}
                                      />
                                    </div> */}
                                    <FilePreviewPart
                                      {...props}
                                      item={item}
                                      handleOpen={handleOpen}
                                      handleSetImage={handleSetImage}
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            })
                          ) : (
                            <span>Dying images or files</span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="PreIm">
                        {" "}
                        No Preview Images or Files Available for Dying
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="UserHeader headerDisplay">
                <div>Weaving</div>
              </div>
              <div className="userPd">
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Karigar</Label>
                      <Input name="weaver" id="weaver" value={form?.weaver} />
                    </Col>
                  </Row>
                </div>
                <Label>Previewing files or images</Label>
                {/* <span className="ms-2">
                  Photo (jpg, png, gif) / Video (not more that 10mb)/ PDF (not
                  more that 5mb)
                </span> */}
                <div className="">
                  <div className="imageDiv1">
                    {form?.weaverMedia?.length > 0 ? (
                      <div className="d-flex">
                        <div
                          className="PreviewDivPart"
                          style={{
                            height:
                              form?.weaverMedia?.length > 0 ? " " : "200px",
                          }}
                        >
                          {form?.weaverMedia?.length > 0 &&
                            form?.weaverMedia.map((item: any, i: any) => {
                              return (
                                <React.Fragment key={i}>
                                  <div className="ArryyIcon">
                                    <DownloadIcon
                                      {...props}
                                      i={i}
                                      item={item}
                                      name={form?.productName + " weaving"}
                                    />
                                    {item?.show === true ? (
                                      <ShowIcon index={i} name="weaving" />
                                    ) : (
                                      <HideIcon index={i} name="weaving" />
                                    )}
                                    {/* <div className="viewIconForPreview">
                                      <ViewEyeIcon
                                        {...props}
                                        onClick={() => {
                                          handleOpen();
                                          setImage(item);
                                        }}
                                      />
                                    </div> */}
                                    <FilePreviewPart
                                      {...props}
                                      item={item}
                                      handleOpen={handleOpen}
                                      handleSetImage={handleSetImage}
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div className="PreIm">
                        {" "}
                        No Preview Images or Files Available for Weaving
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="UserHeader headerDisplay">
                <div>Finishing</div>
              </div>
              <div className="userPd">
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Karigar</Label>
                      <Input
                        name="userRole"
                        id="userRole"
                        value={form?.finisher}
                      />
                    </Col>
                  </Row>
                </div>
                <Label>Previewing files or images</Label>
                {/* <span className="ms-2">
                  Photo (jpg, png, gif) / Video (not more that 10mb)/ PDF (not
                  more that 5mb)
                </span> */}
                <div className="d-flex justify-content-center">
                  <div className="imageDiv1">
                    {form?.finisherMedia?.length > 0 ? (
                      <div className="d-flex">
                        <div className="PreviewDivPart">
                          {form?.finisherMedia?.length > 0 ? (
                            form?.finisherMedia.map((item: any, i: any) => {
                              return (
                                <React.Fragment key={i}>
                                  <div className="ArryyIcon">
                                    <DownloadIcon
                                      {...props}
                                      i={i}
                                      item={item}
                                      name={form?.productName + " finishing"}
                                    />
                                    {item?.show === true ? (
                                      <ShowIcon index={i} name="finishing" />
                                    ) : (
                                      <HideIcon index={i} name="finishing" />
                                    )}
                                    {/* <div className="viewIconForPreview">
                                      <ViewEyeIcon
                                        {...props}
                                        onClick={() => {
                                          handleOpen();
                                          setImage(item);
                                        }}
                                      />
                                    </div> */}
                                    <FilePreviewPart
                                      {...props}
                                      item={item}
                                      handleOpen={handleOpen}
                                      handleSetImage={handleSetImage}
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            })
                          ) : (
                            <span>Finishing images or files</span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="PreIm">
                        {" "}
                        No Preview Images or Files Available for Finishing
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="UserDetailsDiv mb-5">
                <div className="UserHeader headerDisplay">
                  <div>Variants</div>
                </div>
                <div className="userPd">
                  {form?.variants?.length > 0 ? (
                    form?.variants.map((ele: any, i: any) => (
                      <React.Fragment key={i}>
                        <div className="formDiv12">
                          Color:<span>{ele.color}</span>
                        </div>
                        <Label>Previewing images or files</Label>

                        <div className="">
                          <div className="imageDiv1">
                            <div className="d-flex">
                              <div className="PreviewDivPart">
                                {ele?.additionalImages?.length > 0 &&
                                  ele?.additionalImages.map(
                                    (item: any, i: any) => {
                                      return (
                                        <React.Fragment key={i}>
                                          <div className="ArryyIcon">
                                            <DownloadIcon
                                              {...props}
                                              // i={i}
                                              image={item}
                                              name={
                                                form?.productName + " variant"
                                              }
                                              placePosition={"first"}
                                            />
                                            <div
                                              onClick={() => {
                                                handleOpen();
                                                handleSetImage(item);
                                              }}
                                            >
                                              <FilePreview
                                                fileUrl={item}
                                                height={200}
                                                width={180}
                                              />
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))
                  ) : (
                    <div className="PreIm">
                      {" "}
                      No Preview Images or Files Available for Designing
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  getProducts,
})(EditProduct);
