import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import jwt from "jwt-decode";
// components
import SignUPSignInCommonForm from "./SignUPSignInCommonForm";
import notifications from "../../common/Components/Notifications/Notification";
// images
import LogoBanner from "../../assets/images/login.png";

// store
import { checkLoginUser } from "../../Store/auth/login/actions";
const Login = (props: any) => {
  const history = useHistory();
  const useLoginData: any = localStorage.getItem("UsersLoginDeatils" || "");
  const userLoginDetailsData: any = JSON.parse(useLoginData);
  const rememberMe: any = localStorage.getItem("rememberMe");
  const [response, setResponse]: any = useState("");
  const [value, setValues]: any = useState({
    email: userLoginDetailsData !== null ? userLoginDetailsData?.email : "",
    password:
      userLoginDetailsData !== null ? userLoginDetailsData?.password : "",
  });

  const [Errors, setErrors]: any = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading]: any = useState(false);
  const [remember, setRememberMe]: any = useState<any>(rememberMe || false);

  const validate = () => {
    let error: any = "";
    if (value.password === "") {
      error = "Required";
      setErrors({
        ...Errors,
        password: "Password is required",
      });
    }
    if (value.email === "") {
      error = "Required";
      setErrors({
        ...Errors,
        email: "Email Or Phone is required",
      });
    }

    return error;
  };
  const handleOnChange = (type: any) => {
    setValues({
      ...value,
      [type.name]: type.value,
    });
  };
  const handleSubmit = () => {
    localStorage.removeItem("response");
    let result = validate();
    if (result?.length === 0) {
      setLoading(true);
      props.checkLoginUser(
        {
          emailOrPhone: value?.email.toLowerCase().trim(),
          password: value?.password,
        },
        (res: any) => {
          setLoading(false);
          setResponse(res?.message);
          if (res.success) {
            let authUser: any = jwt(res?.data?.token);
            localStorage.setItem("authUser", JSON.stringify(authUser));
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("rememberMe", remember);
            if (remember) {
              localStorage.setItem("UsersLoginDeatils", JSON.stringify(value));
            }

            notifications({
              title: "Success!",
              message: res?.message,
              type: "success",
            });
            history.push("/dashboard");
          } else {
            // notifications({
            //   title: "Error!",
            //   message: res?.message,
            //   type: "danger",
            // });
          }
        }
      );
      // localStorage.setItem("authUser",JSON.stringify(value?.email))
    }
  };
  const handleChangedChecked = (data: any) => {
    setRememberMe(data);
    localStorage.setItem("rememberMe", data);

    if (data === true) {
      localStorage.setItem("UsersLoginDeatils", JSON.stringify(value));
    }
    if (data === false) {
      localStorage.removeItem("UsersLoginDeatils");
    }
  };
  return (
    <>
      <Grid container spacing={0} className="LoginpageNew">
        <Grid item xs={12} md={6} lg={6} className="LoginpageGrid ">
          <SignUPSignInCommonForm
            {...props}
            value={value}
            Errors={Errors}
            handleOnChange={handleOnChange}
            handleSubmit={handleSubmit}
            loading={loading}
            handleChangedChecked={handleChangedChecked}
            remember={remember}
            response={response}
          />
        </Grid>
        <Grid item xs={1} md={6} lg={6} className="displayDesktop">
          <img
            className="logoBanner"
            src={LogoBanner}
            width="100%"
            height={"900px"}
            alt="LogoBanner"
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  checkLoginUser,
})(Login);
