import { types } from "./actionTypes";
const initialState = {
  getWeaveTypeDetails: null,
  updateWeaveTypeDetails: null,
  createWeaveTypeDetails: null,
  deleteWeaveTypeDetails: null,
  getWeaveTypeFilterDetails: null,
};
const weaveTypeReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.GET_WEAVE_TYPE_SUCCESS:
      state = {
        ...state,
        getWeaveTypeDetails: action.payload,
      };
      return state;
    case types.GET_WEAVE_TYPE_FILTER_SUCCESS:
      state = {
        ...state,
        getWeaveTypeFilterDetails: action.payload,
      };
      return state;
    case types.CREATE_WEAVE_TYPE_SUCCESS:
      state = {
        ...state,
        createWeaveTypeDetails: action.payload,
      };
      return state;
    case types.UPDATE_WEAVE_TYPE_SUCCESS:
      state = {
        ...state,
        updateWeaveTypeDetails: action.payload,
      };
      return state;
    case types.DELETE_WEAVE_TYPE_SUCCESS:
      state = {
        ...state,
        deleteWeaveTypeDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default weaveTypeReducer;
