import React from "react";
import HkvBadge from "./assets/images/HKP Badge.png";

const ExpiryLinkPage = (props: any) => {
  return (
    <div className="productfakeAlert mt-5">
      <div className="fakealDiv">
        <img src={HkvBadge} alt="Fake Alert" />
      </div>
      <div className="fakealDivE">
        <h4>
          Product link is expired. <br />
          please tap <br />
          on HKP tag again
        </h4>
      </div>
      <div className="fakealDivE">
        <p>
          {" "}
          Our linkes are expired for <br />
          security purpose
        </p>
      </div>
    </div>
  );
};

export default ExpiryLinkPage;
