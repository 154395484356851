import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import { AxiosResponse } from "axios";
//medthods
import { getApi } from "../../helpers/auth_helpers";

// workers
function* workerDashboard({ callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/dashboard/dashboardwidgets";

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_DASHBOARD_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchDashboard() {
  yield takeEvery(types.GET_DASHBOARD, workerDashboard);
}

function* dashboardSaga() {
  yield all([fork(watchDashboard)]);
}
export default dashboardSaga;
